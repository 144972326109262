<template>
    <div :class="id" uk-modal="bg-close: false">
        <div class="uk-modal-dialog uk-modal-body">
            <slot></slot>
        </div>
    </div>
</template>
<script>
import UIkit from 'uikit/dist/js/uikit'

export default {
    data() {
        return {
            id: 0,
        }
    },
    created() {
        // The odds of a collision is very low
        this.id = Math.random().toString() + Math.random().toString() + Math.random().toString()
    },
    methods: {
        cleanup() {
            const otpModalList = document.getElementsByClassName(this.id)
            for (let i = 1; i < otpModalList.length; i++) {
                otpModalList[i].parentNode.removeChild(otpModalList[i])
            }
        },
        show() {
            this.cleanup()
            const otpModalList = document.getElementsByClassName(this.id)
            UIkit.modal(otpModalList[0]).show()
        },
        hide() {
            this.cleanup()
            const otpModalList = document.getElementsByClassName(this.id)
            UIkit.modal(otpModalList[0]).hide()
        },
    },
}
</script>

<style>
.uk-modal-dialog {
    border-radius: 1rem;
}
</style>
