<template>
    <div class="uk-margin-bottom">
        <div class="uk-card uk-card-default">
            <span @click="$store.commit('txCreate/removeAllocation', index)" v-if="index > 1" class="delete">
                <GL-Icon path="trash.svg" />
            </span>
            <div class="uk-card-body">
                <Stepper
                    :step="currentStep"
                    @select="setStep"
                    :completed="lastCompletedStep"
                    :success="milestoneDetailsCompleted"
                >
                    <Step
                        v-for="(step, stepNum) in steps"
                        :step="stepNum + 1"
                        :name="step.name"
                        :key="step.name"
                        :is-completed="lastCompletedStep > stepNum + 1"
                    >
                        <component
                            :is="step.component"
                            :index="index"
                            @complete-step="completeStep"
                            @view-previous="previousStep"
                        />
                    </Step>
                </Stepper>
            </div>
        </div>
    </div>
</template>

<script>
import Stepper from '@/modules/common/components/stepper'
import Step from '@/modules/common/components/step'
import MilestoneDetails from './milestone-details'
import DriverDetails from './driver-details'
import MilestoneSummary from './milestone-summary'
import UpliftmentLocation from './milestone-upliftment-location'
import DeliveryInstructions from './delivery-instructions'

export default {
    name: 'FuelMilestoneCardCOD',
    components: { Step, Stepper },
    props: {
        index: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            steps: [
                {
                    name: 'Load Details',
                    component: MilestoneDetails,
                },
                {
                    name: 'Upliftment Location',
                    component: UpliftmentLocation,
                },
                {
                    name: 'Delivery Instructions',
                    component: DeliveryInstructions,
                },
                {
                    name: 'Driver Details',
                    component: DriverDetails,
                },
                {
                    name: 'Summary',
                    component: MilestoneSummary,
                },
            ],
            currentStep: 1,
            lastCompletedStep: 0,
        }
    },
    computed: {
        milestoneDetailsCompleted() {
            return this.lastCompletedStep >= this.steps.length - 1
        },
        removable() {
            return this.index !== 0
        },
    },
    mounted() {
        this.initSteps()
    },
    methods: {
        setStep(value) {
            this.currentStep = value
        },
        previousStep() {
            this.currentStep -= 1
        },
        completeStep() {
            this.currentStep += 1
            if (this.lastCompletedStep === this.currentStep - 2) {
                this.lastCompletedStep += 1
            }
            if (this.currentStep === this.steps.length) {
                this.lastCompletedStep = this.currentStep
            }
        },
        initSteps() {
            if (
                this.$store.state.txCreate.allocations.auxiliaryData[this.index].fuelDriverName &&
                this.$store.state.txCreate.allocations.auxiliaryData[this.index].fuelVehicleRegistration
            ) {
                this.lastCompletedStep = 5
                this.currentStep = 5
            }
        },
    },
}
</script>

<style scoped>
.delete {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 100;
}
.delete:hover {
    cursor: pointer;
    color: black;
}
</style>
