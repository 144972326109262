<template>
    <div class="uk-padding-small">
        <h4 class="uk-text-center uk-text-secondary">IS CONFIDENTIALITY REQUIRED FOR THIS TRANSACTION?</h4>
        <div>
            <GL-SwitchInput
                v-model="confidentialIdentities"
                text="Hide the identities of the parties from one another"
                tooltip="Selecting this option means that the identities of the transacting parties will not be disclosed to each other. Their identities will be displayed as 'CONFIDENTIAL'. There is no risk of circumvention when using TradeSafe."
            />
            <GL-SwitchInput
                v-model="confidentialFunds"
                text="Hide your commission or profit from the other parties"
                tooltip="Selecting this option means that your commission and profit will not be disclosed to the other parties. Also, the seller will not know the buyers deposit amount and the buyer will not know what the seller is getting paid."
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'ConfidentialityToggles',
    methods: {
        privacyToEnum(confidentialIdentities, confidentialFunds) {
            if (confidentialIdentities && confidentialFunds) {
                return 'ALL'
            }
            if (confidentialIdentities) {
                return 'DETAILS'
            }
            if (confidentialFunds) {
                return 'CALCULATIONS'
            }
            return 'NONE'
        },
        confidentialIdentitiesFromEnum(privacy) {
            if (privacy === 'ALL') {
                return true
            }
            if (privacy === 'DETAILS') {
                return true
            }
            return false
        },
        confidentialFundsFromEnum(privacy) {
            if (privacy === 'ALL') {
                return true
            }
            if (privacy === 'CALCULATIONS') {
                return true
            }
            return false
        },
    },
    computed: {
        confidentialIdentities: {
            get() {
                return this.confidentialIdentitiesFromEnum(this.$store.state.txCreate.transaction.transaction.privacy)
            },
            set(value) {
                const privacy = this.privacyToEnum(value, this.confidentialFunds)
                this.$store.commit('txCreate/setTransactionField', {
                    key: 'privacy',
                    value: privacy,
                })
            },
        },
        confidentialFunds: {
            get() {
                return this.confidentialFundsFromEnum(this.$store.state.txCreate.transaction.transaction.privacy)
            },
            set(value) {
                const privacy = this.privacyToEnum(this.confidentialIdentities, value)
                this.$store.commit('txCreate/setTransactionField', {
                    key: 'privacy',
                    value: privacy,
                })
            },
        },
    },
}
</script>
