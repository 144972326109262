<template>
    <div
        @click="cardSelect"
        class="card uk-position-relative"
        :class="{ selected: selected, selectable: !disabled, large: large }"
        :uk-tooltip="tooltip"
    >
        <div class="disabled-text" v-if="disabled && disabledText">
            {{ disabledText }}
        </div>
        <div class="uk-flex uk-flex-middle uk-height-1-1">
            <div class="uk-width-2-5">
                <div class="uk-text-center">
                    <div class="icon">
                        <img class="icon" style="width: 2.5rem; height: 2.5rem;" :src="'/icons/' + iconPath" uk-svg />
                    </div>
                    <div class="text">{{ text }}</div>
                </div>
            </div>
            <div class="uk-width-expand">
                <div>
                    <div class="uk-padding-small uk-padding-remove-left" :class="{ description: !large }">
                        {{ description }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CardButtonWideTransactionSetup',
    props: {
        text: {
            type: String,
            default: '',
        },
        large: {
            type: Boolean,
            default: false,
        },
        iconPath: {
            type: String,
            default: 'times.svg',
        },
        value: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            default: '',
        },
        iconSize: {
            type: Number,
            default: 3,
        },
        selected: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        disabledText: {
            type: String,
            default: '',
        },
    },
    computed: {
        tooltip() {
            if (this.disabled && this.value === 'Fuel') {
                return 'Your profile has not been verified to create fuel transactions. Please notify us that you want to create fuel transactions by turning on the "DIESEL, PETROLEUM AND LUBRICATING OILS" toggle in the profile page and submitting the necessary documents.'
            } else if (
                this.disabled &&
                (this.value === 'STANDARD' || this.value === 'MILESTONE' || this.value === 'DRAWDOWN')
            ) {
                return 'We do not support changing the transaction type once the transaction has been created. If you wish to change this value please create a new transaction'
            }
            return ''
        },
    },
    methods: {
        cardSelect() {
            if (!this.disabled) {
                this.$emit('select', this.value)
            }
        },
    },
}
</script>

<style scoped>
.card {
    color: #9fa3a9;
    width: 22rem;
    height: 6rem;
    border-style: solid;
    border-width: 1px;
    border-color: #e2e2e4;
    border-radius: 0.5rem;
    transition-duration: var(--transition-normal);
}
.card.large {
    height: 7rem;
}
.icon {
    display: inline-block;
    margin: 0.5rem;
    margin-top: 1rem;
}
.text {
    font-size: 0.9rem;
    text-transform: uppercase;
}
.description-outer {
    display: flex;
    justify-content: center;
    height: 100%;
    margin-top: 1rem;
}
.description {
    /*padding-top: 1rem;*/
    padding-right: 1rem;
    font-size: 0.9rem;
}
.card.selectable:hover {
    cursor: pointer;
    -webkit-box-shadow: 10px 10px 20px -16px rgba(118, 118, 118, 1);
    -moz-box-shadow: 10px 10px 20px -16px rgba(118, 118, 118, 1);
    box-shadow: 10px 10px 20px -16px rgba(118, 118, 118, 1);
}
.selected {
    color: white;
    background-color: #e50045;
    border-color: #e50045;
}
.disabled-text {
    position: absolute;
    right: -1px;
    bottom: -1px;
    background-color: #e50045;
    color: white;
    padding: 2px;
    font-size: 0.8rem;
    border-radius: 0 0 0.5rem 0;
}
</style>
