<template>
    <div class="uk-margin-top">
        <h3 class="uk-text-center uk-text-secondary">Specify the Details of Each Load</h3>
        <div class="uk-flex uk-flex-center uk-margin-small-right uk-margin-small-left">
            <div class="uk-animation-slide-top-small uk-width-1-1">
                <component
                    :is="milestoneCard"
                    v-for="(allocation, index) in allocations"
                    :key="index"
                    :index="index"
                    class="uk-animation-slide-right-small"
                    :allocation="allocation"
                />
                <div class="uk-text-center uk-padding uk-padding-remove-bottom">
                    <GL-Button type="primary" text="Add Load" @click="$store.commit('txCreate/addAllocation')" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FuelMilestoneCardCOCNoAddress from './milestone-cards/milestones-delivered-to-same-address/milestone-card-coc-same-address'
import FuelMilestoneCardCOCAddress from './milestone-cards/milestones-delivered-to-unique-addresses/milestone-card-coc-unique-address'
import FuelMilestoneCardCODNoAddress from './milestone-cards/milestones-delivered-to-same-address/milestone-card-cod-same-address'
import FuelMilestoneCardCODAddress from './milestone-cards/milestones-delivered-to-unique-addresses/milestone-card-cod-unique-address'

export default {
    name: 'FuelMilestones',
    computed: {
        allocations() {
            return this.$store.state.txCreate.allocations.allocations
        },
        milestonesToSameAddress() {
            return this.$store.state.txCreate.transaction.auxiliaryData.milestonesToSameAddress
        },
        fuelTransactionType() {
            return this.$store.state.txCreate.transaction.auxiliaryData.fuelTransactionType
        },
        milestoneCard() {
            if (this.fuelTransactionType === 'COC') {
                if (this.milestonesToSameAddress) {
                    return FuelMilestoneCardCOCNoAddress
                }
                return FuelMilestoneCardCOCAddress
                // return FuelMilestoneCardCODAddress
            } else if (this.fuelTransactionType === 'COD') {
                if (this.milestonesToSameAddress) {
                    return FuelMilestoneCardCODNoAddress
                }
                return FuelMilestoneCardCODAddress
            }
            return null
        },
    },
    methods: {
        addAllocation() {
            this.$store.commit('txCreate/addAllocation')
        },
    },
}
</script>
