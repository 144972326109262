import {InMemoryCache} from 'apollo-cache-inmemory'
import {ApolloClient} from 'apollo-client'
import {ApolloLink} from 'apollo-link'
import {createUploadLink} from 'apollo-upload-client'
import {onError} from 'apollo-link-error'

export default class ApolloClientWrapper {
    constructor(graphUrl, csrfTokenURL = '/api/xsrf-token') {
        const _this = this

        const getCookieValue = (name) => (
            document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || ''
        )

        let token = getCookieValue('XSRF-TOKEN')

        if (token === '') {
            try {
                let xmlHttp = new XMLHttpRequest()
                xmlHttp.open('GET', csrfTokenURL, false) // false for synchronous request
                xmlHttp.send(null)
                const xsrf = JSON.parse(xmlHttp.responseText)
                token = xsrf.token ?? ''
            } catch (e) {
                console.log(e)
            }
        }

        const options = {
            uri: graphUrl,
            credentials: 'include',
            headers: {
                'X-CSRF-TOKEN': token,
            },
        }

        // Allows file uploads
        const uploadLink = createUploadLink(options)

        // Captures graphQL errors
        const errorLink = onError(({graphQLErrors, networkError}) => {
            _this.errorHandler({
                networkError: networkError,
                graphQLErrors: graphQLErrors,
            })
        })

        // Composites links
        const compositedLink = ApolloLink.from([errorLink, uploadLink])

        const client = new ApolloClient({
            link: compositedLink,
            cache: new InMemoryCache(),
            defaultOptions: {
                watchQuery: {
                    fetchPolicy: 'no-cache',
                },
                query: {
                    fetchPolicy: 'no-cache',
                },
            },
        })

        this.client = client
    }

    errorHandler = function (errors) {
        console.error('Error handler not set')
        console.log(errors)
    }

    getClient() {
        return this.client
    }
}
