<template>
    <div
        @click="navigateTransaction"
        class="uk-card uk-card-default uk-card-body"
        :class="{ 'uk-box-shadow-hover-large': !disabled }"
        :disabled="disabled"
    >
        <div class="uk-padding-small">
            <div class="uk-flex uk-flex-left">
                <div class="uk-flex uk-flex-middle uk-visible@s">
                    <IndustryIcon :transaction="transaction" />
                    <div class="uk-divider-vertical"></div>
                </div>
                <div>
                    <h4 class="uk-margin-remove ellipsis">{{ transaction.title }}</h4>
                    <div class="uk-flex uk-flex-left uk-flex-wrap">
                        <div class="info-item">
                            <GL-InfoItem
                                label="Transaction Value"
                                prefix="R "
                                :value="transaction.calculation.baseValue | currency"
                            />
                        </div>
                        <div class="info-item">
                            <GL-InfoItem label="Date Created" :value="transaction.createdAt | longDate" />
                        </div>
                    </div>
                    <div class="uk-flex uk-flex-left uk-flex-wrap">
                        <div class="info-item">
                            <GL-InfoItem label="Status" :value="transaction | transactionStateDisplay" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="pg-back">
            <div class="pg-inner animated fadeInLeft" :style="progress"></div>
        </div>
    </div>
</template>

<script>
import IndustryIcon from '../../common/components/industry-icon'

export default {
    name: 'TransactionCard',
    components: { IndustryIcon },
    props: {
        transaction: {
            type: Object,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        navigateTransaction() {
            if (this.disabled) {
                return
            }
            this.$router.push('/transaction/' + this.transaction.id)
        },
    },
    computed: {
        stateNumber() {
            const stateToNumber = {
                INVITATION_SENT: 1,
                ACCEPTED: 2,
                FUNDS_DEPOSITED: 2,
                FUNDS_RECEIVED: 3,
                INITIATED: 4,
                DELIVERED: 5,
                FUNDS_RELEASED: 6,
            }
            return stateToNumber[this.transaction.state]
        },
        progress() {
            return {
                width: ((this.stateNumber / 6) * 100).toString() + '%',
            }
        },
    },
}
</script>

<style scoped>
.ellipsis {
    display: inline-block;
    width: calc(90%);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.info-item {
    padding: 0.25rem;
    padding-left: 1rem;
    padding-right: 1rem;
}
.pg-back {
    position: absolute;
    bottom: 0rem;
    height: 0.4rem;
    width: 100%;
    background-color: lightgrey;
}
.pg-inner {
    height: 100%;
    width: 0%;
    background-color: #e50045;
    border-radius: 5rem;
}
.uk-box-shadow-hover-large {
    cursor: pointer;
}
.uk-divider-vertical {
    margin: 0.1rem;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
}
.uk-card {
    border-radius: 0.5rem;
    overflow: hidden;
}
.uk-card-default {
    margin: 0.5rem;
    padding: 0rem;
    padding-bottom: 0rem;
}
.uk-card-default[disabled] {
    opacity: 0.5;
}
</style>
