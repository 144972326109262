export const categories = [
    {
        value: 'Phones & Tablets',
        key: 'PHONES',
    },
    {
        value: 'Computers & Laptops',
        key: 'COMPUTERS',
    },
    {
        value: 'Other electronics',
        key: 'OTHER',
    },
]

export const phoneBrands = [
    {
        name: 'Apple',
        icon: 'apple-brands.svg',
    },
    {
        name: 'Samsung',
        icon: 'samsung.svg',
    },
    {
        name: 'Huawei',
        icon: 'huawei-flat.svg',
    },
    {
        name: 'Nokia',
        icon: 'nokia.svg',
    },
    {
        name: 'Google',
        icon: 'google.svg',
    },
    {
        name: 'Microsoft',
        icon: 'microsoft.svg',
    },
    {
        name: 'Sony',
        icon: 'sony.svg',
    },
    {
        name: 'LG',
        icon: 'lg.svg',
    },
    {
        name: 'Xiaomi',
        icon: 'xiaomi.svg',
    },
    {
        name: 'Other',
        icon: 'mobile-alt-solid.svg',
    },
]

export const computerBrands = [
    {
        name: 'Apple',
        icon: 'apple-brands.svg',
    },
    {
        name: 'Dell',
        icon: 'dell.svg',
    },
    {
        name: 'HP',
        icon: 'hp.svg',
    },
    {
        name: 'Lenovo',
        icon: 'lenovo.svg',
    },
    {
        name: 'Microsoft',
        icon: 'microsoft.svg',
    },
    {
        name: 'Asus',
        icon: 'asus.svg',
    },
    {
        name: 'Sony',
        icon: 'sony.svg',
    },
    {
        name: 'Samsung',
        icon: 'samsung.svg',
    },
    {
        name: 'Acer',
        icon: 'acer.svg',
    },
    {
        name: 'Other',
        icon: 'desktop-solid.svg',
    },
]
