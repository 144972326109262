var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ModalBase',{ref:"modal"},[_c('div',{key:_vm.refreshCount},[_c('h4',[_vm._v(_vm._s(_vm.heading))]),_vm._l((_vm.contextList),function(context){return _c('div',{key:context.id,staticClass:"context-option uk-margin-small",class:{ active: _vm.activeContext === context.id },on:{"click":function($event){return _vm.selectContext(context.id)}}},[_c('div',{staticClass:"uk-padding-small"},[_c('div',{staticClass:"uk-flex uk-flex-between uk-flex-wrap"},[_c('div',[_c('img',{staticClass:"uk-border-circle dropdown-item-image",attrs:{"width":"32","height":"32","src":_vm._f("safeAvatarURL")(context.avatar)}}),_c('div',{staticClass:"uk-margin-small-left uk-inline context-option-text"},[_vm._v(" "+_vm._s(context.name)+" ")])]),_c('div',[_c('div',{staticClass:"uk-inline context-type-lable",class:{ active: _vm.activeContext === context.id }},[_vm._v(" "+_vm._s(context.type)+" ")])])])])])}),_c('GL-ErrorAlert',{attrs:{"errorMessage":_vm.error}}),_c('div',{staticClass:"uk-margin-top"},[_c('hr'),_c('div',{staticClass:"uk-flex uk-flex-right uk-flex-wrap-reverse"},[_c('GL-Button',{class:{
                        'uk-margin-small-right': _vm.$screenLarger('small'),
                        'uk-width-1-1': _vm.$screenSmaller('small'),
                    },attrs:{"text":"Cancel","type":"default","disabled":_vm.updateLoading,"disabled-text":"Cancel"},on:{"click":_vm.cancel}}),_c('GL-Button',{class:{
                        'uk-margin-small-right': _vm.$screenLarger('small'),
                        'uk-width-1-1': _vm.$screenSmaller('small'),
                        'uk-margin-small': _vm.$screenSmaller('small'),
                    },attrs:{"disabled":_vm.updateLoading || !_vm.contextFullyVerified,"loading":_vm.updateLoading,"disabled-text":"Done","tooltip":!_vm.contextFullyVerified && !_vm.updateLoading
                            ? 'Please complete the verification of this company / user before proceeding'
                            : null,"type":"primary","text":"Done","name":"select-context-done"},on:{"click":function($event){return _vm.submit()}}})],1)])],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }