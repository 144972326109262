<template>
    <div>
        <div class="uk-text-center uk-padding-small uk-margin">
            <h3 class="uk-text-secondary uk-margin-remove">Describe in detail what you are {{ userActionText }}</h3>
        </div>

        <div class="uk-flex uk-flex-center">
            <GL-SelectInput
                class="uk-width-1-2@m"
                name="category"
                label="Category"
                :option-list="categories"
                v-model="type"
            />
        </div>
        <div
            v-if="type !== 'OTHER'"
            class="uk-flex uk-flex-center uk-margin-large-top uk-animation-slide-right-small uk-width-1-1"
        >
            <div class="uk-width-3-5@m uk-width-4-5">
                <CardSelection
                    v-model="make"
                    @select="setMake"
                    :items="phoneBrands"
                    v-if="type === 'PHONES'"
                    class="uk-animation-slide-right-small"
                />
                <CardSelection
                    v-model="make"
                    @select="setMake"
                    :items="computerBrands"
                    v-if="type === 'COMPUTERS'"
                    class="uk-animation-slide-right-small"
                />
            </div>
        </div>

        <div
            v-if="type !== 'OTHER' && make"
            class="uk-flex uk-flex-center uk-margin-large-top uk-animation-slide-right-small"
        >
            <div class="uk-width-5-6@m uk-card uk-card-default uk-card-body">
                <div uk-grid>
                    <GL-TextInput
                        v-model="title"
                        class="uk-width-1-2@s uk-margin-small-top"
                        label="Transaction Name*"
                        tooltip="Give your transaction a name i.e. 2020 Galaxy S10 Gold in pristine condition. The transaction name can also include an invoice number or Purchase Order number."
                        :error-messages="validation.requiredField(title)"
                    />
                    <GL-TextInput
                        v-model="specifiedMake"
                        v-if="make === 'Other'"
                        class="uk-width-1-2@s uk-margin-small-top"
                        label="Brand*"
                        :error-messages="validation.requiredField(specifiedMake)"
                    />
                    <GL-TextInput
                        v-model="model"
                        class="uk-width-1-2@s uk-margin-small-top"
                        label="Device Model*"
                        :error-messages="validation.requiredField(model)"
                    />
                    <GL-TextInput
                        v-model="year"
                        numeric
                        right-align
                        class="uk-width-1-2@s uk-margin-small-top"
                        label="Year Of Manufacture"
                    />
                    <GL-TextInput
                        v-model="memory"
                        numeric
                        right-align
                        v-if="type !== 'OTHER'"
                        class="uk-width-1-4@m uk-width-1-2@s uk-margin-small-top"
                        :tooltip="storageToolTip"
                        label="Internal Storage*"
                        :error-messages="validation.requiredField(memory)"
                    />
                    <GL-SelectInput
                        v-model="memoryUnits"
                        :disabled="type === 'PHONES'"
                        v-if="type !== 'OTHER'"
                        class="uk-width-1-4@m uk-width-1-2@s uk-margin-small-top"
                        label="Storage Units*"
                        :option-list="memoryUnitsOptions"
                        :error-messages="validation.requiredField(memoryUnits)"
                    />
                    <GL-TextAreaInput
                        v-model="description"
                        class="uk-width-1-1 uk-margin-small-top"
                        label="Description*"
                        :error-messages="validation.requiredField(description)"
                    />
                    <GL-SelectInput
                        class="uk-width-1-2 uk-margin-small-top"
                        v-model="feeAllocation"
                        label="Who Will Pay The Escrow Fee?*"
                        :option-list="feeAllocationOptions"
                        :error-messages="validation.requiredField(feeAllocation)"
                    />
                    <div class="uk-width-1-2@s uk-visible@s"></div>
                </div>
            </div>
        </div>

        <div v-if="type === 'OTHER'" class="uk-flex uk-flex-center uk-margin-large-top uk-animation-slide-right-small">
            <div class="uk-width-5-6@m uk-card uk-card-default uk-card-body">
                <div uk-grid>
                    <GL-TextInput
                        class="uk-width-1-2@s uk-margin-small-top"
                        name="name"
                        label="Transaction Name*"
                        v-model="title"
                        :error-messages="validation.requiredField(title)"
                    />
                    <GL-TextInput
                        class="uk-width-1-2@s uk-margin-small-top"
                        label="Brand*"
                        v-model="make"
                        :error-messages="validation.requiredField(make)"
                    />
                    <GL-TextInput
                        class="uk-width-1-2@s uk-margin-small-top"
                        label="Device Model*"
                        v-model="model"
                        :error-messages="validation.requiredField(model)"
                    />
                    <GL-TextInput
                        numeric
                        right-align
                        class="uk-width-1-2@s uk-margin-small-top"
                        label="Year of manufacture"
                        v-model="year"
                        :error-messages="validation.requiredField(year)"
                    />
                    <GL-TextAreaInput
                        class="uk-width-1-1 uk-margin-small-top"
                        label="Description*"
                        tooltip="Give the transaction a detailed description. Provide as much detail as possible including the event that needs to take place in order for the funds to be released. You will be able to upload documents to this transaction later on (you can reference these documents in the description box below)."
                        v-model="description"
                        :error-messages="validation.requiredField(description)"
                    />
                    <GL-SelectInput
                        class="uk-width-1-2 uk-margin-small-top"
                        v-model="feeAllocation"
                        name="fee"
                        label="Who Will Pay The Escrow Fee?*"
                        :option-list="feeAllocationOptions"
                        :error-messages="validation.requiredField(feeAllocation)"
                    />
                    <div class="uk-width-1-2@s uk-visible@s"></div>
                </div>
            </div>
        </div>
        <PartiesFees />
        <CreateTxNavButtons :disable-next="!allowProgress" />
    </div>
</template>

<script>
import CardSelection from '@/modules/common/components/input/card-selection'
import { phoneBrands, computerBrands, categories } from '../../../../../../constants/constants-electronics'
import CreateTxNavButtons from '../../common/create-transaction-navigation'
import validation from '@/scripts/transaction-create-field-validation'
import PartiesFees from '../../common/parties-fees/index'

export default {
    name: 'ElectronicsDetails',
    components: { PartiesFees, CreateTxNavButtons, CardSelection },
    data() {
        return {
            phoneBrands,
            computerBrands,
            categories,
            validation,
            memoryUnitsOptions: [
                {
                    key: 'GB',
                    value: 'GB',
                },
                {
                    key: 'TB',
                    value: 'TB',
                },
            ],
        }
    },
    computed: {
        storageToolTip() {
            if (this.type === 'PHONES') {
                return 'A mobile phone has a set internal storage capacity ranging from 16 to 512 gigabytes (GB). This information is usually found on the back of the product box.'
            } else if (this.type === 'COMPUTERS') {
                return 'Computers or tablets has a set internal storage capacity ranging from 128 gigabytes (GB) to 3 terabytes (TB).'
            }
            return ''
        },
        userActionText() {
            if (this.$store.state.txCreate.parties.parties[0].role === 'BUYER') {
                return 'buying'
            } else if (this.$store.state.txCreate.parties.parties[0].role === 'SELLER') {
                return 'selling'
            }
            return 'brokering'
        },
        title: {
            get() {
                return this.$store.state.txCreate.transaction.transaction.title
            },
            set(v) {
                this.$store.commit('txCreate/setTransactionField', { key: 'title', value: v })
            },
        },
        description: {
            get() {
                return this.$store.state.txCreate.transaction.transaction.description
            },
            set(v) {
                this.$store.commit('txCreate/setTransactionField', { key: 'description', value: v })
            },
        },
        feeAllocation: {
            get() {
                return this.$store.state.txCreate.transaction.transaction.feeAllocation
            },
            set(v) {
                this.$store.commit('txCreate/setTransactionField', { key: 'feeAllocation', value: v })
            },
        },
        type: {
            get() {
                return this.$store.state.txCreate.transaction.auxiliaryData.electronicsType
            },
            set(v) {
                this.$store.commit('txCreate/setTransactionAuxiliaryField', { key: 'electronicsType', value: v })
            },
        },
        make: {
            get() {
                return this.$store.state.txCreate.transaction.auxiliaryData.electronicsMake
            },
            set(v) {
                this.$store.commit('txCreate/setTransactionAuxiliaryField', { key: 'electronicsMake', value: v })
            },
        },
        specifiedMake: {
            get() {
                return this.$store.state.txCreate.transaction.auxiliaryData.electronicsSpecifiedMake
            },
            set(v) {
                this.$store.commit('txCreate/setTransactionAuxiliaryField', {
                    key: 'electronicsSpecifiedMake',
                    value: v,
                })
            },
        },
        model: {
            get() {
                return this.$store.state.txCreate.transaction.auxiliaryData.electronicsModel
            },
            set(v) {
                this.$store.commit('txCreate/setTransactionAuxiliaryField', { key: 'electronicsModel', value: v })
            },
        },
        memory: {
            get() {
                return this.$store.state.txCreate.transaction.auxiliaryData.electronicsMemory
            },
            set(v) {
                this.$store.commit('txCreate/setTransactionAuxiliaryField', { key: 'electronicsMemory', value: v })
            },
        },
        memoryUnits: {
            get() {
                return this.$store.state.txCreate.transaction.auxiliaryData.electronicsMemoryUnits
            },
            set(v) {
                this.$store.commit('txCreate/setTransactionAuxiliaryField', { key: 'electronicsMemoryUnits', value: v })
            },
        },
        year: {
            get() {
                return this.$store.state.txCreate.transaction.auxiliaryData.electronicsYear
            },
            set(v) {
                this.$store.commit('txCreate/setTransactionAuxiliaryField', { key: 'electronicsYear', value: v })
            },
        },
        feeAllocationOptions() {
            return this.$store.getters['txCreate/feeAllocations']
        },
        allowProgress() {
            if (this.type === 'PHONES' || this.type === 'COMPUTERS') {
                if (!(this.model && this.memory && this.memoryUnits)) {
                    return false
                }
                if (this.make === 'Other') {
                    if (!this.specifiedMake) {
                        return false
                    }
                }
            } else if (this.type === 'OTHER') {
                if (!(this.make && this.model)) {
                    return false
                }
            }

            return !!(validation.partyDetailsComplete() && validation.basicDetailsComplete())
        },
    },
    watch: {
        type() {
            this.memory = ''
            this.make = ''
            this.specifiedMake = ''
            this.model = ''
            this.year = null
            this.memoryUnits = ''
            if (this.type === 'PHONES' || this.type === 'COMPUTERS') {
                this.memoryUnits = 'GB'
            }
        },
        make() {
            this.specifiedMake = ''
        },
    },
    methods: {
        setMake(v) {
            this.make = v
        },
    },
}
</script>
