<template>
    <div>
        <div class="uk-text-center uk-padding-small uk-margin">
            <h3 class="uk-text-secondary uk-margin-remove">Describe in detail what you are {{ userActionText }}</h3>
        </div>
        <div class="uk-margin-small-left uk-margin-small-right uk-flex uk-flex-center uk-flex-wrap">
            <div class="uk-width-5-6@m">
                <div class="uk-card uk-card-default uk-card-body uk-width-1-1 uk-margin-small">
                    <div class="uk-grid-small" uk-grid>
                        <GL-TextInput
                            class="uk-width-1-1 uk-margin-small-top"
                            label="transaction name"
                            tooltip="Give your transaction a name. For example, invoice 1235 COC IVT 39,000 litres etc."
                            v-model="title"
                            :error-messages="validation.requiredField(title)"
                        />
                        <GL-TextAreaInput
                            class="uk-width-1-1 uk-margin-small-top"
                            label="Description"
                            tooltip="Give the transaction a detailed description. Provide as much details as possible including the event that needs to take place in order for the funds to be released. This can include the customer’s receipt of the Bill of Lading (BOL), Delivery Notes (DN) or pick-up confirmation slips."
                            v-model="description"
                            :error-messages="validation.requiredField(description)"
                        />
                        <GL-SelectInput
                            class="uk-width-1-2@s uk-margin-small-top"
                            label="select the product"
                            :option-list="products"
                            v-model="product"
                            :error-messages="validation.requiredField(product)"
                        />
                        <GL-TextInput
                            :class="{
                                'uk-invisible': !(product === 'Other'),
                                ' uk-animation-slide-right-small': product === 'Other',
                            }"
                            class="uk-width-1-2@s uk-margin-small-top"
                            label="Please Specify Product"
                            v-model="productDescription"
                            :error-messages="validation.requiredField(productDescription)"
                        />
                        <GL-NumberInput
                            class="uk-width-1-2@s uk-margin-small-top"
                            label="Quantity (litres)"
                            right-align
                            v-model="units"
                            :error-messages="validation.requiredField(units)"
                        />
                        <GL-CurrencyInput
                            class="uk-width-1-2@s uk-margin-small-top"
                            label="Price per litre (ZAR)"
                            v-model="unitCost"
                            :error-messages="validation.allocationValue(units * unitCost)"
                        />
                        <GL-NumberInput
                            v-if="!drawdown"
                            class="uk-width-1-4@s uk-margin-small-top"
                            label="Delivery Days"
                            tooltip="Delivery days is the expected duration of the delivery or upliftment of the product."
                            v-model="daysToDeliver"
                            right-align
                            :error-messages="validation.requiredField(daysToDeliver)"
                        />
                        <GL-NumberInput
                            v-if="!drawdown"
                            class="uk-width-1-4@s uk-margin-small-top"
                            label="Inspection Days"
                            tooltip="Inspection days is the expected number of days the customer has to inspect the product."
                            right-align
                            v-model="daysToInspect"
                            :error-messages="validation.requiredField(daysToInspect)"
                        />
                        <GL-SelectInput
                            class="uk-width-1-2 uk-margin-small-top"
                            v-model="feeAllocation"
                            label="Who Will Pay The Escrow Fee?"
                            :option-list="feeAllocationOptions"
                            :error-messages="validation.requiredField(feeAllocation)"
                        />
                    </div>
                </div>
            </div>
        </div>
        <PartiesFees />
        <TransactionInformation />
        <CreateTxNavButtons :disable-next="!allowProgressFuel" />
    </div>
</template>

<script>
import { products } from '../../../../../../constants/fuel'
import PartiesFees from '../../common/parties-fees/index'
import CreateTxNavButtons from '../../common/create-transaction-navigation'
import validation from '@/scripts/transaction-create-field-validation'
import TransactionInformation from '../../transaction-information/index'
import TransactionCreateMixin from '../../transaction-create-mixin'

export default {
    name: 'Fuel',
    components: { TransactionInformation, PartiesFees, CreateTxNavButtons },
    mixins: [TransactionCreateMixin],
    data() {
        return {
            products,
            validation,
        }
    },
    computed: {
        userActionText() {
            if (this.$store.state.txCreate.parties.parties[0].role === 'BUYER') {
                return 'buying'
            } else if (this.$store.state.txCreate.parties.parties[0].role === 'SELLER') {
                return 'supplying'
            }
            return 'brokering'
        },
        feeAllocationOptions() {
            return this.$store.getters['txCreate/feeAllocations']
        },
        product: {
            get() {
                return this.$store.state.txCreate.transaction.auxiliaryData.fuelProduct
            },
            set(v) {
                this.$store.commit('txCreate/setTransactionAuxiliaryField', { key: 'fuelProduct', value: v })
            },
        },
        productDescription: {
            get() {
                return this.$store.state.txCreate.transaction.auxiliaryData.fuelProductDescription
            },
            set(v) {
                this.$store.commit('txCreate/setTransactionAuxiliaryField', { key: 'fuelProductDescription', value: v })
            },
        },
        allowProgressFuel() {
            if (!this.product || (this.product === 'Other' && !this.productDescription)) {
                return false
            }
            return this.allowProgressUnits
        },
    },
}
</script>

<style scoped>
.selectable-card {
    margin: 0.5rem;
}
</style>
