<template>
    <div class="uk-card uk-card-default uk-padding-small uk-margin-small uk-position-relative">
        <!-- Delete Party -->
        <div v-if="roleEnabled" class="delete-button clickable">
            <span @click="$store.commit('txCreate/removeParty', index)" class="close">
                <GL-Icon path="trash.svg" :size="1.2" />
            </span>
        </div>
        <div class="uk-flex uk-flex-left">
            <!-- Avatar -->
            <div>
                <img
                    class="uk-border-circle uk-margin-right animated flipInY avatar"
                    width="48"
                    height="48"
                    :src="null | safeAvatarURL"
                />
            </div>
            <!-- Details -->
            <div class="uk-width-expand">
                <h4 class="uk-margin-small-bottom">
                    <i
                        >{{
                            party.role
                                | transactionRoleDisplay(this.$store.state.txCreate.transaction.transaction.industry)
                        }}
                    </i>
                </h4>
                <div class="uk-grid">
                    <GL-InfoItem v-if="false" label="Role" :value="party.role | transactionRoleDisplay" />
                    <GL-SelectInput
                        v-if="!roleEnabled"
                        class="uk-width-1-3@m"
                        label="Role"
                        v-model="role"
                        :option-list="roleOption"
                        :error-messages="validateRole"
                        :disabled="true"
                    />
                    <GL-SelectInput
                        v-if="roleEnabled"
                        class="uk-width-1-3@m"
                        label="Role"
                        v-model="role"
                        :option-list="roleOptions"
                        :error-messages="validateRole"
                    />
                    <GL-TextInput
                        class="uk-width-1-3@m"
                        label="Email Address"
                        v-model="email"
                        :disabled="party.owner"
                        :error-messages="validateEmail"
                    />
                    <GL-TextInput
                        class="uk-width-1-3@m"
                        label="Confirm Email Address"
                        v-model="emailConfirmation"
                        :disabled="party.owner"
                        :error-messages="validateConfirmEmail"
                    />
                </div>
                <div v-if="showFees" class="uk-margin-small-top uk-margin-bottom" uk-grid>
                    <FeeTypeSelect
                        v-if="!(industry === 'FUEL')"
                        label="Fee Type"
                        v-model="feeType"
                        class="uk-width-1-3@m"
                    />
                    <GL-SelectInput
                        v-else
                        label="Fee Type"
                        :option-list="feeOptions"
                        v-model="feeType"
                        class="uk-width-1-3@m"
                    />
                    <GL-NumberInput
                        v-if="feeType !== 'FLAT'"
                        label="Amount Payable"
                        v-model="fee"
                        right-align
                        class="uk-width-1-3@m"
                        :append="appendIcon"
                    />
                    <GL-CurrencyInput
                        v-if="feeType === 'FLAT'"
                        label="Amount Payable"
                        v-model="fee"
                        right-align
                        class="uk-width-1-3@m"
                    />
                    <GL-SelectInput
                        v-if="allocationEnabled"
                        v-model="feeAllocation"
                        :option-list="feeAllocationOptions"
                        label="Which Party Absorbs This Fee?"
                        class="uk-width-1-3@m"
                    />
                    <GL-SelectInput
                        v-if="!allocationEnabled"
                        :value="'SELLER'"
                        :option-list="allocationOption"
                        label="Which Party Absorbs This Fee?"
                        class="uk-width-1-3@m"
                        :disabled="true"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import validation from '@/scripts/validation/validation'
import { transactionCreate } from '../../../../../constants/constants-transaction-create'
import FeeTypeSelect from '../common/parties-fees/fee-type-select'
export default {
    name: 'PartiesCard',
    components: { FeeTypeSelect },
    props: {
        index: {
            type: Number,
        },
    },
    data() {
        return {
            feeOptions: [
                {
                    key: 'PER_UNIT',
                    value: 'Cents per litre',
                },
                {
                    key: 'PERCENT',
                    value: 'Percentage of the transaction',
                },
                {
                    key: 'FLAT',
                    value: 'Fixed Fee',
                },
            ],
        }
    },
    computed: {
        allocationOption() {
            return [
                {
                    key: 'SELLER',
                    value: 'Seller',
                },
            ]
        },
        appendIcon() {
            if (this.feeType === 'PERCENT') {
                return '%'
            } else if (this.feeType === 'PER_UNIT' && this.industry === 'FUEL') {
                return 'c/l'
            }
            return ''
        },
        feeAllocationOptions() {
            return this.$store.getters['txCreate/feeAllocations']
        },
        allocationEnabled() {
            return this.party.role !== 'AGENT'
        },
        party() {
            return this.$store.state.txCreate.parties.parties[this.index]
        },
        editMode() {
            return this.$store.getters['txCreate/editMode']
        },
        showFees() {
            return !!(
                this.editMode &&
                this.party.role !== 'BUYER' &&
                this.party.role !== 'SELLER' &&
                this.party.role !== 'READ_ONLY'
            )
        },
        roleEnabled() {
            return !(this.party.role === 'BUYER' || this.party.role === 'SELLER' || this.party.role === 'AGENT')
        },
        roleOption() {
            const industry = this.$getIndustryService().getIndustry(
                this.$store.state.txCreate.transaction.transaction.industry,
            )
            return [
                {
                    key: 'BUYER',
                    value: industry.buyerName,
                },
                {
                    key: 'SELLER',
                    value: industry.sellerName,
                },
                {
                    key: 'AGENT',
                    value: 'Agent',
                },
            ]
        },
        roleOptions() {
            return transactionCreate.otherRoles
        },
        role: {
            get() {
                return this.$store.state.txCreate.parties.parties[this.index].role
            },
            set(v) {
                this.$store.commit('txCreate/setPartyField', { index: this.index, key: 'role', value: v })
            },
        },
        email: {
            get() {
                return this.$store.state.txCreate.parties.parties[this.index].email
            },
            set(v) {
                this.$store.commit('txCreate/setPartyField', { index: this.index, key: 'email', value: v })
            },
        },
        emailConfirmation: {
            get() {
                return this.$store.state.txCreate.parties.emailConfirmations[this.index]
            },
            set(value) {
                this.$store.commit('txCreate/setEmailConfirmation', { index: this.index, value })
            },
        },
        fee: {
            get() {
                if (this.feeType === 'PER_UNIT' && this.industry === 'FUEL') {
                    return this.$store.state.txCreate.parties.parties[this.index].fee * 100
                }
                return this.$store.state.txCreate.parties.parties[this.index].fee
            },
            set(v) {
                if (this.feeType === 'PER_UNIT' && this.industry === 'FUEL') {
                    this.$store.commit('txCreate/setPartyField', { index: this.index, key: 'fee', value: v / 100 })
                } else {
                    this.$store.commit('txCreate/setPartyField', { index: this.index, key: 'fee', value: v })
                }
            },
        },
        feeAllocation: {
            get() {
                return this.$store.state.txCreate.parties.parties[this.index].feeAllocation
            },
            set(v) {
                this.$store.commit('txCreate/setPartyField', { index: this.index, key: 'feeAllocation', value: v })
            },
        },
        feeType: {
            get() {
                return this.$store.state.txCreate.parties.parties[this.index].feeType
            },
            set(v) {
                this.$store.commit('txCreate/setPartyField', { index: this.index, key: 'feeType', value: v })
            },
        },
        industry() {
            return this.$store.state.txCreate.transaction.transaction.industry
        },
        otherPartyEmails() {
            const otherParties = this.$store.state.txCreate.parties.parties.filter(
                (party, index) => index !== this.index,
            )
            return otherParties.map(({ email }) => email)
        },
        validateRole() {
            return validation.validate(this.role, [validation.list.required('Please select a role for this party')])
        },
        validateEmail() {
            return validation.validate(this.email, [
                validation.list.required('Please provide a email address for this party'),
                validation.list.unique('Must be a unique email', this.otherPartyEmails),
                validation.list.email('Invalid email'),
            ])
        },
        validateConfirmEmail() {
            return validation.validate(this.emailConfirmation, [
                validation.list.required('Required'),
                validation.list.email('Invalid email'),
                validation.list.same_as('Emails do not match', this.email),
            ])
        },
    },
}
</script>

<style scoped>
.delete-button {
    position: absolute;
    top: 4px;
    right: 6px;
}

.clickable {
    color: #676767;
}

.clickable:hover {
    cursor: pointer;
    color: #999;
}
</style>
