<template><General /></template>

<script>
import General from '../general/index'
export default {
    name: 'AgricultureMilestone',
    components: { General },
}
</script>

<style scoped></style>
