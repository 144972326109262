<template>
    <div class="uk-card uk-card-default fuel-milestone-card uk-margin-bottom uk-width-1-1">
        <span @click="$store.commit('txCreate/removeAllocation', index)" v-if="index > 1" class="close">
            <GL-Icon path="trash.svg" />
        </span>
        <div class="uk-card-body">
            <Stepper
                :step="currentStep"
                @select="setStep"
                :completed="lastCompletedStep"
                :success="milestoneDetailsCompleted"
            >
                <Step
                    v-for="(step, stepNumber) in steps"
                    :step="stepNumber + 1"
                    :name="step.name"
                    :key="stepNumber"
                    :is-completed="lastCompletedStep > stepNumber + 1"
                >
                    <component
                        :is="step.component"
                        :index="index"
                        @complete-step="completeStep"
                        @view-previous="previousStep"
                    />
                </Step>
            </Stepper>
        </div>
    </div>
</template>

<script>
import Stepper from '@/modules/common/components/stepper'
import Step from '@/modules/common/components/step'
import MiningMilestoneDetails from './mining-milestone-details'
import MilestoneDelivery from '../../fuel/milestone-cards/milestones-delivered-to-unique-addresses/delivery-address'
import MiningMilestoneSummary from './mining-mileston-summary'
import DeliveryInstructions from '../../fuel/milestone-cards/milestones-delivered-to-unique-addresses/delivery-instructions'

export default {
    name: 'MiningMilestoneCard',
    components: { Step, Stepper },
    props: {
        index: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            currentStep: 1,
            lastCompletedStep: 0,
        }
    },
    computed: {
        steps() {
            return [
                {
                    name: 'Load Details',
                    component: MiningMilestoneDetails,
                },
                {
                    name: 'Delivery Address',
                    component: MilestoneDelivery,
                },
                {
                    name: 'Delivery Instructions',
                    component: DeliveryInstructions,
                },
                {
                    name: 'Summary',
                    component: MiningMilestoneSummary,
                },
            ]
        },
        milestoneDetailsCompleted() {
            return this.lastCompletedStep >= this.steps.length - 1
        },
    },
    mounted() {
        if (this.$store.state.txCreate.allocations.addresses[this.index].id) {
            this.lastCompletedStep = 4
            this.currentStep = 4
        }
    },
    methods: {
        setStep(value) {
            this.currentStep = value
        },
        previousStep() {
            this.currentStep -= 1
        },
        completeStep() {
            this.currentStep += 1
            if (this.lastCompletedStep === this.currentStep - 2) {
                this.lastCompletedStep += 1
            }
            if (this.currentStep === this.steps.length) {
                this.lastCompletedStep = this.currentStep
            }
        },
    },
}
</script>

<style scoped>
.close {
    position: absolute;
    top: 5px;
    right: 5px;
}

.close:hover {
    cursor: pointer;
    color: black;
}

.fuel-milestone-card {
    border-top: 0;
}
</style>
