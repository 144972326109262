import { formatCurrency, formatNumber } from '@/scripts/filters'
import validation from '@/scripts/validation/validation'

export default {
    getIndustry(industryBase) {
        let industry = industryBase
        industry.buyerName = 'Customer'
        industry.sellerName = 'Supplier'
        industry.allocationName = 'Load'
        industry.allocationsName = 'Loads'

        industry.getMiningMassUnits = function (auxiliaryData) {
            if (auxiliaryData.miningCommodityType === 'PRECIOUS') {
                if (auxiliaryData.miningCommodity === 'DIAMONDS') {
                    return {
                        label: 'Carats',
                        price: 'Price Per Carat',
                    }
                }
                return {
                    label: 'Troy Ounces',
                    price: 'Price Per Troy Ounce',
                }
            }
            return {
                label: 'Tonnage',
                price: 'Price Per Ton',
            }
        }
        industry.allocationValues = function (allocation, transaction) {
            if (!transaction) {
                console.error('Transaction is required to compute MINING values')
            }
            if (!transaction.auxiliaryData) {
                console.error('Transaction auxiliaryData is required to compute MINING values')
            }
            const unitDescription = industry.getMiningMassUnits(JSON.parse(transaction.auxiliaryData))
            const values = []
            values.push({
                name: 'units',
                label: unitDescription.label,
                value: formatNumber(allocation.units),
            })
            values.push({
                name: 'priceperunit',
                label: unitDescription.price,
                value: 'R' + formatCurrency(allocation.unitCost),
            })
            values.push({
                name: 'totalvalue',
                label: `Total`,
                value: 'R' + formatCurrency(allocation.value),
            })
            values.push({
                name: 'deliver',
                label: 'days to deliver',
                value: allocation.daysToDeliver,
            })
            values.push({
                name: 'inspect',
                label: 'days to inspect',
                value: allocation.daysToInspect,
            })

            return values
        }

        industry.allocationAmendableValues = function (allocation, transaction) {
            if (!transaction) {
                console.error('Transaction is required to compute MINING amendableValues')
            }
            if (!transaction.auxiliaryData) {
                console.error('Transaction auxiliaryData is required to compute MINING amendableValues')
            }
            const unitDescription = industry.getMiningMassUnits(JSON.parse(transaction.auxiliaryData))
            const amendableValues = []
            amendableValues.push({
                modeltarget: 'units',
                value: allocation.units,
                component: 'GL-NumberInput',
                componentprops: {
                    label: unitDescription.label,
                    showSpin: true,
                    class: 'uk-width-1-2',
                },
                validations: [validation.list.required_allow_zero(`Please provide the ${unitDescription.label}`)],
            })
            amendableValues.push({
                modeltarget: 'unitCost',
                value: allocation.unitCost,
                component: 'GL-CurrencyInput',
                componentprops: {
                    label: unitDescription.price,
                    showSpin: true,
                    class: 'uk-width-1-2',
                },
                validations: [validation.list.required(`Please provide the ${unitDescription.price}`)],
            })
            amendableValues.push({
                modeltarget: 'daysToDeliver',
                value: allocation.daysToDeliver,
                component: 'GL-NumberInput',
                componentprops: {
                    label: 'DAYS FOR DELIVERY',
                    showSpin: true,
                    class: 'uk-width-1-2',
                },
                validations: [validation.list.required('Please provide the days to deliver')],
            })
            amendableValues.push({
                modeltarget: 'daysToInspect',
                value: allocation.daysToInspect,
                component: 'GL-NumberInput',
                componentprops: {
                    label: 'DAYS FOR INSPECTION',
                    showSpin: true,
                    class: 'uk-width-1-2',
                },
                validations: [validation.list.required('Please provide the days to deliver')],
            })

            return amendableValues
        }

        industry.allocationAmendmentDelta = function (allocation, amendment) {
            let changeList = []
            let auxiliaryData = JSON.parse(allocation.auxiliaryData)

            // if (transaction.allocations.length === 1) {
            //     // This is to keep the change service generalized to allocations
            //     auxiliaryData = JSON.parse(transaction.auxiliaryData)
            // }
            if (allocation.units !== amendment.units) {
                changeList.push(
                    `${industry.getMiningMassUnits(auxiliaryData).label.toLowerCase()} changed from ${formatNumber(
                        allocation.units,
                    )} to ${formatNumber(amendment.units)}`,
                )
            }
            if (allocation.unitCost !== amendment.unitCost) {
                changeList.push(
                    `${industry.getMiningMassUnits(auxiliaryData).price.toLowerCase()} changed from R${formatCurrency(
                        allocation.unitCost,
                    )} to R${formatCurrency(amendment.unitCost)}`,
                )
            }
            if (amendment.daysToDeliver > allocation.daysToDeliver) {
                changeList.push(
                    `The days to deliver was changed from ${allocation.daysToDeliver} to ${amendment.daysToDeliver}`,
                )
            }
            if (amendment.daysToInspect > allocation.daysToInspect) {
                changeList.push(
                    `The days to inspect was changed from ${allocation.daysToInspect} to ${amendment.daysToInspect}`,
                )
            }
            if (amendment.unitCost * amendment.units < allocation.unitCost * allocation.units) {
                changeList.push(
                    `Refund to customer R${formatCurrency(
                        allocation.unitCost * allocation.units - amendment.unitCost * amendment.units,
                    )}`,
                )
            }
            return changeList
        }

        return industry
    },
}
