<template>
    <div class="uk-margin-small-top">
        <div class="uk-text-center uk-text-large uk-margin">Load {{ index + 1 }} - Delivery Location Address</div>
        <div class="uk-grid-small" uk-grid>
            <GL-SelectInput
                class="uk-width-1-1 uk-margin-small-top"
                label="Select the address"
                v-model="selectedAddress"
                :option-list="addressOptions"
            />
            <div
                class="uk-width-1-1 uk-margin-small-top"
                :class="{
                    'uk-invisible': !selectedAddress,
                    'uk-animation-slide-right-small': selectedAddress,
                }"
            >
                <GL-TextInput
                    v-if="id === 'NEW'"
                    class="uk-width-1-1"
                    label="Give Your Address a Name"
                    tooltip="i.e. Office, Home, Warehouse"
                    v-model="name"
                />
                <GL-TextInput
                    class="uk-width-1-1 uk-margin-small-top"
                    label="Street"
                    :disabled="id !== 'NEW'"
                    v-model="line1"
                />
                <div>
                    <div uk-grid>
                        <GL-TextInput
                            :disabled="id !== 'NEW'"
                            class="uk-width-1-2@s uk-margin-small-top"
                            label="Address line 2"
                            v-model="line2"
                        />
                        <GL-TextInput
                            class="uk-width-1-2@s uk-margin-small-top"
                            :disabled="id !== 'NEW'"
                            label="Suburb"
                            v-model="area"
                        />
                    </div>
                </div>
                <div>
                    <div uk-grid>
                        <GL-TextInput
                            class="uk-width-1-2@s uk-margin-small-top"
                            :disabled="id !== 'NEW'"
                            label="City"
                            v-model="city"
                        />
                        <GL-TextInput
                            class="uk-width-1-2@s uk-margin-small-top"
                            :disabled="id !== 'NEW'"
                            label="Postal Code"
                            v-model="code"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="uk-width-1-1@m uk-text-right uk-margin-top uk-card-footer">
            <GL-Button
                text="previous"
                type="primary-outlined"
                class="uk-margin-small-right"
                @click="$emit('view-previous')"
            />
            <GL-Button
                text="next"
                type="primary"
                @click="$emit('complete-step')"
                disabled-text="next"
                :disabled="disableNext"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'MilestoneAddress',
    props: {
        index: {
            type: Number,
            required: true,
        },
    },
    computed: {
        addresses() {
            if (this.$store.state.user.fetchedUser === '__loading__') {
                return []
            }
            return this.$store.state.user.fetchedUser.addresses
        },
        addressOptions() {
            const options = this.addresses.map(({ name, id }) => ({ value: name, key: id }))
            options.push({ value: 'Create New Address', key: 'NEW' })
            return options
        },
        selectedAddress: {
            get() {
                return this.$store.state.txCreate.allocations.addresses[this.index].id
            },
            set(v) {
                if (v === 'NEW') {
                    const address = { id: 'NEW', name: '', line1: '', line2: '', city: '', area: '', code: '' }
                    this.$store.commit('txCreate/initAllocationAddress', { index: this.index, address })
                } else if (v) {
                    const address = this.userAddressById(v)
                    this.$store.commit('txCreate/initAllocationAddress', { index: this.index, address: { ...address } })
                } else {
                    this.$store.commit('txCreate/setAllocationAddressField', { key: 'id', value: v, index: this.index })
                }
            },
        },
        id() {
            return this.$store.state.txCreate.allocations.addresses[this.index].id
        },
        name: {
            get() {
                return this.$store.state.txCreate.allocations.addresses[this.index].name
            },
            set(value) {
                this.$store.commit('txCreate/setAllocationAddressField', { key: 'name', value, index: this.index })
            },
        },
        line1: {
            get() {
                return this.$store.state.txCreate.allocations.addresses[this.index].line1
            },
            set(value) {
                this.$store.commit('txCreate/setAllocationAddressField', { key: 'line1', value, index: this.index })
            },
        },
        line2: {
            get() {
                return this.$store.state.txCreate.allocations.addresses[this.index].line2
            },
            set(value) {
                this.$store.commit('txCreate/setAllocationAddressField', { key: 'line2', value, index: this.index })
            },
        },
        city: {
            get() {
                return this.$store.state.txCreate.allocations.addresses[this.index].city
            },
            set(value) {
                this.$store.commit('txCreate/setAllocationAddressField', { key: 'city', value, index: this.index })
            },
        },
        area: {
            get() {
                return this.$store.state.txCreate.allocations.addresses[this.index].area
            },
            set(value) {
                this.$store.commit('txCreate/setAllocationAddressField', { key: 'area', value, index: this.index })
            },
        },
        code: {
            get() {
                return this.$store.state.txCreate.allocations.addresses[this.index].code
            },
            set(value) {
                this.$store.commit('txCreate/setAllocationAddressField', { key: 'code', value, index: this.index })
            },
        },
        disableNext() {
            return !(
                this.name &&
                this.line1 &&
                this.line2 &&
                this.city &&
                this.area &&
                this.code &&
                this.selectedAddress
            )
        },
    },
    methods: {
        userAddressById(id) {
            return this.$store.state.user.fetchedUser.addresses.find(address => address.id === id)
        },
    },
}
</script>
