var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.currentUser === '__loading__')?_c('div',[_c('div',{staticClass:"uk-text-center"},[_c('GL-Spinner')],1)]):_vm._e(),_c('div',{staticClass:"uk-padding uk-card uk-card-default uk-margin",class:{ 'uk-invisible': _vm.currentUser === '__loading__' },attrs:{"id":"roleSelector"}},[_c('h4',{staticClass:"uk-text-secondary uk-text-center"},[_vm._v("WHAT IS YOUR ROLE IN THIS TRANSACTION?")]),_c('div',{staticClass:"uk-flex uk-flex-center uk-flex-wrap create-tour-step-2"},_vm._l((_vm.availableRoles),function(role){return _c('CardButtonWide',{key:role.key,staticClass:"selectable-card",attrs:{"description":role.description,"text":role.displayText,"value":role.key,"icon-path":role.icon,"selected":role.key === _vm.selectedRole},on:{"select":_vm.selectRole}})}),1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedRole === 'AGENT'),expression:"selectedRole === 'AGENT'"}],staticClass:"uk-padding uk-animation-slide-bottom-small uk-flex uk-flex-center uk-card uk-card-default uk-margin",attrs:{"id":"confidentialitySelector"}},[_c('ConfidentialityToggles')],1),_c('div',{staticClass:"uk-padding uk-card uk-card-default uk-margin create-tour-step-3",class:{
            'uk-invisible': _vm.hideIndustrySelector,
            'uk-animation-slide-bottom-small': _vm.selectedRole && !_vm.selectedIndustry,
        },attrs:{"id":"industrySelector"}},[_c('h4',{staticClass:"uk-text-secondary uk-text-center"},[_vm._v("WHAT IS THE INDUSTRY?")]),_c('div',{staticClass:"uk-flex uk-flex-center uk-flex-wrap"},_vm._l((_vm.availableIndustries),function(industry){return _c('CardButtonWide',{key:industry.name,staticClass:"selectable-card",attrs:{"value":industry.name,"text":industry.name,"icon-path":industry.icon,"description":industry.description,"disabled":_vm.disableIndustry(industry),"disabled-text":industry.disabledText,"selected":industry.value === _vm.selectedIndustry},on:{"select":function($event){return _vm.selectIndustry(industry)}}})}),1)]),_c('div',{staticClass:"uk-padding uk-card uk-card-default uk-margin create-tour-step-4",class:{
            'uk-invisible': _vm.hideTransactionType,
            'uk-animation-slide-bottom-small': _vm.selectedIndustry && !_vm.selectedTransactionType,
        },attrs:{"id":"transactionTypeSelector"}},[_c('h4',{staticClass:"uk-text-secondary uk-text-center"},[_vm._v("SELECT TYPE OF TRANSACTION")]),_c('div',{staticClass:"uk-flex uk-flex-center uk-flex-wrap"},_vm._l((_vm.availableTransactionTypes),function(type){return _c('CardButtonWide',{key:type.name,staticClass:"selectable-card",attrs:{"text":type.name,"value":type.value,"icon-path":type.icon,"description":type.description,"selected":type.value === _vm.selectedTransactionType,"disabled-text":type.disabledText,"disabled":_vm.$route.params.id !== 'create'},on:{"select":_vm.selectTransactionType}})}),1)]),_c('CreateTxNavButtons',{attrs:{"disable-next":!_vm.allowProgress}}),_c('Tour',{attrs:{"name":"createTour","steps":_vm.createTourSteps,"callbacks":_vm.tourCallbacks}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }