import api from '@/scripts/api/api'
import rootStore from '@/store/index'

function hasAddress(fetchedOrganization) {
    if (!fetchedOrganization) {
        return false
    }
    if (!fetchedOrganization.addresses) {
        return false
    }
    if (fetchedOrganization.addresses.length === 0) {
        return false
    }
    return true
}

function hasBankAccount(fetchedOrganization) {
    if (!fetchedOrganization) {
        return false
    }
    if (!fetchedOrganization.bankAccount) {
        return false
    }
    return true
}

function filterFileType(type, fileList) {
    const result = []
    for (const file of fileList) {
        const metadata = JSON.parse(file.metadata)
        if (metadata.type === type) {
            result.push(file)
        }
    }
    return result
}

function areaOfOperationForBackend(operateInFuel, operateInMetals) {
    const enumList = []
    if (operateInFuel) {
        enumList.push('FUEL')
    }
    if (operateInMetals) {
        enumList.push('MINING')
    }

    // TODO: This is a total hack. If list is empty send null not an empty array.
    if (enumList.length === 0) {
        return null
    }

    return enumList
}

function areaOfOperationForFrontend(areaOfOperation) {
    let result = {
        operateInFuel: false,
        operateInMetals: false,
    }
    if (!areaOfOperation) {
        return result
    }
    if (areaOfOperation.includes('FUEL')) {
        result.operateInFuel = true
    }
    if (areaOfOperation.includes('MINING')) {
        result.operateInMetals = true
    }
    return result
}

const organization_schema = {
    id: '',
    details: {
        name: '',
        tradeName: '',
        type: '',
        registrationNumber: '',
        taxNumber: '',
        industry: '',
        canAct: false,
        operateInFuel: false,
        operateInMetals: false,
        createdAt: '',
    },
    avatar: {
        avatarFile: null,
        avatarURL: '',
    },
    banking: {
        bank: '',
        bankName: '',
        branchCode: '',
        accountNumber: '',
        accountType: '',
        verified: false,
    },
    address: {
        id: '',
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        city: '',
        area: '',
        code: '',
    },
    status: {
        verified: false,
    },
    settings: {
        isPrivate: false,
        creditAccount: false,
        payout: {
            interval: '',
            refund: '',
        },
    },
    documents: {
        letterhead: null,
        letterheadUploaded: null,
        cipc: null,
        cipcUploaded: null,
        wholesale: null,
        wholesaleUploaded: null,
        sadpmr: null,
        sadpmrUploaded: null,
    },
}

export default {
    namespaced: true,
    state: {
        organizationList: '__loading__',
        organizationRefetch: true,
        organizationFetchInjections: [],
        organization: '__loading__',
        organizationNew: '__loading__',
        fetchedOrganization: '__loading__',
        organizationCreateStatus: null,
    },
    mutations: {
        // Organization List
        setOrganizationList(state, payload) {
            state.organizationList = payload
        },
        setOrganizationListLoading(state) {
            state.organizationList = '__loading__'
        },
        // Organization New
        setOrganizationNewField(state, payload) {
            const keyList = payload.key.split('|')
            let obj = state.organizationNew
            let key = payload.key
            for (let i = 0; i < keyList.length; i++) {
                key = keyList[i]
                if (i < keyList.length - 1) {
                    obj = obj[key]
                }
            }
            obj[key] = payload.value
        },
        setOrganizationNewReset(state) {
            state.organizationNew = JSON.parse(JSON.stringify(organization_schema))
        },
        // Organization Current
        setOrganizationField(state, payload) {
            const keyList = payload.key.split('|')
            let obj = state.organization
            let key = payload.key
            for (let i = 0; i < keyList.length; i++) {
                key = keyList[i]
                if (i < keyList.length - 1) {
                    obj = obj[key]
                }
            }
            obj[key] = payload.value
        },
        setOrganizationFetched(state, payload) {
            state.fetchedOrganization = payload
            state.organization = JSON.parse(JSON.stringify(organization_schema))

            state.organization.id = state.fetchedOrganization.id
            state.organization.avatar.avatarURL = state.fetchedOrganization.avatar
            state.organization.details.name = state.fetchedOrganization.name
            state.organization.details.tradeName = state.fetchedOrganization.tradeName
            state.organization.details.type = state.fetchedOrganization.type
            state.organization.details.registrationNumber = state.fetchedOrganization.registration
            state.organization.details.taxNumber = state.fetchedOrganization.taxNumber
            state.organization.details.createdAt = state.fetchedOrganization.createdAt
            state.organization.details.operateInFuel = areaOfOperationForFrontend(
                state.fetchedOrganization.areaOfOperation,
            ).operateInFuel
            state.organization.details.operateInMetals = areaOfOperationForFrontend(
                state.fetchedOrganization.areaOfOperation,
            ).operateInMetals

            if (hasBankAccount(state.fetchedOrganization)) {
                state.organization.banking.bank = state.fetchedOrganization.bankAccount.bank
                state.organization.banking.bankName = state.fetchedOrganization.bankAccount.bankName
                state.organization.banking.branchCode = state.fetchedOrganization.bankAccount.branchCode
                state.organization.banking.accountNumber = state.fetchedOrganization.bankAccount.accountNumber
                state.organization.banking.accountType = state.fetchedOrganization.bankAccount.accountType
                state.organization.banking.verified = state.fetchedOrganization.bankAccount.verified
            }
            if (hasAddress(state.fetchedOrganization)) {
                state.organization.address.id = state.fetchedOrganization.addresses[0].id
                state.organization.address.addressLine1 = state.fetchedOrganization.addresses[0].line1
                state.organization.address.addressLine2 = state.fetchedOrganization.addresses[0].line2
                state.organization.address.addressLine3 = state.fetchedOrganization.addresses[0].line3
                state.organization.address.city = state.fetchedOrganization.addresses[0].city
                state.organization.address.area = state.fetchedOrganization.addresses[0].area
                state.organization.address.code = state.fetchedOrganization.addresses[0].code
            }
            if (state.fetchedOrganization.files) {
                state.organization.documents.letterhead = null
                state.organization.documents.cipc = null
                state.organization.documents.wholesale = null
                state.organization.documents.sadpmr = null
                state.organization.documents.letterheadUploaded = filterFileType(
                    'letterhead',
                    state.fetchedOrganization.files,
                )
                state.organization.documents.cipcUploaded = filterFileType('cipc', state.fetchedOrganization.files)
                state.organization.documents.wholesaleUploaded = filterFileType(
                    'wholesale',
                    state.fetchedOrganization.files,
                )
                state.organization.documents.sadpmrUploaded = filterFileType('sadpmr', state.fetchedOrganization.files)
            }

            if (payload.settings) {
                state.organization.settings.isPrivate = payload.settings.private
                state.organization.settings.creditAccount = payload.settings.creditAccount

                if (payload.settings.payout) {
                    state.organization.settings.payout.interval = payload.settings.payout.interval
                    state.organization.settings.payout.refund = payload.settings.payout.refund
                }
            }

            if (state.fetchedOrganization.status) {
                state.organization.status.verified = state.fetchedOrganization.status.verified
            }
        },
        setOrganizationLoading(state) {
            state.organization = '__loading__'
        },
    },
    actions: {
        async organizationListFetch() {
            this.commit('organization/setOrganizationListLoading')
            const organizationList = await api.organization.organizationListFetch()
            console.log('Organization List Fetched: ')
            console.log(organizationList)

            this.commit('organization/setOrganizationList', organizationList.data)
        },
        async organizationListRefetch() {
            const organizationList = await api.organization.organizationListFetch()
            console.log('Organization List Refetched: ')
            console.log(organizationList)
            this.commit('organization/setOrganizationList', organizationList.data)
        },
        async organizationFetch(store) {
            const context = store.rootGetters['context/context']

            if (context.id) {
                const organization = await api.organization.organizationFetch({id: context.id})

                for (const injected of store.state.organizationFetchInjections) {
                    const result = await injected.method(organization)
                    console.log(result)
                    organization[injected.keyName] = result
                }

                console.log('Organization Fetched: ')
                console.log(organization)

                this.commit('organization/setOrganizationFetched', organization)
                store.state.organizationRefetch = false
            }
        },
        async organizationCreate({state, getters}) {
            // Create organization
            state.organizationCreateStatus = 'Creating new organisation'
            const organization = await api.organization.organizationCreate(getters['organizationCreateData'])

            // Set created organization as active
            this.commit('organization/setOrganizationFetched', organization)

            // Autofill the details from creation
            state.organization.address = state.organizationNew.address
            state.organization.banking = state.organizationNew.banking
            state.organization.documents = state.organizationNew.documents

            // Save details
            try {
                state.organizationCreateStatus = 'Creating organization address'
                await this.dispatch('organization/addressUpdate')

                state.organizationCreateStatus = 'Creating organization bank account'
                await this.dispatch('organization/bankingUpdate')
                await this.dispatch('organization/documentsUpdate')
            } catch (error) {
                state.organizationCreateStatus = 'File Upload Failed'
                await this.dispatch('organization/organizationDelete')
                throw error
            }

            // Select new organization as context
            await rootStore.dispatch('context/contextSelect', {id: organization.id})

            // Get new organization list
            await rootStore.dispatch('organization/organizationListRefetch')

            // Finally fetch the organization to be sure details are in sync
            await rootStore.dispatch('organization/organizationFetch')
            state.organizationCreateStatus = null
            return organization
        },
        async organizationUpdate({state, getters}) {
            await api.organization.organizationUpdate({
                id: state.organization.id,
                updateInput: getters['organizationUpdateData'],
            })
        },
        async bankingUpdate(store) {
            if (store.state.organization.banking.bank === 'OTHER') {
                await api.organization.organizationBankAccountCreate({
                    id: store.state.organization.id,
                    bankAccount: {
                        bank: store.state.organization.banking.bank,
                        bankName: store.state.organization.banking.bankName,
                        branchCode: store.state.organization.banking.branchCode,
                        accountNumber: store.state.organization.banking.accountNumber,
                        accountType: store.state.organization.banking.accountType,
                    },
                })
            } else {
                await api.organization.organizationBankAccountCreate({
                    id: store.state.organization.id,
                    bankAccount: {
                        bank: store.state.organization.banking.bank,
                        accountNumber: store.state.organization.banking.accountNumber,
                        accountType: store.state.organization.banking.accountType,
                    },
                })
            }
        },
        async organizationSettingsUpdate(store) {
            await api.organization.organizationUpdateSettings({
                id: store.state.organization.id,
                input: {
                    private: store.state.organization.settings.isPrivate,
                    creditAccount: store.state.organization.settings.creditAccount,
                    payout: {
                        interval: store.state.organization.settings.payout.interval,
                        refund: store.state.organization.settings.payout.refund,
                    },
                },
            })
        },
        async bankingVerify() {
            await api.organization.organizationBankAccountVerify()
        },
        async addressUpdate(store) {
            const address = {
                name: 'Company Address',
                line1: store.state.organization.address.addressLine1,
                line2: store.state.organization.address.addressLine2,
                line3: store.state.organization.address.addressLine3,
                city: store.state.organization.address.city,
                area: store.state.organization.address.area,
                code: store.state.organization.address.code,
                country: 'ZAF',
            }
            console.log(store.state.fetchedOrganization)
            if (hasAddress(store.state.fetchedOrganization)) {
                await api.organization.organizationAddressUpdate({
                    id: store.state.organization.address.id,
                    address: address,
                })
            } else {
                await api.organization.organizationAddressCreate({
                    id: store.state.organization.id,
                    address: address,
                })
            }
        },
        async organizationAvatarCreate(store, payload) {
            await api.organization.organizationAvatarCreate({
                id: store.state.organization.id,
                image: payload.file,
            })
            await rootStore.dispatch('organization/organizationListRefetch')
            await rootStore.dispatch('context/contextFetch')
        },
        async organizationDelete(store) {
            await api.organization.organizationDelete({id: store.state.organization.id})
            await rootStore.dispatch('context/contextSelect', {id: rootStore.getters['user/user'].id})
            rootStore.commit('organization/setOrganizationListLoading')
        },
        async documentsUpdate(store) {
            if (store.state.organization.documents.letterhead) {
                store.state.organizationCreateStatus = 'Uploading the signed letterhead'
                await this.dispatch('organization/organizationFileCreate', {
                    documents: store.state.organization.documents.letterhead,
                    organizationId: store.state.organization.id,
                    type: 'letterhead',
                })
            }
            if (store.state.organization.documents.cipc) {
                store.state.organizationCreateStatus = 'Uploading CK, COR 14.3 or CIPC disclosure document'
                await this.dispatch('organization/organizationFileCreate', {
                    documents: store.state.organization.documents.cipc,
                    organizationId: store.state.organization.id,
                    type: 'cipc',
                })
            }
            if (store.state.organization.documents.wholesale) {
                store.state.organizationCreateStatus = 'Uploading diesel wholesale license'
                await this.dispatch('organization/organizationFileCreate', {
                    documents: store.state.organization.documents.wholesale,
                    organizationId: store.state.organization.id,
                    type: 'wholesale',
                })
            }
            if (store.state.organization.documents.sadpmr) {
                store.state.organizationCreateStatus = 'Uploading SADPMR permit'
                await this.dispatch('organization/organizationFileCreate', {
                    documents: store.state.organization.documents.sadpmr,
                    organizationId: store.state.organization.id,
                    type: 'sadpmr',
                })
            }
        },
        async organizationFileCreate(store, payload) {
            for (const file of payload.documents) {
                await api.organization.organizationFileCreate({
                    organizationId: payload.organizationId,
                    file: file,
                    metadata: JSON.stringify({
                        type: payload.type,
                    }),
                })
            }
        },
        async organizationFileDelete(store, payload) {
            await api.organization.organizationFileDelete({
                id: payload.id,
            })
        },
    },
    getters: {
        organizationCreateData: state => {
            const data = {}
            data.name = state.organizationNew.details.name
            data.type = state.organizationNew.details.type
            data.registrationNumber = state.organizationNew.details.registrationNumber
            data.areaOfOperation = areaOfOperationForBackend(
                state.organizationNew.details.operateInFuel,
                state.organizationNew.details.operateInMetals,
            )
            if (state.organizationNew.details.tradeName) {
                data.tradeName = state.organizationNew.details.tradeName
            }
            if (state.organizationNew.details.taxNumber) {
                data.taxNumber = state.organizationNew.details.taxNumber
            }
            return data
        },
        organizationUpdateData: (state, getters, rootState) => {
            const data = {}
            data.name = state.organization.details.name
            data.type = state.organization.details.type
            data.registrationNumber = state.organization.details.registrationNumber
            data.areaOfOperation = areaOfOperationForBackend(
                state.organization.details.operateInFuel,
                state.organization.details.operateInMetals,
            )
            if (state.organization.details.tradeName !== rootState.context.context.tradeName) {
                data.tradeName = state.organization.details.tradeName
            }
            if (state.organization.details.taxNumber !== rootState.context.context.taxNumber) {
                data.taxNumber = state.organization.details.taxNumber
            }
            return data
        },
        organizationList: state => {
            return state.organizationList
        },
        organization: state => {
            return state.organization
        },
        detailsChanged: state => {
            if (
                state.organization.details.name !== state.fetchedOrganization.name ||
                state.organization.details.tradeName !== state.fetchedOrganization.tradeName ||
                state.organization.details.type !== state.fetchedOrganization.type ||
                state.organization.details.taxNumber !== state.fetchedOrganization.taxNumber ||
                state.organization.details.registrationNumber !== state.fetchedOrganization.registration ||
                state.organization.details.operateInFuel !==
                areaOfOperationForFrontend(state.fetchedOrganization.areaOfOperation).operateInFuel ||
                state.organization.details.operateInMetals !==
                areaOfOperationForFrontend(state.fetchedOrganization.areaOfOperation).operateInMetals
            ) {
                return true
            }
            return false
        },
        bankingChanged: state => {
            if (hasBankAccount(state.fetchedOrganization) === false) {
                if (
                    state.organization.banking.accountNumber !== '' ||
                    state.organization.banking.accountType !== '' ||
                    state.organization.banking.bank !== '' ||
                    state.organization.banking.bankName !== '' ||
                    state.organization.banking.branchCode !== ''
                ) {
                    return true
                }
                return false
            }
            if (
                state.organization.banking.accountNumber !== state.fetchedOrganization.bankAccount.accountNumber ||
                state.organization.banking.accountType !== state.fetchedOrganization.bankAccount.accountType ||
                state.organization.banking.bank !== state.fetchedOrganization.bankAccount.bank ||
                state.organization.banking.bankName !== state.fetchedOrganization.bankAccount.bankName ||
                state.organization.banking.branchCode !== state.fetchedOrganization.bankAccount.branchCode
            ) {
                return true
            }
            return false
        },
        addressChanged: state => {
            if (hasAddress(state.fetchedOrganization) === false) {
                if (
                    state.organization.address.addressLine1 !== '' ||
                    state.organization.address.addressLine2 !== '' ||
                    state.organization.address.addressLine3 !== '' ||
                    state.organization.address.city !== '' ||
                    state.organization.address.area !== '' ||
                    state.organization.address.code !== ''
                ) {
                    return true
                } else {
                    return false
                }
            }
            if (
                state.organization.address.addressLine1 !== state.fetchedOrganization.addresses[0].line1 ||
                state.organization.address.addressLine2 !== state.fetchedOrganization.addresses[0].line2 ||
                state.organization.address.addressLine3 !== state.fetchedOrganization.addresses[0].line3 ||
                state.organization.address.city !== state.fetchedOrganization.addresses[0].city ||
                state.organization.address.area !== state.fetchedOrganization.addresses[0].area ||
                state.organization.address.code !== state.fetchedOrganization.addresses[0].code
            ) {
                return true
            }
            return false
        },
        documentsChanged: state => {
            if (
                state.organization.documents.letterhead !== null ||
                state.organization.documents.cipc !== null ||
                state.organization.documents.wholesale !== null ||
                state.organization.documents.sadpmr !== null
            ) {
                return true
            }
            return false
        },
    },
}
