import Vue from 'vue'

// Register global components here
import Icon from '@/modules/common/components/global/gl-icon.vue'
import Spinner from '@/modules/common/components/global/gl-spinner.vue'
import Button from '@/modules/common/components/global/gl-button.vue'
import Alert from '@/modules/common/components/global/gl-alert.vue'
import ErrorAlert from '@/modules/common/components/global/gl-error-alert.vue'
import Progress from '@/modules/common/components/global/gl-progress.vue'
import InfoItem from '@/modules/common/components/global/gl-info-item.vue'

import SwitchInput from '@/modules/common/components/global/gl-switch-input.vue'
import CurrencyInput from '@/modules/common/components/global/gl-currency-input.vue'
import ToggleInput from '@/modules/common/components/global/gl-toggle-input.vue'
import TextInput from '@/modules/common/components/global/gl-text-input.vue'
import TextAreaInput from '@/modules/common/components/global/gl-text-area-input.vue'
import EmailInput from '@/modules/common/components/global/gl-email-input.vue'
import MobileInput from '@/modules/common/components/global/gl-mobile-input.vue'
import NumberInput from '@/modules/common/components/global/gl-number-input.vue'
import PasswordInput from '@/modules/common/components/global/gl-password-input.vue'
import SelectInput from '@/modules/common/components/global/gl-select-input.vue'
import CheckboxInput from '@/modules/common/components/global/gl-checkbox-input.vue'

Vue.component('GL-Icon', Icon)
Vue.component('GL-Spinner', Spinner)
Vue.component('GL-Button', Button)
Vue.component('GL-Alert', Alert)
Vue.component('GL-ErrorAlert', ErrorAlert)
Vue.component('GL-Progress', Progress)
Vue.component('GL-InfoItem', InfoItem)

Vue.component('GL-SwitchInput', SwitchInput)
Vue.component('GL-CurrencyInput', CurrencyInput)
Vue.component('GL-ToggleInput', ToggleInput)
Vue.component('GL-TextInput', TextInput)
Vue.component('GL-TextAreaInput', TextAreaInput)
Vue.component('GL-EmailInput', EmailInput)
Vue.component('GL-MobileInput', MobileInput)
Vue.component('GL-NumberInput', NumberInput)
Vue.component('GL-PasswordInput', PasswordInput)
Vue.component('GL-SelectInput', SelectInput)
Vue.component('GL-CheckboxInput', CheckboxInput)

import VueTelInput from 'vue-tel-input'
Vue.use(VueTelInput)
