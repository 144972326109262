<template>
    <div class="uk-position-relative" v-on-clickaway="hideSearch">
        <GL-TextInput
            :value="value"
            :label="label"
            ref="input"
            @input="updateValue($event)"
            @focus="showSuggestions = true"
            :error-messages="errorMessages"
        />
        <div class="dropdown-list" :class="{ 'uk-hidden': !showSuggestions }">
            <div
                @click="suggestionSelect(suggestion)"
                class="dropdown-item"
                v-for="suggestion in suggestions"
                :key="suggestion"
            >
                {{ suggestion }}
            </div>
        </div>
    </div>
</template>

<script>
import { mixin as clickaway } from '@/scripts/click-away'

export default {
    name: 'SearchableSelect',
    mixins: [clickaway],
    props: {
        searchData: {
            type: Array,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        value: {
            type: String,
            default: '',
        },
        errorMessages: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            showSuggestions: false,
            userSelected: false,
        }
    },
    computed: {
        suggestions() {
            if (!this.value || this.value.length === 0) {
                return this.searchData
            }
            const query = this.value.toLowerCase()
            const suggestions = []
            for (const item of this.searchData) {
                if (item.toLowerCase().includes(query)) {
                    suggestions.push(item)
                }
            }
            return suggestions
        },
    },
    methods: {
        suggestionSelect(value) {
            this.updateValue(value)
            this.showSuggestions = false
        },
        hideSearch() {
            this.showSuggestions = false
        },
        updateValue(e) {
            this.$emit('input', e)
        },
    },
}
</script>

<style lang="scss" scoped>
.dropdown-list {
    position: absolute;
    width: 90%;
    max-height: 20rem;
    margin-top: 4px;
    z-index: 100;
    overflow-y: auto;
    overflow-x: hidden;
    background: #ffffff;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    border-radius: 0.25rem;
}

.dropdown-item {
    width: 100%;
    padding: 1rem;
    cursor: pointer;
    transition: 300ms;
    &:hover {
        background-color: #e2e2e4;
    }
}
</style>
