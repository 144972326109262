<template>
    <div>
        <div class="uk-text-center uk-padding-small uk-margin">
            <h3 class="uk-text-secondary uk-margin-remove">
                Describe in detail the vehicle you are {{ userActionText }}
            </h3>
        </div>
        <div class="uk-flex uk-flex-center">
            <form class="uk-width-4-5@m">
                <div class="uk-card uk-card-default uk-card-body uk-width-1-1 uk-margin-small">
                    <div uk-grid>
                        <GL-TextInput
                            class="uk-width-1-2@s uk-margin-small-top"
                            label="Transaction Name*"
                            tooltip="Give your transaction a name i.e. BMW Z4 2010. The transaction name can also include an invoice number or Purchase Order number."
                            v-model="title"
                            :error-messages="validation.requiredField(title)"
                        />
                        <GL-SelectInput
                            v-model="vehicleType"
                            class="uk-width-1-2@s uk-margin-small-top"
                            label="Type of Vehicle*"
                            :option-list="vehicleTypes"
                            :error-messages="validation.requiredField(vehicleType)"
                        />
                        <GL-SelectInput
                            v-if="vehicleType === 'MOTOR_VEHICLE'"
                            v-model="vehicleMake"
                            class="uk-width-1-2@s uk-margin-small-top"
                            label="Make*"
                            :option-list="vehicleMakes"
                            :error-messages="validation.requiredField(vehicleMake)"
                        />
                        <GL-TextInput
                            v-else
                            class="uk-width-1-2@s uk-margin-small-top"
                            label="Make*"
                            v-model="vehicleMake"
                            :error-messages="validation.requiredField(vehicleMake)"
                        />
                        <GL-TextInput
                            class="uk-width-1-2@s uk-margin-small-top"
                            label="Model*"
                            v-model="vehicleModel"
                            :error-messages="validation.requiredField(vehicleModel)"
                        />
                        <GL-NumberInput
                            class="uk-width-1-4@s uk-margin-small-top"
                            numeric
                            right-align
                            label="Year*"
                            v-model="vehicleYear"
                            :error-messages="validation.requiredField(vehicleYear)"
                        />
                        <GL-NumberInput
                            v-if="vehicleType === 'MOTOR_VEHICLE' || vehicleType === 'MOTOR_CYCLE'"
                            class="uk-width-1-4@s uk-margin-small-top uk-animation-slide-right-small"
                            label="Mileage (Km)*"
                            right-align
                            v-model="vehicleKilometers"
                            :error-messages="validation.requiredField(vehicleKilometers)"
                        />
                        <GL-TextInput
                            v-if="vehicleType === 'MOTOR_VEHICLE' || vehicleType === 'MOTOR_CYCLE'"
                            class="uk-width-1-2@s uk-margin-small-top uk-animation-slide-right-small"
                            label="VIN Number"
                            tooltip="leave blank if the VIN number is unavailable."
                            v-model="vehicleVIN"
                        />
                        <GL-NumberInput
                            v-if="vehicleType === 'AIRPLANE' || vehicleType === 'HELICOPTER'"
                            class="uk-width-1-3@s uk-margin-small-top uk-animation-slide-right-small"
                            label="Airframe Total Airtime (Hours)"
                            tooltip="Leave blank if the airframe total airtime is unavailable"
                            v-model="vehicleAirframeHours"
                        />
                        <GL-NumberInput
                            v-if="vehicleType === 'AIRPLANE' || vehicleType === 'HELICOPTER'"
                            class="uk-width-1-4@s uk-margin-small-top uk-animation-slide-right-small"
                            label="Engine Time (Hours)"
                            tooltip="Leave blank if the engine time is unavailable"
                            v-model="vehicleEngineHours"
                        />
                        <GL-TextAreaInput
                            class="uk-width-1-1 uk-margin-small-top"
                            label="Description*"
                            tooltip="Give the transaction a detailed description. Provide as much detail as possible including the event that needs to take place in order for the funds to be released. You will be able to upload documents to this transaction later on (you can reference these documents in the description box below)."
                            v-model="description"
                            :error-messages="validation.requiredField(description)"
                        />
                        <GL-SelectInput
                            class="uk-width-1-2@s uk-margin-small-top"
                            v-model="feeAllocation"
                            label="Who Will Pay The Escrow Fee?*"
                            :option-list="feeAllocationOptions"
                            :error-messages="validation.requiredField(feeAllocation)"
                        />
                        <div v-if="!drawdown" class="uk-width-1-2@s uk-visible@s"></div>
                        <GL-CurrencyInput
                            v-model="value"
                            class="uk-width-1-2@s uk-margin-small-top"
                            label="price*"
                            :error-messages="validation.allocationValue(value)"
                        />
                        <GL-NumberInput
                            v-if="!drawdown"
                            v-model="daysToDeliver"
                            right-align
                            class="uk-width-1-4@s uk-margin-small-top"
                            label="Delivery Days*"
                            tooltip="Delivery days is the expected duration of the delivery of the vehicle."
                            :error-messages="validation.requiredField(daysToDeliver)"
                        />
                        <GL-NumberInput
                            v-if="!drawdown"
                            v-model="daysToInspect"
                            right-align
                            class="uk-width-1-4@s uk-margin-small-top"
                            tooltip="Inspection days is the number of days the buyer has to inspect the vehicle"
                            label="Inspection Days*"
                            :error-messages="validation.requiredField(daysToInspect)"
                        />
                    </div>
                </div>
            </form>
        </div>

        <PartiesFees />
        <TransactionInformation />
        <CreateTxNavButtons :disable-next="!allowProgressVehicle" />
    </div>
</template>

<script>
import { vehicleMakes, vehicleTypes } from '../../../../../../constants/vehicles'
import CreateTxNavButtons from '../../common/create-transaction-navigation'
import PartiesFees from '../../common/parties-fees/index'
import validation from '@/scripts/transaction-create-field-validation'
import TransactionInformation from '../../transaction-information/index'
import TransactionCreateMixin from '../../transaction-create-mixin'

export default {
    name: 'VehicleDetails',
    components: { TransactionInformation, PartiesFees, CreateTxNavButtons },
    mixins: [TransactionCreateMixin],
    data() {
        return {
            vehicleMakes,
            vehicleTypes,
            validation,
        }
    },
    computed: {
        userActionText() {
            if (this.$store.state.txCreate.parties.parties[0].role === 'BUYER') {
                return 'buying'
            } else if (this.$store.state.txCreate.parties.parties[0].role === 'SELLER') {
                return 'selling'
            }
            return 'brokering'
        },
        vehicleType: {
            get() {
                return this.$store.state.txCreate.transaction.auxiliaryData.vehicleType
            },
            set(v) {
                this.$store.commit('txCreate/setTransactionAuxiliaryField', { key: 'vehicleType', value: v })
            },
        },
        vehicleMake: {
            get() {
                return this.$store.state.txCreate.transaction.auxiliaryData.vehicleMake
            },
            set(v) {
                this.$store.commit('txCreate/setTransactionAuxiliaryField', { key: 'vehicleMake', value: v })
            },
        },
        vehicleModel: {
            get() {
                return this.$store.state.txCreate.transaction.auxiliaryData.vehicleModel
            },
            set(v) {
                this.$store.commit('txCreate/setTransactionAuxiliaryField', { key: 'vehicleModel', value: v })
            },
        },
        vehicleVIN: {
            get() {
                return this.$store.state.txCreate.transaction.auxiliaryData.vehicleVIN
            },
            set(v) {
                this.$store.commit('txCreate/setTransactionAuxiliaryField', { key: 'vehicleVIN', value: v })
            },
        },
        vehicleYear: {
            get() {
                return this.$store.state.txCreate.transaction.auxiliaryData.vehicleYear
            },
            set(v) {
                this.$store.commit('txCreate/setTransactionAuxiliaryField', { key: 'vehicleYear', value: v })
            },
        },
        vehicleKilometers: {
            get() {
                return this.$store.state.txCreate.transaction.auxiliaryData.vehicleKilometers
            },
            set(v) {
                this.$store.commit('txCreate/setTransactionAuxiliaryField', { key: 'vehicleKilometers', value: v })
            },
        },
        vehicleEngineHours: {
            get() {
                return this.$store.state.txCreate.transaction.auxiliaryData.vehicleEngineHours
            },
            set(v) {
                this.$store.commit('txCreate/setTransactionAuxiliaryField', { key: 'vehicleEngineHours', value: v })
            },
        },
        vehicleAirframeHours: {
            get() {
                return this.$store.state.txCreate.transaction.auxiliaryData.vehicleAirframeHours
            },
            set(v) {
                this.$store.commit('txCreate/setTransactionAuxiliaryField', { key: 'vehicleAirframeHours', value: v })
            },
        },
        feeAllocationOptions() {
            return this.$store.getters['txCreate/feeAllocations']
        },
        allowProgressVehicle() {
            if (!(this.vehicleYear && this.vehicleMake && this.vehicleModel && this.vehicleType)) {
                return false
            }

            if (this.vehicleType === 'MOTOR_VEHICLE' || this.vehicleType === 'MOTOR_CYCLE') {
                if (!this.vehicleKilometers) {
                    return false
                }
            }
            return this.allowProgress
        },
    },
    watch: {
        vehicleType() {
            if (this.vehicleType === 'MOTOR_VEHICLE' || this.vehicleType === 'MOTOR_CYCLE') {
                this.vehicleEngineHours = 0
                this.vehicleAirframeHours = 0
            } else if (this.vehicleType === 'AIRPLANE' || this.vehicleType === 'HELICOPTER') {
                this.vehicleVIN = ''
                this.vehicleKilometers = 0
            } else if (this.vehicleType === 'BOAT') {
                this.vehicleVIN = ''
                this.vehicleKilometers = 0
                this.vehicleEngineHours = 0
                this.vehicleAirframeHours = 0
            }
        },
    },
}
</script>
