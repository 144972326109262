import { formatCurrency, formatNumber } from '@/scripts/filters'
import validation from '@/scripts/validation/validation'

export default {
    getIndustry(industryBase) {
        let industry = industryBase
        industry.buyerName = 'Customer'
        industry.sellerName = 'Supplier'
        industry.allocationName = 'Load'
        industry.allocationsName = 'Loads'

        industry.allocationAmendableValues = function (allocation, transaction) {
            if (!transaction) {
                console.error('Transaction is required to compute FUEL amendableValues')
            }
            if (!transaction.auxiliaryData) {
                console.error('Transaction auxiliaryData is required to compute FUEL amendableValues')
            }
            const amendableValues = []
            amendableValues.push({
                modeltarget: 'units',
                value: allocation.units,
                component: 'GL-NumberInput',
                componentprops: {
                    label: 'Litres',
                    showSpin: true,
                    class: 'uk-width-1-2',
                },
                validations: [validation.list.required_allow_zero(`Please provide the litres`)],
            })
            amendableValues.push({
                modeltarget: 'unitCost',
                value: allocation.unitCost,
                component: 'GL-CurrencyInput',
                componentprops: {
                    label: 'Price per litre',
                    showSpin: true,
                    class: 'uk-width-1-2',
                },
                validations: [validation.list.required(`Please provide the price per litre`)],
            })
            amendableValues.push({
                modeltarget: 'daysToDeliver',
                value: allocation.daysToDeliver,
                component: 'GL-NumberInput',
                componentprops: {
                    label: 'DAYS FOR DELIVERY',
                    showSpin: true,
                    class: 'uk-width-1-2',
                },
                validations: [validation.list.required('Please provide the days to deliver')],
            })
            amendableValues.push({
                modeltarget: 'daysToInspect',
                value: allocation.daysToInspect,
                component: 'GL-NumberInput',
                componentprops: {
                    label: 'DAYS FOR INSPECTION',
                    showSpin: true,
                    class: 'uk-width-1-2',
                },
                validations: [validation.list.required('Please provide the days to deliver')],
            })

            return amendableValues
        }
        industry.allocationAmendmentDelta = function (allocation, amendment) {
            let changeList = []
            if (allocation.units !== amendment.units) {
                changeList.push(
                    `Litreage changed from ${formatNumber(allocation.units)} to ${formatNumber(
                        amendment.units,
                    )} litres`,
                )
            }
            if (allocation.unitCost !== amendment.unitCost) {
                changeList.push(
                    `Price per liter changed from R${formatCurrency(allocation.unitCost)} to R${formatCurrency(
                        amendment.unitCost,
                    )}`,
                )
            }
            if (amendment.daysToDeliver > allocation.daysToDeliver) {
                changeList.push(
                    `The days to deliver was changed from ${allocation.daysToDeliver} to ${amendment.daysToDeliver}`,
                )
            }
            if (amendment.daysToInspect > allocation.daysToInspect) {
                changeList.push(
                    `The days to inspect was changed from ${allocation.daysToInspect} to ${amendment.daysToInspect}`,
                )
            }
            if (amendment.unitCost * amendment.units < allocation.unitCost * allocation.units) {
                changeList.push(
                    `Refund to customer R${formatCurrency(
                        allocation.unitCost * allocation.units - amendment.unitCost * amendment.units,
                    )}`,
                )
            }
            return changeList
        }

        return industry
    },
}
