<template>
    <GL-Alert v-if="errorMessage" type="danger">
        {{ errorMessage }}
    </GL-Alert>
</template>

<script>
export default {
    name: 'ErrorAlert',
    props: {
        errorMessage: {
            type: String,
            default: '',
        },
    },
}
</script>
