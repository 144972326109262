<template>
    <div
        @click="cardSelect"
        class="card"
        :disabled="disabled"
        :class="{ selected: selected, 'card-wide': !!description }"
    >
        <div>
            <GL-Icon :path="iconPath" :size="iconSize" class="icon" />
            <div class="text">{{ text }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CardButton',
    props: {
        text: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        description: {
            type: String,
            default: '',
        },
        iconPath: {
            type: String,
            default: 'times.svg',
        },
        iconSize: {
            type: Number,
            default: 3,
        },
        selected: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        cardSelect() {
            if (this.disabled) {
                return
            }
            this.$emit('select', this.text)
        },
    },
}
</script>

<style scoped>
.icon {
    display: inline-block;
    margin: 1rem;
}
.text {
    font-size: 1rem;
    text-transform: uppercase;
}
.description-outer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.description {
    padding-top: 1rem;
    padding-right: 1rem;
}
.card {
    width: 8rem;
    height: 8rem;
    color: #9fa3a9;

    text-align: center;

    cursor: pointer;

    border-style: solid;
    border-width: 1px;
    border-color: #e2e2e4;
    border-radius: 0.5rem;

    display: inline-block;

    transition-duration: var(--transition-normal);
}
.card:hover:enabled {
    -webkit-box-shadow: 10px 10px 20px -16px rgba(118, 118, 118, 1);
    -moz-box-shadow: 10px 10px 20px -16px rgba(118, 118, 118, 1);
    box-shadow: 10px 10px 20px -16px rgba(118, 118, 118, 1);
}
.card[disabled] {
    cursor: default;
    opacity: 0.5;
}
.card-wide {
    /*min-width: 23rem;*/
    width: 22rem;
}
.selected {
    color: white;
    background-color: #e50045;
    border-color: #e50045;
}
</style>
