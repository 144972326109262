<template>
    <div class="uk-animation-slide-top-small uk-flex uk-flex-center uk-margin-small-left uk-margin-small-right">
        <div class="uk-width-4-5@m">
            <div class="uk-text-center">
                <h3 class="uk-text-center uk-text-secondary">
                    Delivery of the product will take place at the following address.
                </h3>
                <p class="uk-text-center uk-text-bold">
                    Please note that these address details will be disclosed to the other party.
                </p>
            </div>
            <div class="uk-card uk-card-default uk-padding-small uk-width-1-1">
                <div class="uk-margin-small-left uk-margin-small-bottom uk-margin-small-right">
                    <div class="uk-grid-small" uk-grid>
                        <div class="uk-width-1-2@s uk-margin-small-top">
                            <SearchableSelect
                                v-model="fuelZone"
                                label="Fuel Zone"
                                :search-data="fuelZones"
                                :error-messages="validation.fuelZone(fuelZone)"
                            />
                        </div>
                        <div class="uk-width-1-2@s uk-margin-small-top">
                            <SearchableSelect
                                v-model="magisterialDistrict"
                                label="Magisterial District"
                                :search-data="magisterialDistricts"
                                :error-messages="validation.magisterialDistrict(magisterialDistrict)"
                                :class="{
                                    'uk-invisible': !magisterialDistricts.length,
                                    'uk-animation-slide-right-small': magisterialDistricts.length,
                                }"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div
                :class="{ 'uk-invisible': !(magisterialDistrict && fuelZone) }"
                class="uk-animation-slide-top-small uk-width-1-1 uk-margin-top"
            >
                <AddressForm hide-sub-heading hide-heading />
            </div>
        </div>
    </div>
</template>

<script>
import SearchableSelect from '@/modules/common/components/input/searchable-select'
import fuelZones from '../../../../../../constants/fuelZones'
import AddressForm from '../address-form'
import validation from '@/scripts/transaction-create-field-validation'
export default {
    name: 'CODFuelDeliveryAddress',
    components: { SearchableSelect, AddressForm },
    data() {
        return {
            validation,
        }
    },
    mounted() {
        this.$store.commit('txCreate/deliveryAddressRequired', true)
    },
    computed: {
        fuelZones() {
            const zones = fuelZones.map(zone => zone.zone)
            return zones.filter((zone, index, self) => {
                return self.indexOf(zone) === index
            })
        },
        magisterialDistricts() {
            const districts = fuelZones.filter(zone => zone.zone === this.fuelZone)
            return districts.map(district => `${district.magisterialDistrict} - ${district.province}`)
        },
        fuelZone: {
            get() {
                return this.$store.state.txCreate.transaction.auxiliaryData.fuelZone
            },
            set(value) {
                this.$store.commit('txCreate/setTransactionAuxiliaryField', { key: 'fuelZone', value })
            },
        },
        magisterialDistrict: {
            get() {
                return this.$store.state.txCreate.transaction.auxiliaryData.fuelMagisterialDistrict
            },
            set(value) {
                this.$store.commit('txCreate/setTransactionAuxiliaryField', {
                    key: 'fuelMagisterialDistrict',
                    index: 0,
                    value,
                })
            },
        },
    },
    watch: {
        fuelZone() {
            this.magisterialDistrict = ''
        },
    },
}
</script>

<style scoped>
.content {
    margin: 0.5rem;
}
</style>
