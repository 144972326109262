export const constantsCommodities = [
    {
        value: '',
        key: '',
        category: 'ALL',
    },
    {
        value: 'Aluminium',
        key: 'ALUMINIUM',
        category: 'NON_PRECIOUS',
    },
    {
        value: 'Brass',
        key: 'BRASS',
        category: 'NON_PRECIOUS',
    },
    {
        value: 'Bronze',
        key: 'BRONZE',
        category: 'NON_PRECIOUS',
    },
    {
        value: 'Chrome Concentrate',
        key: 'CHROME CONCENTRATE',
        category: 'NON_PRECIOUS',
    },
    {
        value: 'Chrome Ore',
        key: 'CHROME ORE',
        category: 'NON_PRECIOUS',
    },
    {
        value: 'Coal',
        key: 'COAL',
        category: 'NON_METALLIC',
    },
    {
        value: 'Coking',
        key: 'COKING',
        category: 'NON_METALLIC',
    },
    {
        value: 'Thermal',
        key: 'THERMAL',
        category: 'NON_METALLIC',
    },
    {
        value: 'Cobalt',
        key: 'COBALT',
        category: 'NON_PRECIOUS',
    },
    {
        value: 'Copper',
        key: 'COPPER',
        category: 'NON_PRECIOUS',
    },
    {
        value: 'Diamonds',
        key: 'DIAMONDS',
        category: 'PRECIOUS',
    },
    {
        value: 'Gemstones',
        key: 'GEMSTONES',
        category: 'NON_METALLIC',
    },
    {
        value: 'Gold',
        key: 'GOLD',
        category: 'PRECIOUS',
    },
    {
        value: 'Iron Ore',
        key: 'IRON ORE',
        category: 'NON_PRECIOUS',
    },
    {
        value: 'Lead',
        key: 'LEAD',
        category: 'NON_PRECIOUS',
    },
    {
        value: 'Manganese',
        key: 'MANGANESE',
        category: 'NON_PRECIOUS',
    },
    {
        value: 'Nickel',
        key: 'NICKEL',
        category: 'NON_PRECIOUS',
    },
    {
        value: 'Palladium',
        key: 'PALLADIUM',
        category: 'PRECIOUS',
    },
    {
        value: 'Platinum',
        key: 'PLATINUM',
        category: 'PRECIOUS',
    },
    {
        value: 'Rhodium',
        key: 'RHODIUM',
        category: 'PRECIOUS',
    },
    {
        value: 'Silicon',
        key: 'SILICON',
        category: 'NON_METALLIC',
    },
    {
        value: 'Silver',
        key: 'SILVER',
        category: 'NON_PRECIOUS',
    },
    {
        value: 'Stainless Steel',
        key: 'STAINLESS STEEL',
        category: 'NON_PRECIOUS',
    },
    {
        value: 'Steel',
        key: 'STEEL',
        category: 'NON_PRECIOUS',
    },
    {
        value: 'Titanium',
        key: 'TITANIUM',
        category: 'NON_PRECIOUS',
    },
    {
        value: 'Zinc',
        key: 'ZINC',
        category: 'NON_PRECIOUS',
    },
    {
        value: 'Other',
        key: 'OTHER',
        category: 'ALL',
    },
]
