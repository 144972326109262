function includesNumber(value) {
    const matches = value.match(/\d+/g)
    if (matches == null) {
        return false
    }
    return true
}

function includesUpperCase(value) {
    if (value.toLowerCase() === value) {
        return false
    }
    return true
}

function includesLowerCase(value) {
    if (value.toUpperCase() === value) {
        return false
    }
    return true
}

function strongPassword(value) {
    const password = value.toLowerCase()
    // Contains password
    if (password.includes('password')) {
        return false
    }

    // Repeating symbols
    let sameCount = 0
    for (let i = 0; i < password.length - 1; i++) {
        if (password[i] === password[i + 1]) {
            sameCount++
        } else {
            sameCount = 0
        }
        if (sameCount >= 4) {
            return false
        }
    }

    // Increment
    let incCount = 0
    for (let i = 0; i < password.length - 1; i++) {
        const a = parseInt(password[i])
        const b = parseInt(password[i + 1])

        if (isNaN(a) === false && isNaN(b) === false) {
            if (a + 1 === b) {
                incCount++
            } else {
                incCount = 0
            }
        }

        if (incCount >= 3) {
            return false
        }
    }

    return true
}

export function password() {
    return function (value) {
        const errors = []

        if (value.length < 8) {
            errors.push('Password must be at least 8 characters long')
        }

        if (includesNumber(value) === false) {
            errors.push('Password must contain a number')
        }

        if (includesUpperCase(value) === false) {
            errors.push('Password must contain an uppercase character')
        }

        if (includesLowerCase(value) === false) {
            errors.push('Password must contain a lowercase character')
        }

        if (strongPassword(value) === false) {
            errors.push('Password must be strong')
        }
        return errors
    }
}
