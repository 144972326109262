export const vehicleMakes = [
    { key: 'Abarth', value: 'Abarth' },
    { key: 'Alfa Romeo', value: 'Alfa Romeo' },
    { key: 'Asia Wing', value: 'Asia Wing' },
    { key: 'Aston Martin', value: 'Aston Martin' },
    { key: 'Audi', value: 'Audi' },
    { key: 'Backdraft', value: 'Backdraft' },
    { key: 'Baic', value: 'Baic' },
    { key: 'BAW', value: 'BAW' },
    { key: 'Bentley', value: 'Bentley' },
    { key: 'BMW', value: 'BMW' },
    { key: 'Cadillac', value: 'Cadillac' },
    { key: 'CAM', value: 'CAM' },
    { key: 'Chana', value: 'Chana' },
    { key: 'Changan', value: 'Changan' },
    { key: 'Chery', value: 'Chery' },
    { key: 'Chevrolet', value: 'Chevrolet' },
    { key: 'Chrysler', value: 'Chrysler' },
    { key: 'Citroen', value: 'Citroen' },
    { key: 'CMC', value: 'CMC' },
    { key: 'Dacia', value: 'Dacia' },
    { key: 'Daewoo', value: 'Daewoo' },
    { key: 'Daihatsu', value: 'Daihatsu' },
    { key: 'Datsun', value: 'Datsun' },
    { key: 'Daimler', value: 'Daimler' },
    { key: 'DFM', value: 'DFM' },
    { key: 'DFSK', value: 'DFSK' },
    { key: 'DMC', value: 'DMC' },
    { key: 'Dodge', value: 'Dodge' },
    { key: 'DTV', value: 'DTV' },
    { key: 'Emgrand', value: 'Emgrand' },
    { key: 'FAW', value: 'FAW' },
    { key: 'Ferrari', value: 'Ferrari' },
    { key: 'Fiat', value: 'Fiat' },
    { key: 'Force', value: 'Force' },
    { key: 'Ford', value: 'Ford' },
    { key: 'Foton', value: 'Foton' },
    { key: 'Fudi', value: 'Fudi' },
    { key: 'Fuso', value: 'Fuso' },
    { key: 'GAZ', value: 'GAZ' },
    { key: 'Geely', value: 'Geely' },
    { key: 'Golden Journey', value: 'Golden Journey' },
    { key: 'GoNow', value: 'GoNow' },
    { key: 'GWM', value: 'GWM' },
    { key: 'Hafei', value: 'Hafei' },
    { key: 'Haise', value: 'Haise' },
    { key: 'Hajadu', value: 'Hajadu' },
    { key: 'Haval', value: 'Haval' },
    { key: 'Hino', value: 'Hino' },
    { key: 'Honda', value: 'Honda' },
    { key: 'Hummer', value: 'Hummer' },
    { key: 'Hyundai', value: 'Hyundai' },
    { key: 'Infiniti', value: 'Infiniti' },
    { key: 'Isuzu', value: 'Isuzu' },
    { key: 'Iveco', value: 'Iveco' },
    { key: 'Jac', value: 'Jac' },
    { key: 'Jaguar', value: 'Jaguar' },
    { key: 'Jeep', value: 'Jeep' },
    { key: 'Jinbeu', value: 'Jinbeu' },
    { key: 'JMC', value: 'JMC' },
    { key: 'Kia', value: 'Kia' },
    { key: 'KTM', value: 'KTM' },
    { key: 'Lada', value: 'Lada' },
    { key: 'Lamborghini', value: 'Lamborghini' },
    { key: 'Lancia', value: 'Lancia' },
    { key: 'Land Rover', value: 'Land Rover' },
    { key: 'Lexus', value: 'Lexus' },
    { key: 'Lotus', value: 'Lotus' },
    { key: 'Mahindra', value: 'Mahindra' },
    { key: 'Mandarin', value: 'Mandarin' },
    { key: 'Maserati', value: 'Maserati' },
    { key: 'Maybach', value: 'Maybach' },
    { key: 'Maxus', value: 'Maxus' },
    { key: 'Mazda', value: 'Mazda' },
    { key: 'Meiya', value: 'Meiya' },
    { key: 'Mercedes-Benz', value: 'Mercedes-Benz' },
    { key: 'MG', value: 'MG' },
    { key: 'Mini', value: 'Mini' },
    { key: 'Mitsubishi', value: 'Mitsubishi' },
    { key: 'Morgan', value: 'Morgan' },
    { key: 'Nanfeng', value: 'Nanfeng' },
    { key: 'Nissan', value: 'Nissan' },
    { key: 'Noble', value: 'Noble' },
    { key: 'Opel', value: 'Opel' },
    { key: 'Peugeot', value: 'Peugeot' },
    { key: 'Polarsun', value: 'Polarsun' },
    { key: 'Porsche', value: 'Porsche' },
    { key: 'Proton', value: 'Proton' },
    { key: 'Renault', value: 'Renault' },
    { key: 'Rolls-Royce', value: 'Rolls-Royce' },
    { key: 'Rover', value: 'Rover' },
    { key: 'Saab', value: 'Saab' },
    { key: 'SAIC', value: 'SAIC' },
    { key: 'Seat', value: 'Seat' },
    { key: 'Smart', value: 'Smart' },
    { key: 'Soyat', value: 'Soyat' },
    { key: 'SsangYong', value: 'SsangYong' },
    { key: 'Subaru', value: 'Subaru' },
    { key: 'Suzuki', value: 'Suzuki' },
    { key: 'Tata', value: 'Tata' },
    { key: 'Toyota', value: 'Toyota' },
    { key: 'TVR', value: 'TVR' },
    { key: 'Volkswagen', value: 'Volkswagen' },
    { key: 'Volvo', value: 'Volvo' },
    { key: 'Zotye', value: 'Zotye' },
    { key: 'ZX Auto', value: 'ZX Auto' },
    { key: 'Other', value: 'Other' },
]

export const vehicleTypes = [
    {
        key: 'MOTOR_VEHICLE',
        value: 'Motor vehicle',
    },
    {
        key: 'MOTOR_CYCLE',
        value: 'Motor Cycle',
    },
    {
        key: 'BOAT',
        value: 'Boat',
    },
    {
        key: 'AIRPLANE',
        value: 'Airplane',
    },
    {
        key: 'HELICOPTER',
        value: 'Helicopter',
    },
]
