<template>
    <InputBase :label="label" :tooltip="tooltip" :error-messages="errorMessages">
        <div>
            <div
                class="option"
                name="fee-type-select-flat"
                :class="{ selected: value === 'FLAT' }"
                @click="select('FLAT')"
            >
                Fixed Fee
            </div>
            <div
                class="option"
                name="fee-type-select-percent"
                :class="{ selected: value === 'PERCENT' }"
                @click="select('PERCENT')"
            >
                Percentage
            </div>
        </div>
    </InputBase>
</template>

<script>
import InputBase from '@/modules/common/components/global/input-base'
export default {
    name: 'FeeTypeSelect',
    components: { InputBase },
    props: {
        label: {
            type: String,
            default: '',
        },
        value: {
            type: String,
            default: '',
        },
        tooltip: {
            type: String,
            default: '',
        },
        errorMessages: {
            type: Array,
            default() {
                return []
            },
        },
        name: {
            type: String,
            default: '',
        },
    },
    methods: {
        select(v) {
            this.$emit('input', v)
        },
    },
}
</script>

<style lang="scss" scoped>
$ts-red: #e50045;
.option {
    border: 1px solid $ts-red;
    color: $ts-red;
    border-radius: 0.5rem;
    display: inline-block;
    padding: 0.5rem 0.25rem;
    width: 42%;
    max-width: 6rem;
    min-width: 84px;
    text-align: center;
    margin-right: 0.25rem;
    transition-duration: var(--transition-normal);
    &.selected {
        background-color: $ts-red;
        color: white;
    }
    &:hover {
        cursor: pointer;
        color: white;
        background-color: $ts-red;
    }
}
</style>
