<template>
    <div class="uk-display-inline">
        <!-- <span :uk-spinner="'ratio: ' + size"> </span> -->
        <div ref="lottie_target" :style="`width: ${size + 1}rem; height: ${size + 1}rem; display:inline-block`"></div>
    </div>
</template>

<script>
import lottie from '@/scripts/lottie'
export default {
    name: 'GL-Spinner',
    props: {
        size: {
            type: Number,
            default: 1,
        },
        animationFile: {
            type: String,
            default: 'loader_3.json',
        },
    },
    mounted() {
        lottie.setQuality('low')
        this.animation = lottie.loadAnimation({
            container: this.$refs['lottie_target'],
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: '/animations/' + this.animationFile,
            // rendererSettings: {
            //     preserveAspectRatio: 'xMaxYMax slice'
            // }
        })
        // this.animation.setSpeed(0.7);
    },
}
</script>
