import IndustryService from './scripts/service-industry'
import SeenService from './scripts/service-seen'
var transactionHelper = {
    methods: {
        allocationDeliveryInstructions(allocation) {
            try {
                return JSON.parse(allocation.deliveryInstructions).deliveryInstructions
            } catch {
                return ''
            }
        },
    },
    computed: {
        transactionType() {
            return this.transaction.workflow
        },
        transaction() {
            return this.$store.state.transaction.transaction
        },
        auxiliaryData() {
            return JSON.parse(this.transaction.auxiliaryData)
        },
        owner() {
            return this.transaction.metadata.owner
        },
        role() {
            return this.transaction.metadata.activeParty.role
        },
        userAccepted() {
            return (
                this.transaction.metadata.activeParty.acceptance &&
                this.transaction.metadata.activeParty.acceptance.accepted
            )
        },
        hasManyParties() {
            return this.transaction.parties.length > 2
        },
        totalUnits() {
            let total = 0
            for (const allocation of this.transaction.allocations) {
                total += allocation.units
            }
            return total
        },
        otherParties() {
            if (this.hasManyParties) {
                return 'other parties'
            }
            const industry = IndustryService.getIndustry(this.transaction.industry)
            if (this.role === 'BUYER') {
                return industry.sellerName
            }
            if (this.role === 'SELLER') {
                return industry.buyerName
            }
        },
        ownerName() {
            const industry = IndustryService.getIndustry(this.transaction.industry)
            const owner = this.transaction.parties.find(party => party.owner)
            console.log('Owner', owner)
            // If details are not avaliable (confidential)
            if (!owner.details) {
                if (owner.role === 'BUYER') {
                    return 'the' + industry.buyerName
                }
                if (this.role === 'SELLER') {
                    return 'the' + industry.sellerName
                }
                if (owner.role === 'AGENT') {
                    return 'the Lead Agent'
                }
                return ''
            }
            if (owner.details.organization) {
                return owner.details.organization.tradeName || owner.details.organization.name
            }
            if (owner.details.user) {
                return owner.details.user.givenName
            }
        },
        sellerName() {
            const industry = IndustryService.getIndustry(this.transaction.industry)
            const seller = this.transaction.parties.find(party => party.role === 'SELLER')
            // If details are not avaliable (confidential)
            if (!seller.details) {
                return 'The ' + industry.sellerName
            }
            if (seller.details.organization) {
                return seller.details.organization.tradeName || seller.details.organization.name
            }
            if (seller.details.user) {
                return seller.details.user.givenName
            }
            return 'The ' + industry.sellerName
        },
        buyerName() {
            const industry = IndustryService.getIndustry(this.transaction.industry)
            const buyer = this.transaction.parties.find(party => party.role === 'BUYER')
            // If details are not avaliable (confidential)
            if (!buyer.details) {
                return 'The ' + industry.buyerName
            }
            if (buyer.details.organization) {
                return buyer.details.organization.tradeName || buyer.details.organization.name
            }
            if (buyer.details.user) {
                return buyer.details.user.givenName
            }
            return 'The ' + industry.buyerName
        },
        ECD() {
            if (this.hasMilestones === false) {
                if (!this.transaction.allocations[0].inspectBy) {
                    return null
                }
                // Replace dashes with slashes because Safari
                let ecd = new Date(this.transaction.allocations[0].inspectBy.replace(/-/g, "/"))
                return ecd.toLocaleDateString('en-GB')
            }
        },
        transactionPartyFilled() {
            if (this.userAccepted) {
                return this.transaction
            }

            let transaction_altered = JSON.parse(JSON.stringify(this.transaction))
            let context = this.$store.getters['context/context']

            if (context !== '__loading__') {
                let party = transaction_altered.parties.find(party => party.role === this.transaction.metadata.activeParty.role)

                party.avatar = context.avatar
                party.details = {}
                party.details.user = {
                    givenName: context.givenName,
                    familyName: context.familyName,
                }

                if (context.__typename && context.__typename.toUpperCase() === 'ORGANIZATION') {
                    party.details.organization = {
                        name: `${context.name}`,
                        registration: context.registration,
                        taxNumber: context.taxNumber,
                        tradeName: context.tradeName,
                        type: context.type,
                    }
                }
                for (let i = 0; i < transaction_altered.parties.length; i++) {
                    if (transaction_altered.parties[i].email === context.email) {
                        transaction_altered.parties[i] = party
                    }
                }

                for (let comment of transaction_altered.comments) {
                    if (comment.owner.name === context.email) {
                        comment.owner.avatar = context.avatar
                        comment.owner.name = context.name
                    }
                }
            }

            return transaction_altered
        },
        hasMilestones() {
            if (this.transaction.allocations.length > 1) {
                return true
            }
            return false
        },
        hasNewComments() {
            if (!this.transaction.comments || this.transaction.comments.length === 0) {
                return false
            }
            for (const comment of this.transaction.comments) {
                if (comment.isNew && SeenService.hasSeen(comment.id) === false) {
                    return true
                }
            }
            return false
        },
        hasNewFiles() {
            if (!this.transaction.files || this.transaction.files.length === 0) {
                return false
            }
            for (const file of this.transaction.files) {
                if (file.isNew && SeenService.hasSeen(file.id) === false) {
                    return true
                }
            }
            return false
        },
        hasAmendments() {
            for (const allocation of this.transaction.allocations) {
                if (allocation.amendments !== null && allocation.amendments.length > 0) {
                    return true
                }
            }
            return false
        },
        hasTrackingInformation() {
            if (this.transaction.allocations[0].deliveryInstructions) {
                const deliveryInstructions = JSON.parse(this.transaction.allocations[0].deliveryInstructions)
                if (deliveryInstructions.courier) {
                    return true
                }
            }
            return false
        },
        trackingInfo() {
            if (this.hasTrackingInformation) {
                return JSON.parse(this.transaction.allocations[0].deliveryInstructions)
            }
            return {}
        },
        deliveryInstructions() {
            try {
                return JSON.parse(this.transaction.allocations[0].deliveryInstructions).deliveryInstructions
            } catch {
                return ''
            }
        },
        hasActiveAmendments() {
            for (const allocation of this.transaction.allocations) {
                if (allocation.amendments !== null) {
                    for (const amendment of allocation.amendments) {
                        if (amendment.state === 'CREATED') {
                            return true
                        }
                    }
                }
            }
            return false
        },
    },
}

export default transactionHelper
