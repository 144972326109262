import {
    fees,
    VAT,
    minimumEscrowFeeIncludingVAT,
    minimumEscrowFeeExcludingVAT,
    escrowFeePerLitre,
} from '../constants/constants-escrow-fees'

export function fuelEscrowFeeExcludingVat(units) {
    const fee = units * escrowFeePerLitre
    if (fee < minimumEscrowFeeExcludingVAT) {
        return minimumEscrowFeeExcludingVAT
    }
    return fee
}

export function escrowFeeExcludingVat(value) {
    const fee = (value * rate(value)) / (1 + VAT)
    if (fee < minimumEscrowFeeExcludingVAT) {
        return minimumEscrowFeeExcludingVAT
    }
    return fee
}

export function vat(value) {
    return escrowFeeExcludingVat(value) * VAT
}

export function escrowFeeIncludingVat(value) {
    const fee = value * rate(value)
    if (fee < minimumEscrowFeeIncludingVAT) {
        return minimumEscrowFeeIncludingVAT
    }
    return fee
}

export function rate(value) {
    if (!value) {
        return 0
    }
    const fee = fees.GENERAL.find(fee => value >= fee.lower && value <= fee.upper)
    return fee.rate
}

export function partyEscrowFee(role, feeAllocation, escrowFeeIncludingVat) {
    if (feeAllocation === 'BUYER_SELLER_AGENT') {
        return escrowFeeIncludingVat / 3
    } else if (role === feeAllocation) {
        return escrowFeeIncludingVat
    } else if (feeAllocation.includes(role)) {
        return escrowFeeIncludingVat / 2
    }
    return 0
}

export function partyReceives(feeType, feeValue, transactionValue, transactionUnits) {
    const value = parseFloat(feeValue)
    if (feeType === 'FLAT') {
        return value
    } else if (feeType === 'PERCENT') {
        return transactionValue * (value / 100)
    } else if (feeType === 'PER_UNIT') {
        return transactionUnits * value
    }
}

export function partyBeneficiaryFees(role, allParties, transactionValue, transactionUnits) {
    if (role !== 'AGENT' && role !== 'BUYER' && role !== 'SELLER') {
        return null
    }

    const fees = []
    const beneficiaries = allParties.filter(
        party => party.role !== 'AGENT' && party.role !== 'SELLER' && party.role !== 'BUYER' && party.role !== 'READ_ONLY',
    )

    for (const party of beneficiaries) {
        const partyData = {
            name: party.role,
            email: party.email,
            value: partyReceives(party.feeType, party.fee, transactionValue, transactionUnits),
        }
        if (party.feeAllocation === 'BUYER_SELLER_AGENT') {
            partyData.value = partyData.value / 3
            fees.push(partyData)
        } else if (party.feeAllocation === role) {
            fees.push(partyData)
        } else if (party.feeAllocation.includes(role)) {
            partyData.value = partyData.value / 2
            fees.push(partyData)
        }
    }
    return fees
}

export function partiesTotalFee(role, allParties, escrowFeeAllocation, transactionValue, transactionUnits) {
    const beneficiaryFee = partyBeneficiaryFees(role, allParties, transactionValue, transactionUnits).reduce(
        (acc, bene) => acc + bene.value,
        0,
    )
    const escrowFee = partyEscrowFee(role, escrowFeeAllocation, escrowFeeIncludingVat(transactionValue))
    if (role === 'SELLER') {
        const agent = allParties.find(party => party.role === 'AGENT')
        if (agent) {
            const agentFee = partyReceives(agent.feeType, agent.fee, transactionValue, transactionUnits)
            return beneficiaryFee + escrowFee + agentFee
        }
    }
    return beneficiaryFee + escrowFee
}

export function partiesTotalFeeFuel(role, allParties, escrowFeeAllocation, transactionValue, transactionUnits) {
    const beneficiaryFee = partyBeneficiaryFees(role, allParties, transactionValue, transactionUnits).reduce(
        (acc, bene) => acc + bene.value,
        0,
    )
    const escrowFee = partyEscrowFee(role, escrowFeeAllocation, fuelEscrowFeeExcludingVat(transactionUnits) * (1 + VAT))
    if (role === 'SELLER') {
        const agent = allParties.find(party => party.role === 'AGENT')
        if (agent) {
            return (
                beneficiaryFee + escrowFee + partyReceives(agent.feeType, agent.fee, transactionValue, transactionUnits)
            )
        }
    }

    return beneficiaryFee + escrowFee
}
