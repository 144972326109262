<template>
    <div class="tx-create-nav">
        <CancelTransactionCreateModal ref="cancelModal"/>
        <div v-if="error" class="uk-flex uk-flex-center">
            <GL-Alert class="uk-width-5-6@s" type="danger">
                {{ error }}
            </GL-Alert>
        </div>
        <hr class="uk-margin-remove-bottom"/>
        <div
                class="uk-flex uk-flex-wrap uk-padding-small uk-flex-right"
                :class="{ 'uk-flex-between': !editMode, 'uk-flex-right': editMode }"
        >
            <GL-Button
                    text="cancel"
                    type="secondary-outlined"
                    name="cancel"
                    class="create-transaction-control-button uk-width-1-1@s uk-width-auto@s"
                    @click="showModal"
                    v-if="!editMode && !frame"
            />

            <GL-Button
                    text="cancel"
                    type="secondary-outlined"
                    name="cancel"
                    class="create-transaction-control-button uk-width-1-1@s uk-width-auto@s"
                    @click="reset"
                    v-if="!editMode && frame"
            />
            <div>
                <GL-Button
                        :text="leftButtonLabel"
                        v-if="!hideBackButton"
                        name="back"
                        type="secondary"
                        class="create-transaction-control-button uk-width-1-1@s uk-width-auto@s uk-margin-right"
                        @click="leftButtonActions"
                />
                <GL-Button
                        :text="rightButtonLabel"
                        type="primary"
                        name="next"
                        :disabled="disableNext"
                        :disabled-text="rightButtonLabel"
                        class="create-transaction-control-button uk-width-1-1@s uk-width-auto@s"
                        :loading="loading"
                        loading-text="Saving"
                        :tooltip="rightButtonTooltip"
                        @click="rightButtonActions"
                />
            </div>
        </div>
    </div>
</template>

<script>
import CancelTransactionCreateModal from './cancel-transaction-create-modal'

export default {
    name: 'CreateTxNavButtons',
    components: {CancelTransactionCreateModal},
    data() {
        return {
            error: '',
            loading: false,
        }
    },
    props: {
        disableNext: {
            type: Boolean,
            required: true,
        },
        lastStep: {
            type: Boolean,
            default: false,
        },
        rightButtonTooltip: {
            type: String,
            default: '',
        },
    },
    computed: {
        editMode() {
            return this.$store.getters['txCreate/editMode']
        },
        hideBackButton() {
            return !!(this.editMode && this.lastStep)
        },
        leftButtonLabel() {
            if (this.editMode) {
                return 'Back To Overview'
            }
            return 'Back'
        },
        rightButtonLabel() {
            if (this.editMode) {
                if (this.lastStep) {
                    return 'Done'
                }
                return 'Save'
            }
            if (this.lastStep) {
                return 'Finish And Invite'
            }
            return 'Next'
        },
        frame() {
            return top !== window
        },
    },
    methods: {
        scrollTop() {
            window.scrollTo({top: 0, behavior: 'smooth'})
        },
        leftButtonActions() {
            if (this.editMode) {
                this.$store.dispatch('txCreate/setStepByName', 'Overview')
            } else {
                this.$store.commit('txCreate/decrementShowStep')
            }
            this.scrollTop()
        },
        async rightButtonActions() {
            if (this.editMode && this.lastStep) {
                await this.$router.push(this.$route.path)
            } else {
                this.loading = true
                try {
                    this.error = ''
                    await this.$store.dispatch('txCreate/saveAndAdvanceStep')
                    if (!this.lastStep) {
                        this.scrollTop()
                    }
                } catch (e) {
                    this.error = this.$handleError(e)
                }
                this.loading = false
            }
        },
        showModal() {
            if (this.$store.state.transaction.transaction !== '__loading__') {
                this.$refs.cancelModal.show()
            } else {
                this.$router.push('/')
            }
        },
        reset() {
            this.$router.go(0)
        },
    },
}
</script>

<style scoped>
.tx-create-nav {
    margin-top: 2rem;
}
</style>
