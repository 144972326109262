import Vue from 'vue'

const store = Vue.observable({
    refreshCount: 0,
    dict: {},
})

function save() {
    try {
        const stringy = JSON.stringify(store.dict)
        localStorage.setItem('seen', stringy)
    } catch (error) {
        store.dict = {}
        const stringy = JSON.stringify(store.dict)
        localStorage.setItem('seen', stringy)
    }
}
function load() {
    const stringy = localStorage.getItem('seen')
    if (stringy) {
        const obj = JSON.parse(stringy)
        store.dict = obj
    }
}
function markSeen(key, value) {
    store.refreshCount += 1
    Vue.set(store.dict, key, value)
}
function hasSeen(key) {
    if (store.dict[key]) {
        return true
    }
    return false
}
function markTransactionSeen(transaction) {
    for (const comment of transaction.comments) {
        markSeen(comment.id, true)
    }
    for (const file of transaction.files) {
        markSeen(file.id, true)
    }
    save()
}

export default {
    hasSeen,
    markTransactionSeen,
}

load()
