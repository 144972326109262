// TODO: Remove the script from the folder.
// It's a leftover from refactoring

import { valid_zone, valid_magisterial_district } from './fuel'
import Validation from '_shared/validation/validation'

const validation = new Validation({
    valid_zone,
    valid_magisterial_district,
})

export default validation
