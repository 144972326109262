import TransactionFileCreate from './graphql/transaction/transaction-file-create.gql'
import TransactionFileDelete from './graphql/transaction/transaction-file-delete.gql'
import TransactionFilesFetch from './graphql/transaction/transaction-files-fetch.gql'
import TransactionFileUpdate from './graphql/transaction/transaction-files-update.gql'
import TransactionFilesNotify from './graphql/transaction/transaction-files-notify.gql'

export default class APITransactionFiles {
    constructor(api) {
        this.api = api
    }

    async fetch(transactionId) {
        const response = await this.api.client.query({
            query: TransactionFilesFetch,
            variables: {
                transactionId,
            },
        })
        return response.data.transactionFiles
    }
    async create(fileDetails) {
        const response = await this.api.client.mutate({
            mutation: TransactionFileCreate,
            variables: {
                transactionId: fileDetails.transactionId,
                file: fileDetails.file,
                metadata: fileDetails.metadata,
            },
        })
        return response.data.transactionFileCreate
    }
    async delete(fileId) {
        const response = await this.api.client.mutate({
            mutation: TransactionFileDelete,
            variables: {
                id: fileId,
            },
        })
        return response.data.transactionFileCreate
    }
    async update(fileId, metadata, partiesIds) {
        const response = await this.api.client.mutate({
            mutation: TransactionFileUpdate,
            variables: {
                id: fileId,
                metadata: metadata,
                partiesIds: partiesIds,
            },
        })
        return response.data.transactionFileUpdate
    }
    async notify(transactionId, fileList) {
        const response = await this.api.client.query({
            query: TransactionFilesNotify,
            variables: {
                transactionId,
                fileList,
            },
        })
        return response.data.transactionFilesNotify
    }
}
