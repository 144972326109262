<template>
    <v-tour :name="name" :steps="steps" :callbacks="callbacks">
        <template slot-scope="tour">
            <transition name="fade">
                <v-step
                    v-for="(step, index) of tour.steps"
                    v-if="tour.currentStep === index"
                    :key="index"
                    :step="step"
                    :previous-step="tour.previousStep"
                    :next-step="tour.nextStep"
                    :stop="tour.stop"
                    :is-first="tour.isFirst"
                    :is-last="tour.isLast"
                    :labels="tour.labels"
                >
                    <template>
                        <div slot="header">
                            <div class="uk-position-right step-number">{{ index + 1 }}/{{ tour.steps.length }}</div>
                            <div v-if="step.header" class="v-step__header">
                                <strong>{{ step.header.title }}</strong>
                            </div>
                        </div>
                    </template>
                    <template v-if="tour.currentStep === tour.steps.length - 1">
                        <div slot="actions">
                            <button
                                class="v-step__button"
                                data-id="link_content_content link"
                                data-text="tour | previous button click"
                                @click="tour.previousStep"
                            >
                                Previous
                            </button>
                            <button
                                class="v-step__button"
                                data-id="link_content_content link"
                                data-text="tour | close button click"
                                @click="tour.stop"
                            >
                                Close
                            </button>
                        </div>
                    </template>
                </v-step>
            </transition>
        </template>
    </v-tour>
</template>
<script>
export default {
    name: 'Tour',
    props: {
        steps: {
            type: Array,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        callbacks: {
            type: Object,
        },
        showProfileLink: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        goToMyProfile(tour) {
            tour.stop()
            this.$router.push('/profile')
        },
    },
}
</script>
