<template>
    <div>
        <div v-if="visible">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Step',
    props: {
        name: {
            type: String,
            required: true,
        },
        tourStep: {
            type: String,
        },
    },
    data() {
        return {
            visible: false,
        }
    },
    methods: {
        show() {
            this.visible = true
        },
        hide() {
            this.visible = false
        },
    },
}
</script>
