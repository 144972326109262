<template>
    <div class="MilestoneRunningSummary">
        <div class="uk-padding-small">
            <div class="uk-margin-small">Milestone Summary</div>
            <div class="content-block uk-margin-small">
                <div class="uk-text-right">Running Total</div>
                <div class="uk-text-right">R {{ transactionValue | currency }}</div>
            </div>
            <div class="content-block uk-margin-small">
                <div class="uk-text-right">Seller's Fee</div>
                <div class="uk-text-right">R {{ totalFeeForMilestone | currency }}</div>
            </div>
            <div class="content-block uk-margin-small">
                <div class="uk-text-right">Payable To Seller</div>
                <div class="uk-text-right">R {{ amountPaidOnCompletionOfMilestone | currency }}</div>
            </div>
            <div class="content-block uk-margin-small">
                <div class="uk-text-right">Total Payable To Seller</div>
                <div class="uk-text-right">R {{ totalAmountDueToSeller | currency }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { partiesTotalFee } from '../../../../../../scripts/transaction-calculation'
export default {
    name: 'MilestoneRunningSummary',
    props: {
        index: {
            type: Number,
            required: true,
        },
    },
    computed: {
        transactionValue() {
            return this.$store.getters['txCreate/transactionValue']
        },
        transactionUnits() {
            return this.$store.getters['txCreate/totalUnits']
        },
        milestoneValue() {
            return this.$store.state.txCreate.allocations.allocations[this.index].value
        },
        milestoneUnits() {
            return this.$store.state.txCreate.allocations.allocations[this.index].units
        },
        amountPaidOnCompletionOfMilestone() {
            return this.milestoneValue - this.totalFeeForMilestone
        },
        totalAmountDueToSeller() {
            return this.transactionValue - this.sellerTotalFee
        },
        totalFeeForMilestone() {
            return this.sellerTotalFee * (this.milestoneValue / this.transactionValue)
        },
        sellerTotalFee() {
            return partiesTotalFee(
                'SELLER',
                this.$store.state.txCreate.parties.parties,
                this.$store.state.txCreate.transaction.transaction.feeAllocation,
                this.transactionValue,
                this.$store.getters['txCreate/transactionUnits'],
            )
        },
    },
}
</script>

<style lang="scss" scoped>
.MilestoneRunningSummary {
    width: 13.5rem;
    background-color: $tradesafe-red;
    color: white;
    border-radius: 0.5rem;
    position: absolute;
    top: 0;
    right: -14.5rem;
}
.content-block {
    border: 1px solid white;
    border-radius: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
</style>
