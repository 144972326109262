import * as Sentry from '@sentry/browser'
import Vue from 'vue'

// What needs to be tested for error handling:
/*
- Promise rejection events | Tracked in window.onhandlerejection
- GraphQL and network errors | try catch or catch all fallback in App.vue
- Component rendering errors | catch all fallback in App.vue
*/

export default class ErrorHandler {
    constructor(
        messages = {
            networkError: 'The server could not be reached. Please ensure you have a stable connection to the internet',
            fallbackError: 'Something went wrong with your request',
        },
    ) {
        console.log('Error Handler Instantiated')
        this.messages = messages

        // This catches any promise errors that are not caught by their callers
        // (mainly occurs in the store when fetching data since those operations aren't surrounded by try catch)
        this.store = Vue.observable({
            errorMessage: '',
        })
        const _this = this
        window.onunhandledrejection = function (rejection) {
            console.log('window.onunhandledrejection')
            console.log(rejection)
            _this.handle(rejection.error)
            //_this.store.errorMessage = rejection.reason
        }
    }
    authenticationError = function (error) {
        console.log('Authentication Error handler not set')
        console.log(error)
    }

    apolloErrors = null
    setApolloErrors(apolloErrors) {
        this.apolloErrors = apolloErrors
    }

    handle(error) {
        // Always console log error
        console.log('Error caught : ')
        console.log(error)

        // Report to sentry
        Sentry.captureException(error)

        // Check apolloErrors
        if (this.apolloErrors) {
            console.log('this.apolloErrors')
            console.log(this.apolloErrors)
            if (this.apolloErrors.networkError) {
                this.apolloErrors = null
                return this.messages.networkError
            }
            if (this.apolloErrors.graphQLErrors && this.apolloErrors.graphQLErrors.length > 0) {
                const errorInstance = this.apolloErrors.graphQLErrors[0]

                // If unauthenticated log out
                if (errorInstance.message === 'Unauthenticated.') {
                    this.authenticationError(this.apolloErrors.graphQLErrors[0])
                    this.apolloErrors = null
                    return errorInstance.message
                }

                if (errorInstance.extensions && errorInstance.extensions.reason) {
                    this.apolloErrors = null
                    return errorInstance.extensions.reason
                }

                if (errorInstance.extensions && errorInstance.extensions.validation) {
                    const keys = Object.keys(errorInstance.extensions.validation)
                    const message = errorInstance.extensions.validation[keys[0]][0]
                    this.apolloErrors = null
                    return message
                }

                this.apolloErrors = null
                return errorInstance.message
            }
        }

        // Fallback message
        this.apolloErrors = null
        if (typeof error !== 'undefined' && typeof error.displayMessage !== 'undefined') {
            return error.displayMessage
        }
        return this.messages.fallbackError
    }
}
