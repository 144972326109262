import api from '@/scripts/api/api'
import rootStore from '@/store/index'

export default {
    namespaced: true,
    state: {
        transactionListRefetch: true,
        transactionList: '__loading__',
        transactionListPage: 1,
        transactionListType: 'ACTIVE', // 'COMPLETED' 'CANCELED'
        transactionListContextId: null,
        transactionDepositDetails: '__loading__',
        transaction: '__loading__',
        transactionFetchInjections: [],
        transactionPayment: {
            escrowAccount: false,
            escrowAccountAmount: 0,
        },
    },
    mutations: {
        setTransaction(state, payload) {
            state.transaction = payload
        },
        setTransactionList(state, payload) {
            state.transactionList = payload
            state.transactionListRefetch = false
            state.transactionListContextId = rootStore.getters['context/context'].id
        },
        setTransactionListContextId(state, payload) {
            state.transactionListContextId = payload
        },
        setTransactionListPage(state, payload) {
            state.transactionListPage = payload
        },
        setTransactionListType(state, payload) {
            state.transactionListType = payload
        },
        setTransactionListRefetch(state, payload) {
            state.transactionListRefetch = payload
        },
    },
    actions: {
        async transactionListFetch(store) {
            let filter = {}
            if (store.state.transactionListType == 'ACTIVE') {
                filter.inState = [
                    'CREATED',
                    'INVITATION_SENT',
                    'NEGOTIATION',
                    'ACCEPTED',
                    'FUNDS_DEPOSITED',
                    'FUNDS_RECEIVED',
                    'INITIATED',
                    'GOODS_ACCEPTED',
                    'DELIVERED',
                    'DISPUTED',
                    'LEGAL',
                    'SUSPENDED',
                    'ADMIN_SUSPENDED',
                ]
            }
            if (store.state.transactionListType == 'COMPLETE') {
                filter.inState = ['FUNDS_RELEASED']
            }
            if (store.state.transactionListType == 'CANCELED') {
                filter.inState = ['CANCELED', 'DECLINED']
            }

            const transactionList = await api.transaction.transactionListFetch({
                page: store.state.transactionListPage,
                filter: filter,
            })
            console.log('Transaction List Fetched: ')
            console.log(transactionList)

            store.commit('setTransactionList', transactionList)

            return transactionList
        },
        async transactionDepositDetailsFetch(store) {
            store.state.transactionDepositDetails = '__loading__'
            const transactionDepositDetails = await api.transaction.transactionDepositDetailsFetch()
            console.log('Transaction Deposit Details Fetched: ')
            console.log(transactionDepositDetails)
            store.state.transactionDepositDetails = transactionDepositDetails
        },
        async transactionFetch(store, payload) {
            store.state.transaction = '__loading__'
            const transaction = await api.transaction.transactionFetch(payload.id)
            const files = await api.transactionFiles.fetch(payload.id)
            transaction.files = files
            for (const injected of store.state.transactionFetchInjections) {
                const result = await injected.method(transaction)
                transaction[injected.keyName] = result
            }

            console.log('Transaction Fetched: ')
            console.log(transaction)
            store.commit('setTransaction', transaction)
        },
        async transactionRefetch(store) {
            const transaction = await api.transaction.transactionFetch(store.state.transaction.id)
            transaction.files = await api.transactionFiles.fetch(store.state.transaction.id)

            for (const injected of store.state.transactionFetchInjections) {
                const result = await injected.method(transaction)
                transaction[injected.keyName] = result
            }
            console.log('Transaction Refetched: ')
            console.log(transaction)
            store.commit('setTransaction', transaction)

            //This code exist to show the user the banner on the overview page stating update was a success
            if (store.rootGetters['txCreate/editMode']) {
                store.commit('txCreate/setTransactionEdited', true, {root: true})
            }
        },
        async transactionCancel(store, payload) {
            await api.transaction.transactionCancel({
                id: store.state.transaction.id,
                comment: payload.reason,
            })
        },
        async transactionDecline(store, payload) {
            await api.transaction.transactionDecline({
                id: store.state.transaction.id,
                comment: payload.reason,
            })
        },
        async transactionDelete(store, payload) {
            await api.transaction.transactionDelete({
                id: payload.id,
            })
        },
        async transactionInvite(store) {
            await api.transaction.transactionInvite({id: store.state.transaction.id})
        },
        async transactionAccept(store) {
            await api.transaction.transactionAccept({id: store.state.transaction.id})
        },
        async transactionDeposit(store, payload) {
            const result = await api.transaction.transactionDeposit({
                id: store.state.transaction.id,
                method: payload.method,
                value: payload.value,
            })
            return result
        },
        async transactionInitiateDelivery(store, payload) {
            // This method is only for single allocation transactions
            await store.dispatch('allocationInitiateDelivery', {
                id: store.state.transaction.allocations[0].id,
                name: payload.name,
                code: payload.code,
            })
        },
        async transactionAcceptDelivery(store) {
            // This method is only for single allocation transactions
            await api.transaction.allocation.allocationAcceptDelivery({
                id: store.state.transaction.allocations[0].id,
            })
        },
        async transactionDisputeDelivery(store, payload) {
            // This method is only for single allocation transactions
            await store.dispatch('allocationDisputeDelivery', {
                id: store.state.transaction.allocations[0].id,
                comment: payload.comment,
                documentation: payload.documentation,
            })
        },
        async transactionAmendmentCreate(store, payload) {
            // This method is only for single allocation transactions
            await store.dispatch('allocationAmendmentCreate', {
                id: store.state.transaction.allocations[0].id,
                value: payload.value,
                units: payload.units,
                unitCost: payload.unitCost,
                daysToDeliver: payload.daysToDeliver,
                daysToInspect: payload.daysToInspect,
                comment: payload.comment,
            })
        },
        async allocationInitiateDelivery(store, payload) {
            if (payload.name || payload.code) {
                await api.transaction.allocation.allocationCourierDetails({
                    id: payload.id,
                    name: payload.name,
                    code: payload.code,
                })
            }

            await api.transaction.allocation.allocationStartDelivery({
                id: payload.id,
            })
        },
        async allocationAcceptDelivery(store, payload) {
            await api.transaction.allocation.allocationAcceptDelivery({
                id: payload.id,
            })
        },
        async allocationDisputeDelivery(store, payload) {
            await api.transaction.allocation.allocationDisputeDelivery({
                id: payload.id,
                comment: payload.comment,
            })
            if (payload.documentation) {
                for (const file of payload.documentation) {
                    await api.transaction.files.create({
                        transactionId: store.state.transaction.id,
                        file: file,
                        metadata: JSON.stringify({
                            type: 'disputeDocumentation',
                            allocationId: payload.id,
                        }),
                    })
                }
            }
        },
        async allocationAmendmentCreate(store, payload) {
            await api.transaction.amendment.amendmentCreate({
                allocationId: payload.id,
                input: {
                    value: payload.value,
                    units: payload.units,
                    unitCost: payload.unitCost,
                    daysToDeliver: payload.daysToDeliver,
                    daysToInspect: payload.daysToInspect,
                    comment: payload.comment,
                },
            })
            await store.dispatch('transactionCommentCreate', {
                body: payload.comment,
            })
        },
        async allocationAmendmentAccept(store, payload) {
            await api.transaction.amendment.amendmentAccept({
                id: payload.id,
            })
        },
        async allocationAmendmentDecline(store, payload) {
            await api.transaction.amendment.amendmentDecline({
                id: payload.id,
                comment: payload.comment,
            })
        },
        async allocationAmendmentCancel(store, payload) {
            await api.transaction.amendment.amendmentCancel({
                id: payload.id,
                comment: payload.comment,
            })
        },
        async transactionCommentCreate(store, payload) {
            await api.transaction.transactionCommentCreate({
                transactionId: store.state.transaction.id,
                body: payload.body,
            })
        },
    },
    getters: {
        transactionList: state => {
            if (api.user.signedIn() !== true) {
                return '__loading__'
            }
            if (rootStore.getters['user/user'] === '__loading__') {
                return '__loading__'
            }
            if (!rootStore.getters['user/user'].status.emailVerified) {
                return '__loading__'
            }
            if (rootStore.getters['context/context'] === '__loading__') {
                return '__loading__'
            }
            if (rootStore.getters['context/context'].id !== state.transactionListContextId) {
                rootStore.dispatch('transaction/transactionListFetch')
                return '__loading__'
            }
            if (state.transactionListRefetch === true) {
                rootStore.dispatch('transaction/transactionListFetch')
                return '__loading__'
            }
            return state.transactionList
        },
        transaction: state => {
            return state.transaction
        },
        transactionDepositDetails: state => {
            let signedIn = api.user.signedIn()

            signedIn.then(function (result) {
                if (result === true && state.transactionDepositDetails === '__loading__') {
                    rootStore.dispatch('transaction/transactionDepositDetailsFetch')
                    return '__loading__'
                }
            })

            return state.transactionDepositDetails
        },
        transactionEFTPayment: state => {
            return {
                transactionValue: state.transaction.calculation.baseValue,
                transactionFee: state.transaction.calculation.processingFees.eft.totalFee,
                transactionDepositReceived: state.transaction.calculation.totalDeposits,
                transactionDepositRequired:
                    state.transaction.calculation.totalValue - state.transaction.calculation.totalDeposits,
            }
        },
        transactionACCPayment: state => {
            return {
                transactionValue: state.transaction.calculation.baseValue,
                transactionFee: state.transaction.calculation.processingFeeTotal,
                transactionDepositReceived: state.transaction.calculation.totalDeposits,
                transactionDepositRequired:
                    state.transaction.calculation.totalValue - state.transaction.calculation.totalDeposits,
                accountOpening: rootStore.getters['context/context'].funds.balance,
                accountClosing:
                    rootStore.getters['context/context'].funds.balance -
                    (state.transaction.calculation.totalValue - state.transaction.calculation.totalDeposits),
            }
        },
        transactionOZOWPayment: state => {
            console.log(state.transaction.calculation)
            return {
                transactionValue: state.transaction.calculation.baseValue,
                transactionFee: state.transaction.calculation.processingFeeTotal,
                transactionDepositReceived: state.transaction.calculation.totalDeposits,
                transactionDepositRequired:
                    state.transaction.calculation.totalValue - state.transaction.calculation.totalDeposits,
                transactionOzowFee: state.transaction.calculation.processingFees.ozow.totalFee,
                transactionOzowTotal:
                    state.transaction.calculation.totalValue - state.transaction.calculation.totalDeposits + state.transaction.calculation.processingFees.ozow.totalFee,
            }
        },
    },
}
