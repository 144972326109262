import api from '@/scripts/api/api'

export default {
    state: {
        documents: [],
        loading: false,
        documentsUpdated: false,
        newlyAddedFilenames: [],
    },
    getters: {
        savedDocuments: (state, getters) => {
            const savedTransaction = getters['fetchedTransaction']
            return savedTransaction.files
        },
    },
    mutations: {
        addFilenameToNewList(state, filename) {
            state.newlyAddedFilenames = [...state.newlyAddedFilenames, filename]
        },
        removeFilenameFromNewList(state, filename) {
            state.newlyAddedFilenames = state.newlyAddedFilenames.filter(name => name !== filename)
        },
        addDocumentsToCreate(state, payload) {
            for (const file of payload) {
                state.documents.push({ state: '__CREATING__', file })
            }
        },
        addInitialDocuments(state, payload) {
            state.documents = []
            for (const file of payload) {
                state.documents.push({ state: '__SAVED__', file })
            }
        },
        removeSavedDocumentFromState(state, payload) {
            state.documents = state.documents.filter(doc => doc.file.id !== payload.file.id)
        },
        removeFailedDocumentFromState(state, payload) {
            state.documents = state.documents.filter(
                doc => doc.state !== '__FAILED__' && doc.file.name !== payload.file.name,
            )
        },
        cleanDocuments(state) {
            state.documents = state.documents.filter(doc => doc.state === '__SAVED__')
        },
        setAllowedParties(state, payload) {
            state.documents = state.documents.map(doc => {
                if (doc.file.id === payload.fileId) {
                    doc.file.allowedParties = Array.from(payload.allowedParties)
                    doc.updated = true
                }
                return doc
            })
        },
    },
    actions: {
        async sendNewFileNotification({ state, getters }) {
            const transaction = getters['fetchedTransaction']
            if (state.newlyAddedFilenames.length && transaction.state !== 'CREATED') {
                state.loading = true
                await api.transactionFiles.notify(transaction.id, state.newlyAddedFilenames)
                state.loading = false
                state.newlyAddedFilenames = []
            }
        },
        async initDocuments({ state, getters, commit }) {
            const transaction = getters['fetchedTransaction']
            state.newlyAddedFilenames = []
            if (transaction === '__loading__') {
                state.documents = []
            } else {
                const docs = await api.transactionFiles.fetch(transaction.id)
                commit('addInitialDocuments', docs)
            }
        },
        async manageDocuments({ commit, state, dispatch }) {
            commit('cleanDocuments')
            state.loading = true
            await dispatch('sendNewFileNotification')
            const updated = state.documents.filter(doc => doc.updated)
            if (updated.length) {
                for (const doc of updated) {
                    await api.transactionFiles.update(doc.file.id, JSON.stringify(''), doc.file.allowedParties)
                    delete doc.updated
                }
                await dispatch('transaction/transactionRefetch', null, { root: true })
            } else if (state.documentsUpdated) {
                state.documentsUpdated = false
                await dispatch('transaction/transactionRefetch', null, { root: true })
            }
            state.loading = false
        },
        async saveDocuments({ state, getters, commit }) {
            const transaction = getters['fetchedTransaction']
            const docsToCreate = state.documents.filter(doc => doc.state === '__CREATING__')
            state.loading = true
            while (docsToCreate.length > 0) {
                const doc = docsToCreate[0]
                try {
                    const createdFile = await api.transactionFiles.create({
                        transactionId: transaction.id,
                        file: doc.file,
                    })
                    doc.state = '__SAVED__'
                    doc.file = createdFile
                    commit('addFilenameToNewList', createdFile.filename)
                } catch (e) {
                    doc.state = '__FAILED__'
                } finally {
                    docsToCreate.shift()
                }
            }
            state.documentsUpdated = true
            state.loading = false
        },
        async deleteDocument({ commit, state }, document) {
            if (document.state === '__FAILED__') {
                return commit('removeFailedDocumentFromState', document)
            } else {
                document.state = '__DELETING__'
                state.loading = true
                await api.transactionFiles.delete(document.file.id)
                commit('removeSavedDocumentFromState', document)
                commit('removeFilenameFromNewList', document.file.filename)
                state.documentsUpdated = true
                state.loading = false
            }
        },
    },
}
