<template>
    <div class="page-home">
        <div
            class="uk-flex uk-flex-center"
            :class="{
                'uk-overflow-hidden': !$store.state.tour.tourRunning,
                small: $screenSmaller('small'),
                medium: $screenLarger('small') && $screenSmaller('medium'),
            }"
        >
            <div class="banner banner-left animated slideInLeft">
                <div class="uk-flex uk-flex-right">
                    <div class="welcome">
                        Welcome To
                        <div class="tradesafe">Tradesafe</div>
                    </div>
                </div>
            </div>

            <div class="banner banner-center">
                <div v-if="user === '__loading__'">
                    <div class="uk-text-center">
                        <GL-Spinner :size="3" />
                    </div>
                </div>
                <div v-if="user !== '__loading__'">
                    <div class="b1 animated flipInX">
                        <button
                            class="uk-button uk-button-slant home-tour-step-3"
                            @click="createTransaction"
                            :disabled="!createTransactionEnabled"
                            :uk-tooltip="createTransactionTooltip"
                        >
                            Create Transaction
                        </button>
                    </div>
                    <div class="b2 animated flipInX">
                        <button @click="navigate('profile')" class="uk-button uk-button-slant outline">
                            View Your Profile
                        </button>
                    </div>
                    <div class="b3 animated flipInX">
                        <button @click="navigate('escrow-account')" class="uk-button uk-button-slant outline">
                            Fund Escrow Account
                        </button>
                    </div>
                </div>
            </div>

            <div class="banner banner-right animated slideInRight">
                <div class="uk-padding">
                    <div v-if="context !== '__loading__'">
                        <div class="title">
                            {{ context.__typename === 'Organization' ? 'Business' : 'Personal' }}
                            Escrow Account
                        </div>
                        <div class="uk-margin home-tour-step-5">
                            <label class="top"> Escrow Account Balance </label>
                            <div class="bot">R{{ context.funds.pendingBalance | currency }}</div>
                        </div>

                        <div class="uk-margin home-tour-step-6">
                            <label class="top"> Allocated To Transaction </label>
                            <div class="bot">R{{ context.funds.active | currency }}</div>
                        </div>

                        <div class="uk-margin home-tour-step-7">
                            <label class="top"> Payable To You </label>
                            <div class="bot">R{{ context.funds.owed | currency }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <h2 class="uk-text-center">Your Active Transactions</h2>
        <div class="uk-container">
            <!-- If Context Loading -->
            <div v-if="context === '__loading__'" style="color: black; padding-top: 1rem" class="uk-text-center">
                <GL-Spinner :size="5" />
            </div>
            <!-- If Context Finished -->
            <div v-if="context !== '__loading__'">
                <!-- If Context Email Unverified -->
                <div v-if="context.status.emailVerified === false">
                    <!-- If Context Has Active Transactions -->
                    <div class="uk-text-center uk-margin-large" v-if="context.status.hasActiveTransactions === true">
                        <GL-Alert type="warning">
                            <GL-Icon path="exclamation-circle-solid.svg" :size="2" /> <br />
                            <br />
                            You have active transactions waiting for you. <br />
                            To view and access your transactions, please verify your email.
                        </GL-Alert>
                    </div>
                    <div class="uk-text-center uk-margin-large" v-if="context.status.hasActiveTransactions === false">
                        Your transactions will show here.
                    </div>
                </div>
                <!-- If Context Email Verified -->
                <div v-if="context.status.emailVerified === true">
                    <!-- If TransactionList Loading -->
                    <div
                        v-if="transactionList === '__loading__'"
                        style="color: black; padding-top: 1rem"
                        class="uk-text-center"
                    >
                        <GL-Spinner :size="5" />
                    </div>
                    <!-- If TransactionList Finished -->
                    <div v-if="transactionList !== '__loading__'">
                        <!-- If No Transactions -->
                        <div class="uk-text-center uk-margin-large" v-if="transactionList.data.length === 0">
                            You have no active transactions
                        </div>
                        <!-- If Transactions -->
                        <div class="uk-grid uk-grid-small" v-if="transactionList.data.length > 0">
                            <div
                                v-for="transaction in transactionList.data"
                                :key="transaction.id"
                                class="uk-width-1-2@m"
                            >
                                <TransactionCard
                                    :transaction="transaction"
                                    :disabled="!navigateTransactionEnabled"
                                    :uk-tooltip="navigateTransactionTooltip"
                                />
                            </div>
                        </div>
                        <div class="uk-flex uk-flex-center uk-margin-top">
                            <div class="uk-display-block">
                                <Pagination
                                    :paginatorInfo="transactionList.paginatorInfo"
                                    @set-page="navigateTransactionListPage"
                                />
                            </div>
                        </div>
                        <div class="uk-margin-large"></div>
                    </div>
                </div>
            </div>
        </div>
        <SetContextModal
            ref="SetContextModal"
            heading="Select the user or organization that you would like to create the transaction on behalf of."
        />
        <!-- DONT DELETE THIS DIV IT'S REQUIRED FOR THE TOUR -->
        <div v-if="!loadingTour"></div>
        <Tour name="homeTour" :steps="homeTourSteps" :callbacks="tourCallbacks" :show-profile-link="true" />
    </div>
</template>

<script>
import TransactionCard from '../components/transaction-card'
import Pagination from '@/modules/common/components/pagination'
import SetContextModal from '@/modules/common/components/set-context-modal'
import { steps as homeTourSteps, callbacks as tourCallbacks } from '@/scripts/tours/home-tour'
import Tour from '../../common/components/tour'
import Vuex from 'vuex'

export default {
    name: 'Home',
    components: {
        Tour,
        TransactionCard,
        Pagination,
        SetContextModal,
    },
    data() {
        return {
            homeTourSteps,
            componentMounted: false,
            tourCallbacks,
            launchTour: false,
            // loadingTour: true, ---- this can be removed. I'm not sure why it was here in the first place - Kobus
        }
    },
    mounted() {
        this.$store.dispatch('transaction/transactionListFetch')
        if (this.$route.query.ozow === 'true') {
            setTimeout(() => {
                this.$globalEventEmit('notification', {
                    type: 'success',
                    title: 'Success!',
                    body: 'You have made payment to TradeSafe using Ozow. It may take a few minutes for your escrow account balance to be updated',
                })
            }, 1000)
        }
    },
    methods: {
        navigateTransactionListPage(page) {
            this.$store.commit('transaction/setTransactionListPage', page)
            this.$store.commit('transaction/setTransactionList', '__loading__')
            this.$store.dispatch('transaction/transactionListFetch')
        },
        async createTransaction() {
            if (this.$store.getters['context/contextList'].length > 1) {
                const proceed = await this.$refs.SetContextModal.show()
                if (proceed.submit) {
                    this.$router.push('/transaction/create')
                }
            } else {
                this.$router.push('/transaction/create')
            }
        },
        navigate(target) {
            this.$router.push(target)
        },
    },
    computed: {
        ...Vuex.mapState({
            user: state => state.user.user,
            context: state => state.context.context,
            transactionList: state => state.transaction.transactionList,
        }),
        createTransactionEnabled() {
            if (
                this.context === '__loading__' ||
                this.user === '__loading__' ||
                this.$store.getters['context/contextVerification']
            ) {
                return false
            }
            return true
        },
        createTransactionTooltip() {
            if (this.$store.getters['context/contextVerification']) {
                return this.$store.getters['context/contextVerification'].message
            }
            return null
        },
        navigateTransactionEnabled() {
            if (this.$store.getters['context/contextVerification']) {
                return false
            }
            return true
        },
        navigateTransactionTooltip() {
            if (this.$store.getters['context/contextVerification']) {
                return this.$store.getters['context/contextVerification'].message
            }
            return null
        },
        loadingTour() {
            if (this.context === '__loading__' || this.user === '__loading__') {
                return true
            }
            if (this.$route.query.tour === 'yes') {
                this.$getTourService().markCreateSeen(false)
                this.$getTourService().markProfileSeen(false)
                this.$getTourService().markHomeSeen(false)
            }
            if (this.$getTourService().hasSeenHome() === false) {
                this.$getTourService().markHomeSeen(true)
                if (this.$screenLarger('medium')) {
                    this.$tours['homeTour'].start()
                }
            }
            return false
        },
    },
    watch: {
        context: function () {
            this.$store.dispatch('transaction/transactionListFetch')
        },
    },
}
</script>
<style lang="scss" scoped>
@import '@/scss/pages/home.scss';
</style>
