<template>
    <div v-if="showPartyFees" class="uk-flex uk-flex-center uk-margin-large-top">
        <div class="uk-width-5-6@m">
            <div class="uk-text-center">
                <h3 class="uk-text-secondary">
                    What is the fee payable to the parties below and who will absorb this cost?
                </h3>
            </div>
            <div v-for="(party, index) in parties" :key="index" class="uk-margin-small">
                <PartyFeeCard
                    :index="index"
                    v-if="party.role !== 'BUYER' && party.role !== 'SELLER' && party.role !== 'READ_ONLY'"
                >
                    <PartyFeeForm :index="index" />
                </PartyFeeCard>
            </div>
        </div>
    </div>
</template>

<script>
import PartyFeeCard from './party-fee-card'
import { transactionCreate } from '../../../../../../constants/constants-transaction-create'

export default {
    name: 'PartiesFees',
    components: { PartyFeeCard },
    data() {
        return {
            transactionCreate,
        }
    },
    computed: {
        parties() {
            return this.$store.state.txCreate.parties.parties
        },
        showPartyFees() {
            for (const party of this.parties) {
                if (party.role !== 'BUYER' && party.role !== 'SELLER' && party.role !== 'READ_ONLY') {
                    return true
                }
            }
            return false
        },
    },
}
</script>
