import api from '@/scripts/api/api'
import errorHandler from '@/scripts/error-handler'

export default {
    namespaced: true,
    state: {
        accountStatement: '__loading__',
        accountStatementPage: 1,
        accountDepositDetails: '__loading__',
        accountDepositValues: null,
        accountDepositValuesError: null,
        withdrawAmount: 0,
        depositAmount: 0,
        paymentMethod: null,
    },
    mutations: {
        setAccountStatementListPage(state, payload) {
            state.accountStatementPage = payload.page
        },
        setWithdrawAmount(state, payload) {
            state.withdrawAmount = payload
        },
        setDepositAmount(state, payload) {
            state.depositAmount = payload
        },
        setPaymentMethod(state, payload) {
            state.paymentMethod = payload
        },
        setAccountDepositValues(state, payload) {
            state.accountDepositValues = payload
        },
        setDepositValuesError(state, payload) {
            state.accountDepositValuesError = payload
        },
    },
    actions: {
        async accountStatementFetch(store) {
            store.state.accountStatement = '__loading__'
            const accountStatement = await api.escrowAccount.accountStatementFetch(store.state.accountStatementPage)
            store.state.accountStatement = accountStatement
        },
        async accountDepositFetch(store) {
            store.state.accountDepositDetails = '__loading__'
            const accountDeposit = await api.escrowAccount.accountDepositFetch()
            store.state.accountDepositDetails = accountDeposit
        },
        async fetchDepositValues({ commit, state }) {
            try {
                commit('setAccountDepositValues', '__loading__')
                const value = await api.escrowAccount.accountDepositValues(state.depositAmount)
                commit('setAccountDepositValues', value)
            } catch (e) {
                commit('setAccountDepositValuesError', errorHandler.handle(e))
            }
        },
        async accountDeposit({ state }) {
            if (
                state.accountDepositValues &&
                state.paymentMethod === 'OZOW' &&
                state.accountDepositValues.ozow.value
            ) {
                return await api.escrowAccount.accountDeposit({
                    value: state.accountDepositValues.ozow.value,
                    paymentMethod: state.paymentMethod,
                })
            }
        },
        async accountWithdraw({ state }) {
            await api.escrowAccount.accountWithdraw(state.withdrawAmount)
        },
    },
}
