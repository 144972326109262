<template>
    <div>
        <component :is="deliveryComponent" />
    </div>
</template>
<script>
import mixinTransaction from '@/modules/common/mixin-transaction'
export default {
    mixins: [mixinTransaction],
    computed: {
        deliveryComponent() {
            const componentMap = {
                FUEL: 'fuel',
                MINING: 'mining',
            }
            const industry = componentMap[this.transaction.industry] || 'general'
            return () => import(`./overview-delivery-${industry}`)
        },
    },
}
</script>
