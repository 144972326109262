<template>
    <div>
        <div class="uk-text-center uk-padding-small uk-margin">
            <h3 class="uk-text-secondary uk-margin-remove">Describe in detail what you are {{ userActionText }}</h3>
        </div>

        <div class="uk-flex uk-flex-center">
            <form class="uk-width-4-5@m">
                <div class="uk-card uk-card-default uk-card-body uk-width-1-1 uk-margin-small">
                    <div uk-grid>
                        <GL-TextInput
                            class="uk-width-1-1"
                            label="transaction name"
                            v-model="title"
                            tooltip="Give your transaction a name i.e. Sale of ERF 2549 Bantry Bay etc. The transaction name can also include an invoice number or Purchase Order Number."
                            :error-messages="validation.requiredField(title)"
                        />
                        <GL-SelectInput
                            class="uk-width-1-2@s uk-margin-small-top"
                            label="property type"
                            v-model="type"
                            :option-list="propertyTypes"
                            :error-messages="validation.requiredField(type)"
                        />

                        <GL-TextInput
                            class="uk-width-1-2@s uk-margin-small-top"
                            label="street"
                            v-model="street"
                            :error-messages="validation.requiredField(street)"
                        />
                        <GL-TextInput
                            class="uk-width-1-2@s uk-margin-small-top"
                            label="suburb"
                            v-model="suburb"
                            :error-messages="validation.requiredField(suburb)"
                        />
                        <GL-TextInput
                            class="uk-width-1-2@s uk-margin-small-top"
                            label="city"
                            v-model="city"
                            :error-messages="validation.requiredField(city)"
                        />
                        <GL-TextInput
                            class="uk-width-1-2@s uk-margin-small-top"
                            label="province"
                            v-model="province"
                            :error-messages="validation.requiredField(province)"
                        />
                        <GL-SelectInput
                            class="uk-width-1-4@s uk-margin-small-top"
                            label="area units"
                            :option-list="areaUnitOptions"
                            v-model="areaUnits"
                            :error-messages="validation.requiredField(areaUnits)"
                        />
                        <GL-NumberInput
                            class="uk-width-1-4@s uk-margin-small-top"
                            label="area"
                            right-align
                            :append="areaUnitsAppend"
                            v-model="area"
                            :error-messages="validation.requiredField(area)"
                        />
                        <GL-TextAreaInput
                            class="uk-width-1-1 uk-margin-small-top"
                            label="Description"
                            tooltip="Give the transaction a detailed description. Provide as much detail as possible including the event that needs to take place in order for the funds to be released. You will be able to upload documents to this transaction later on (you can reference these documents in the description box below)."
                            v-model="description"
                            :error-messages="validation.requiredField(description)"
                        />
                        <GL-SelectInput
                            class="uk-width-1-2 uk-margin-small-top"
                            v-model="feeAllocation"
                            label="Who Will Pay The Escrow Fee?"
                            :option-list="feeAllocationOptions"
                            :error-messages="validation.requiredField(feeAllocation)"
                        />
                        <div class="uk-width-1-2@s uk-visible@s"></div>
                    </div>
                </div>
            </form>
        </div>
        <PartiesFees />
        <CreateTxNavButtons :disable-next="!allowProgress" />
    </div>
</template>

<script>
import { propertyTypes, areaUnits } from '../../../../../../constants/constants-property'
import PartiesFees from '../../common/parties-fees/index'
import CreateTxNavButtons from '../../common/create-transaction-navigation'
import validation from '@/scripts/transaction-create-field-validation'

export default {
    name: 'PropertyDetails',
    components: { CreateTxNavButtons, PartiesFees },
    data() {
        return {
            propertyTypes,
            areaUnitOptions: areaUnits,
            validation,
        }
    },
    computed: {
        userActionText() {
            if (this.$store.state.txCreate.parties.parties[0].role === 'BUYER') {
                return 'buying'
            } else if (this.$store.state.txCreate.parties.parties[0].role === 'SELLER') {
                return 'selling'
            }
            return 'brokering'
        },
        areaUnitsAppend() {
            if (this.areaUnits === 'SQUARE_METERS') {
                return 'm2'
            } else if (this.areaUnits === 'HECTARES') {
                return 'ha'
            }
            return ''
        },
        feeAllocationOptions() {
            return this.$store.getters['txCreate/feeAllocations']
        },
        title: {
            get() {
                return this.$store.state.txCreate.transaction.transaction.title
            },
            set(v) {
                this.$store.commit('txCreate/setTransactionField', { key: 'title', value: v })
            },
        },
        description: {
            get() {
                return this.$store.state.txCreate.transaction.transaction.description
            },
            set(v) {
                this.$store.commit('txCreate/setTransactionField', { key: 'description', value: v })
            },
        },
        feeAllocation: {
            get() {
                return this.$store.state.txCreate.transaction.transaction.feeAllocation
            },
            set(v) {
                this.$store.commit('txCreate/setTransactionField', { key: 'feeAllocation', value: v })
            },
        },
        type: {
            get() {
                return this.$store.state.txCreate.transaction.auxiliaryData.propertyType
            },
            set(value) {
                this.$store.commit('txCreate/setTransactionAuxiliaryField', { key: 'propertyType', value })
            },
        },
        street: {
            get() {
                return this.$store.state.txCreate.transaction.auxiliaryData.propertyStreet
            },
            set(value) {
                this.$store.commit('txCreate/setTransactionAuxiliaryField', { key: 'propertyStreet', value })
            },
        },
        suburb: {
            get() {
                return this.$store.state.txCreate.transaction.auxiliaryData.propertySuburb
            },
            set(value) {
                this.$store.commit('txCreate/setTransactionAuxiliaryField', { key: 'propertySuburb', value })
            },
        },
        city: {
            get() {
                return this.$store.state.txCreate.transaction.auxiliaryData.propertyCity
            },
            set(value) {
                this.$store.commit('txCreate/setTransactionAuxiliaryField', { key: 'propertyCity', value })
            },
        },
        province: {
            get() {
                return this.$store.state.txCreate.transaction.auxiliaryData.propertyProvince
            },
            set(value) {
                this.$store.commit('txCreate/setTransactionAuxiliaryField', { key: 'propertyProvince', value })
            },
        },
        areaUnits: {
            get() {
                return this.$store.state.txCreate.transaction.auxiliaryData.propertyAreaUnits
            },
            set(value) {
                this.$store.commit('txCreate/setTransactionAuxiliaryField', { key: 'propertyAreaUnits', value })
            },
        },
        area: {
            get() {
                return this.$store.state.txCreate.transaction.auxiliaryData.propertyArea
            },
            set(value) {
                this.$store.commit('txCreate/setTransactionAuxiliaryField', { key: 'propertyArea', value })
            },
        },
        allowProgress() {
            if (
                !(this.type && this.street && this.suburb && this.city && this.province && this.areaUnits && this.area)
            ) {
                return false
            }

            return !!(validation.partyDetailsComplete() && validation.basicDetailsComplete())
        },
    },
    watch: {
        type() {
            this.areaUnits = 'SQUARE_METERS'
        },
    },
}
</script>
