import TransactionCalculation from './graphql/transaction/transaction-calculation.gql'

export default class APICalculator {
    constructor(api) {
        this.api = api
    }
    async calculator(input) {
        const result = await this.api.client.mutate({
            mutation: TransactionCalculation,
            variables: {
                input,
            },
        })
        return result.data.calculator
    }
}
