<template>
    <div class="uk-flex uk-flex-center uk-flex-middle" :style="containerStyle">
        <img :src="stateIcon" :style="`width: ${size}rem; height: ${size}rem; color: #fff`" uk-svg />
    </div>
</template>

<script>
import { industries } from '@/constants/constants-industries'
export default {
    props: {
        transaction: {
            type: Object,
        },
        size: {
            type: Number,
            default: 2.5,
        },
    },
    computed: {
        stateIcon() {
            const industry = industries.find(({ value }) => value === this.transaction.industry)
            return '/icons/' + industry.icon
        },
        containerStyle() {
            return {
                'border-radius': this.size * 2 + 'rem',
                width: this.size * 2 + 'rem',
                height: this.size * 2 + 'rem',
                background: 'linear-gradient(-45deg, #5b5b5b 0%, #7A7E82 100%)',
            }
        },
    },
}
</script>
