<template>
    <div>
        <div v-if="status === true">
            <!-- Error handler -->
            <div v-if="errorMessage">
                <div class="uk-container uk-margin-top">
                    <h1 class="uk-text-center uk-text-muted">Sorry</h1>
                    <h3 class="uk-text-center">
                        An error has been encountered. <br/>
                        We have automatically alerted our engineers. <br/>
                        If the problem persists please reach out to us at
                    </h3>
                    <h3 class="uk-text-muted uk-text-center uk-margin-remove-top">
                        support@tradesafe.co.za / +27 (0) 10 020 3101
                    </h3>
                    <div class="uk-text-center">
                        <h3 class="uk-text-muted uk-text-center uk-margin-remove-top">
                            You can reload the app to try again
                        </h3>
                        <GL-Button type="primary" text="Reload App" @click="reload"/>
                        <p class="uk-margin-large-top">
                            Here is some helpful information our support staff might need : <br/>
                        </p>
                        <p class="uk-text-warning">
                            {{ errorMessage }}
                        </p>
                    </div>
                </div>
            </div>
            <!-- App render -->
            <div v-if="!errorMessage">
                <DefaultLayout>
                    <router-view/>
                </DefaultLayout>
            </div>
        </div>
        <div v-if="status === false">
            <div class="uk-container">
                <div class="uk-flex uk-flex-center">
                    <div
                        class="uk-card uk-card-default uk-card-body uk-width-large uk-margin-large-bottom uk-margin-large-top"
                    >
                        <div class="uk-text-center">
                            <GL-Spinner :size="8"/>
                        </div>

                        <h2 class="uk-text-center">Loading</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DefaultLayout from '@/modules/common/layouts/layout-default.vue'
import errorHandler from '@/scripts/error-handler'
import api from '@/scripts/api/api'

export default {
    name: 'app',
    components: {
        DefaultLayout,
    },
    data() {
        return {
            status: false,
        }
    },

    errorCaptured: function (error) {
        errorHandler.store.errorMessage = error
        // Log error in console and sentry
        this.$handleError(error)
        // Prevent further propegation
        return false
    },

    async mounted() {
        if (window.location.pathname.startsWith('/pay/')
            || window.location.pathname.startsWith('/signed_out')
            || window.location.pathname.startsWith('/letterofcomfort/')) {
            this.status = true
        } else {
            api.auth.loginStatus().then(status => {
                this.status = status

                if (status === true) {
                    this.$store.dispatch('user/userFetch')
                    this.$store.dispatch('organization/organizationListFetch')
                    this.$store.dispatch('context/contextFetch')
                } else {
                    api.auth.loginRequest()
                }
            })
        }
    },
    methods: {
        reload() {
            // window.location = '/'
            window.location.reload()
        },
    },
    computed: {
        errorMessage() {
            return errorHandler.store.errorMessage
        },
    },
}
</script>

<style lang="scss">
@import '@/scss/main.scss';
</style>
