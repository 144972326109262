<template>
    <div class="uk-animation-slide-right-small">
        Select the parties who can view this document
        <div class="uk-margin-small-top">
            <GL-SwitchInput
                class="uk-margin-small-bottom"
                :text="party.role"
                :disabled="party.role === 'AGENT'"
                v-for="party in parties"
                :key="party.id"
                :value="partyAllowed(party.id)"
                @input="selectParty(party.id)"
            />
        </div>
        <div class="uk-text-right">
            <GL-Button text="done" type="primary uk-button-small" @click="$emit('done')" />
        </div>
    </div>
</template>
<script>
export default {
    name: 'DocumentVisibilityEdit',
    props: {
        index: {
            type: Number,
            required: true,
        },
    },
    computed: {
        parties() {
            return this.$store.state.transaction.transaction.parties
        },
        allowedParties() {
            if (!this.file.allowedParties) {
                return this.parties.map(({ id }) => id)
            }
            return this.file.allowedParties
        },
        file() {
            return this.$store.state.txCreate.documents.documents[this.index].file
        },
    },
    methods: {
        partyAllowed(partyId) {
            return this.allowedParties.includes(partyId)
        },
        selectParty(partyId) {
            if (this.partyAllowed(partyId)) {
                const allowedParties = this.allowedParties.filter(id => id !== partyId)
                this.$store.commit('txCreate/setAllowedParties', { fileId: this.file.id, allowedParties })
            } else {
                const allowedParties = [...this.allowedParties, partyId]
                this.$store.commit('txCreate/setAllowedParties', { fileId: this.file.id, allowedParties })
            }
        },
    },
}
</script>
