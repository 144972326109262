<template>
    <div>
        <div class="uk-text-center">
            <h4 class="uk-text-secondary">Specify the delivery details for this transaction</h4>
        </div>
        <div class="uk-margin uk-flex uk-flex-center uk-flex-wrap">
            <CardButtonWide
                icon-path="truck-solid.svg"
                text="Single Address"
                value="Single Address"
                large
                description="All loads to be delivered to the same address"
                class="selectable-card uk-margin-small-right"
                :selected="milestonesToSameAddress"
                @select="milestonesToSameAddress = true"
            />
            <CardButtonWide
                icon-path="route-solid.svg"
                text="Multiple Addresses"
                value="Multiple Addresses"
                large
                description="The loads have different delivery addresses"
                class="selectable-card"
                :selected="!milestonesToSameAddress"
                @select="milestonesToSameAddress = false"
            />
        </div>
        <div v-if="editMode">
            <transition
                enter-active-class="uk-animation-slide-top-small"
                leave-active-class="uk-animation-slide-top-small uk-animation-reverse"
                mode="out-in"
            >
                <div
                    v-if="milestonesToSameAddress"
                    class="uk-flex uk-flex-center uk-margin-small-right uk-margin-small-left uk-margin-large"
                >
                    <div class="uk-width-5-6@m uk-margin-small-right uk-margin-small-left">
                        <AddressForm />
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import AddressForm from '../../delivery/address-form'
import CardButtonWide from '@/modules/common/components/input/card-button-wide-transaction-setup'

export default {
    name: 'MiningAddress',
    components: {
        AddressForm,
        CardButtonWide,
    },
    computed: {
        milestonesToSameAddress: {
            get() {
                return this.$store.state.txCreate.transaction.auxiliaryData.milestonesToSameAddress
            },
            set(value) {
                this.$store.commit('txCreate/setTransactionAuxiliaryField', { key: 'milestonesToSameAddress', value })
            },
        },
        editMode() {
            return this.$store.getters['txCreate/editMode']
        },
    },
}
</script>

<style scoped></style>
