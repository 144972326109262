import Vue from 'vue'
import VueRouter from 'vue-router'

import api from './scripts/api/api'
// ********************************************************************
// Routes
// ********************************************************************
import CommonRoutes from './modules/common/common-routes'
import HomeRoutes from './modules/home/home-routes'
import ProfileRoutes from './modules/profile/profile-routes'
import EscrowAccountRoutes from './modules/escrow-account/escrow-account-routes'
import TransactionRoutes from './modules/transaction/transaction-routes'
import DashboardRoutes from './modules/dashboard/dashboard-routes'
let routes = []

Array.prototype.push.apply(routes, CommonRoutes)
Array.prototype.push.apply(routes, HomeRoutes)
Array.prototype.push.apply(routes, ProfileRoutes)
Array.prototype.push.apply(routes, EscrowAccountRoutes)
Array.prototype.push.apply(routes, TransactionRoutes)
Array.prototype.push.apply(routes, DashboardRoutes)

// ********************************************************************
// Middleware
// ********************************************************************
function middleware(to, from, next) {
    // Redirects
    if (to.path === '/login') {
        api.auth.loginRequest()
        return
    }

    if (to.path === '/oauth/callback') {
        api.auth.login(to.query)
        return
    }

    if (to.path === '/register') {
        api.auth.register()
        return
    }

    next()
}

// ********************************************************************
// Scroll Behaviour
// ********************************************************************
function scrollBehavior(to, from) {
    if (to.name === 'Profile' && from.name === 'Profile') {
        return
    }
    if (to.query.overviewtab !== from.query.overviewtab) {
        return
    }
    return { x: 0, y: 0 }
}

// ********************************************************************
// Initialization
// ********************************************************************
Vue.use(VueRouter)
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior,
})

router.beforeEach(middleware)

api.setRouter(router)
export default router
