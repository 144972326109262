import api from '@/scripts/api/api'
import {
    cleanObjsForTransactionUpdateInPlace,
    createParties,
    findElementsToCreate,
    findElementsToDelete,
    findPartiesToUpdate,
} from '@/scripts/transaction-create-helpers'

const party_schema = {
    id: '',
    email: '',
    role: '',
    fee: null,
    feeType: null,
    feeAllocation: null,
    owner: false,
}

export default {
    state: {
        parties: [{ ...party_schema }],
        emailConfirmations: [],
    },
    getters: {
        counterParty: state => {
            return state.parties.find(party => !party.owner)
        },
        counterPartyIndex: state => {
            return state.parties.map(({ owner }) => owner).indexOf(false)
        },
        savedParties: (state, getters) => {
            const savedTransaction = getters['fetchedTransaction']
            return savedTransaction.parties
        },
        partiesToUpdate: (state, getters) => {
            return findPartiesToUpdate(state.parties, getters['savedParties'], party_schema)
        },
        partiesToDelete: (state, getters) => {
            return findElementsToDelete(state.parties, getters['savedParties'])
        },
        partiesToCreate: state => {
            return findElementsToCreate(state.parties)
        },
        partyDataComplete: state => {
            for (const party of state.parties) {
                if (party.role !== 'BUYER' && party.role !== 'SELLER') {
                    if (party.role === 'AGENT') {
                        if (!(party.fee && party.feeType)) {
                            return false
                        }
                    } else if (!(party.fee && party.feeType && party.feeAllocation)) {
                        return false
                    }
                }
            }
            return true
        },
    },
    mutations: {
        setPartyField(state, payload) {
            state.parties[payload.index][payload.key] = payload.value
        },
        setCurrentUserRole(state, payload) {
            state.parties = createParties(payload.role, payload.email)
            state.emailConfirmations = Array(state.parties.length).fill('')
            //The party at index 0 is the creating party
            state.emailConfirmations[0] = state.parties[0].email
        },
        addParty(state) {
            state.parties.push({ ...party_schema })
            state.emailConfirmations.push('')
        },
        removeParty(state, index) {
            state.parties = state.parties.filter((party, i) => i !== index)
            state.emailConfirmations = state.emailConfirmations.filter((email, i) => i !== index)
        },
        setEmailConfirmation(state, payload) {
            state.emailConfirmations = state.emailConfirmations.map((email, index) =>
                index === payload.index ? payload.value : email,
            )
        },
    },
    actions: {
        initParties({ state, getters }) {
            const transaction = getters['fetchedTransaction']
            if (transaction === '__loading__') {
                state.parties = [{ ...party_schema }]
                state.emailConfirmations = []
            } else {
                state.parties = []
                state.emailConfirmations = []
                for (const party of transaction.parties) {
                    const temp = {}
                    for (const key in party_schema) {
                        temp[key] = party[key]
                    }
                    if (party.owner) {
                        state.parties.unshift(temp)
                        state.emailConfirmations.unshift(temp.email)
                    } else {
                        state.parties.push(temp)
                        state.emailConfirmations.push(temp.email)
                    }
                }
            }
        },
        async saveParties({ getters, dispatch }) {
            const transaction = getters['fetchedTransaction']
            if (transaction !== '__loading__') {
                const parties = {
                    create: JSON.parse(JSON.stringify(getters['partiesToCreate'])),
                    update: JSON.parse(JSON.stringify(getters['partiesToUpdate'])),
                    delete: JSON.parse(JSON.stringify(getters['partiesToDelete'])),
                }

                cleanObjsForTransactionUpdateInPlace(parties)

                if (Object.keys(parties).length) {
                    await api.transaction.create.transactionUpdate({
                        id: transaction.id,
                        parties,
                    })
                    await dispatch('transaction/transactionRefetch', null, { root: true })
                    dispatch('initializeState')
                }
            }
        },
    },
}
