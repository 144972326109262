<template>
    <div>
        <div class="wrapper">
            <Navbar v-if="!frame" />
            <Notification />
            <slot />
            <div class="push"></div>
        </div>
        <Footer v-if="!frame" />
    </div>
</template>

<script>
import Navbar from '@/modules/common/components/navbar'
import Notification from '@/modules/common/components/notification'
import Footer from '@/modules/common/components/footer'

export default {
    name: 'Default',
    components: {
        Navbar,
        Notification,
        Footer,
    },

    computed: {
        frame() {
            return top !== window
        },
    },
}
</script>

<style scoped>
body {
    min-height: 100vh;
}
.wrapper {
    min-height: 100vh;
    margin-bottom: -10.55rem;
}
.push {
    height: 12rem;
}
</style>
