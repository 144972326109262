<template>
    <div>
        <div class="uk-text-center uk-padding-small uk-margin">
            <h3 class="uk-text-secondary uk-margin-remove">Describe in detail what you are {{ userActionText }}</h3>
        </div>
        <div class="uk-flex uk-flex-center uk-flex-wrap uk-margin-small-right uk-margin-small-left">
            <div class="uk-card uk-card-default uk-card-body uk-width-5-6@m">
                <div uk-grid>
                    <GL-TextInput
                        class="uk-width-1-1"
                        label="Transaction Name"
                        :tooltip="transactionTitleTooltip"
                        v-model="title"
                        :error-messages="validation.requiredField(title)"
                    />
                    <GL-TextAreaInput
                        class="uk-width-1-1 uk-margin-small-top"
                        label="Description"
                        tooltip="Give the transaction a detailed description. Provide as much detail as possible including the event that needs to take place in order for the funds to be released. You will be able to upload documents to this transaction later on (you can reference these documents in the description box below)."
                        v-model="description"
                        :error-messages="validation.requiredField(description)"
                    />
                    <GL-SelectInput
                        class="uk-width-1-2@m uk-margin-small-top"
                        v-model="feeAllocation"
                        label="Who Will Pay The Escrow Fee?"
                        :option-list="feeAllocationOptions"
                        :error-messages="validation.requiredField(feeAllocation)"
                    />
                    <div v-if="!drawdown" class="uk-width-1-2@m uk-visible@m"></div>

                    <GL-CurrencyInput
                        v-model="value"
                        class="uk-width-1-2@m uk-margin-small-top"
                        label="price"
                        :error-messages="validation.allocationValue(value)"
                    />
                    <GL-NumberInput
                        v-if="!drawdown"
                        v-model="daysToDeliver"
                        :tooltip="deliveryDaysTooltip"
                        class="uk-width-1-4@m uk-width-1-2@s uk-margin-small-top"
                        label="Delivery Days"
                        right-align
                        :error-messages="validation.requiredField(daysToDeliver)"
                    />
                    <GL-NumberInput
                        v-if="!drawdown"
                        v-model="daysToInspect"
                        :tooltip="inspectionDaysTooltip"
                        class="uk-width-1-4@m uk-width-1-2@s uk-margin-small-top"
                        label="Inspection Days"
                        right-align
                        :error-messages="validation.requiredField(daysToInspect)"
                    />
                </div>
            </div>
        </div>

        <PartiesFees />

        <TransactionInformation />
        <CreateTxNavButtons :disable-next="!allowProgress" />
    </div>
</template>

<script>
import PartiesFees from '../../common/parties-fees/index'
import CreateTxNavButtons from '../../common/create-transaction-navigation'
import validation from '@/scripts/transaction-create-field-validation'
import TransactionInformation from '../../transaction-information/index'
import TransactionCreateMixin from '../../transaction-create-mixin'

export default {
    name: 'GeneralDetails',
    components: { TransactionInformation, PartiesFees, CreateTxNavButtons },
    mixins: [TransactionCreateMixin],
    data() {
        return {
            validation,
        }
    },
    computed: {
        userActionText() {
            if (this.$store.state.txCreate.parties.parties[0].role === 'BUYER') {
                return 'buying'
            } else if (this.$store.state.txCreate.parties.parties[0].role === 'SELLER') {
                return 'selling'
            }
            return 'brokering'
        },
        transactionTitleTooltip() {
            switch (this.$store.state.txCreate.transaction.transaction.industry) {
                case 'FILMS_PRODUCTION':
                    return 'Give your transaction a name i.e. Baby Joe’s photo shoot etc. The transaction name can also include an invoice number or Purchase Order Number.'
                case 'EVENTS':
                    return 'Give your transaction a name i.e. Wedding planning  - Joe and Cate’s Big Day etc. The transaction name can also include an invoice number or Purchase Order Number.'
                case 'ART_ANTIQUES_COLLECTIBLES':
                    return 'Give your transaction a name i.e. The Starry Night by Vincent van Gogh. The transaction name can also include an invoice number or Purchase Order Number.'
                case 'CONSTRUCTION':
                    return 'Give your transaction a name i.e. Renovation of Eagle Office Park Sandton. The transaction name can also include an invoice number or Purchase Order number.'
                case 'CONTRACT_WORK_FREELANCING':
                    return 'Give your transaction a name i.e. Build new logo and CI portfolio for client Joe. The transaction name can also include an invoice number of Purchase Order Number.'
                case 'MERGERS_ACQUISITIONS':
                    return 'Give your transaction a name i.e. Sale of 35% of Joe (Pty) Ltd, Acquisition of 100% of Automated Drones Ltd or Investment opportunity in concentrating solar power systems company ThunderTech Inc etc.'
                default:
                    return 'Give your transaction a name i.e. BMW Z4 2010. The transaction name can also include an invoice number or Purchase Order number.'
            }
        },
        deliveryDaysTooltip() {
            switch (this.$store.state.txCreate.transaction.transaction.industry) {
                case 'MERGERS_ACQUISITIONS':
                    return 'Delivery days is the expected duration of a M&A transaction, sales process, or investment period, from start to finish.'
                case 'RENTAL':
                    return 'Delivery days is the period of the rental or lease agreement'
                case 'ART_ANTIQUES_COLLECTIBLES':
                    return 'Delivery days is the expected duration of the delivery of the antiques or collectables.'
                case 'CONSTRUCTION':
                    return 'Delivery days is the expected duration to complete the construction project or deliverable.'
                case 'CONTRACT_WORK_FREELANCING':
                    return 'Delivery days is the expected duration of the project or freelance work.'
                default:
                    return 'Delivery days is the expected duration of the delivery of the goods or services.'
            }
        },
        inspectionDaysTooltip() {
            switch (this.$store.state.txCreate.transaction.transaction.industry) {
                case 'MERGERS_ACQUISITIONS':
                    return 'Inspection days is the number of days the buyer or investor has to confirm that the transaction’s condition precedents have been fulfilled.'
                case 'ART_ANTIQUES_COLLECTIBLES':
                    return 'Inspections days is the number of days the buyer has to inspect the masterpiece.'
                case 'CONSTRUCTION':
                    return 'Inspection days is the number of days the buyer has to inspect the construction project or deliverable.'
                case 'CONTRACT_WORK_FREELANCING':
                    return 'Inspection days is the number of days the buyer has to inspect the project or freelance work.'
                default:
                    return 'Inspection days is the number of days a buyer has to inspect the goods or services'
            }
        },
        feeAllocationOptions() {
            return this.$store.getters['txCreate/feeAllocations']
        },
    },
}
</script>
