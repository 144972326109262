<template>
    <InputBase :label="label" :error-messages="errorMessages">
        <div>
            <div
                :class="{
                    'option-active': allowPrecious,
                    'option-disabled': !allowPrecious,
                    selected: value === 'PRECIOUS',
                }"
                @click="select('PRECIOUS')"
                :uk-tooltip="tooltip"
            >
                Precious Metals
            </div>
            <div class="option-active" :class="{ selected: value === 'NON_PRECIOUS' }" @click="select('NON_PRECIOUS')">
                Non-Precious Metals
            </div>
            <div class="option-active" :class="{ selected: value === 'NON_METALLIC' }" @click="select('NON_METALLIC')">
                Non-Metallic Minerals
            </div>
        </div>
    </InputBase>
</template>

<script>
import InputBase from '@/modules/common/components/global/input-base'
export default {
    name: 'PreciousNonPreciousSelector',
    components: { InputBase },
    props: {
        label: {
            type: String,
            default: '',
        },
        value: {
            type: String,
            default: '',
        },
        errorMessages: {
            type: Array,
            default() {
                return []
            },
        },
        name: {
            type: String,
            default: '',
        },
    },
    computed: {
        allowPrecious() {
            const org = this.$store.state.organization.organization
            const context = this.$store.state.context.context
            if (org !== '__loading__' && context !== '__loading__') {
                if (org.id === context.id && org.details.operateInMetals) {
                    return true
                }
            }
            return false
        },
        tooltip() {
            if (!this.allowPrecious) {
                return 'The selected organization is not verified to trade in precious metals. Please update the details in the profile page and upload the necessary documents to enable this organization functionality.'
            }
            return ''
        },
    },
    methods: {
        select(v) {
            if (v === 'PRECIOUS' && !this.allowPrecious) {
                return
            }
            this.$emit('select', v)
        },
    },
}
</script>

<style lang="scss" scoped>
.option-active {
    border: 1px solid $tradesafe-red;
    color: $tradesafe-red;
    border-radius: 0.5rem;
    display: inline-block;
    padding: 0.5rem 0.25rem;
    width: 42%;
    max-width: 10rem;
    min-width: 84px;
    text-align: center;
    margin-right: 0.25rem;
    transition-duration: var(--transition-normal);
    &.selected {
        background-color: $tradesafe-red;
        color: white;
    }
    &:hover {
        cursor: pointer;
        color: white;
        background-color: $tradesafe-red;
    }
}

.option-disabled {
    color: $tradesafe-dark-grey;
    border: 1px solid $tradesafe-dark-grey;
    border-radius: 0.5rem;
    display: inline-block;
    padding: 0.5rem 0.25rem;
    width: 42%;
    max-width: 10rem;
    min-width: 84px;
    text-align: center;
    margin-right: 0.25rem;
    transition-duration: var(--transition-normal);
    &:hover {
        cursor: pointer;
    }
}
</style>
