import { createSteps } from '@/scripts/create-transaction-step-builder'
import partiesModule from './store-transaction-create-modules/store-transaction-create-parties'
import allocationsModule from './store-transaction-create-modules/store-transaction-create-allocations'
import transactionModule from './store-transaction-create-modules/store-transaction-create-transaction'
import addressModule from './store-transaction-create-modules/store-transaction-create-address'
import documentsModule from './store-transaction-create-modules/store-transaction-create-documents'
import loadsModule from './store-transaction-create-modules/store-transaction-create-loads'
import calculationModule from './store-transaction-create-modules/store-transaction-create-calculation'
import api from '@/scripts/api/api'
import router from '@/router'

import * as filters from '@/scripts/filters'

export default {
    namespaced: true,
    state: {
        loading: false,
        showStep: 1,
        lastCompletedStep: 0,
        transactionType: '',
        transactionEdited: false,
    },
    getters: {
        editMode: () => {
            return !!(router.app._route.query && router.app._route.query.edit)
        },
        createTransactionSteps: (state, getters, rootState) => {
            return createSteps(
                rootState.txCreate.transaction.transaction.industry,
                rootState.txCreate.transaction.transaction.workflow,
                rootState.txCreate.transaction.auxiliaryData.milestonesToSameAddress,
            )
        },
        fetchedTransaction: (state, getters, rootState) => {
            // This transaction will be __new__ if this is the create process
            return rootState.transaction.transaction
        },
        address: state => {
            return state.address.address
        },
        feeAllocations: state => {
            const industry = state.transaction.transaction.industry
            const buyer = filters.transactionRoleDisplay('BUYER', industry)
            const seller = filters.transactionRoleDisplay('SELLER', industry)
            const agent = filters.transactionRoleDisplay('AGENT', industry)

            if (state.parties.parties.find(party => party.role === 'AGENT')) {
                return [
                    {
                        value: `${buyer} pays`,
                        key: 'BUYER',
                    },
                    {
                        value: `${seller} pays`,
                        key: 'SELLER',
                    },
                    {
                        value: `${agent} pays`,
                        key: 'AGENT',
                    },
                    {
                        value: `${buyer} ${seller} split`,
                        key: 'BUYER_SELLER',
                    },
                    {
                        value: `${buyer} / ${agent} split`,
                        key: 'BUYER_AGENT',
                    },
                    {
                        value: `${seller} / ${agent} split`,
                        key: 'SELLER_AGENT',
                    },
                    {
                        value: `${buyer} / ${seller} / ${agent} split`,
                        key: 'BUYER_SELLER_AGENT',
                    },
                ]
            }
            return [
                {
                    value: `${buyer} pays`,
                    key: 'BUYER',
                },
                {
                    value: `${seller} pays`,
                    key: 'SELLER',
                },
                {
                    value: `${buyer} ${seller} split`,
                    key: 'BUYER_SELLER',
                },
            ]
        },
        stepName: (state, getters) => {
            const steps = getters['createTransactionSteps']
            return steps[state.showStep - 1].name
        },
    },
    modules: {
        parties: partiesModule,
        allocations: allocationsModule,
        transaction: transactionModule,
        address: addressModule,
        documents: documentsModule,
        loads: loadsModule,
        calculator: calculationModule,
    },
    mutations: {
        setShowStep(state, value) {
            state.showStep = value
        },
        setTransactionEdited(state, value) {
            state.transactionEdited = value
        },
        toggleLoading(state) {
            state.loading = !state.loading
        },
        completeStep(state, stepsLength) {
            if (state.showStep <= state.lastCompletedStep && state.showStep < stepsLength) {
                state.showStep += 1
            } else {
                // A new step has been completed
                if (state.showStep < stepsLength) {
                    state.showStep += 1
                }
                if (state.lastCompletedStep < stepsLength) {
                    state.lastCompletedStep += 1
                }
            }
        },
        decrementShowStep(state) {
            if (state.showStep === 1) {
                router.push('/')
            } else {
                state.showStep -= 1
            }
        },
        setTransactionType(state, payload) {
            state.transactionType = payload
        },
    },
    actions: {
        setStepByName({ getters, state }, stepName) {
            const stepNames = getters['createTransactionSteps'].map(({ name }) => name)
            const stepNumber = stepNames.indexOf(stepName)
            state.showStep = stepNumber + 1
            state.lastCompletedStep = stepNumber
        },
        initializeState({ dispatch }) {
            dispatch('initTransactionCreate')
            dispatch('initAllocations')
            dispatch('initParties')
            dispatch('initTransaction')
            dispatch('initAddress')
            dispatch('initDocuments')
            dispatch('initTransactionCreateSteps')
        },
        initTransactionCreate({ state, getters }) {
            const transaction = getters['fetchedTransaction']
            if (transaction !== '__loading__') {
                const auxData = JSON.parse(transaction.auxiliaryData)
                state.transactionType = auxData.transactionType
            } else {
                state.transactionType = ''
                state.showStep = 1
                state.lastCompletedStep = 0
            }
        },
        initTransactionCreateSteps({ state, getters, dispatch }) {
            const transaction = getters['fetchedTransaction']

            if (transaction === '__loading__') {
                state.transactionType = ''
                state.showStep = 1
                state.lastCompletedStep = 0
            } else if (transaction.state === 'INVITATION_SENT') {
                dispatch('setStepByName', 'Overview')
            } else {
                if (state.showStep === 1 && state.lastCompletedStep === 0) {
                    if (
                        (transaction.directorsCheck && transaction.directorsCheck.status === 'PENDING') ||
                        (transaction.directorsCheck && transaction.directorsCheck.status === 'APPROVED')
                    ) {
                        dispatch('setStepByName', 'Overview')
                    } else {
                        dispatch('setStepByName', 'Details')
                    }
                }
            }
        },
        async saveAndAdvanceStep({ state, getters, dispatch, commit }) {
            const steps = getters['createTransactionSteps']
            commit('toggleLoading')
            if (steps[state.showStep - 1].name === 'Parties') {
                await dispatch('saveParties')
            } else if (steps[state.showStep - 1].name === 'Create') {
                await dispatch('saveSetup')
            } else if (steps[state.showStep - 1].name === 'Details') {
                await dispatch('saveTransaction')
            } else if (steps[state.showStep - 1].name === 'Delivery') {
                await dispatch('saveAddress')
                await dispatch('initializeState')
            } else if (steps[state.showStep - 1].name === 'Loads') {
                await dispatch('saveLoads')
            } else if (steps[state.showStep - 1].name === 'Milestones') {
                await dispatch('saveAllocations')
            } else if (steps[state.showStep - 1].name === 'Overview') {
                await dispatch('manageInvite')
            } else if (steps[state.showStep - 1].name === 'Documents') {
                await dispatch('manageDocuments')
            }
            if (getters['editMode']) {
                dispatch('setStepByName', 'Overview')
            } else {
                commit('completeStep', steps.length)
            }
            commit('toggleLoading')
        },
        async manageInvite(store) {
            const transaction = store.getters['fetchedTransaction']
            await api.transaction.create.transactionInvite(transaction.id)
            store.dispatch('transaction/transactionFetch', { id: transaction.id }, { root: true })
            store.dispatch('transaction/transactionListFetch', {}, { root: true })
        },
    },
}
