<template>
    <General />
</template>

<script>
import General from '../general/index'
export default {
    name: 'EventDetails',
    components: { General },
}
</script>
