<template>
    <div>
        <FuelOrderType />
        <SingleOrMultiAddressSelector
            v-if="fuelTransactionType === 'COD'"
            name="COD"
        />
        <SingleOrMultiAddressSelector
            v-if="fuelTransactionType === 'COC'"
            title="Specify the upliftment location for this transaction"
            single-description="All loads to be uplifted from the same address"
            multi-description="The loads have different upliftment locations"
        />
        <component v-if="editMode && milestonesToSameAddress" :is="addressComponent" />

        <FuelLoads v-if="fuelTransactionType" />

        <TransactionInformation />
        <CreateTxNavButtons :disable-next="!allowProgress" />
    </div>
</template>

<script>
import { products } from '../../../../../../constants/fuel'
import FuelMilestoneCardCODAddress from './milestone-cards/milestones-delivered-to-unique-addresses/milestone-card-cod-unique-address'
import FuelMilestoneCardCOCAddress from './milestone-cards/milestones-delivered-to-unique-addresses/milestone-card-coc-unique-address'
import CreateTxNavButtons from '../../common/create-transaction-navigation'
import FuelOrderType from '../../delivery/fuel/fuel-order-type'
import SingleOrMultiAddressSelector from './single-or-mulitiple-address-select'
import FuelMilestoneCardCOCNoAddress from './milestone-cards/milestones-delivered-to-same-address/milestone-card-coc-same-address'
import FuelMilestoneCardCODNoAddress from './milestone-cards/milestones-delivered-to-same-address/milestone-card-cod-same-address'
import FuelLoads from './fuel-loads'
import TransactionInformation from '../../transaction-information/index'
import COCFuelDeliveryAddress from '../../delivery/fuel/coc-fuel-delivery-address'
import CODFuelDeliveryAddress from '../../delivery/fuel/cod-fuel-delivery-address'

export default {
    name: 'FuelMilestone',
    components: {
        TransactionInformation,
        COCFuelDeliveryAddress,
        CODFuelDeliveryAddress,
        FuelLoads,
        SingleOrMultiAddressSelector,
        FuelOrderType,
        CreateTxNavButtons,
    },
    data() {
        return {
            products,
            showCalculation: false,
        }
    },
    mounted() {
        this.$store.dispatch('txCreate/transactionCalculation')
    },
    computed: {
        editMode() {
            return this.$store.getters['txCreate/editMode']
        },
        milestoneCard() {
            if (this.fuelTransactionType === 'COC') {
                if (this.milestonesToSameAddress) {
                    return FuelMilestoneCardCOCNoAddress
                }
                return FuelMilestoneCardCOCAddress
            } else if (this.fuelTransactionType === 'COD') {
                if (this.milestonesToSameAddress) {
                    return FuelMilestoneCardCODNoAddress
                }
                return FuelMilestoneCardCODAddress
            }
            return null
        },
        milestonesToSameAddress() {
            return this.$store.state.txCreate.transaction.auxiliaryData.milestonesToSameAddress
        },
        addressComponent() {
            if (this.fuelTransactionType === 'COD') {
                return 'CODFuelDeliveryAddress'
            } else if (this.fuelTransactionType === 'COC') {
                return 'COCFuelDeliveryAddress'
            }
            return null
        },
        fuelTransactionType() {
            return this.$store.state.txCreate.transaction.auxiliaryData.fuelTransactionType
        },
        addressDataComplete() {
            if (this.milestonesToSameAddress) {
                const address = this.$store.state.txCreate.address.address
                const addressComplete = !!(
                    address.id &&
                    address.name &&
                    address.line1 &&
                    address.line2 &&
                    address.city &&
                    address.area &&
                    address.code
                )
                const transactionAuxData = this.$store.state.txCreate.transaction.auxiliaryData
                if (this.fuelTransactionType === 'COD') {
                    return !!(
                        transactionAuxData.fuelZone &&
                        transactionAuxData.fuelMagisterialDistrict &&
                        addressComplete
                    )
                } else if (this.fuelTransactionType === 'COC') {
                    if (
                        transactionAuxData.fuelUpliftmentLocation === 'Private Depot' ||
                        transactionAuxData.fuelUpliftmentLocation === 'Mini Depot'
                    ) {
                        return addressComplete
                    }
                    return !!transactionAuxData.fuelUpliftmentLocation
                }
            } else if (!this.milestonesToSameAddress) {
                return true
            }
            return false
        },
        allocations() {
            return this.$store.state.txCreate.allocations.allocations
        },
        auxData() {
            return this.$store.state.txCreate.allocations.auxiliaryData
        },
        allocationDataComplete() {
            if (this.fuelTransactionType === 'COC') {
                for (let i = 0; i < this.allocations.length; i += 1) {
                    if (
                        !(
                            this.allocations[i].units &&
                            this.allocations[i].unitCost &&
                            this.allocations[i].daysToDeliver &&
                            this.allocations[i].daysToInspect &&
                            this.allocations[i].title &&
                            this.allocations[i].description &&
                            this.auxData[i].fuelDriverName &&
                            this.auxData[i].fuelVehicleRegistration
                        )
                    ) {
                        return false
                    }
                }
            } else {
                for (const alloc of this.allocations) {
                    if (
                        !(
                            alloc.units &&
                            alloc.unitCost &&
                            alloc.daysToDeliver &&
                            alloc.daysToInspect &&
                            alloc.title &&
                            alloc.description
                        )
                    ) {
                        return false
                    }
                }
            }
            return true
        },
        addressDataCompleteUniqueAddresses() {
            const addresses = this.$store.state.txCreate.allocations.addresses
            for (const address of addresses) {
                if (address.id) {
                    if (
                        !(
                            address.name &&
                            address.line1 &&
                            address.line2 &&
                            address.area &&
                            address.city &&
                            address.code
                        )
                    ) {
                        return false
                    }
                }
            }
            return true
        },
        addressDataCompleteSameAddress() {
            const address = this.$store.state.txCreate.address.address
            return !!(address.name && address.line1 && address.line2 && address.area && address.city && address.code)
        },
        allowProgress() {
            if (this.allocations.length < 2) {
                return false
            }
            if (!this.allocationDataComplete) {
                return false
            }
            if (!this.milestonesToSameAddress) {
                return this.addressDataCompleteUniqueAddresses
            }
            if (this.editMode && this.milestonesToSameAddress) {
                return this.addressDataCompleteSameAddress
            }
            return true
        },
        calculationDataChanged() {
            return this.$store.getters['txCreate/calculationDataChanged']
        },
        calculationDataReady() {
            return this.$store.getters['txCreate/calculationDataReady']
        },
    },
    watch: {
        calculationDataChanged() {
            if (this.calculationDataChanged && !this.milestonesToSameAddress) {
                this.$store.commit('txCreate/setCalculation', '__loading__')
                setTimeout(() => this.$store.dispatch('txCreate/transactionCalculation'), 1500)
            }
        },
        calculationDataReady() {
            if (!this.$store.state.txCreate.calculator.dataUsedInPreviousCalculation && this.calculationDataReady) {
                if (!this.milestonesToSameAddress) {
                    this.$store.commit('txCreate/setCalculation', '__loading__')
                    setTimeout(() => this.$store.dispatch('txCreate/transactionCalculation'), 1500)
                }
            }
        },
    },
}
</script>

<style scoped>
.selectable-card {
    margin: 0.5rem;
}
</style>
