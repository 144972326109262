<template>
    <div>
        <component :is="form" />
    </div>
</template>

<script>
import { transactionCreate } from '../../../../../constants/constants-transaction-create'
import General from './general/index'
import GeneralMilestone from './general-milestone/index'
import Fuel from './fuel/index'
import FuelMilestone from './fuel-milestone/index'
import MiningAndMetals from './mining/index'
import MiningAndMetalsMilestone from './mining-milestone/index'
import Vehicle from './vehicle/index'
import VehicleMilestone from './vehicle-milestone/index'
import Electronics from './electronics/index'
import ElectronicsMilestone from './electronics-milestone/index'
import Agriculture from './agriculture/index'
import AgricultureMilestone from './agriculture-milestone/index'
import Property from './property/index'
import PropertyMilestone from './property-milestone/index'
import Domains from './domains/index'
import DomainsMilestone from './domains-milestone/index'
import Art from './art/index'
import ArtMilestone from './art-milestone/index'
import Construction from './construction/index'
import ConstructionMilestone from './construction-milestone/index'
import Events from './events/index'
import EventsMilestone from './events-milestone/index'
import Film from './film/index'
import FilmMilestone from './film-milestone/index'
import Freelancing from './freelancing/index'
import FreelancingMilestone from './freelancing-milestone/index'
import Mergers from './mergers/index'
import MergersMilestone from './mergers-milestone/index'

export default {
    name: 'TransactionDetails',
    components: {
        Freelancing,
        FreelancingMilestone,
        Mergers,
        MergersMilestone,
        FilmMilestone,
        Film,
        Events,
        EventsMilestone,
        Construction,
        ConstructionMilestone,
        Art,
        ArtMilestone,
        Domains,
        DomainsMilestone,
        Property,
        PropertyMilestone,
        General,
        GeneralMilestone,
        Electronics,
        ElectronicsMilestone,
        Fuel,
        FuelMilestone,
        MiningAndMetals,
        MiningAndMetalsMilestone,
        Vehicle,
        VehicleMilestone,
        Agriculture,
        AgricultureMilestone,
    },
    data() {
        return {
            forms: {
                industry: 'General Goods',
            },
        }
    },
    computed: {
        form() {
            const selectedIndustry = this.$store.state.txCreate.transaction.transaction.industry
            const txType = this.$store.state.txCreate.transaction.transaction.workflow
            if (!selectedIndustry || !txType) {
                return null
            } else {
                const industry = transactionCreate.industries.find(industry => industry.value === selectedIndustry)
                return txType === 'MILESTONE' ? industry.formComponentName + 'Milestone' : industry.formComponentName
            }
        },
    },
}
</script>
