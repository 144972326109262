import AccountDepositFetch from './graphql/escrow-account/account-deposit-fetch.gql'
import AccountStatementFetch from './graphql/escrow-account/account-statement-fetch.gql'
import AccountWithdraw from './graphql/escrow-account/account-withdraw.gql'
import AccountDepositValues from './graphql/escrow-account/account-deposit-query.gql'
import AccountDeposit from './graphql/escrow-account/account-deposit-mutation.gql'

export default class APIEscrowAccount {
    constructor(api) {
        this.api = api
    }

    async accountDepositFetch() {
        const result = await this.api.client.query({
            query: AccountDepositFetch,
        })
        return result.data.depositDetails
    }
    async accountStatementFetch(page) {
        const result = await this.api.client.query({
            query: AccountStatementFetch,
            variables: {
                page: page,
            },
        })
        return result.data.accountStatement
    }
    async accountWithdraw(value) {
        const result = await this.api.client.mutate({
            mutation: AccountWithdraw,
            variables: {
                value,
            },
        })
        return result.data.accountWithdraw
    }
    async accountDepositValues(value) {
        const result = await this.api.client.query({
            query: AccountDepositValues,
            variables: {
                value,
            },
        })
        return result.data.accountDeposit
    }
    async accountDeposit(input) {
        const result = await this.api.client.mutate({
            mutation: AccountDeposit,
            variables: {
                value: input.value,
                method: input.paymentMethod,
            },
        })
        return result.data.accountDeposit
    }
}
