import api from '@/scripts/api/api'
import validation from '@/scripts/transaction-create-field-validation'

export default {
    state: {
        calculation: '__loading__',
        calculationError: '',
        dataUsedInPreviousCalculation: null,
    },
    mutations: {
        setDataUsedInPreviousCalculation(state, data) {
            state.dataUsedInPreviousCalculation = data
        },
        setCalculation(state, calc) {
            state.calculation = calc
        },
        setCalculationError(state, error) {
            state.calculationError = error
        },
    },
    getters: {
        calculatorPartiesStructure: (state, getters, rootState) => {
            return rootState.txCreate.parties.parties.map(({ role, fee, feeType, feeAllocation }) => {
                if (role === 'BUYER' || role === 'SELLER') {
                    return { role }
                } else if (role === 'AGENT') {
                    return { role, fee, feeType, feeAllocation }
                } else {
                    return { role, fee, feeType, feeAllocation }
                }
            })
        },
        calculationDataReady: (state, getters, rootState) => {
            if (!rootState.txCreate.transaction.transaction.feeAllocation) {
                return false
            }
            for (const allocation of rootState.txCreate.allocations.allocations) {
                if ((allocation.units && allocation.unitCost) || allocation.value) {
                    return validation.partyDetailsComplete()
                }
            }
            return false
        },
        calculationData: (state, getters, rootState) => {
            const data = {}
            data.feeAllocation = rootState.txCreate.transaction.transaction.feeAllocation
            data.allocations = rootState.txCreate.allocations.allocations.map(({ id, value, units, unitCost }) => {
                if (units && unitCost) {
                    return { id, units, unitCost, value: units * unitCost }
                }
                return { id, value }
            })
            data.parties = getters['calculatorPartiesStructure']
            data.industry = rootState.txCreate.transaction.transaction.industry
            return data
        },
        calculationDataChanged: (state, getters) => {
            if (
                !getters['calculationDataReady'] ||
                !state.dataUsedInPreviousCalculation ||
                state.calculation === '__loading__'
            ) {
                return false
            }

            const current = getters['calculationData']
            const old = state.dataUsedInPreviousCalculation
            if (
                current.feeAllocation !== old.feeAllocation ||
                current.parties.length !== old.parties.length ||
                current.allocations.length !== old.allocations.length
            ) {
                return true
            }

            if (
                old.allocations.reduce((acc, a) => (acc + a.units ? a.units : 0), 0) !==
                    current.allocations.reduce((acc, a) => (acc + a.units ? a.units : 0), 0) ||
                old.allocations.reduce((acc, a) => (acc + a.value ? a.value : 0), 0) !==
                    current.allocations.reduce((acc, a) => (acc + a.value ? a.value : 0), 0) ||
                old.allocations.reduce((acc, a) => (acc + a.unitCost ? a.unitCost : 0), 0) !==
                    current.allocations.reduce((acc, a) => (acc + a.unitCost ? a.unitCost : 0), 0)
            ) {
                return true
            }

            if (
                old.parties.reduce((acc, p) => (acc + p.fee ? p.fee : 0), 0) !==
                current.parties.reduce((acc, p) => (acc + p.fee ? p.fee : 0), 0)
            ) {
                return true
            }

            for (let i = 0; i < old.parties.length; i += 1) {
                if (
                    old.parties[i].role !== current.parties[i].role ||
                    old.parties[i].feeAllocation !== current.parties[i].feeAllocation ||
                    old.parties[i].feeType !== current.parties[i].feeType
                ) {
                    return true
                }
            }
            return false
        },
    },
    actions: {
        async transactionCalculation({ getters, commit }) {
            if (getters['calculationDataReady']) {
                const calculationData = getters['calculationData']
                try {
                    commit('setCalculationError', '')
                    commit('setCalculation', '__loading__')
                    const calc = await api.calculator.calculator(calculationData)
                    commit('setCalculation', calc)
                    commit('setDataUsedInPreviousCalculation', calculationData)
                } catch (e) {
                    commit('setCalculationError', 'Unable to get transaction calculation')
                }
            }
        },
    },
}
