<template>
    <div>
        <component :is="detailsComponent" />
    </div>
</template>
<script>
import mixinTransaction from '@/modules/common/mixin-transaction'
export default {
    mixins: [mixinTransaction],
    computed: {
        detailsComponent() {
            const componentMap = {
                FUEL: 'fuel',
                MINING: 'mining',
                VEHICLES_WATERCRAFT: 'vehicles',
                CELLPHONES_COMPUTERS: 'electronics',
                SOFTWARE_DEV_WEB_DOMAINS: 'web',
                PROPERTY: 'property',
                AGRICULTURE_LIVESTOCK_GAME: 'agriculture',
                MERGERS_ACQUISITIONS: 'mergers',
            }
            const industry = componentMap[this.transaction.industry] || 'general'
            return () => import(`./overview-details-${industry}`)
        },
    },
}
</script>
