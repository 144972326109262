<template>
    <ModalBase ref="modal">
        <div :key="refreshCount">
            <h4>{{ heading }}</h4>
            <div
                v-for="context in contextList"
                :key="context.id"
                class="context-option uk-margin-small"
                :class="{ active: activeContext === context.id }"
                @click="selectContext(context.id)"
            >
                <div class="uk-padding-small">
                    <div class="uk-flex uk-flex-between uk-flex-wrap">
                        <div>
                            <img
                                width="32"
                                height="32"
                                class="uk-border-circle dropdown-item-image"
                                :src="context.avatar | safeAvatarURL"
                            />
                            <div class="uk-margin-small-left uk-inline context-option-text">
                                {{ context.name }}
                            </div>
                        </div>
                        <div>
                            <div class="uk-inline context-type-lable" :class="{ active: activeContext === context.id }">
                                {{ context.type }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <GL-ErrorAlert :errorMessage="error" />
            <div class="uk-margin-top">
                <hr />
                <div class="uk-flex uk-flex-right uk-flex-wrap-reverse">
                    <GL-Button
                        @click="cancel"
                        text="Cancel"
                        type="default"
                        :disabled="updateLoading"
                        disabled-text="Cancel"
                        :class="{
                            'uk-margin-small-right': $screenLarger('small'),
                            'uk-width-1-1': $screenSmaller('small'),
                        }"
                    />
                    <GL-Button
                        :class="{
                            'uk-margin-small-right': $screenLarger('small'),
                            'uk-width-1-1': $screenSmaller('small'),
                            'uk-margin-small': $screenSmaller('small'),
                        }"
                        :disabled="updateLoading || !contextFullyVerified"
                        :loading="updateLoading"
                        disabled-text="Done"
                        :tooltip="
                            !contextFullyVerified && !updateLoading
                                ? 'Please complete the verification of this company / user before proceeding'
                                : null
                        "
                        type="primary"
                        text="Done"
                        name="select-context-done"
                        @click="submit()"
                    />
                </div>
            </div>
        </div>
    </ModalBase>
</template>

<script>
import ModalBase from '@/modules/common/components/modal-base'
export default {
    name: 'SetContextModal',
    components: {
        ModalBase,
    },
    props: {
        heading: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            updateLoading: false,
            activeContext: null,
            refreshCount: 0,
            error: '',
            onsubmit: () => {
                console.error('no onsubmit callback was provided')
            },
            onclose: () => {
                console.error('no onclose callback was provided')
            },
            onresend: () => {
                console.error('no onresend callback was provided')
            },
        }
    },
    computed: {
        contextList() {
            return this.$store.getters['context/contextList']
        },
        context() {
            return this.$store.getters['context/context']
        },
        contextFullyVerified() {
            return this.$store.getters['context/contextVerification'] === null
        },
    },
    methods: {
        async selectContext(contextId) {
            try {
                this.activeContext = contextId
                this.updateLoading = true
                await this.$store.dispatch('context/contextSelect', { id: contextId })
            } catch (error) {
                this.activeContext = this.context.id
                this.error = this.$handleError(error)
            } finally {
                this.updateLoading = false
            }
        },
        show() {
            this.$refs.modal.show()
            this.activeContext = this.context.id
            let _this = this
            return new Promise(resolve => {
                _this.onsubmit = () => {
                    resolve({ submit: true })
                }
                _this.onclose = () => {
                    resolve({ submit: false })
                }
            })
        },
        submit() {
            try {
                this.onsubmit()
            } catch (error) {
                this.$handleError(error)
            } finally {
                this.$refs.modal.hide()
            }
        },
        cancel() {
            try {
                this.onclose()
            } catch (error) {
                this.$handleError(error)
            } finally {
                this.$refs.modal.hide()
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.context-option {
    border: 1px solid $tradesafe-light-grey;
    border-radius: 0.5rem;
    white-space: nowrap;

    &.active {
        background-color: $tradesafe-red;
        border: 2px solid $tradesafe-red;
        color: #fff;
    }

    &:hover {
        cursor: pointer;
    }
}

.context-option-text {
    overflow-wrap: normal;
    max-width: 18rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media only screen and (max-width: 600px) {
    .context-option-text {
        max-width: 10rem;
    }
}

.context-type-lable {
    background: $tradesafe-red;
    color: #fff;
    text-transform: uppercase;
    font-size: 0.8rem;
    padding: 1px 10px;
    border-radius: 0.5rem;
    &.active {
        background: #fff;
        color: #666;
    }
}
</style>
