<template>
    <div class="MilestoneRunningSummary">
        <div class="uk-padding-small">
            <div class="uk-margin-small">Running Totals</div>
            <div class="content-block uk-margin-small">
                <div class="uk-text-right">
                    {{ totalUnitHeading }}
                </div>
                <div class="uk-text-right">{{ totalUnits }}</div>
            </div>
            <div class="content-block uk-margin-small">
                <div class="uk-text-right">Total Value</div>
                <div class="uk-text-right">R {{ totalValue | currency }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MilestoneRunningSummaryUnits',
    props: {
        index: {
            type: Number,
            required: true,
        },
        totalUnitHeading: {
            type: String,
            required: true,
        },
    },
    computed: {
        totalUnits() {
            return this.$store.getters['txCreate/transactionUnits']
        },
        totalValue() {
            return this.$store.getters['txCreate/transactionValueUnitBased']
        },
    },
}
</script>

<style lang="scss" scoped>
.MilestoneRunningSummary {
    /*width: 15rem;*/
    background-color: $tradesafe-red;
    color: white;
    border-radius: 0.5rem;
    position: absolute;
    top: 0;
    right: -11.5rem;
    width: 11rem;
}
.content-block {
    border: 1px solid white;
    border-radius: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
</style>
