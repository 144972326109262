import TransactionCreate from './graphql/transaction/transaction-create.gql'
import TransactionUpdate from './graphql/transaction/transaction-update.gql'
import TransactionInvite from './graphql/transaction/transaction-invite.gql'

import PartyCreate from './graphql/parties/party-create.gql'
import PartyUpdate from './graphql/parties/party-update.gql'
import PartyDelete from './graphql/parties/party-delete.gql'

export default class APITransactionCreate {
    constructor(api) {
        this.api = api
    }

    async transactionCreate(input) {
        const response = await this.api.client.mutate({
            mutation: TransactionCreate,
            variables: {
                input,
            },
        })
        return response.data.transactionCreate
    }
    async transactionUpdate(input) {
        const response = await this.api.client.mutate({
            mutation: TransactionUpdate,
            variables: {
                input,
            },
        })
        return response.data.transactionUpdate
    }
    async transactionInvite(id) {
        const response = await this.api.client.mutate({
            mutation: TransactionInvite,
            variables: {
                id,
            },
        })
        return response.data.transactionInvite
    }
    async partyCreate(transactionId, input) {
        const response = await this.api.client.mutate({
            mutation: PartyCreate,
            variables: {
                transactionId,
                input,
            },
        })
        return response.data.partyCreate
    }
    async partyUpdate(id, input) {
        const response = await this.api.client.mutate({
            mutation: PartyUpdate,
            variables: {
                id,
                input,
            },
        })
        return response.data.partyCreate
    }
    async partyDelete(id) {
        const response = await this.api.client.mutate({
            mutation: PartyDelete,
            variables: {
                id,
            },
        })
        return response.data.partyCreate
    }
}
