<template>
    <div class="uk-margin-small-top">
        <div class="uk-text-center uk-text-large uk-margin">Load {{ index + 1 }} - Summary</div>
        <div>
            <ul class="uk-subnav uk-subnav-pill uk-child-width-expand" uk-switcher>
                <li class="uk-active"><a class="rounded uk-text-center" href="#">Load Description</a></li>
                <li><a class="rounded uk-text-center" href="#">Amount Payable</a></li>
                <li><a class="rounded uk-text-center" href="#">Delivery Location</a></li>
            </ul>
            <ul class="uk-switcher uk-margin">
                <li class="uk-margin-left">
                    <GL-InfoItem label="Load Title" :value="title" />
                    <GL-InfoItem label="Load Description" :value="description" />
                    <div uk-grid>
                        <GL-InfoItem label="Commodity Type" :value="commodityType" />
                        <GL-InfoItem label="Commodity" :value="commodity" />
                        <GL-InfoItem label="Delivery Days" right-align :value="daysToDeliver" />
                        <GL-InfoItem label="Inspection Days" right-align :value="daysToInspect" />
                    </div>
                </li>
                <li class="uk-margin-left">
                    <div v-if="calculationDataReady && calculation === '__loading__' && !error">
                        <div class="uk-text-center">
                            <GL-Spinner :size="2" />
                        </div>
                    </div>
                    <GL-Alert v-else-if="!calculationDataReady && !error" type="info">
                        You need to complete the details for the other loads before you can see the payable breakdown
                    </GL-Alert>
                    <GL-Alert v-else-if="error" type="danger">
                        {{ error }}
                    </GL-Alert>
                    <div v-else>
                        <div uk-grid>
                            <GL-InfoItem :label="massUnits.label" :value="units" right-align thousand-separate />
                            <GL-InfoItem
                                :label="massUnits.price"
                                prefix="R"
                                :value="unitCost | currency"
                                right-align
                                thousand-separate
                            />
                            <GL-InfoItem
                                label="Load Value"
                                prefix="R"
                                :value="(unitCost * units) | currency"
                                right-align
                                thousand-separate
                            />
                            <GL-InfoItem
                                label="Supplier's Costs"
                                prefix="R"
                                :value="sellerFeeForThisMilestone | currency"
                                right-align
                                thousand-separate
                            />
                            <GL-InfoItem
                                label="Amount Payable To Supplier"
                                prefix="R"
                                underline
                                :value="payout | currency"
                                right-align
                                thousand-separate
                            />
                        </div>
                        <div uk-grid>
                            <GL-InfoItem
                                :label="'Total Running ' + massUnits.label"
                                :value="totalMassUpToCurrentIndex"
                                right-align
                                thousand-separate
                            />
                            <GL-InfoItem
                                label="Total Running Value"
                                prefix="R"
                                :value="totalValueUpToCurrentIndex | currency"
                                right-align
                                thousand-separate
                            />
                        </div>
                    </div>
                </li>
                <li class="uk-margin-left">
                    <div v-if="address" class="uk-grid-row-collapse" uk-grid>
                        <div class="uk-width-1-2@s">
                            <GL-InfoItem label="Street / Complex" :value="address.line1" />
                        </div>
                        <div class="uk-width-1-2@s">
                            <GL-InfoItem label="Street line 2" :value="address.line2" />
                        </div>
                        <div class="uk-width-1-2@s">
                            <GL-InfoItem label="Suburb" :value="address.line3" />
                        </div>
                        <div class="uk-width-1-2@s">
                            <GL-InfoItem label="City" :value="address.city" />
                        </div>
                        <div class="uk-width-1-2@s">
                            <GL-InfoItem label="State / Province" :value="address.area" />
                        </div>
                        <div class="uk-width-1-2@s">
                            <GL-InfoItem label="Area Code" :value="address.code" />
                        </div>
                        <div class="uk-width-1-1@s">
                            <GL-InfoItem label="Delivery Instructions" :value="deliveryInstructions" />
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="uk-width-1-1@m uk-text-right uk-margin-top uk-card-footer">
            <GL-Button
                text="previous"
                type="primary-outlined"
                class="uk-margin-small-right"
                @click="$emit('view-previous')"
            />
        </div>
    </div>
</template>

<script>
import { constantsCommodities } from '../../../../../../../constants/constants-commodities'

export default {
    name: 'MiningMilestoneSummary',
    props: {
        index: {
            type: Number,
            required: true,
        },
        showAddress: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        calculation() {
            return this.$store.state.txCreate.calculator.calculation
        },
        error() {
            return this.$store.state.txCreate.calculator.calculationError
        },
        units() {
            return this.$store.state.txCreate.allocations.allocations[this.index].units
        },
        unitCost() {
            return this.$store.state.txCreate.allocations.allocations[this.index].unitCost
        },
        value() {
            return this.units * this.unitCost
        },
        daysToInspect() {
            return this.$store.state.txCreate.allocations.allocations[this.index].daysToInspect
        },
        daysToDeliver() {
            return this.$store.state.txCreate.allocations.allocations[this.index].daysToDeliver
        },
        deliveryInstructions() {
            return this.$store.state.txCreate.allocations.allocations[this.index].deliveryInstructions
        },
        address() {
            return this.$store.state.txCreate.allocations.addresses[this.index]
        },
        description() {
            return this.$store.state.txCreate.allocations.allocations[this.index].description
        },
        title() {
            return this.$store.state.txCreate.allocations.allocations[this.index].title
        },
        totalValueUpToCurrentIndex() {
            let total = 0
            for (let i = this.index; i >= 0; i -= 1) {
                total +=
                    this.$store.state.txCreate.allocations.allocations[i].units *
                    this.$store.state.txCreate.allocations.allocations[i].unitCost
            }
            return total
        },
        totalMassUpToCurrentIndex() {
            let total = 0
            for (let i = this.index; i >= 0; i -= 1) {
                total += this.$store.state.txCreate.allocations.allocations[i].units
            }
            return total
        },
        massUnits() {
            if (this.commodityType === 'PRECIOUS') {
                if (this.commodity === 'DIAMONDS') {
                    return {
                        label: 'Carats',
                        price: 'Price Per Carat',
                    }
                }
                return {
                    label: 'Troy Ounces',
                    price: 'Price Per Troy Ounce',
                }
            }
            return {
                label: 'Tonnage',
                price: 'Price Per Ton',
            }
        },
        commodityData() {
            const selectedCommodity = this.$store.state.txCreate.transaction.auxiliaryData.miningCommodity
            const commodity = constantsCommodities.find(com => com.key === selectedCommodity)
            if (commodity) {
                return commodity
            }
            return selectedCommodity
        },
        payout() {
            if (this.calculation !== '__loading__' && this.calculation.allocations[this.index]) {
                return this.calculation.allocations[this.index].payout
            }
            return 0
        },
        sellerFeeForThisMilestone() {
            if (this.calculation !== '__loading__' && this.calculation.allocations[this.index]) {
                return this.calculation.allocations[this.index].processingFee
            }
            return 0
        },
        commodityType() {
            if (this.commodityData.category) {
                if (this.commodityData.category === 'NON_PRECIOUS') {
                    return 'Non Precious'
                } else if (this.commodityData.category === 'PRECIOUS') {
                    return 'Precious'
                } else if (this.commodityData.category === 'NON_METALLIC') {
                    return 'Non-Metallic Mineral'
                }
            }
            return 'None Provided'
        },
        calculationDataReady() {
            return this.$store.getters['txCreate/calculationDataReady']
        },
        initialCalculation() {
            return this.$store.state.txCreate.calculator.dataUsedInPreviousCalculation
        },
        commodity() {
            if (this.commodityData.value) {
                return this.commodityData.value
            }
            return 'None Provided'
        },
    },
}
</script>
<style lang="scss" scoped>
$primary: #e50045;
.rounded {
    border-radius: 0.5rem;
}
</style>
