<template>
    <div>
        <div v-if="!transaction.files || transaction.files.length === 0">
            No files have been uploaded to this transaction.
        </div>
        <div v-if="transaction && transaction.files && transaction.files.length !== 0">
            <div
                class="outline uk-padding-small uk-margin-small"
                v-for="(file, index) in transaction.files"
                :key="index"
            >
                <div uk-grid>
                    <div class="uk-width-expand">{{ file.filename }}</div>
                    <div>
                        <a :href="file.uri" class="clickable" :download="file.filename">
                            <GL-Icon path="download-solid.svg" :size="1.25" />
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="uk-text-right uk-margin-top" v-if="showEditButton">
            <GL-Button text="edit files" type="primary uk-button-small" @click="navigateEditPage" />
        </div>
    </div>
</template>
<script>
import mixinTransaction from '@/modules/common/mixin-transaction'

export default {
    mixins: [mixinTransaction],
    computed: {
        showEditButton() {
            if (this.transaction.metadata.activeParty.role === 'READ_ONLY') {
                return false
            }
            const state = this.transaction.state
            return !(
                this.$store.getters['txCreate/editMode'] ||
                state === 'CREATED' ||
                state === 'DECLINED' ||
                state === 'CANCELED' ||
                state === 'ADMIN_CANCELED' ||
                state === 'SUSPENDED' ||
                state === 'ADMIN_SUSPENDED'
            )
        },
    },
    methods: {
        navigateEditPage() {
            this.$router.push(`/transaction/${this.transaction.id}/documents`)
        },
    },
    mounted() {
        this.$getSeenService().markTransactionSeen(this.transaction)
    },
}
</script>

<style scoped>
.outline {
    border-style: solid;
    border-width: 0.08rem;
    border-radius: 0.5rem;
    border-color: #ddd;
}

.clickable {
    color: #676767;
}

.clickable:hover {
    cursor: pointer;
    color: #999;
}
</style>
