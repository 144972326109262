import api from '@/scripts/api/api'

const address_schema = {
    id: '',
    name: '',
    line1: '',
    line2: '',
    line3: '',
    city: '',
    area: '',
    code: '',
    country: 'ZAF',
}

export default {
    state: {
        address: { ...address_schema },
        deliveryAddressRequired: false,
    },
    getters: {
        SBSATransactionAddressDetailsUpdated: (state, getters, rootState) => {
            const transaction = getters['fetchedTransaction']
            const currentAddress = transaction.allocations[0].address

            const currentDeliveryInstructions = transaction.allocations[0].deliveryInstructions
            const txCreateDeliveryInstructions = rootState.txCreate.allocations.allocations[0].deliveryInstructions
            if (currentDeliveryInstructions !== txCreateDeliveryInstructions) {
                return true
            }

            if (!state.deliveryAddressRequired && currentAddress) {
                return true
            } else if (!currentAddress && state.deliveryAddressRequired) {
                return true
            } else if (currentAddress && currentAddress.id !== state.address.id) {
                return true
            }
            return getters['addressUpdated']
        },
        addressUpdated: (state, getters, rootState) => {
            const selectedAddress = rootState.user.fetchedUser.addresses.find(({ id }) => id === state.address.id)
            if (selectedAddress) {
                for (const key of Object.keys(address_schema)) {
                    if (state.address[key] !== selectedAddress[key]) {
                        return true
                    }
                }
            }
            return false
        },
        savedAddress: (state, getters) => {
            const transaction = getters['fetchedTransaction']
            if (transaction.allocations && transaction.allocations[0].address) {
                return transaction.allocations[0].address
            }
            return { ...address_schema }
        },
        updatedAddressFields: (state, getters, rootState) => {
            const updated = {}
            const selectedAddress = rootState.user.fetchedUser.addresses.find(({ id }) => id === state.address.id)
            for (const key of Object.keys(address_schema)) {
                if (state.address[key] !== selectedAddress[key]) {
                    updated[key] = state.address[key]
                }
            }
            return updated
        },
    },
    mutations: {
        deliveryAddressRequired(state, value) {
            state.deliveryAddressRequired = value
        },
        setAddressField(state, payload) {
            state.address[payload.key] = payload.value
        },
    },
    actions: {
        initAddress({ state, getters }) {
            const transaction = getters['fetchedTransaction']
            if (
                transaction === '__loading__' ||
                transaction.allocations.length === 0 ||
                !transaction.allocations[0].address
            ) {
                state.deliveryAddressRequired = false
                state.address = { ...address_schema }
            } else {
                state.deliveryAddressRequired = true
                state.address = { ...transaction.allocations[0].address }
            }
        },
        async saveAddress({ dispatch, state, getters }) {
            const transaction = getters['fetchedTransaction']
            if (state.deliveryAddressRequired) {
                if (state.address.id === 'NEW') {
                    await dispatch('createAddress')
                } else if (getters['addressUpdated']) {
                    await dispatch('updateAddress')
                }
                await dispatch('addAddressToTransaction')
            } else if (transaction.allocations[0].address) {
                await dispatch('removeAddressFromTransaction')
            }
            await dispatch('txCreate/saveTransaction', {}, { root: true })
        },
        async updateAddress({ state, getters, dispatch }) {
            const { name, line1, line2, line3, area, code, city, id } = state.address
            if (getters['addressUpdated']) {
                await api.user.userAddressUpdate({
                    id,
                    address: {
                        name,
                        line1,
                        line2,
                        line3,
                        area,
                        code,
                        city,
                        country: 'ZAF',
                    },
                })

                await dispatch('transaction/transactionRefetch', null, { root: true })
            }
        },
        async createAddress({ state, commit, dispatch }) {
            const { name, line1, line2, line3, area, code, city } = state.address
            const address = await api.user.userAddressCreate({ name, line1, line2, line3, area, code, city, country: 'ZAF' })
            await dispatch('user/userFetch', {}, { root: true })
            commit('setAddressField', { key: 'id', value: address.id })
        },
        async addAddressToTransaction({ state, commit, rootState }) {
            const allocations = rootState.txCreate.allocations.allocations

            for (let i = 0; i < allocations.length; i += 1) {
                commit(
                    'txCreate/setAllocationAddressField',
                    { index: i, key: 'id', value: state.address.id },
                    { root: true },
                )
            }
        },
        async removeAddressFromTransaction({ commit, rootState }) {
            const allocations = rootState.txCreate.allocations.allocations

            for (let i = 0; i < allocations.length; i += 1) {
                commit('txCreate/setAllocationAddressField', { index: i, key: 'id', value: '' }, { root: true })
            }
        },
    },
}
