import UserFetch from './graphql/user/user-fetch.gql'
import UserUpdate from './graphql/user/user-update.gql'
import UserDelete from './graphql/user/user-delete.gql'

import UserBankAccountCreate from './graphql/user/user-bank-account-create.gql'
import UserBankAccountVerify from './graphql/user/user-bank-account-verify.gql'

import UserAddressListFetch from './graphql/user/user-address-list-fetch.gql'
import UserAddressCreate from './graphql/user/user-address-create.gql'
import UserAddressUpdate from './graphql/user/user-address-update.gql'
import UserAddressDelete from './graphql/user/user-address-delete.gql'

import UserUpdatePassword from './graphql/user/user-update-password.gql'
import UserUpdateSettings from './graphql/user/user-update-settings.gql'

import UserSendMobileOTP from './graphql/user/user-send-mobile-otp.gql'
import UserVerifyMobileOTP from './graphql/user/user-verify-mobile-otp.gql'
import UserResendEmail from './graphql/user/user-resend-email-verification.gql'

import UserFileCreate from './graphql/user/user-file-create.gql'
import UserFileDelete from './graphql/user/user-file-delete.gql'
import UserAvatarCreate from './graphql/user/user-avatar-create.gql'

import SyncUserinfo from './graphql/user/user-sync-userinfo.gql'
import UserConsentUpdate from './graphql/user/user-consent-update.gql'

export default class APIUser {
    constructor(api) {
        this.api = api
        this.signed_in = true
    }

    async signedIn() {
        return await this.api.auth.loginStatus()
    }

    signOut() {
        this.api.auth.logout()
    }

    async userFetch() {
        const result = await this.api.client.query({
            query: UserFetch,
        })

        return result.data.profile
    }

    async userUpdate(input) {
        const result = await this.api.client.mutate({
            mutation: UserUpdate,
            variables: {
                input: input,
            },
        })
        return result.data.UserUpdate
    }

    async userDelete() {
        const result = await this.api.client.mutate({
            mutation: UserDelete,
        })
        return result.data.UserDelete
    }

    // ******************************************************************************
    async userBankAccountCreate(input) {
        const result = await this.api.client.mutate({
            mutation: UserBankAccountCreate,
            variables: {
                input: input,
            },
        })
        return result.data.UserBankAccountCreate
    }

    async userBankAccountVerify() {
        const result = await this.api.client.mutate({
            mutation: UserBankAccountVerify,
        })
        return result.data.UserBankAccountVerify
    }

    // ******************************************************************************
    async userAddressCreate(input) {
        const result = await this.api.client.mutate({
            mutation: UserAddressCreate,
            variables: {
                input: input,
            },
        })
        return result.data.addressCreate
    }

    async userAddressListFetch() {
        const result = await this.api.client.mutate({
            mutation: UserAddressListFetch,
        })
        return result.data.UserAddressListFetch
    }

    async userAddressUpdate(input) {
        const result = await this.api.client.mutate({
            mutation: UserAddressUpdate,
            variables: {
                id: input.id,
                input: input.address,
            },
        })
        return result.data.UserAddressUpdate
    }

    async userAddressDelete(input) {
        const result = await this.api.client.mutate({
            mutation: UserAddressDelete,
            variables: {
                id: input.id,
            },
        })
        return result.data.UserAddressDelete
    }

    // ******************************************************************************
    async userSendMobileOTP() {
        const result = await this.api.client.mutate({
            mutation: UserSendMobileOTP,
        })
        return result.data
    }

    async userVerifyMobileOTP(input) {
        const result = await this.api.client.mutate({
            mutation: UserVerifyMobileOTP,
            variables: {
                code: input.code,
            },
        })
        return result.data
    }

    async userResendEmail() {
        const result = await this.api.client.mutate({
            mutation: UserResendEmail,
        })
        return result.data
    }

    // ******************************************************************************
    async userUpdatePassword(input) {
        const result = await this.api.client.mutate({
            mutation: UserUpdatePassword,
            variables: {
                input: input,
            },
        })
        return result.data.UserUpdatePassword
    }

    async userUpdateSettings(input) {
        const result = await this.api.client.mutate({
            mutation: UserUpdateSettings,
            variables: {
                input: input,
            },
        })
        return result.data.UserUpdateSettings
    }

    // ******************************************************************************
    async userFileCreate(fileDetails) {
        const result = await this.api.client.mutate({
            mutation: UserFileCreate,
            variables: {
                file: fileDetails.file,
                metadata: fileDetails.metadata,
            },
        })
        return result.data.UserFileCreate
    }

    async userFileDelete(input) {
        const result = await this.api.client.mutate({
            mutation: UserFileDelete,
            variables: {
                id: input.id,
            },
        })
        return result.data.UserFileCreate
    }

    async userAvatarCreate(fileDetails) {
        const result = await this.api.client.mutate({
            mutation: UserAvatarCreate,
            variables: {
                image: fileDetails.image,
            },
        })
        console.log(result)
    }

    async syncUserInfo() {
        const result = await this.api.client.query({
            query: SyncUserinfo,
        })
        console.log(result)
    }

    async userConsentUpdate(input) {
        const result = await this.api.client.mutate({
            mutation: UserConsentUpdate,
            variables: {
                input: input,
            }
        })
        console.log(result)
    }
}
