<template>
    <div>
        <div class="uk-text-center uk-padding-small uk-margin">
            <h3 class="uk-text-secondary uk-margin-remove">Describe in detail what you are {{ userActionText }}</h3>
        </div>
        <div class="uk-margin-small-left uk-margin-small-right uk-flex uk-flex-center uk-flex-wrap">
            <div class="uk-width-5-6@m">
                <div class="uk-card uk-card-default uk-card-body uk-width-1-1 uk-margin-small">
                    <div class="uk-grid-small" uk-grid>
                        <GL-TextInput
                            class="uk-width-1-1 uk-margin-small-top"
                            label="transaction name"
                            tooltip="Give your transaction a name. For example, Inv. 1234 10 000 tonnes of thermal coal etc."
                            v-model="title"
                            :error-messages="validation.requiredField(title)"
                        />
                        <GL-TextAreaInput
                            class="uk-width-1-1 uk-margin-small-top"
                            label="Description"
                            tooltip="Give the transaction a detailed description. Provide as much details as possible including the event that needs to take place in order for the funds to be released. This can include the customer’s receipt of the Delivery Note (DN) or metallurgical assay certificate etc."
                            v-model="description"
                            :error-messages="validation.requiredField(description)"
                        />
                        <GL-NumberInput
                            v-if="!drawdown"
                            class="uk-width-1-4@s uk-margin-small-top"
                            label="Delivery Days"
                            tooltip="Delivery days is the expected duration of the delivery of the commodities."
                            v-model="daysToDeliver"
                            right-align
                            :error-messages="validation.requiredField(daysToDeliver)"
                        />
                        <GL-NumberInput
                            v-if="!drawdown"
                            class="uk-width-1-4@s uk-margin-small-top"
                            label="Inspection Days"
                            tooltip="Inspection days is the expected number of days the customer has to inspect the commodities."
                            right-align
                            v-model="daysToInspect"
                            :error-messages="validation.requiredField(daysToInspect)"
                        />
                        <GL-SelectInput
                            class="uk-width-1-2 uk-margin-small-top"
                            v-model="feeAllocation"
                            label="Who Will Pay The Escrow Fee?"
                            :option-list="feeAllocationOptions"
                            :error-messages="validation.requiredField(feeAllocation)"
                        />
                        <div class="uk-width-1-1 uk-margin-small-top">
                            <PreciousNonPreciousSelector
                                @select="selectCommodityType"
                                :value="commodityType"
                                label="Select the commodity type"
                            />
                        </div>
                        <GL-SelectInput
                            :class="{
                                'uk-invisible': !commodityType,
                                ' uk-animation-slide-right-small': commodityType,
                            }"
                            class="uk-width-1-2@s uk-margin-small-top"
                            label="Commodity Type"
                            :option-list="commodities"
                            v-model="commodity"
                            :error-messages="validation.requiredField(commodity)"
                        />
                        <GL-TextInput
                            :class="{
                                'uk-invisible': !(commodity === 'OTHER'),
                                ' uk-animation-slide-right-small': commodity === 'OTHER',
                            }"
                            class="uk-width-1-2@s uk-margin-small-top"
                            v-model="commodityDescription"
                            label="Please Specify Product"
                            :error-messages="validation.requiredField(commodityDescription)"
                        />
                        <GL-NumberInput
                            :class="{
                                'uk-invisible': !commodity,
                                ' uk-animation-slide-right-small': commodity,
                            }"
                            class="uk-width-1-2@s uk-margin-small-top"
                            right-align
                            v-model="units"
                            :label="massUnits.label"
                            :error-messages="validation.requiredField(units)"
                        />
                        <GL-CurrencyInput
                            :class="{
                                'uk-invisible': !commodity,
                                ' uk-animation-slide-right-small': commodity,
                            }"
                            class="uk-width-1-2@s uk-margin-small-top"
                            v-model="unitCost"
                            :error-messages="validation.requiredField(units)"
                            :label="massUnits.price"
                        />
                    </div>
                </div>
                <PartiesFees />
                <TransactionInformation />
                <CreateTxNavButtons :disable-next="!allowProgressMining" />
            </div>
        </div>
    </div>
</template>

<script>
import { transactionCreate } from '../../../../../../constants/constants-transaction-create'
import CreateTxNavButtons from '../../common/create-transaction-navigation'
import { constantsCommodities } from '../../../../../../constants/constants-commodities'
import PreciousNonPreciousSelector from './precious-non-precious-selector'
import PartiesFees from '../../common/parties-fees/index'
import validation from '@/scripts/transaction-create-field-validation'
import TransactionInformation from '../../transaction-information/index'
import TransactionCreateMixin from '../../transaction-create-mixin'

export default {
    name: 'MiningAndMetals',
    mixins: [TransactionCreateMixin],
    components: { TransactionInformation, PartiesFees, PreciousNonPreciousSelector, CreateTxNavButtons },
    data() {
        return {
            feeSplitOptions: transactionCreate.feeAllocation,
            feeSplit: null,
            paymentType: null,
            validation,
        }
    },
    computed: {
        userActionText() {
            if (this.$store.state.txCreate.parties.parties[0].role === 'BUYER') {
                return 'buying'
            } else if (this.$store.state.txCreate.parties.parties[0].role === 'SELLER') {
                return 'supplying'
            }
            return 'brokering'
        },
        commodity: {
            get() {
                return this.$store.state.txCreate.transaction.auxiliaryData.miningCommodity
            },
            set(v) {
                this.$store.commit('txCreate/setTransactionAuxiliaryField', { key: 'miningCommodity', value: v })
            },
        },
        commodityType: {
            get() {
                return this.$store.state.txCreate.transaction.auxiliaryData.miningCommodityType
            },
            set(v) {
                this.$store.commit('txCreate/setTransactionAuxiliaryField', { key: 'miningCommodityType', value: v })
            },
        },
        commodityDescription: {
            get() {
                return this.$store.state.txCreate.transaction.auxiliaryData.miningCommodityDescription
            },
            set(v) {
                this.$store.commit('txCreate/setTransactionAuxiliaryField', {
                    key: 'miningCommodityDescription',
                    value: v,
                })
            },
        },
        massUnits() {
            if (this.commodityType === 'PRECIOUS') {
                if (this.commodity === 'DIAMONDS') {
                    return {
                        label: 'Carats',
                        price: 'Price Per Carat',
                    }
                }
                return {
                    label: 'Troy Ounces',
                    price: 'Price Per Troy Ounce',
                }
            }
            return {
                label: 'Tonnage',
                price: 'Price Per Ton',
            }
        },
        commodities() {
            if (this.commodityType) {
                return constantsCommodities.filter(
                    commodity => commodity.category === this.commodityType || commodity.category === 'ALL',
                )
            }
            return constantsCommodities
        },
        feeAllocationOptions() {
            return this.$store.getters['txCreate/feeAllocations']
        },
        allowProgressMining() {
            if (!this.commodity || !this.commodityType || (this.commodity === 'OTHER' && !this.commodityDescription)) {
                return false
            }
            return this.allowProgressUnits
        },
    },
    watch: {
        commodity() {
            if (this.commodity !== 'OTHER') {
                this.commodityDescription = ''
            }
        },
    },
    methods: {
        selectCommodityType(v) {
            this.commodityType = v
        },
    },
}
</script>

<style scoped>
.selectable-card {
    margin: 0.5rem;
}
</style>
