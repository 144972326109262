<template>
    <div>
        <div class="uk-margin-right" v-if="loading">
            <GL-Spinner animationFile="loader_3_white.json"/>
        </div>
        <div v-if="!loading">
            <div class="context-menu uk-flex uk-flex-left uk-flex-middle">
                <img
                    width="32"
                    height="32"
                    class="uk-border-circle dropdown-item-image"
                    :src="context.avatar | safeAvatarURL"
                />
                <div class="uk-visible@s context-menu-text uk-margin-small-left uk-margin-small-right">
                    {{ context.name }}
                </div>
                <GL-Icon class="uk-visible@s" path="chevron-down.svg" :size="1"/>
            </div>
            <div ref="context-dropdown" uk-dropdown="mode: click; pos:bottom-justify; offset: 3">
                <div
                    v-if="contextList() === '__loading__'"
                    class="uk-text-center uk-margin-small-top uk-margin-small-bottom"
                >
                    <GL-Spinner :size="1"/>
                </div>

                <div v-if="contextList() !== '__loading__'">
                    <div
                        v-for="context in contextList()"
                        :key="context.id"
                        class="dropdown-item uk-flex uk-flex-left uk-flex-middle"
                        @click="selectContext(context.id)"
                    >
                        <div class="dropdown-item-image">
                            <img
                                width="32"
                                height="32"
                                class="uk-border-circle dropdown-item-image"
                                :src="context.avatar | safeAvatarURL"
                            />
                        </div>
                        <div class="dropdown-item-text-container uk-visible@s">
                            <div class="dropdown-item-text">
                                {{ context.name }}
                            </div>
                            <div style="font-size: 0.75rem">
                                {{ context.__typename }}
                            </div>
                        </div>
                    </div>
                </div>

                <hr class="uk-margin-small"/>

                <div
                    class="dropdown-item uk-flex uk-flex-left uk-flex-middle"
                    @click="viewCompanies"
                    :disabled="!!createBusinessTooltip"
                    :uk-tooltip="createBusinessTooltip"
                >
                    <div class="dropdown-item-image">
                        <img src="/icons/plus-circle.svg"/>
                    </div>
                    <div class="dropdown-item-text-container uk-visible@s">
                        <div class="dropdown-item-text">Add company</div>
                    </div>
                </div>

                <div class="dropdown-item uk-flex uk-flex-left uk-flex-middle" @click="signOut">
                    <div class="dropdown-item-image">
                        <img src="/icons/sign-out-alt-solid.svg" uk-svg/>
                    </div>
                    <div class="dropdown-item-text-container uk-visible@s">
                        <div class="dropdown-item-text">Sign Out</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import UIkit from 'uikit'
import Vuex from 'vuex'

export default {
    name: 'ContextSwitcher',
    data() {
        return {
            updateLoading: false,
            errorMessage: '',
            createBusinessTooltip: 'Your profile is not complete',
        }
    },
    methods: {
        contextList() {
            return this.$store.getters['context/contextList']
        },
        async selectContext(contextId) {
            const el = this.$refs['context-dropdown']
            UIkit.dropdown(el).hide(false)
            try {
                this.updateLoading = true
                await this.$store.dispatch('context/contextSelect', {id: contextId})

                if (this.$route.name === 'Transaction') {
                    this.$router.replace({path: '/'})
                }
            } catch (error) {
                this.errorMessage = this.$handleError(error)
            } finally {
                this.updateLoading = false
            }
        },
        viewCompanies() {
            if (this.createBusinessTooltip) {
                return
            }
            const el = this.$refs['context-dropdown']
            UIkit.dropdown(el).hide(false)
            this.$router.push('/company/add')
        },
        signOut() {
            this.$store.dispatch('user/signOut')
        },
    },
    computed: {
        ...Vuex.mapState({
            context: state => state.context.context,
            user: state => state.user.user,
        }),
        verified() {
            if (this.user !== '__loading__') {
                return this.user.status.emailVerified && this.user.status.mobileVerified
            }

            return false
        },
        loading() {
            return this.context === '__loading__' || this.updateLoading
        },
    },
    watch: {
        verified: function (newValue) {
            this.createBusinessTooltip = 'Your profile is not verified'

            if (newValue === true) {
                this.createBusinessTooltip = null
            }
        },
    },
}
</script>
<style lang="scss" scoped>
@import '@/scss/components/context-switcher.scss';
</style>
