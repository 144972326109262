export function same_as(message = 'error', otherValue) {
    return function (value) {
        const errors = []
        if (value !== otherValue) {
            errors.push(message)
        }

        return errors
    }
}
export function unique(message = 'error', otherValues) {
    return function (value) {
        const errors = []
        if (otherValues.includes(value)) {
            errors.push(message)
        }

        return errors
    }
}
export function required(message = 'error') {
    return function (value) {
        const errors = []
        if (!value) {
            errors.push(message)
        }

        return errors
    }
}

export function required_allow_zero(message = 'error') {
    return function (value) {
        const errors = []
        if (value !== 0 && !value) {
            errors.push(message)
        }

        return errors
    }
}
