import Vue from 'vue'
import Vuex from 'vuex'

import organization from '_shared/store/store-organization'
import escrowAccount from '_shared/store/store-escrow-account'
import transaction from '_shared/store/store-transaction'
import txCreate from '_shared/store/store-transaction-create'
import context from '_shared/store/store-context'
import user from '_shared/store/store-user'
import tour from '_shared/store/store-tour'

import dashboard from './store-dashboard'

Vue.use(Vuex)

export default new Vuex.Store({
    actions: {},
    getters: {},
    modules: {
        txCreate,
        organization,
        user,
        context,
        escrowAccount,
        transaction,
        dashboard,
        tour,
    },
})
