// Taken from vuelidate
const emailRegex = /(^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/

export function email(message = 'error') {
    return function (value) {
        const errors = []

        if (!value) {
            return errors
        }

        if (!emailRegex.test(value)) {
            errors.push(message)
        }

        return errors
    }
}
