<template>
    <div :class="'uk-alert uk-alert-' + type" uk-alert>
        <p>
            <slot></slot>
        </p>
    </div>
</template>

<script>
export default {
    name: 'Alert',
    props: {
        type: {
            type: String,
            default: '',
        },
    },
}
</script>

<style scoped>
.uk-alert {
    border-radius: 0.5rem;
}

.uk-alert-solid-primary {
    background-color: #1e87f0;
    color: white;
}
.uk-alert-solid-info {
    background-color: #0c7991;
    color: white;
}
.uk-alert-solid-warning {
    background-color: #faa05a;
    color: white;
}
.uk-alert-solid-success {
    background-color: #32d296;
    color: white;
}
</style>
