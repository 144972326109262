<template>
    <div class="uk-flex uk-flex-center">
        <div class="uk-card uk-card-default uk-position-relative uk-width-4-5@m uk-margin-bottom">
            <span @click="$store.commit('txCreate/removeAllocation', index)" v-if="index > 1" class="delete">
                <GL-Icon path="trash.svg" />
            </span>
            <div class="uk-card-body uk-position-relative">
                <div class="uk-text-center uk-text-large uk-margin-small-top uk-margin-bottom">
                    Load {{ index + 1 }} - Details
                </div>
                <div class="uk-margin-small-right uk-margin-small-left uk-margin-small-top uk-margin-small-bottom">
                    <div class="uk-grid-small" uk-grid>
                        <GL-TextInput
                            class="uk-width-1-1"
                            label="Load Title"
                            v-model="title"
                            tooltip="Give your load a name. For example, Order ZA001 Load 001 - first of five loads for 39,000 litres each etc."
                        />
                        <GL-TextAreaInput
                            class="uk-width-1-1 uk-margin-small-top"
                            tooltip="Give the load a detailed description. Provide as much details as possible including the event that needs to take place in order for the funds to be released. This can include the customer’s receipt of the Bill of Lading (BOL), Delivery Notes (DN) or pick-up confirmation slips."
                            label="Load Description"
                            v-model="description"
                        />
                        <GL-NumberInput
                            class="uk-width-1-2@m uk-margin-small-top"
                            label="litres"
                            right-align
                            v-model="units"
                        />
                        <GL-CurrencyInput
                            class="uk-width-1-2@m uk-margin-small-top"
                            label="Price per litre (ZAR)"
                            v-model="unitCost"
                        />
                        <GL-NumberInput
                            class="uk-width-1-4@m uk-margin-small-top"
                            label="Delivery Days"
                            tooltip="Delivery days is the expected duration of the delivery or upliftment of the product."
                            right-align
                            v-model="daysToDeliver"
                        />
                        <GL-NumberInput
                            class="uk-width-1-4@m uk-margin-small-top"
                            label="Inspection Days"
                            tooltip="Inspection days is the expected number of days the customer has to inspect the product."
                            right-align
                            v-model="daysToInspect"
                        />
                        <div class="uk-width-expand"></div>
                        <GL-CurrencyInput
                            disabled
                            v-if="unitCost && units"
                            class="uk-width-1-4@m uk-margin-small-top"
                            label="Load Value"
                            :value="unitCost * units"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FuelMilestoneCardCODNoAddress',
    props: {
        index: {
            type: Number,
            required: true,
        },
    },
    computed: {
        title: {
            get() {
                return this.$store.state.txCreate.allocations.allocations[this.index].title
            },
            set(v) {
                this.$store.commit('txCreate/setAllocationField', { index: this.index, key: 'title', value: v })
            },
        },
        description: {
            get() {
                return this.$store.state.txCreate.allocations.allocations[this.index].description
            },
            set(v) {
                this.$store.commit('txCreate/setAllocationField', { index: this.index, key: 'description', value: v })
            },
        },
        lastMilestone() {
            return this.index + 1 === this.$store.state.txCreate.allocations.allocations.length
        },
        transactionValue() {
            return this.$store.getters['txCreate/transactionValueUnitBased']
        },
        units: {
            get() {
                return this.$store.state.txCreate.allocations.allocations[this.index].units
            },
            set(value) {
                this.$store.commit('txCreate/setAllocationField', {
                    index: this.index,
                    key: 'units',
                    value,
                })
            },
        },
        unitCost: {
            get() {
                return this.$store.state.txCreate.allocations.allocations[this.index].unitCost
            },
            set(value) {
                this.$store.commit('txCreate/setAllocationField', {
                    index: this.index,
                    key: 'unitCost',
                    value,
                })
            },
        },
        daysToInspect: {
            get() {
                return this.$store.state.txCreate.allocations.allocations[this.index].daysToInspect
            },
            set(value) {
                this.$store.commit('txCreate/setAllocationField', {
                    index: this.index,
                    key: 'daysToInspect',
                    value,
                })
            },
        },
        daysToDeliver: {
            get() {
                return this.$store.state.txCreate.allocations.allocations[this.index].daysToDeliver
            },
            set(value) {
                this.$store.commit('txCreate/setAllocationField', {
                    index: this.index,
                    key: 'daysToDeliver',
                    value,
                })
            },
        },
    },
}
</script>

<style scoped>
.delete {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 100;
}
.delete:hover {
    cursor: pointer;
    color: black;
}
</style>
