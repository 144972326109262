export const fees = {
    GENERAL: [
        {
            lower: 0,
            upper: 10000,
            rate: 0.0115,
        },
        {
            lower: 10001,
            upper: 100000,
            rate: 0.01,
        },
        {
            lower: 100001,
            upper: 500000,
            rate: 0.0085,
        },
        {
            lower: 500001,
            upper: 1000000,
            rate: 0.0065,
        },
        {
            lower: 1000001,
            upper: 5000000,
            rate: 0.0045,
        },
        {
            lower: 5000001,
            upper: 10000000,
            rate: 0.0035,
        },
        {
            lower: 1000000,
            upper: Infinity,
            rate: 0.0025,
        },
    ],
}

export const minimumTransactionValue = 200

export const minimumEscrowFeeIncludingVAT = 115

export const minimumEscrowFeeExcludingVAT = 100

export const VAT = 0.15

export const escrowFeePerLitre = 0.01
