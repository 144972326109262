import Login from './graphql/auth/login.graphql'
import Logout from './graphql/auth/logout.graphql'
import LoginRequest from './graphql/auth/login-request.graphql'
import LoginRefresh from './graphql/auth/login-refresh.graphql'
import Status from './graphql/auth/status.graphql'

export default class APIAuth {
    constructor(api) {
        this.api = api
    }

    login(query) {
        if (typeof query.code === 'undefined' || typeof query.state === 'undefined') {
            window.location = `/`
        }

        const result = this.api.client.query({
            query: Login,

            variables: {
                code: query.code,
                state: query.state,
            },
        })

        result.then(r => {
            if (r.data.login === true) {
                window.location = `/`
            }
        })

        // window.location = `/login`
    }
    loginRequest() {
        const result = this.api.client.query({
            query: LoginRequest,
        })

        result.then(r => {
            const payload = `client_id=${r.data.loginRequest.clientId}&redirect_uri=${r.data.loginRequest.redirectUri}&response_type=${r.data.loginRequest.responseType}&scope=${r.data.loginRequest.scope}&state=${r.data.loginRequest.state}&code_challenge=${r.data.loginRequest.codeChallenge}&code_challenge_method=${r.data.loginRequest.codeChallengeMethod}`

            window.location = `${r.data.loginRequest.authorizationUrl}?${payload}`
        })
    }
    async loginRefresh() {
        const result = await this.api.client.query({
            query: LoginRefresh,
        })

        return result.data.loginRefresh
    }
    async loginStatus() {
        const result = await this.api.client.query({
            query: Status,
        })

        return result.data.status
    }
    register() {
        window.location = `${this.api.config.authServer}/register`
    }
    registerRequest() {
        const result = this.api.client.query({
            query: LoginRequest,
        })

        result.then(r => {
            const payload = `client_id=${r.data.loginRequest.clientId}&redirect_uri=${r.data.loginRequest.redirectUri}&response_type=${r.data.loginRequest.responseType}&scope=${r.data.loginRequest.scope}&state=${r.data.loginRequest.state}&code_challenge=${r.data.loginRequest.codeChallenge}&code_challenge_method=${r.data.loginRequest.codeChallengeMethod}&target=register`

            window.location = `${r.data.loginRequest.authorizationUrl}?${payload}`
        })
    }
    verifyEmail() {
        window.location = `${this.api.config.authServer}/email/verify`
    }

    async logout() {
        const result = await this.api.client.query({
            query: Logout,
        })

        if (result) {
            window.location = `${this.api.config.authServerLogout}`
        }
    }
}
