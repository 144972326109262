import { render, staticRenderFns } from "./create-transaction-navigation.vue?vue&type=template&id=5d6a5bfd&scoped=true"
import script from "./create-transaction-navigation.vue?vue&type=script&lang=js"
export * from "./create-transaction-navigation.vue?vue&type=script&lang=js"
import style0 from "./create-transaction-navigation.vue?vue&type=style&index=0&id=5d6a5bfd&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d6a5bfd",
  null
  
)

export default component.exports