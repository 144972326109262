export function min_length(message = 'error', min = 0) {
    return function (value) {
        const errors = []
        if (value.length < min) {
            errors.push(message)
        }

        return errors
    }
}

export function max_length(message = 'error', max = 10) {
    return function (value) {
        const errors = []
        if (value.length > max) {
            errors.push(message)
        }

        return errors
    }
}
