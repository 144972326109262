<template>
    <div>
        <div class="uk-text-center uk-margin-bottom">
            <h3 class="uk-text-secondary uk-margin-remove">Where Must Delivery of the Commodities Take Place?</h3>
        </div>
        <div class="uk-flex uk-flex-center">
            <div class="uk-width-5-6@m">
                <AddressForm :hide-heading="true" />
            </div>
        </div>
        <CreateTxNavButtons :disable-next="!allowProgress" />
    </div>
</template>
<script>
import AddressForm from '../address-form'
import CreateTxNavButtons from '../../common/create-transaction-navigation'
export default {
    components: { CreateTxNavButtons, AddressForm },
    mounted() {
        this.$store.commit('txCreate/deliveryAddressRequired', true)
    },
    computed: {
        allowProgress() {
            return (
                this.$store.state.txCreate.address.address.name &&
                this.$store.state.txCreate.address.address.line1 &&
                this.$store.state.txCreate.address.address.line2 &&
                this.$store.state.txCreate.address.address.city &&
                this.$store.state.txCreate.address.address.area &&
                this.$store.state.txCreate.address.address.code
            )
        },
    },
}
</script>
