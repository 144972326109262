import api from '@/scripts/api/api'

// CREATED
// INVITATION_SENT
// NEGOTIATION
// ACCEPTED
// FUNDS_DEPOSITED
// FUNDS_RECEIVED
// INITIATED
// GOODS_ACCEPTED
// DELIVERED
// FUNDS_RELEASED
// CANCELED
// DECLINED
// DISPUTED
// LEGAL
// SUSPENDED
// ADMIN_SUSPENDED
// ADMIN_CANCELED
// DISPUTED_EXTENDED
// DISPUTED_RENEGOTIATE

export default {
    namespaced: true,
    state: {
        transactionStates: [
            {
                key: 'active',
                value: 'Active',
            },
            {
                key: 'completed',
                value: 'Completed',
            },
            {
                key: 'cancelled',
                value: 'Cancelled',
            },
        ],
        transactionState: 'active',
        transactions: '__loading__',
        paginator: null,
        queryData: {
            page: 1,
            // orderBy: [{ field: 'CREATED_AT', order: 'DESC' }],
            first: 8,
        },
    },
    mutations: {
        setTransactions(state, payload) {
            state.transactions = payload
        },
        setPaginator(state, payload) {
            state.paginator = payload
        },
        setTransactionState(state, payload) {
            state.transactionState = payload
        },
        setPage(state, payload) {
            state.queryData.page = parseInt(payload)
        },
        setFirst(state, payload) {
            state.queryData.first = parseInt(payload)
        },
    },
    getters: {
        transactionFilter: state => {
            if (state.transactionState === 'active') {
                return { notState: ['CANCELED', 'DECLINED', 'SUSPENDED', 'ADMIN_CANCELED'] }
            } else if (state.transactionState === 'completed') {
                return { inState: ['FUNDS_RELEASED'] }
            }
            return { inState: ['CANCELED', 'DECLINED', 'SUSPENDED', 'ADMIN_CANCELED'] }
        },
    },
    actions: {
        async fetchTransactions({ state, commit, getters, rootState }) {
            if (rootState.user.user.status.emailVerified) {
                const queryData = state.queryData
                queryData.filter = getters['transactionFilter']
                commit('setTransactions', '__loading__')
                const response = await api.transaction.transactionListFetch(queryData)
                commit('setTransactions', response.data)
                commit('setPaginator', response.paginatorInfo)
            } else {
                commit('setTransactions', [])
            }
        },
    },
}
