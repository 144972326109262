import fuelData from './fuelZones'

export const products = [
    { key: 'Diesel ULS 10ppm', value: 'Diesel ULS 10ppm' },
    { key: 'Diesel LS 50ppm', value: 'Diesel LS 50ppm' },
    { key: 'Diesel 500ppm', value: 'Diesel 500ppm' },
    { key: 'Petrol Unleaded 93', value: 'Petrol Unleaded 93' },
    { key: 'Petrol Unleaded 95', value: 'Petrol Unleaded 95' },
    { key: 'Petrol LRP', value: 'Petrol LRP' },
    { key: 'Illuminating Paraffin', value: 'Illuminating Paraffin' },
    { key: 'Lubricating Oils', value: 'Lubricating Oils' },
    { key: 'Other', value: 'Other' },
]

export const upliftmentLocations = [
    { key: 'Alrode', value: 'Alrode' },
    { key: 'Cape Town', value: 'Cape Town' },
    { key: 'East London', value: 'East London' },
    { key: 'Island View Terminal (IVT)', value: 'Island View Terminal (IVT)' },
    { key: 'Klerksdorp', value: 'Klerksdorp' },
    { key: 'Kroonstad', value: 'Kroonstad' },
    { key: 'Mosselbay', value: 'Mosselbay' },
    { key: 'Natref', value: 'Natref' },
    { key: 'Port Elizabeth', value: 'Port Elizabeth' },
    { key: 'Secunda', value: 'Secunda' },
    { key: 'Tarlton', value: 'Tarlton' },
    { key: 'Watloo', value: 'Watloo' },
    { key: 'Witbank', value: 'Witbank' },
    { key: 'Mini Depot', value: 'Mini Depot' },
    { key: 'Private Depot', value: 'Private Depot' },
]

export function fuelZones() {
    const zones = fuelData.map(zone => zone.zone)
    return zones.filter((zone, index, self) => {
        return self.indexOf(zone) === index
    })
}

export function magisterialDistricts() {
    return fuelData.map(district => `${district.magisterialDistrict} - ${district.province}`)
}
