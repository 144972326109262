const party_schema = {
    id: '',
    email: '',
    role: '',
    fee: null,
    feeType: null,
    feeAllocation: null,
    owner: false,
}

export function createParties(currentUserRole, currentUserEmail) {
    // It is expected that the first item in the parties array is the tx owner
    const parties = []
    const user = { ...party_schema }
    user.role = currentUserRole
    user.email = currentUserEmail
    user.owner = true
    parties.push(user)

    const seller = { ...party_schema }
    seller.role = 'SELLER'
    const buyer = { ...party_schema }
    buyer.role = 'BUYER'

    if (currentUserRole === 'AGENT') {
        user.feeAllocation = 'SELLER'
        parties.push(seller)
        parties.push(buyer)
    } else if (currentUserRole === 'BUYER') {
        parties.push(seller)
    } else if (currentUserRole === 'SELLER') {
        parties.push(buyer)
    }

    return parties
}

export function findPartiesToUpdate(workingElements, savedElements, schema) {
    const updated = []
    for (const working of workingElements) {
        const saved = savedElements.find(({ id }) => id === working.id)
        if (saved) {
            for (const key of Object.keys(schema)) {
                if (working[key] !== saved[key]) {
                    updated.push(working)
                    break
                }
            }
        }
    }
    return updated
}

export function findElementsToDelete(workingElements, savedElements) {
    const deleted = []
    for (const saved of savedElements) {
        const working = workingElements.find(({ id }) => id === saved.id)
        if (!working) {
            deleted.push(saved)
        }
    }
    return deleted
}

export function findElementsToCreate(workingElements) {
    const elmToCreate = []
    for (const working of workingElements) {
        if (!working.id) {
            elmToCreate.push(working)
        }
    }
    return elmToCreate
}

export function removeEmptyListsInPlace(obj) {
    Object.keys(obj).forEach(key => {
        if (!obj[key].length) {
            delete obj[key]
        }
    })
}

export function removeEmptyObjectKeys(obj) {
    const o = JSON.parse(JSON.stringify(obj))
    for (const key in o) {
        if (Object.prototype.hasOwnProperty.call(o, key) && !o[key]) {
            delete o[key]
        }
    }
    return o
}

export function prepareAuxiliaryData(auxData) {
    const data = JSON.parse(JSON.stringify(auxData))
    for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
            if (key !== 'milestonesToSameAddress' && !data[key]) {
                delete data[key]
            } else if (Array.isArray(data[key])) {
                data[key] = data[key].filter(elem => elem.length)
                if (data[key].length === 0) {
                    delete data[key]
                }
            }
        }
    }
    return JSON.stringify(data)
}

function cleanAllocationCosts(obj) {
    if (obj.unitCost && obj.units) {
        delete obj.value
    } else if (!obj.unitCost && !obj.units && obj.value) {
        delete obj.units
        delete obj.unitCost
    }
}

function cleanAllocationDays(alloc) {
    if (!alloc.daysToDeliver) {
        delete alloc.daysToDeliver
    }
    if (!alloc.daysToInspect) {
        delete alloc.daysToInspect
    }
}

export function cleanObjsForTransactionUpdateInPlace(obj) {
    if (obj.create) {
        obj.create = obj.create.map(o => {
            delete o.id
            delete o.owner
            if (o.auxiliaryData) {
                o.auxiliaryData = prepareAuxiliaryData(o.auxiliaryData)
            }
            cleanAllocationCosts(o)
            cleanAllocationDays(o)
            return o
        })
    }
    if (obj.update) {
        obj.update = obj.update.map(o => {
            delete o.__typename
            delete o.owner
            delete o.calculation
            delete o.details
            delete o.acceptance
            if (o.auxiliaryData) {
                o.auxiliaryData = prepareAuxiliaryData(o.auxiliaryData)
            }
            cleanAllocationCosts(o)
            cleanAllocationDays(o)
            return o
        })
    }
    if (obj.delete) {
        if (obj.delete.length) {
            obj.delete = obj.delete.map(({ id }) => id)
        } else {
            delete obj.delete
        }
    }
    removeEmptyListsInPlace(obj)
}

export function arraysContainSameValues(arr1, arr2) {
    if (arr1.length === arr2.length) {
        for (const v of arr1) {
            if (arr2.indexOf(v) === -1) {
                return false
            }
        }
        return true
    }
    return false
}

export function findAllocationsToCreate(allocations, auxDataList, addresses) {
    const allocToCreate = []

    for (let i = 0; i < allocations.length; i += 1) {
        if (!allocations[i].id) {
            if (allocations[i].value > 0 || (allocations[i].units > 0 && allocations[i].unitCost > 0)) {
                const { deliveryInstructions, ...allocationData } = allocations[i]
                allocToCreate.push({
                    ...allocationData,
                    deliveryInstructions: JSON.stringify({ deliveryInstructions }),
                    auxiliaryData: auxDataList[i],
                    addressId: addresses[i].id,
                })
            }
        }
    }
    return allocToCreate
}

function auxiliaryDataUpdated(workingAuxData, savedAuxData, schema) {
    for (const key in schema) {
        if (workingAuxData[key] && savedAuxData[key] && workingAuxData[key] !== savedAuxData[key]) {
            return true
        } else if (workingAuxData[key] && !savedAuxData[key]) {
            return true
        } else if (!workingAuxData[key] && savedAuxData[key]) {
            return true
        }
    }
    return false
}

export function findAllocationsToUpdate(
    allocations,
    auxDataList,
    addresses,
    savedAllocations,
    allocSchema,
    auxDataSchema,
) {
    const updated = []
    for (let i = 0; i < allocations.length; i += 1) {
        const saved = savedAllocations.find(({ id }) => id === allocations[i].id)
        if (saved) {
            const savedAux = JSON.parse(saved.auxiliaryData)
            if (auxiliaryDataUpdated(auxDataList[i], savedAux, auxDataSchema)) {
                updated.push({ ...allocations[i], auxiliaryData: auxDataList[i], addressId: addresses[i].id })
            } else if (
                (!addresses[i].id && saved.address && saved.address.id) ||
                (addresses[i].id && !saved.address) ||
                (addresses[i].id && saved.address && addresses[i].id !== saved.address.id)
            ) {
                updated.push({ ...allocations[i], auxiliaryData: auxDataList[i], addressId: addresses[i].id })
            } else {
                for (const key of Object.keys(allocSchema)) {
                    if (key === 'deliveryInstructions') {
                        try {
                            const savedDeliveryInstruction = JSON.parse(saved[key])
                            if (allocations[i][key] !== savedDeliveryInstruction.deliveryInstructions) {
                                updated.push({
                                    ...allocations[i],
                                    auxiliaryData: auxDataList[i],
                                    addressId: addresses[i].id,
                                })
                                break
                            }
                        } catch {
                            // Saved delivery instructions are null
                            if (allocations[i][key]) {
                                updated.push({
                                    ...allocations[i],
                                    auxiliaryData: auxDataList[i],
                                    addressId: addresses[i].id,
                                })
                                break
                            }
                        }
                    } else if (allocations[i][key] !== saved[key]) {
                        updated.push({ ...allocations[i], auxiliaryData: auxDataList[i], addressId: addresses[i].id })
                        break
                    }
                }
            }
        }
    }
    return updated
}
