import OrganizationListFetch from './graphql/organization/organization-list-fetch.gql'

import OrganizationFetch from './graphql/organization/organization-fetch.gql'
import OrganizationCreate from './graphql/organization/organization-create.gql'
import OrganizationUpdate from './graphql/organization/organization-update.gql'
import OrganizationDelete from './graphql/organization/organization-delete.gql'

import OrganizationAddressCreate from './graphql/organization/organization-address-create.gql'
import OrganizationAddressUpdate from './graphql/organization/organization-address-update.gql'
import OrganizationAddressDelete from './graphql/organization/organization-address-delete.gql'

import OrganizationBankAccountCreate from './graphql/organization/organization-bank-account-create.gql'
import OrganizationBankAccountVerify from './graphql/organization/organization-bank-account-verify.gql'

import OrganizationFileCreate from './graphql/organization/organization-file-create.gql'
import OrganizationFileDelete from './graphql/organization/organization-file-delete.gql'

import OrganizationUpdateSettings from './graphql/organization/organization-update-settings.gql'
import OrganizationAvatarCreate from './graphql/organization/organization-avatar-create.gql'

export default class APIOrganization {
    constructor(api) {
        this.api = api
    }

    async organizationListFetch() {
        const result = await this.api.client.query({
            query: OrganizationListFetch,
        })
        return result.data.organizations
    }
    async organizationFetch(input) {
        const result = await this.api.client.query({
            query: OrganizationFetch,
            variables: {
                id: input.id,
            },
        })
        return result.data.organization
    }
    async organizationCreate(input) {
        const result = await this.api.client.mutate({
            mutation: OrganizationCreate,
            variables: {
                input: input,
            },
        })
        return result.data.organizationCreate
    }
    async organizationUpdate(input) {
        const result = await this.api.client.mutate({
            mutation: OrganizationUpdate,
            variables: {
                id: input.id,
                input: input.updateInput,
            },
        })
        return result.data.OrganizationUpdate
    }
    async organizationDelete(input) {
        const result = await this.api.client.mutate({
            mutation: OrganizationDelete,
            variables: {
                id: input.id,
            },
        })
        return result.data.OrganizationDelete
    }
    // ******************************************************************************
    async organizationAddressCreate(input) {
        const result = await this.api.client.mutate({
            mutation: OrganizationAddressCreate,
            variables: {
                id: input.id,
                input: input.address,
            },
        })
        return result.data.OrganizationAddressCreate
    }
    async organizationAddressUpdate(input) {
        const result = await this.api.client.mutate({
            mutation: OrganizationAddressUpdate,
            variables: {
                id: input.id,
                input: input.address,
            },
        })
        return result.data.OrganizationAddressUpdate
    }
    async organizationAddressDelete(input) {
        const result = await this.api.client.mutate({
            mutation: OrganizationAddressDelete,
            variables: {
                id: input.id,
            },
        })
        return result.data.OrganizationAddressDelete
    }
    // ******************************************************************************
    async organizationBankAccountCreate(input) {
        const result = await this.api.client.mutate({
            mutation: OrganizationBankAccountCreate,
            variables: {
                id: input.id,
                input: input.bankAccount,
            },
        })
        return result.data.OrganizationBankAccountCreate
    }
    async organizationBankAccountVerify() {
        const result = await this.api.client.mutate({
            mutation: OrganizationBankAccountVerify,
        })
        return result.data.OrganizationBankAccountVerify
    }

    // ******************************************************************************
    async organizationFileCreate(fileDetails) {
        const result = await this.api.client.mutate({
            mutation: OrganizationFileCreate,
            variables: {
                organizationId: fileDetails.organizationId,
                file: fileDetails.file,
                metadata: fileDetails.metadata,
            },
        })
        console.log(result)
    }
    async organizationFileDelete(input) {
        const result = await this.api.client.mutate({
            mutation: OrganizationFileDelete,
            variables: {
                id: input.id,
            },
        })
        console.log(result)
    }
    async organizationUpdateSettings(input) {
        const result = await this.api.client.mutate({
            mutation: OrganizationUpdateSettings,
            variables: {
                id: input.id,
                input: input.input,
            },
        })
        return result.data.OrganizationUpdateSettings
    }

    async organizationAvatarCreate(fileDetails) {
        const result = await this.api.client.mutate({
            mutation: OrganizationAvatarCreate,
            variables: {
                id: fileDetails.id,
                image: fileDetails.image,
            },
        })
        console.log(result)
    }
}
