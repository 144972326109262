<template>
    <General />
</template>

<script>
import General from '../general/index'
export default {
    name: 'FreelancingMilestone',
    components: { General },
}
</script>

<style scoped></style>
