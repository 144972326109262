export const Categories = [
    {
        value: 'Farming Equipment',
        key: 'Farming Equipment',
    },
    {
        value: 'Farming Infrastructure',
        key: 'Farming Infrastructure',
    },
    {
        value: 'Field Crops',
        key: 'Field Crops',
    },
    {
        value: 'Fruit',
        key: 'Fruit',
    },
    {
        value: 'Game',
        key: 'Game',
    },
    {
        value: 'Gardening',
        key: 'Gardening',
    },
    {
        value: 'Livestock',
        key: 'Livestock',
    },
    {
        value: 'Vegetables',
        key: 'Vegetables',
    },
    {
        value: 'Tractors & Vehicles',
        key: 'Tractors & Vehicles',
    },
    {
        value: 'Other',
        key: 'OTHER',
    },
]
