<template>
    <div class="uk-margin-small-top">
        <div class="uk-text-center uk-text-large uk-margin">Load {{ index + 1 }} - Details</div>
        <div class="uk-grid-small" uk-grid>
            <GL-TextInput
                class="uk-width-1-1"
                label="load title"
                v-model="title"
                tooltip="Give your load a name. For example, Order ZA001 Load 001 - first of five loads for 39,000 litres each etc."
            />
            <GL-TextAreaInput
                class="uk-width-1-1 uk-margin-small-top"
                label="Load Description"
                v-model="description"
                tooltip="Give the load a detailed description. Provide as much details as possible including the event that needs to take place in order for the funds to be released. This can include the customer’s receipt of the Bill of Lading (BOL), Delivery Notes (DN) or pick-up confirmation slips."
            />
            <GL-NumberInput class="uk-width-1-2@m uk-margin-small-top" label="litres" right-align v-model="units" />
            <GL-CurrencyInput
                class="uk-width-1-2@m uk-margin-small-top"
                label="Price Per litre (ZAR)"
                v-model="unitCost"
            />
            <GL-NumberInput
                class="uk-width-1-4@m uk-margin-small-top"
                label="Delivery Days"
                right-align
                tooltip="Delivery days is the expected duration of the delivery or upliftment of the product."
                v-model="daysToDeliver"
            />
            <GL-NumberInput
                class="uk-width-1-4@m uk-margin-small-top"
                label="Inspection Days"
                right-align
                tooltip="Inspection days is the expected number of days the customer has to inspect the product."
                v-model="daysToInspect"
            />
            <div class="uk-width-1-2@m" />
        </div>
        <div class="uk-width-1-1 uk-text-right uk-card-footer uk-margin-top">
            <GL-Button
                text="next"
                type="primary"
                @click="$emit('complete-step')"
                :disabled="disableNext"
                disabled-text="next"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'MilestoneDetails',
    props: {
        index: {
            type: Number,
            required: true,
        },
    },
    computed: {
        title: {
            get() {
                return this.$store.state.txCreate.allocations.allocations[this.index].title
            },
            set(v) {
                this.$store.commit('txCreate/setAllocationField', { index: this.index, key: 'title', value: v })
            },
        },
        description: {
            get() {
                return this.$store.state.txCreate.allocations.allocations[this.index].description
            },
            set(v) {
                this.$store.commit('txCreate/setAllocationField', { index: this.index, key: 'description', value: v })
            },
        },
        units: {
            get() {
                return this.$store.state.txCreate.allocations.allocations[this.index].units
            },
            set(value) {
                this.$store.commit('txCreate/setAllocationField', {
                    index: this.index,
                    key: 'units',
                    value,
                })
            },
        },
        unitCost: {
            get() {
                return this.$store.state.txCreate.allocations.allocations[this.index].unitCost
            },
            set(value) {
                this.$store.commit('txCreate/setAllocationField', {
                    index: this.index,
                    key: 'unitCost',
                    value,
                })
            },
        },
        daysToInspect: {
            get() {
                return this.$store.state.txCreate.allocations.allocations[this.index].daysToInspect
            },
            set(value) {
                this.$store.commit('txCreate/setAllocationField', {
                    index: this.index,
                    key: 'daysToInspect',
                    value,
                })
            },
        },
        daysToDeliver: {
            get() {
                return this.$store.state.txCreate.allocations.allocations[this.index].daysToDeliver
            },
            set(value) {
                this.$store.commit('txCreate/setAllocationField', {
                    index: this.index,
                    key: 'daysToDeliver',
                    value,
                })
            },
        },
        disableNext() {
            return !(
                this.daysToInspect &&
                this.daysToDeliver &&
                this.unitCost &&
                this.units &&
                this.title &&
                this.description
            )
        },
    },
}
</script>
