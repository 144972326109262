import APIContext from './api-context'
import APIUser from './api-user'
import APIOrganization from './api-organization'
import APITransaction from './api-transaction'
import APIEscrowAccount from './api-escrow-account'
import APICalculator from './api-calculator'
import APITransactionFiles from './api-transaction-files'
import APIAuth from './api-auth'
import APITransactionDrawdown from './api-transaction-drawdown'

export default class API {
    constructor(config, clientWrapper) {
        // Set config
        this.config = config

        // Expose sub API
        this.auth = new APIAuth(this)
        this.context = new APIContext(this)
        this.user = new APIUser(this)
        this.organization = new APIOrganization(this)
        this.transaction = new APITransaction(this)
        this.escrowAccount = new APIEscrowAccount(this)
        this.calculator = new APICalculator(this)
        this.transactionFiles = new APITransactionFiles(this)
        this.drawdown = new APITransactionDrawdown(this)

        // Set Apollo Client token getter
        this.client = clientWrapper.getClient()
    }
    setRouter(router) {
        this.router = router
    }
}
