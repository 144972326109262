var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"uk-grid-collapse uk-child-width-expand@s uk-text-center uk-flex-nowrap stepper",attrs:{"hidden":_vm.hideSteps,"uk-grid":""}},_vm._l((_vm.tabs),function(tab,index){return _c('div',{key:tab.name,staticClass:"stepper-tab",class:[
                {
                    completed: index < _vm.completed,
                    active: index === _vm.completed,
                    viewing: index === _vm.step - 1,
                    success: _vm.success,
                    selectable: _vm.selectableSteps,
                },
                tab.tourStep,
            ],on:{"click":function($event){return _vm.select(index + 1)}}},[_c('div',{staticClass:"stepper-tab-content uk-flex uk-flex-center uk-flex-middle"},[(index < _vm.completed)?_c('div',{staticClass:"stepper-tab-step"},[_c('img',{attrs:{"alt":"completed check","src":"/icons/check.svg","size":1,"uk-svg":""}})]):_c('div',{staticClass:"stepper-tab-step uk-text-large"},[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('div',{staticClass:"stepper-tab-name uk-visible@m"},[_c('span',{staticClass:"uk-margin-left"},[_vm._v(" "+_vm._s(tab.name)+" ")])])])])}),0),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }