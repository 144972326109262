import APITransactionAllocation from './api-transaction-allocation'
import APITransactionAmendment from './api-transaction-amendment'
import APITransactionFiles from './api-transaction-files'
import APITransactionCreate from './api-transaction-create'

import TransactionListFetch from './graphql/transaction/transaction-list-fetch.gql'
import TransactionDepositDetailsFetch from './graphql/transaction/transaction-deposit-details-fetch.gql'
import TransactionUpdate from './graphql/transaction/transaction-update.gql'
import TransactionUpdateBuyer from './graphql/transaction/transaction-update-buyer.gql'
import TransactionFetch from './graphql/transaction/transaction-fetch.gql'
import TransactionInvite from './graphql/transaction/transaction-invite.gql'
import TransactionAccept from './graphql/transaction/transaction-accept.gql'
import TransactionDeposit from './graphql/transaction/transaction-deposit.gql'
import TransactionCancel from './graphql/transaction/transaction-cancel.gql'
import TransactionDecline from './graphql/transaction/transaction-decline.gql'
import TransactionDelete from './graphql/transaction/transaction-delete.gql'
import TransactionPreview from './graphql/transaction/transaction-preview.gql'
import transactionPaymentOptions from './graphql/transaction/transaction-payment-options.gql'
import transactionPaymentCreate from './graphql/transaction/transaction-payment-create.gql'
import TransactionInvoice from './graphql/transaction/transaction-invoice.gql'

import TransactionCommentCreate from './graphql/transaction/transaction-comment-create.gql'

export default class APITransaction {
    constructor(api) {
        this.api = api

        this.amendment = new APITransactionAmendment(api)
        this.allocation = new APITransactionAllocation(api)
        this.files = new APITransactionFiles(api)
        this.create = new APITransactionCreate(api)
    }

    async transactionInvoice(transactionId) {
        const result = await this.api.client.query({
            query: TransactionInvoice,
            variables: {
                id: transactionId,
            },
        })
        return result.data.transactionInvoice
    }
    async transactionUpdate(input) {
        const result = await this.api.client.mutate({
            mutation: TransactionUpdate,
            variables: {
                input,
            },
        })
        return result.data.transactionUpdate
    }
    async transactionUpdateBuyer(input) {
        const result = await this.api.client.mutate({
            mutation: TransactionUpdateBuyer,
            variables: {
                id: input.id,
                input: input.input,
            },
        })
        return result.data.transactionUpdateBuyer
    }
    async transactionDepositDetailsFetch() {
        const result = await this.api.client.query({
            query: TransactionDepositDetailsFetch,
        })
        return result.data.depositDetails
    }
    async transactionListFetch(params = {}) {
        const result = await this.api.client.query({
            query: TransactionListFetch,
            variables: {
                first: params.first,
                page: params.page,
                filter: params.filter,
            },
        })
        return result.data.transactions
    }
    async transactionFetch(id) {
        const result = await this.api.client.query({
            query: TransactionFetch,
            variables: {
                id: id,
            },
        })
        return result.data.transaction
    }
    async transactionCancel(input) {
        const result = await this.api.client.mutate({
            mutation: TransactionCancel,
            variables: {
                id: input.id,
                comment: input.comment,
            },
        })
        return result.data.transaction
    }
    async transactionDecline(input) {
        const result = await this.api.client.mutate({
            mutation: TransactionDecline,
            variables: {
                id: input.id,
                comment: input.comment,
            },
        })
        return result.data.transaction
    }
    async transactionInvite(input) {
        const result = await this.api.client.mutate({
            mutation: TransactionInvite,
            variables: {
                id: input.id,
            },
        })
        return result.data.transaction
    }
    async transactionAccept(input) {
        const result = await this.api.client.mutate({
            mutation: TransactionAccept,
            variables: {
                id: input.id,
            },
        })
        return result.data.transaction
    }
    async transactionDeposit(input) {
        const result = await this.api.client.mutate({
            mutation: TransactionDeposit,
            variables: {
                id: input.id,
                method: input.method,
                value: input.value,
            },
        })
        console.log(result)
        return result.data.transactionDeposit
    }
    async transactionDelete(input) {
        const result = await this.api.client.mutate({
            mutation: TransactionDelete,
            variables: {
                id: input.id,
            },
        })
        return result.data.transaction
    }
    async transactionPreview(code) {
        const result = await this.api.client.query({
            query: TransactionPreview,
            variables: {
                code: code,
            },
        })
        return result.data.transactionPreview
    }
    async transactionPaymentOptions(id) {
        const result = await this.api.client.query({
            query: transactionPaymentOptions,
            variables: {
                id: id,
            },
        })
        return result.data.transactionPaymentOptions
    }
    async transactionPaymentCreate(id, method, methodReference = null) {
        const result = await this.api.client.mutate({
            mutation: transactionPaymentCreate,
            variables: {
                id: id,
                method: method,
                methodReference: methodReference,
            },
        })
        return result.data.transactionPaymentCreate
    }
    // ***********************************************
    async transactionCommentCreate(input) {
        const result = await this.api.client.mutate({
            mutation: TransactionCommentCreate,
            variables: {
                transactionId: input.transactionId,
                input: {
                    body: input.body,
                },
            },
        })
        return result.data.transactionCommentCreate
    }
}
