export default [
    {
        path: '/profile/',
        name: 'Profile',
        component: () => import('./views/profile.vue'),
    },
    {
        path: '/company/add',
        name: 'OrganizationAdd',
        component: () => import('./views/organization-add.vue'),
    },
]
