<template>
    <div>
        <div class="footer uk-flex uk-flex-center">
            <div class="uk-padding uk-text-center">
                <p class="uk-margin-top uk-text-small">All Right Reserved ©{{ copyrightYear }} TradeSafe</p>
                <p class="uk-margin-top uk-text-small "><a href='https://www.tradesafe.co.za/documents/privacy_policy.pdf' target='_blank'>Privacy Policy</a></p>
                <div class="uk-flex uk-flex-center">
                    <img style="width: 10rem; height: 3rem" src="/icons/ts_logo.svg" uk-svg />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Footer',

    data() {
        return {
            copyrightYear: new Date().getFullYear(),
        }
    },
}
</script>

<style scoped>
.footer {
    background-color: #4e4e4e;
    color: white;
}
.footer a {
    color: white;
}
.footer a:hover {
text-decoration: underline;
}
</style>
