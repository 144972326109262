<template>
    <div>
        <component :is="feesComponent" :transaction="transaction" />
    </div>
</template>
<script>
export default {
    name: 'OverviewFees',
    props: {
        /*
            Transaction object is expected to have :
                - industry
                - auxliliaryData (raw text)
                - feeAllocation
                - calculation
                - calculation.baseValue
                - calculation.processingFeeValue
                - calculation.processingFeeTotal
                - calculation.parties
                - calculation.parties.role
                - parties
                - parties.role
                - parties.feeAllocation
                - allocations
                - allocation.title
                - allocation.value
        */
        transaction: {
            type: Object,
            required: true,
        },
    },
    computed: {
        feesComponent() {
            const componentMap = {
                FUEL: 'fuel',
                MINING: 'mining',
            }
            const industry = componentMap[this.transaction.industry] || 'general'
            return () => import(`./overview-fees-${industry}`)
        },
    },
}
</script>
