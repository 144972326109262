<template>
    <div class="uk-padding-small uk-margin-small">
        <!-- Status -->
        <div
            v-if="badge"
            :class="'uk-card-badge uk-label uk-label-' + badge.type"
            style="padding-top: 0.2rem; padding-bottom: 0.2rem; border-radius: 0.25rem"
        >
            {{ badge.text }}
        </div>
        <div class="uk-flex uk-flex-left uk-flex-wrap">
            <!-- Avatar -->
            <div>
                <div>
                    <img
                        class="uk-border-circle uk-margin-right animated flipInY avatar"
                        width="48"
                        height="48"
                        style="min-width: 48px"
                        :src="party.avatar | safeAvatarURL"
                    />
                </div>
            </div>
            <!-- Details -->
            <div>
                <!-- <div v-if="party.role === 'READ_ONLY'">
                    <div class="uk-grid">
                        <GL-InfoItem label="Role" :value="party.role | transactionRoleDisplay(industry)" />
                        <GL-InfoItem label="Email" :value="party.email | confidential" />
                    </div>
                </div> -->
                <h4 class="uk-margin-small-bottom">
                    {{ name }}
                    <span
                        class="uk-badge uk-margin-left"
                        v-if="party.owner"
                        style="padding: 0.8rem; border-radius: 0.25rem"
                    >
                        Transaction Creator
                    </span>
                </h4>
                <div class="uk-grid">
                    <GL-InfoItem label="Role" :value="party.role | transactionRoleDisplay(industry)" />
                    <GL-InfoItem label="Email" :value="party.email | confidential" />
                    <GL-InfoItem
                        label="Trading As"
                        v-if="partyIsOrganization(party) && party.details.organization.tradeName"
                        :value="party.details.organization.tradeName"
                    />
                    <GL-InfoItem
                        label="Registration No."
                        v-if="partyIsOrganization(party)"
                        :value="party.details.organization.registration"
                        :rightAlign="true"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import mixinParty from '@/modules/common/mixin-party'
import { formatOrganizationSuffix } from '../../../scripts/filters'
export default {
    mixins: [mixinParty],
    props: {
        party: {
            type: Object,
        },
        badge: {
            type: Object,
        },
        industry: {
            type: String,
        },
    },
    computed: {
        name() {
            if (this.partyIsUser(this.party)) {
                return this.party.details.user.givenName + ' ' + this.party.details.user.familyName
            }
            if (this.partyIsOrganization(this.party)) {
                return (
                    this.party.details.organization.name +
                    ' ' +
                    formatOrganizationSuffix(this.party.details.organization.type)
                )
            }
            if (this.party.role === 'READ_ONLY') {
                return ''
            }
            return 'Pending Acceptance'
        },
    },
}
</script>
<style scoped>
.avatar {
    width: 48px;
    height: 48px;
}
</style>
