<template>
    <div>
        <div class="uk-text-center uk-padding-small">
            <h3 class="uk-text-secondary uk-margin-remove">Let’s set up the milestones for this transaction</h3>
        </div>
        <div class="uk-flex uk-flex-center uk-margin-small-left uk-margin-small-right">
            <div class="uk-width-4-5@m">
                <MilestoneCard
                    v-for="(allocation, index) in allocations"
                    class="uk-width-1-1 uk-margin-top uk-animation-slide-right"
                    :allocation="allocation"
                    :index="index"
                    :key="index"
                    @removeAllocation="removeAllocation"
                />
                <div class="uk-text-center uk-padding">
                    <GL-Button type="primary" text="Add Milestone" @click="$store.commit('txCreate/addAllocation')" />
                </div>
            </div>
        </div>
        <TransactionInformation />
        <CreateTxNavButtons :disable-next="!allowProgress" />
    </div>
</template>

<script>
import { transactionCreate } from '../../../../../../constants/constants-transaction-create'
import MilestoneCard from './general-milestone-card'
import CreateTxNavButtons from '../../common/create-transaction-navigation'
import TransactionInformation from '../../transaction-information/index'

export default {
    name: 'GeneralMilestone',
    components: { TransactionInformation, MilestoneCard, CreateTxNavButtons },
    data() {
        return {
            feeSplitOptions: transactionCreate.feeAllocation,
            showCalculation: false,
        }
    },
    computed: {
        allowProgress() {
            if (this.allocations.length < 2) {
                return false
            }
            for (const alloc of this.allocations) {
                if (!(alloc.title && alloc.description && alloc.value && alloc.daysToDeliver && alloc.daysToInspect))
                    return false
            }
            return true
        },
        allocations() {
            return this.$store.state.txCreate.allocations.allocations
        },
        allocationValueComplete() {
            for (const allocation of this.allocations) {
                if (!allocation.value) {
                    return false
                }
            }
            return true
        },
    },
    methods: {
        removeAllocation(index) {
            this.$store.commit('txCreate/removeAllocation', index)
        },
    },
}
</script>

<style lang="scss" scoped>
$primary: #e50045;
.new-entry-button {
    color: $primary;
    &:hover {
        cursor: pointer;
        color: lighten($primary, 10%);
        text-decoration: underline;
    }
}
</style>
