<template>
    <div>
        <div :class="{ 'uk-text-right': rightAlign }">
            <label :class="{ underline: underline }"> {{ label }} </label>
        </div>
        <p
            class="uk-margin-remove-top uk-margin-small-bottom"
            :class="{ 'uk-text-right': rightAlign, multiline: multiline }"
        >
            {{ displayValue }}
        </p>
    </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
        },
        prefix: {
            type: String,
            default: '',
        },
        suffix: {
            type: String,
            default: '',
        },
        value: {
            type: [String, Number],
        },
        rightAlign: {
            type: Boolean,
            default: false,
        },
        thousandSeparate: {
            type: Boolean,
            default: false,
        },
        underline: {
            type: Boolean,
            default: false,
        },
        multiline: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        displayValue() {
            if (!this.value) {
                return 'None Provided'
            }
            if (this.thousandSeparate) {
                return `${this.prefix} ${this.value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')} ${
                    this.suffix
                }`
            }
            return `${this.prefix} ${this.value} ${this.suffix}`
        },
    },
}
</script>
<style scoped>
.multiline {
    white-space: pre-wrap;
}
</style>
