<template>
    <div v-if="fuelTransactionType" class="uk-animation-slide-top-small uk-padding">
        <div class="uk-text-center">
            <h4 class="uk-text-secondary">{{ title }}</h4>
        </div>
        <div class="uk-margin uk-flex uk-flex-center uk-flex-wrap uk-padding-small">
            <CardButtonWide
                icon-path="truck-solid.svg"
                text="Single Address"
                value="Single Address"
                large
                :description="singleDescription"
                class="selectable-card uk-margin-small-right"
                :selected="milestonesToSameAddress"
                @select="milestonesToSameAddress = true"
            />
            <CardButtonWide
                icon-path="route-solid.svg"
                text="Multiple Addresses"
                value="Multiple Addresses"
                large
                :description="multiDescription"
                class="selectable-card"
                :selected="!milestonesToSameAddress"
                @select="milestonesToSameAddress = false"
            />
        </div>
    </div>
</template>

<script>
import CardButtonWide from '@/modules/common/components/input/card-button-wide-transaction-setup'
export default {
    name: 'SingleOrMultiAddressSelector',
    components: { CardButtonWide },
    data() {
        return {
            delivery: null,
        }
    },
    computed: {
        fuelTransactionType() {
            return this.$store.state.txCreate.transaction.auxiliaryData.fuelTransactionType
        },
        milestonesToSameAddress: {
            get() {
                return this.$store.state.txCreate.transaction.auxiliaryData.milestonesToSameAddress
            },
            set(value) {
                this.$store.commit('txCreate/setTransactionAuxiliaryField', {
                    key: 'milestonesToSameAddress',
                    value,
                })
            },
        },
    },
    props: {
        title: {
            type: String,
            default: 'Specify the delivery details for this transaction',
        },
        singleDescription: {
            type: String,
            default: 'All loads to be delivered to the same address',
        },
        multiDescription: {
            type: String,
            default: 'The loads have different delivery addresses',
        },
    },
}
</script>
