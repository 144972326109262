import PageNotFound from './views/page-not-found.vue'

export default [
    {
        path: '/about',
        name: 'About',
        component: () => import('./views/about.vue'),
    },
    {
        path: '/support',
        name: 'Support',
        component: () => import('./views/support.vue'),
    },
    {
        path: '/signed_out',
        name: 'Signed Out',
        component: () => import('./views/signed_out.vue'),
    },
    {
        path: '*',
        component: PageNotFound,
    },
]
