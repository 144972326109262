<template>
    <div>
        <DomainInputField v-for="(domain, index) in domains" :key="index" :index="index" />
    </div>
</template>

<script>
import DomainInputField from './domain-input-field'
export default {
    name: 'DomainInputFieldList',
    components: { DomainInputField },
    computed: {
        domains() {
            return this.$store.state.txCreate.transaction.auxiliaryData.domains
        },
    },
}
</script>

<style scoped></style>
