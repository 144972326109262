import ContextFetch from './graphql/context/context-fetch.gql'
import ContextSet from './graphql/context/context-set.gql'
import ContextReset from './graphql/context/context-reset.gql'

export default class APIContext {
    constructor(api) {
        this.api = api
    }

    async contextFetch() {
        const result = await this.api.client.query({
            query: ContextFetch,
        })
        return result.data.me
    }
    async contextSet(input) {
        const result = await this.api.client.mutate({
            mutation: ContextSet,
            variables: {
                organizationId: input.id,
            },
        })
        return result.data
    }
    async contextReset() {
        const result = await this.api.client.mutate({
            mutation: ContextReset,
        })
        return result.data
    }
}
