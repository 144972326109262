<template>
    <div class="pogressContainer">
        <span :style="'width:' + progress + '%'" class="bar">
            <span class="text"> {{ text }} </span>
        </span>
    </div>
</template>

<script>
export default {
    props: {
        progress: Number,
        text: String,
    },
}
</script>

<style scoped>
.bar {
    background-color: #e50045;
    height: 0.5rem;
    display: block;
    border-radius: 0.25rem;
}
.text {
    margin-left: 1rem;
    color: black;
}
.pogressContainer {
    background-color: #f8f8f8;
    border-radius: 0.5rem;
    overflow: hidden;
}
</style>
