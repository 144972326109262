export default {
    namespaced: true,
    state: {
        tourRunning: false,
        showTours: false,
        homeTourShown: false,
        profileTourShown: false,
        transactionCreateTourShown: false,
    },
    mutations: {
        setTourRunning(state, payload) {
            state.tourRunning = payload
        },
        setShowTours(state, payload) {
            state.showTours = payload
        },
        homeTourShown(state) {
            state.homeTourShown = true
        },
        profileTourShown(state) {
            state.profileTourShown = true
        },
        transactionCreateTourShown(state) {
            state.transactionCreateTourShown = true
        },
    },
}
