<template>
    <div class="uk-margin-small-top">
        <div class="uk-text-center uk-text-large uk-margin">Load {{ index + 1 }} - Summary</div>
        <div>
            <ul class="uk-subnav uk-subnav-pill uk-child-width-expand" uk-switcher>
                <li class="uk-active"><a class="rounded uk-text-center" href="#">Load Description</a></li>
                <li><a class="rounded uk-text-center" href="#">Amount Payable</a></li>
                <li><a class="rounded uk-text-center" href="#">Delivery Location</a></li>
            </ul>
            <ul class="uk-switcher uk-margin">
                <li class="uk-margin-left">
                    <GL-InfoItem label="Load Title" :value="title" />
                    <GL-InfoItem label="Load Description" :value="description" />
                    <div uk-grid>
                        <GL-InfoItem
                            label="Driver Name"
                            v-if="fuelTransactionType === 'COC'"
                            :value="auxData.fuelDriverName"
                        />
                        <GL-InfoItem
                            label="Vehicle Registration"
                            v-if="fuelTransactionType === 'COC'"
                            :value="auxData.fuelVehicleRegistration"
                        />
                    </div>
                    <div class="uk-margin-small-top" uk-grid>
                        <GL-InfoItem label="Product" :value="product" />
                        <GL-InfoItem label="Delivery Days" right-align :value="daysToDeliver" />
                        <GL-InfoItem label="Inspection Days" right-align :value="daysToInspect" />
                    </div>
                </li>

                <li class="uk-margin-left">
                    <div v-if="calculationDataReady && calculation === '__loading__' && !error">
                        <div class="uk-text-center">
                            <GL-Spinner :size="2" />
                        </div>
                    </div>
                    <GL-Alert v-else-if="!calculationDataReady && !error" type="info">
                        You need to complete the details for the other loads before you can see the payable breakdown
                    </GL-Alert>
                    <GL-Alert v-else-if="error" type="danger">
                        {{ error }}
                    </GL-Alert>
                    <div v-else>
                        <div uk-grid>
                            <GL-InfoItem label="Litres" :value="units" right-align thousand-separate />
                            <GL-InfoItem
                                label="Price per Litre"
                                prefix="R"
                                :value="unitCost | currency"
                                right-align
                                thousand-separate
                            />
                            <GL-InfoItem
                                label="Load Value"
                                prefix="R"
                                :value="(unitCost * units) | currency"
                                right-align
                                thousand-separate
                            />
                            <GL-InfoItem
                                label="Supplier's Costs"
                                prefix="R"
                                :value="sellerFeeForThisMilestone | currency"
                                right-align
                                thousand-separate
                            />
                            <GL-InfoItem
                                label="Amount Payable To Supplier"
                                prefix="R"
                                underline
                                :value="payout | currency"
                                right-align
                                thousand-separate
                            />
                        </div>
                        <div uk-grid>
                            <GL-InfoItem
                                label="Total Running Liters"
                                :value="totalLitresUpToCurrentIndex"
                                right-align
                                thousand-separate
                            />
                            <GL-InfoItem
                                label="Total Running Value"
                                prefix="R"
                                :value="totalValueUpToCurrentIndex | currency"
                                right-align
                                thousand-separate
                            />
                        </div>
                    </div>
                </li>
                <li class="uk-margin-left">
                    <div v-if="fuelTransactionType === 'COC'">
                        <GL-InfoItem label="Upliftment Location" :value="auxData.fuelUpliftmentLocation" />
                    </div>
                    <div v-if="fuelTransactionType === 'COD'" class="uk-grid-row-collapse" uk-grid>
                        <GL-InfoItem label="Fuel Zone" :value="auxData.fuelZone" />
                        <GL-InfoItem label="Magisterial District" :value="auxData.fuelMagisterialDistrict" />
                    </div>
                    <div
                        v-if="
                            address &&
                            (auxData.fuelUpliftmentLocation === 'Mini Depot' ||
                                auxData.fuelUpliftmentLocation === 'Private Depot' ||
                                fuelTransactionType === 'COD')
                        "
                        class="uk-grid-row-collapse uk-margin-top"
                        uk-grid
                    >
                        <div class="uk-width-1-2@s">
                            <GL-InfoItem label="Depot / Address Name" :value="address.name" />
                        </div>
                        <div class="uk-width-1-2@s">
                            <GL-InfoItem label="Street / Complex" :value="address.line1" />
                        </div>
                        <div class="uk-width-1-2@s">
                            <GL-InfoItem label="Street line 2" :value="address.line2" />
                        </div>
                        <div class="uk-width-1-2@s">
                            <GL-InfoItem label="Suburb" :value="address.line3" />
                        </div>
                        <div class="uk-width-1-2@s">
                            <GL-InfoItem label="City" :value="address.city" />
                        </div>
                        <div class="uk-width-1-2@s">
                            <GL-InfoItem label="State / Province" :value="address.area" />
                        </div>
                        <div class="uk-width-1-2@s">
                            <GL-InfoItem label="Area Code" :value="address.code" />
                        </div>
                    </div>
                    <div class="uk-width-1-1@s">
                        <GL-InfoItem label="Delivery Instructions" :value="deliveryInstructions" />
                    </div>
                </li>
            </ul>
        </div>
        <div>
            <div class="uk-width-1-1@m uk-text-right uk-margin-top uk-card-footer">
                <GL-Button
                    text="previous"
                    type="primary-outlined"
                    class="uk-margin-small-right"
                    @click="$emit('view-previous')"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MilestoneSummary',
    props: {
        index: {
            type: Number,
            required: true,
        },
    },
    computed: {
        fuelTransactionType() {
            return this.$store.state.txCreate.transaction.auxiliaryData.fuelTransactionType
        },
        calculation() {
            return this.$store.state.txCreate.calculator.calculation
        },
        error() {
            return this.$store.state.txCreate.calculator.calculationError
        },
        payout() {
            if (this.calculation !== '__loading__') {
                return this.calculation.allocations[this.index].payout
            }
            return 0
        },
        sellerFeeForThisMilestone() {
            if (this.calculation !== '__loading__') {
                return this.calculation.allocations[this.index].processingFee
            }
            return 0
        },
        title() {
            return this.$store.state.txCreate.allocations.allocations[this.index].title
        },
        description() {
            return this.$store.state.txCreate.allocations.allocations[this.index].description
        },
        units() {
            return this.$store.state.txCreate.allocations.allocations[this.index].units
        },
        unitCost() {
            return this.$store.state.txCreate.allocations.allocations[this.index].unitCost
        },
        daysToDeliver() {
            return this.$store.state.txCreate.allocations.allocations[this.index].daysToDeliver
        },
        daysToInspect() {
            return this.$store.state.txCreate.allocations.allocations[this.index].daysToInspect
        },
        value() {
            return this.unitCost * this.units
        },
        address() {
            return this.$store.state.txCreate.allocations.addresses[this.index]
        },
        deliveryInstructions() {
            return this.$store.state.txCreate.allocations.allocations[this.index].deliveryInstructions
        },
        auxData() {
            return this.$store.state.txCreate.allocations.auxiliaryData[this.index]
        },
        product() {
            return this.$store.state.txCreate.transaction.auxiliaryData.fuelProduct
        },
        totalUnits() {
            return this.$store.getters['txCreate/transactionUnits']
        },
        totalValue() {
            return this.$store.getters['txCreate/transactionValueUnitBased']
        },
        totalValueUpToCurrentIndex() {
            let total = 0
            for (let i = this.index; i >= 0; i -= 1) {
                total +=
                    this.$store.state.txCreate.allocations.allocations[i].units *
                    this.$store.state.txCreate.allocations.allocations[i].unitCost
            }
            return total
        },
        totalLitresUpToCurrentIndex() {
            let total = 0
            for (let i = this.index; i >= 0; i -= 1) {
                total += this.$store.state.txCreate.allocations.allocations[i].units
            }
            return total
        },
        calculationDataReady() {
            return this.$store.getters['txCreate/calculationDataReady']
        },
        initialCalculation() {
            return this.$store.state.txCreate.calculator.dataUsedInPreviousCalculation
        },
    },
}
</script>
<style>
.rounded {
    border-radius: 0.5rem;
}
</style>
