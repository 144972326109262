import store from '@/store'

export const steps = [
    {
        target: '.home-tour-step-1',
        content: 'This is your homepage where you can transact safely and securely. Let’s take a look around.',
        header: {
            title: 'Welcome to TradeSafe',
        },
        params: {
            enableScrolling: false,
            highlight: true,
        },
    },
    {
        target: '.home-tour-step-2',
        content:
            'This is where you complete your profile and update your information such as your mobile number and bank account details. Your profile needs to be verified before you can use TradeSafe.',
        params: {
            enableScrolling: false,
            highlight: true,
        },
    },
    {
        target: '.home-tour-step-3',
        content:
            'You can create your first transaction here. We will guide you step-by-step how to set up your first transaction.',
        params: {
            enableScrolling: false,
            highlight: true,
        },
    },
    {
        target: '.home-tour-step-4',
        content:
            'This is where you can fund your escrow account. Payment to the escrow account can be done either by normal EFT or InstantEFT (via our partners Ozow).',
        params: {
            enableScrolling: false,
            highlight: true,
        },
    },
    {
        target: '.home-tour-step-5',
        content: 'This is your escrow account balance. It is the value of the funds currently in your escrow account.',
        params: {
            enableScrolling: false,
            highlight: true,
        },
    },
    {
        target: '.home-tour-step-6',
        content: 'This is the total value of funds in escrow that are allocated to transactions you involved in.',
        params: {
            enableScrolling: false,
            highlight: true,
        },
    },
    {
        target: '.home-tour-step-7',
        content: 'This is the total value of funds in your active transactions that are payable to you.',
        params: {
            enableScrolling: false,
            highlight: true,
        },
    },
    {
        target: '.home-tour-step-8',
        content: 'View your current, completed and cancelled transactions here.',
        params: {
            enableScrolling: false,
            highlight: true,
        },
    },
    {
        target: '.home-tour-step-9',
        content:
            'You can toggle between your personal and business escrow account here. To create a business account, just go to My Profile and add a company. We will show you how.',
        params: {
            enableScrolling: false,
            placement: 'left',
            highlight: true,
        },
    },
    {
        target: '.home-tour-step-10',
        content: 'But first things first, we need to verify your profile before you can begin using TradeSafe.',
        params: {
            enableScrolling: false,
            highlight: true,
        },
    },
]

export const callbacks = {
    onStart: () => {
        store.commit('tour/setTourRunning', true)
    },
    onStop: () => {
        store.commit('tour/setTourRunning', false)
    },
}
