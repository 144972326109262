import DrawdownCreate from './graphql/drawdown/drawdown-create.gql'
import DrawdownFileCreate from './graphql/drawdown/drawdown-file-create.gql'
import DrawdownApprove from './graphql/drawdown/drawdown-approve.gql'
import DrawdownReject from './graphql/drawdown/drawdown-reject.gql'
import DrawdownDelete from './graphql/drawdown/drawdown-delete.gql'
import DrawdownRequest from './graphql/drawdown/drawdown-request.gql'
import DrawdownCancel from './graphql/drawdown/drawdown-cancel.gql'

export default class APITransactionDrawdown {
    constructor(api) {
        this.api = api
    }

    async drawdownCreate(input) {
        const res = await this.api.client.mutate({
            mutation: DrawdownCreate,
            variables: {
                input,
            },
        })
        return res.data.drawdownCreate
    }

    async drawdownFileCreate(drawdownId, file, metadata, partiesIds = []) {
        const res = await this.api.client.mutate({
            mutation: DrawdownFileCreate,
            variables: {
                drawdownId,
                file,
                metadata,
                partiesIds,
            },
        })
        return res.data.drawdownFileCreate
    }

    async drawdownApprove(drawdownId) {
        const res = await this.api.client.mutate({
            mutation: DrawdownApprove,
            variables: {
                id: drawdownId,
            },
        })
        return res.data.drawdownApprove
    }

    async drawdownRequest(drawdownId) {
        const res = await this.api.client.mutate({
            mutation: DrawdownRequest,
            variables: {
                id: drawdownId,
            },
        })
        return res.data.drawdownRequest
    }

    async drawdownReject(drawdownId, comment) {
        const res = await this.api.client.mutate({
            mutation: DrawdownReject,
            variables: {
                id: drawdownId,
                comment: comment,
            },
        })
        return res.data.drawdownReject
    }

    async drawdownCancel(drawdownId, comment) {
        const res = await this.api.client.mutate({
            mutation: DrawdownCancel,
            variables: {
                id: drawdownId,
                comment: comment,
            },
        })
        return res.data.drawdownCancel
    }

    async drawdownDelete(drawdownId) {
        const res = await this.api.client.mutate({
            mutation: DrawdownDelete,
            variables: {
                id: drawdownId,
            },
        })
        return res.data.drawdownDelete
    }
}
