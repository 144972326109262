export function min_value(message = 'error', minValue) {
    return function (value) {
        const errors = []
        if (value < minValue) {
            errors.push(message)
        }

        return errors
    }
}
export function max_value(message = 'error', maxValue) {
    return function (value) {
        const errors = []
        if (value > maxValue) {
            errors.push(message)
        }

        return errors
    }
}
