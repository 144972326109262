<template>
    <InputBase
        :right-align-label="rightAlign"
        :label="label"
        :tooltip="tooltip"
        :error-messages="errorMessages"
        :dirty="dirty"
    >
        <div class="uk-inline uk-width-1-1">
            <span v-if="prepend" class="uk-form-icon">{{ prepend }}</span>
            <span v-if="append" class="uk-form-icon uk-form-icon-flip">{{ append }}</span>
            <input
                :name="name"
                :value="finalValue || null"
                :class="{ 'uk-form-danger': errorMessages.length > 0 && dirty, 'right-align': rightAlign }"
                :min="min"
                :max="max"
                :step="step"
                :type="focussed ? 'number' : 'text'"
                :disabled="disabled"
                autocomplete="off"
                class="uk-input"
                @blur="blur"
                @focus="focus"
                @input="input($event)"
            />
        </div>
    </InputBase>
</template>

<script>
import InputBase from './input-base'
export default {
    name: 'GL-NumberInput',
    components: {
        InputBase,
    },
    data() {
        return {
            dirty: false,
            focussed: false,
        }
    },
    props: {
        label: {
            type: String,
            default: '',
        },
        rightAlign: {
            type: Boolean,
            default: false,
        },
        value: {
            type: Number,
            default: 0,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        append: {
            type: String,
            default: '',
        },
        prepend: {
            type: String,
            default: '',
        },
        min: {
            type: String,
            default: '',
        },
        max: {
            type: String,
            default: '',
        },
        step: {
            type: String,
            default: 'any',
        },
        tooltip: {
            type: String,
            default: '',
        },
        errorMessages: {
            type: Array,
            default() {
                return []
            },
        },
        name: {
            type: String,
            default: '',
        },
    },
    computed: {
        finalValue() {
            if (!this.value && !this.dirty) {
                return null
            }
            if (this.focussed) {
                return this.value.toString()
            } else {
                return this.value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
            }
        },
    },
    methods: {
        isValid() {
            if (this.dirty) {
                return true
            }
            if (this.errorMessages.length === 0) {
                return true
            }
            return false
        },
        input(event) {
            if (event && event.target) {
                if (!event.target.value) {
                    event.target.value = 0
                }
                event.target.value = event.target.value.toString()
                event.target.value = event.target.value.replace(/[^0-9.]/g, '')

                if (event.target.value !== '') {
                    this.dirty = true
                    this.$emit('input', parseFloat(event.target.value))
                } else {
                    this.$emit('input', event.target.value)
                }
            }
        },
        focus() {
            this.focussed = true
        },
        blur() {
            this.focussed = false
        },
    },
}
</script>
