<template>
    <div class="uk-position-relative uk-width-1-2@s uk-width-2-3">
        <GL-TextInput
            :label="label"
            :tooltip="index === 0 ? 'i.e. www.maverickjoe.co.za or www.joesadverisiting.agency' : ''"
            v-model="domain"
            :error-messages="errors"
            class="uk-margin-small-top"
        />
        <div
            v-if="lastDomain"
            @click="$store.commit('txCreate/addAuxiliaryFieldArrayElement', { array: 'domains' })"
            class="inline-icon"
        >
            <GL-Icon path="plus-solid.svg" />
        </div>
        <div
            v-if="!lastDomain"
            @click="$store.commit('txCreate/deleteAuxiliaryFieldArrayElement', { array: 'domains', index })"
            class="inline-icon"
        >
            <GL-Icon path="trash.svg" />
        </div>
    </div>
</template>

<script>
import validation from '@/scripts/transaction-create-field-validation'
export default {
    name: 'DomainInputField',
    props: {
        index: { type: Number, required: true },
    },
    data() {
        return {
            validation,
        }
    },
    computed: {
        label() {
            if (this.domains.length === 1) {
                return 'Domain Name URL'
            }
            return `Domain Name URL ${this.index + 1}`
        },
        errors() {
            return validation.requiredField(this.domain)
        },
        domains() {
            return this.$store.state.txCreate.transaction.auxiliaryData.domains
        },
        domain: {
            get() {
                return this.$store.state.txCreate.transaction.auxiliaryData.domains[this.index]
            },
            set(value) {
                this.$store.commit('txCreate/setAuxiliaryFieldArrayValue', {
                    array: 'domains',
                    index: this.index,
                    value,
                })
            },
        },
        lastDomain() {
            return this.index === this.$store.state.txCreate.transaction.auxiliaryData.domains.length - 1
        },
    },
}
</script>

<style lang="scss" scoped>
.inline-icon {
    position: absolute;
    top: 1.9rem;
    right: -1.9rem;
    color: $tradesafe-red;
    cursor: pointer;
    &:hover {
        color: lighten($tradesafe-red, 10%);
    }
}
</style>
