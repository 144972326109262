function id_ZA_Func(value) {
    if (!value) {
        return false
    }

    const idNumber = value.replace(/\s+/g, '')

    function isNumeric(n) {
        return !isNaN(parseFloat(n)) && isFinite(n)
    }

    if (idNumber.length !== 13 || !isNumeric(idNumber)) {
        return false
    }

    let tempTotal = 0
    let checkSum = 0
    let multiplier = 1

    for (let i = 0; i < 13; ++i) {
        tempTotal = parseInt(idNumber.charAt(i)) * multiplier
        if (tempTotal > 9) {
            tempTotal = parseInt(tempTotal.toString().charAt(0)) + parseInt(tempTotal.toString().charAt(1))
        }
        checkSum = checkSum + tempTotal
        multiplier = multiplier % 2 === 0 ? 1 : 2
    }

    if (checkSum % 10 !== 0) {
        return false
    }

    const idYear = parseFloat(idNumber.substring(0, 2))
    const currentDate = new Date()
    const currentYear = currentDate.getFullYear() - 2000
    const cutoffYear = currentYear - 18

    if (idYear > cutoffYear && idYear < currentYear) {
        return false
    }

    return true
}

// TODO: There isn't a need for this to be two functions they can be combined into one
export function id_za(message = 'error') {
    return function (value) {
        const errors = []

        if (id_ZA_Func(value) === false) {
            errors.push(message)
        }

        return errors
    }
}
