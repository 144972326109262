<template>
    <div class="form-group">
        <div :class="{ 'uk-text-right': rightAlignLabel }">
            <label v-if="label">
                {{ label }}
            </label>
            <label v-if="!label" style="opacity: 0; width: 0px"> . </label>
            <GL-Icon v-if="tooltip" class="tool" path="question-circle-o.svg" :size="1" :uk-tooltip="tooltip" />
            <GL-Icon
                v-if="!tooltip"
                class="tool"
                path="question-circle-o.svg"
                :size="1"
                :uk-tooltip="tooltip"
                style="opacity: 0; width: 0px"
            />
        </div>
        <transition
            enter-active-class="uk-animation-shake "
            leave-active-class="uk-animation-slide-left-small uk-animation-fast uk-animation-reverse"
        >
            <div class="uk-form-controls">
                <!--
                ##########################################
                This is where the input control is slotted
                ##########################################
            -->
                <slot></slot>
            </div>
        </transition>

        <div v-if="dirty" class="uk-animation-shake">
            <transition-group
                name="slide-fade"
                enter-active-class="uk-animation-shake "
                leave-active-class="uk-animation-slide-left-small uk-animation-fast uk-animation-reverse"
            >
                <div v-for="error in errorMessages" :key="error">
                    <div class="error">
                        {{ error }}
                    </div>
                </div>
            </transition-group>
        </div>
    </div>
</template>

<script>
export default {
    name: 'InputBase',
    props: {
        label: {
            type: String,
            default: '',
        },
        rightAlignLabel: {
            type: Boolean,
            default: false,
        },
        tooltip: {
            type: String,
            default: '',
        },
        errorMessages: {
            type: Array,
            default() {
                return []
            },
        },
        dirty: {
            type: Boolean,
            default: false,
        },
    },
}
</script>
<style scoped>
.tool {
    margin-left: 0.2rem;
    margin-bottom: 0.2rem;
    display: inline-block;
}
.error {
    font-size: small;
    color: red;
}
.warning {
    font-size: small;
    color: orange;
}
</style>
