<template>
    <div class="uk-animation-slide-top-small uk-flex uk-flex-center uk-margin-small-left uk-margin-small-right">
        <div class="uk-width-4-5@m">
            <div class="uk-text-center">
                <h3 class="uk-text-center uk-text-secondary">
                    Upliftment of the product will take place at the following address.
                </h3>
                <p class="uk-text-center uk-text-bold">
                    Please note that these address details will be disclosed to the other party.
                </p>
            </div>
            <div class="uk-card uk-card-default uk-padding-small uk-width-1-1">
                <div
                    class="uk-margin-small-right uk-margin-small-left uk-margin-small-top uk-margin-small-bottom uk-grid-small"
                    uk-grid
                >
                    <GL-SelectInput
                        label="upliftment location"
                        :option-list="upliftmentLocations"
                        v-model="upliftmentLocation"
                        :error-messages="validation.requiredField(upliftmentLocation)"
                        class="uk-width-1-1"
                    />
                    <GL-TextInput
                        v-if="!milestoneTransaction"
                        label="Vehicle Registration"
                        class="uk-width-1-2@s"
                        v-model="vehicleRegistration"
                        :error-messages="validation.requiredField(upliftmentLocation)"
                    />
                    <GL-TextInput
                        v-if="!milestoneTransaction"
                        label="Driver Name"
                        class="uk-width-1-2@s"
                        v-model="driverName"
                        :error-messages="validation.requiredField(driverName)"
                    />
                </div>
            </div>
            <div
                v-if="upliftmentLocation === 'Mini Depot' || upliftmentLocation === 'Private Depot'"
                class="uk-animation-slide-top-small uk-width-1-1 uk-margin-top"
            >
                <AddressForm
                    hide-heading
                    hide-sub-heading
                    address-name-label="Give this depot a name"
                    address-name-tooltip=""
                />
            </div>
        </div>
    </div>
</template>

<script>
import { upliftmentLocations } from '../../../../../../constants/fuel'
import AddressForm from '../address-form'
import validation from '../../../../../../scripts/transaction-create-field-validation'

export default {
    name: 'COCFuelDeliveryAddress',
    components: { AddressForm },
    data() {
        return {
            upliftmentLocations,
            validation,
        }
    },
    computed: {
        driverName: {
            get() {
                return this.$store.state.txCreate.transaction.auxiliaryData.fuelDriverName
            },
            set(value) {
                this.$store.commit('txCreate/setTransactionAuxiliaryField', {
                    key: 'fuelDriverName',
                    value,
                })
            },
        },
        vehicleRegistration: {
            get() {
                return this.$store.state.txCreate.transaction.auxiliaryData.fuelVehicleRegistration
            },
            set(value) {
                this.$store.commit('txCreate/setTransactionAuxiliaryField', {
                    key: 'fuelVehicleRegistration',
                    value,
                })
            },
        },
        upliftmentLocation: {
            get() {
                return this.$store.state.txCreate.transaction.auxiliaryData.fuelUpliftmentLocation
            },
            set(value) {
                this.$store.commit('txCreate/setTransactionAuxiliaryField', {
                    key: 'fuelUpliftmentLocation',
                    value,
                })
            },
        },
        milestoneTransaction() {
            return this.$store.getters['txCreate/milestoneTransaction']
        },
    },
    watch: {
        upliftmentLocation() {
            if (this.upliftmentLocation === 'Mini Depot' || this.upliftmentLocation === 'Private Depot') {
                this.$store.commit('txCreate/deliveryAddressRequired', true)
            } else {
                this.$store.commit('txCreate/deliveryAddressRequired', false)
            }
        },
    },
}
</script>

<style scoped>
.content {
    margin: 0.5rem;
}
</style>
