<template>
    <div>
        <div v-if="paginatorInfo && paginatorInfo.total > paginatorInfo.perPage">
            <ul class="uk-pagination uk-flex-center">
                <li v-if="paginatorInfo.currentPage !== 1" @click="navigate(paginatorInfo.currentPage - 1)">
                    <a>
                        <GL-Icon path="pagination-previous.svg" :size="1" />
                    </a>
                </li>
                <li v-if="showFirstButton" @click="navigate(1)">
                    <a
                        ><span>{{ 1 }}</span></a
                    >
                </li>
                <li v-if="showFirstButton" class="uk-disabled"><span>...</span></li>
                <li
                    v-for="page in pages"
                    :key="page.index"
                    @click="navigate(page.index)"
                    :class="{ 'uk-active': page.isActive }"
                >
                    <a
                        ><span>{{ page.index }}</span></a
                    >
                </li>
                <li v-if="showLastButton" class="uk-disabled"><span>...</span></li>
                <li v-if="showLastButton" @click="navigate(paginatorInfo.lastPage)">
                    <a
                        ><span>{{ paginatorInfo.lastPage }}</span></a
                    >
                </li>
                <li
                    v-if="paginatorInfo.currentPage !== paginatorInfo.lastPage"
                    @click="navigate(paginatorInfo.currentPage + 1)"
                >
                    <a>
                        <GL-Icon path="pagination-next.svg" :size="1" />
                    </a>
                </li>
            </ul>
        </div>
        <p />
    </div>
</template>

<script>
export default {
    name: 'Pagination',
    props: {
        paginatorInfo: {
            type: Object,
            required: true,
        },
        maxVisibleButtons: {
            type: Number,
            default: 5,
        },
    },
    computed: {
        showFirstButton() {
            return !this.pages.find(page => page.index === 1)
        },
        showLastButton() {
            return !this.pages.find(page => page.index === this.paginatorInfo.lastPage)
        },
        startPage() {
            // When on the first page
            if (this.paginatorInfo.currentPage === 1) {
                return 1
            }
            // When on the last page
            if (this.paginatorInfo.currentPage === this.paginatorInfo.lastPage) {
                const res = this.paginatorInfo.lastPage - this.maxVisibleButtons + 1
                return res < 1 ? 1 : res
            }
            // When in between
            return this.paginatorInfo.currentPage - 1
        },
        pages() {
            const range = []
            for (
                let i = this.startPage;
                i <= Math.min(this.startPage + this.maxVisibleButtons - 1, this.paginatorInfo.lastPage);
                i += 1
            ) {
                range.push({
                    index: i,
                    isActive: i === this.paginatorInfo.currentPage,
                })
            }
            return range
        },
    },
    methods: {
        navigate(nav) {
            if (this.paginatorInfo !== undefined) {
                this.$emit('set-page', nav)
            }
        },
    },
}
</script>
