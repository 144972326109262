<template>
    <div>
        <div class="uk-flex uk-flex-center">
            <div class="uk-card uk-card-default uk-padding uk-width-5-6@m">
                <div class="uk-text-center">
                    <div class="uk-flex uk-flex-center">
                        <div
                            class="uk-padding uk-padding-remove-top uk-padding-remove-bottom"
                            :class="{ 'uk-padding-remove-right uk-padding-remove-left': $screenSmaller('small') }"
                        >
                            <div
                                class="uk-padding-small"
                                :class="{ 'uk-padding-remove-right uk-padding-remove-left': $screenSmaller('small') }"
                            >
                                <h3 class="uk-text-secondary">
                                    You can upload documents to your transaction that will help you finalise your deal
                                </h3>
                            </div>
                            <div
                                class="uk-padding-small"
                                :class="{ 'uk-padding-remove-right uk-padding-remove-left': $screenSmaller('small') }"
                            >
                                <p>
                                    {{ informationText }}
                                </p>
                                <p>
                                    We support the following file types : .xls .xlsx .pdf .doc .docx .ppt .pptx .gif
                                    .jpg .jpeg .png .tiff .txt .odp .odt .ods
                                </p>
                                <p>The maximum file size is : 25Mb</p>
                                <p>You can upload documents at any stage during the transaction process.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="uk-flex uk-flex-center uk-flex-wrap">
                    <DocumentCard v-for="(document, index) in documents" :key="index" :index="index" />
                    <UploadCard @add="addFiles"></UploadCard>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import UploadCard from './upload-card'
import DocumentCard from './document-card'

export default {
    name: 'Documents',
    components: { UploadCard, DocumentCard },
    props: {
        industry: {
            type: String,
            required: true,
        },
    },
    computed: {
        documents() {
            return this.$store.state.txCreate.documents.documents
        },
        informationText() {
            switch (this.industry) {
                case 'FUEL':
                    return 'You can upload supporting documentation such as pro-forma invoices, upliftment schedules, corporate offers and other agreements.'
                case 'CELLPHONES_COMPUTERS':
                    return 'This can include contracts, supporting documentation or pictures. For example, the seller of a laptop computer can take multiple photos of the device and upload it to the transaction. It is advisable that you upload supporting documentation to your transaction. This will protect the parties if the matter is disputed.'
                case 'AGRICULTURE_LIVESTOCK_GAME':
                    return 'This can include contracts, supporting documentation or pictures. For example, the seller of a John Deere tractor can take multiple photos of the vehicle and upload it to the transaction. It is advisable that you upload supporting documentation to your transaction. This will protect the parties if the matter is disputed.'
                case 'FILMS_PRODUCTION':
                    return 'This can include contracts, supporting documentation or pictures. It is advisable that you upload supporting documentation to your transaction. This will protect the parties if the matter is disputed.'
                case 'EVENTS':
                    return 'This can include contracts, supporting documentation or pictures. It is advisable that you upload supporting documentation to your transaction. This will protect the parties if the matter is disputed.'
                case 'SOFTWARE_DEV_WEB_DOMAINS':
                    return 'This can include contracts, supporting documentation or pictures. It is advisable that you upload supporting documentation to your transaction. This will protect the parties if the matter is disputed.'
                case 'ART_ANTIQUES_COLLECTIBLES':
                    return 'This can include contracts, supporting documentation or pictures. For example, the seller of the work of art can take multiple photos of the masterpiece, or upload a proof of authenticity to the transaction. It is advisable that you upload supporting documentation to your transaction. This will protect the parties if the matter is disputed.'
                case 'CONSTRUCTION':
                    return 'This can include contracts, supporting documentation or pictures. For example, you can upload documents such as the Bill of Quantities (BOQ), Scope of Work (SOW), project plans or invoices etc. It is advisable that you upload supporting documentation to your transaction. This will protect the parties if the matter is disputed.'
                case 'CONTRACT_WORK_FREELANCING':
                    return 'This can include contracts, supporting documentation or pictures. For example, you can upload documents such as a scope of work, project specs, invoices, kick-off agreements etc. It is advisable that you upload supporting documentation to your transaction. This will protect the parties if the matter is disputed.'
                case 'MERGERS_ACQUISITIONS':
                    return 'This can include contracts, supporting documentation or pictures. For example, you can upload documents such as Term Sheets, Sales Purchase Agreements (SPA), Letters of Intent (LoI), Share Subscription Agreements etc. It is advisable that you upload supporting documentation to your transaction. This will protect the parties if the matter is disputed.'
                case 'PROPERTY':
                    return 'This can include contracts, supporting documentation or pictures. For example, the homeowner can upload multiple photos of the property and/or a electrical certificate of compliance etc. It is advisable that you upload supporting documentation to your transaction. This will protect the parties if the matter is disputed.'
                case 'MINING':
                    return 'This can include contracts, supporting documentation or pictures. For example, you can upload purchase orders, pro-forma invoices or corporate offers to your transaction. It is advisable that you upload supporting documentation to your transaction. This will protect the parties if the matter is disputed.'
                default:
                    return 'This can include contracts, supporting documentation or pictures. For example, the seller of a motor vehicle can take multiple photos of the vehicle and upload it to the transaction. It is advisable that you upload supporting documentation to your transaction. This will protect the parties if the matter is disputed.'
            }
        },
    },
    methods: {
        addFiles(files) {
            this.$store.commit('txCreate/addDocumentsToCreate', files)
            this.$store.dispatch('txCreate/saveDocuments')
        },
    },
}
</script>
