<template>
    <div>
        <div
            v-if="!editMode"
            class="uk-card uk-card-default uk-padding uk-margin"
            :class="{ 'uk-margin-left uk-margin-right': $screenLarger('medium') }"
        >
            <div class="uk-text-center">
                <div class="uk-padding-small uk-padding-remove-top">
                    <h3 class="uk-text-secondary">Good job. You are almost done</h3>
                </div>
                <div class="uk-padding-small uk-padding-remove-top uk-padding-remove-bottom">
                    <p>
                        The last step is for you to review the details of your transaction. Please make sure you are
                        happy before sending it off to the other {{ partiesPlural }}. The 'FINISH AND INVITE' button can
                        be found at the bottom of this page.
                    </p>
                    <p>Click the ‘EDIT’ button if you would like to make changes.</p>
                </div>
            </div>
        </div>
        <div :class="{ 'uk-margin-left uk-margin-right': $screenLarger('medium') }">
            <div
                v-for="item in components"
                :key="item.value"
                class="uk-card uk-card-default uk-card-body uk-animation-slide-right-small uk-margin"
                :class="{ 'uk-card-small': $screenSmaller('small') }"
            >
                <div class="uk-flex uk-flex-between">
                    <div>
                        <h3 class="uk-text-secondary">
                            {{ item.name }}
                        </h3>
                    </div>
                    <div>
                        <GL-Button
                            type="primary-outlined uk-button-small"
                            text="edit"
                            @click="navigateToSection(item.editStep)"
                        />
                    </div>
                </div>
                <component :is="item.value" :transaction="transactionPartyFilled"> </component>
            </div>
        </div>
        <CreateTxNavButtons
            :last-step="true"
            :disable-next="disableInvite"
            :right-button-tooltip="inviteButtonTooltip"
        />
    </div>
</template>

<script>
import OverviewDetails from '@/modules/common/components/overview/overview-details/overview-details'
import OverviewFees from '@/modules/common/components/overview/overview-fees/overview-fees'
import OverviewParties from '@/modules/common/components/overview/overview-parties'
import OverviewFiles from '@/modules/common/components/overview/overview-files'
import OverviewDelivery from '@/modules/common/components/overview/overview-delivery/overview-delivery'
import OverviewMilestones from '@/modules/common/components/overview/overview-milestones/overview-milestones'
import OverviewConfidentiality from '@/modules/common/components/overview/overview-confidentiality'

import CreateTxNavButtons from '../common/create-transaction-navigation'
import mixinTransaction from '@/modules/common/mixin-transaction'

export default {
    mixins: [mixinTransaction],
    name: 'OverviewIndex',
    components: {
        CreateTxNavButtons,
        OverviewDetails,
        OverviewFees,
        OverviewParties,
        OverviewDelivery,
        OverviewFiles,
        OverviewMilestones,
        OverviewConfidentiality,
    },
    mounted() {
        if (this.$store.getters['txCreate/editMode'] && this.$store.state.txCreate.transactionEdited) {
            this.$store.commit('txCreate/setTransactionEdited', false)
            this.$globalEventEmit('notification', {
                type: 'success',
                title: 'Success!',
                body: 'The transaction has been updated with your recent changes. We have informed the other party/s',
            })
        }
    },
    computed: {
        disableInvite() {
            const transaction = this.$store.getters['transaction/transaction']
            for (const party of transaction.calculation.parties) {
                if (party.payout < 0) {
                    return true
                }
            }
            return false
        },
        inviteButtonTooltip() {
            if (this.disableInvite) {
                return 'One of the parties in the transaction has a negative payable value. Please correct this before inviting the other parties.'
            }
            return ''
        },
        editMode() {
            return this.$store.getters['txCreate/editMode']
        },
        partiesPlural() {
            if (this.$store.state.transaction.transaction !== '__loading__') {
                return this.$store.state.transaction.transaction.parties.length > 2 ? 'parties' : 'party'
            }
            return 'party'
        },
        hasMilestones() {
            return this.$store.state.transaction.transaction.allocations.length > 1
        },
        isConfidential() {
            return this.$store.state.transaction.transaction.privacy !== 'NONE'
        },
        milestonesToSameAddress() {
            const auxData = JSON.parse(this.$store.state.transaction.transaction.auxiliaryData)
            if (auxData && auxData.milestonesToSameAddress) {
                return true
            }
            return false
        },
        components() {
            const tabs = [
                {
                    name: 'General Details',
                    value: 'OverviewDetails',
                    editStep: 'Details',
                },
            ]

            if (!this.transaction) {
                return tabs
            }

            if (this.hasMilestones) {
                let tabName = 'Milestones'
                let editStep = 'Milestones'

                if (this.transaction.industry === 'FUEL' || this.transaction.industry === 'MINING') {
                    tabName = 'Loads'
                    editStep = 'Loads'
                }

                tabs.push({
                    name: tabName,
                    value: 'OverviewMilestones',
                    editStep: editStep,
                })
            }

            if (this.isConfidential) {
                tabs.push({
                    name: 'Confidentiality',
                    value: 'OverviewConfidentiality',
                    editStep: 'Create',
                })
            }

            tabs.push({
                name: 'Fees and Costs',
                value: 'OverviewFees',
                editStep: 'Details',
            })
            tabs.push({
                name: 'Trade Parties',
                value: 'OverviewParties',
                editStep: 'Parties',
            })

            if (this.transaction.industry === 'FUEL' || this.transaction.industry === 'MINING') {
                const editStep = this.milestonesToSameAddress ? 'Delivery' : 'Loads'
                tabs.push({
                    name: 'Delivery Details',
                    value: 'OverviewDelivery',
                    editStep: editStep,
                })
            } else if (this.transaction.industry !== 'PROPERTY') {
                tabs.push({
                    name: 'Delivery Details',
                    value: 'OverviewDelivery',
                    editStep: 'Delivery',
                })
            }

            tabs.push({
                name: 'Files',
                value: 'OverviewFiles',
                editStep: 'Documents',
            })

            return tabs
        },
    },
    methods: {
        navigateToSection(section) {
            this.$globalEventEmit('notificationHide')
            this.$store.dispatch('txCreate/setStepByName', section)
        },
    },
}
</script>
