<template>
    <div>
        <div class="uk-container uk-text-center uk-margin-large-top uk-margin-large-bottom">
            <h2>This page isn't available</h2>
            <h3>The link you followed may be broken, or the page may have been removed.</h3>
            <div class="uk-padding">
                <img src="/images/404.svg" class="uk-width-large animated swing" />
            </div>
            <a href="/"> Go back home </a>
        </div>
        <Footer />
    </div>
</template>

<script>
export default {
    name: 'PageNotFound',
}
</script>
