<template>
    <div>
        <CardButton
            class="selectable-card"
            v-for="item in items"
            :key="item.name"
            :icon-path="item.icon"
            :text="item.name"
            :selected="value === item.name"
            @select="select(item.name)"
        />
    </div>
</template>

<script>
import CardButton from './card-button'
export default {
    name: 'CardSelection',
    components: { CardButton },
    props: {
        value: {
            type: String,
            required: true,
        },
        items: {
            type: Array,
            default() {
                return [
                    {
                        name: '',
                        icon: '',
                    },
                ]
            },
        },
    },
    methods: {
        select(v) {
            this.$emit('select', v)
        },
    },
}
</script>

<style scoped>
.selectable-card {
    margin: 0.25rem;
}
</style>
