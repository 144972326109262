import validation from '@/scripts/validation/validation'
import { minimumTransactionValue } from '../constants/constants-escrow-fees'
import store from '@/store'

export default {
    requiredField(value) {
        return validation.validate(value, [validation.list.required('Required')])
    },
    fuelZone(value) {
        return validation.validate(value, [validation.list.required('Required'), validation.list.valid_zone()])
    },
    magisterialDistrict(value) {
        return validation.validate(value, [
            validation.list.required('Required'),
            validation.list.valid_magisterial_district(),
        ])
    },
    allocationValue(value) {
        return validation.validate(value, [
            validation.list.required('Required'),
            validation.list.min_value(
                `Minimum Transaction Value R ${minimumTransactionValue}`,
                minimumTransactionValue,
            ),
        ])
    },
    partyDetailsComplete() {
        for (const party of store.state.txCreate.parties.parties) {
            if (party.role !== 'BUYER' && party.role !== 'SELLER' && party.role !== 'READ_ONLY') {
                if (party.role === 'AGENT') {
                    if (!(party.fee && party.feeType)) {
                        return false
                    }
                } else {
                    if (!(party.fee && party.feeAllocation && party.feeType)) {
                        return false
                    }
                }
            }
        }
        return true
    },
    basicDetailsComplete() {
        return !!(
            store.state.txCreate.transaction.transaction.title &&
            store.state.txCreate.transaction.transaction.description &&
            store.state.txCreate.transaction.transaction.feeAllocation
        )
    },
    allocationDetailsComplete() {
        const mileStones = store.state.txCreate.allocations.allocations.length > 1

        for (const allocation of store.state.txCreate.allocations.allocations) {
            if (!allocation.value) {
                if (!(allocation.units && allocation.unitCost)) {
                    return false
                }
            }
            if (mileStones) {
                if (!(allocation.title && allocation.description)) {
                    return false
                }
            }
            if (!(allocation.daysToDeliver && allocation.daysToInspect)) {
                return false
            }
        }
        return true
    },
}
