<template>
    <div>
        <div class="uk-card uk-card-default uk-position-relative">
            <span @click="$store.commit('txCreate/removeAllocation', index)" v-if="index > 1" class="close">
                <GL-Icon path="trash.svg" />
            </span>
            <div class="uk-card-body">
                <div class="uk-text-center uk-text-large">Milestone {{ index + 1 }}</div>
                <div>
                    <div uk-grid>
                        <GL-TextInput
                            class="uk-width-1-1"
                            label="Give your milestone a name"
                            :name="'milestone-title-' + index"
                            v-model="title"
                            :tooltip="milestoneTitleTooltip"
                            :error-messages="validation.requiredField(title)"
                        />
                        <GL-TextAreaInput
                            class="uk-width-1-1 uk-margin-small-top"
                            label="Milestone Description"
                            :name="'milestone-description-' + index"
                            tooltip="Give the milestone a detailed description. Provide as much detail as possible including the event that needs to take place in order for the funds to be released. You will be able to upload documents to this transaction later on (you can reference these documents in the description box below)."
                            :error-messages="validation.requiredField(description)"
                            v-model="description"
                        />
                        <GL-CurrencyInput
                            class="uk-width-1-2@m uk-margin-small-top"
                            label="price"
                            :name="'milestone-value-' + index"
                            v-model="value"
                            :error-messages="validation.allocationValue(value)"
                        />

                        <GL-NumberInput
                            class="uk-width-1-4@m uk-margin-small-top"
                            label="Delivery Days"
                            v-model="daysToDeliver"
                            :name="'milestone-delivery-days-' + index"
                            right-align
                            :tooltip="deliveryDaysTooltip"
                            :error-messages="validation.requiredField(daysToDeliver)"
                        />
                        <GL-NumberInput
                            class="uk-width-1-4@m uk-margin-small-top"
                            label="Inspection Days"
                            :name="'milestone-inspection-days-' + index"
                            v-model="daysToInspect"
                            :tooltip="inspectionDaysTooltip"
                            right-align
                            :error-messages="validation.requiredField(daysToInspect)"
                        />
                    </div>
                </div>
            </div>
        </div>
        <MilestoneRunningSummary
            class="uk-visible@l uk-animation-slide-right-small"
            v-if="lastMilestone && transactionValue"
            :index="index"
        />
    </div>
</template>

<script>
import MilestoneRunningSummary from './milestone-running-summary'
import validation from '@/scripts/transaction-create-field-validation'
export default {
    name: 'MilestoneCard',
    components: { MilestoneRunningSummary },
    props: {
        index: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            validation,
        }
    },
    computed: {
        milestoneTitleTooltip() {
            switch (this.$store.state.txCreate.transaction.transaction.industry) {
                case 'FILMS_PRODUCTION':
                    return 'Give your milestone a name i.e. Setting up location for Baby Joe’s photo shoot etc.'
                case 'AGRICULTURE_LIVESTOCK_GAME':
                    return 'Give your milestone a name i.e. Delivery of first batch of 2020 6 Series Row-Crop Tractors etc.'
                case 'CELLPHONES_COMPUTERS':
                    return 'Give your milestone a name i.e. Delivery of mount case for Dell PowerEdge R740 Rackmount server etc.'
                case 'PROPERTY':
                    return 'Give your milestone a name i.e. Handover of electrical certificate of compliance etc.'
                case 'EVENTS':
                    return 'Give your milestone a name i.e. Provide wedding album - Joe and Cate’s Big Day etc.'
                case 'ART_ANTIQUES_COLLECTIBLES':
                    return 'Give your milestone a name i.e. Provide signed certificate of authenticity etc.'
                case 'CONSTRUCTION':
                    return 'Give your milestone a name i.e. Site establishment of construction team on Eagle Office Park Renovations Project etc.'
                case 'CONTRACT_WORK_FREELANCING':
                    return 'Give your milestone a name i.e. Prepare wireframes for new website design etc.'
                case 'MERGERS_ACQUISITIONS':
                    return 'Give your milestone a name i.e. Release of 35% of the transaction value on fulfillment of condition precedents A-F etc.'
                case 'VEHICLES_WATERCRAFT':
                    return 'Give your milestone a name i.e Loading of BMW Z4 2010 onto professional vehicle courier trailer for haulage etc.'
                default:
                    return 'Give your milestone a name i.e. Prepare fiberglass foundation for the new swimming pool to be built etc.'
            }
        },
        deliveryDaysTooltip() {
            switch (this.$store.state.txCreate.transaction.transaction.industry) {
                case 'MERGERS_ACQUISITIONS':
                    return 'Delivery days is the expected duration of a specific M&A or sales process.'
                case 'AGRICULTURE_LIVESTOCK_GAME':
                    return 'Delivery Days is the expected duration of the delivery of the agricultural goods or services for this milestone.'
                case 'PROPERTY':
                    return 'Delivery days is the expected duration for the completion of this milestone.'
                case 'RENTAL':
                    return 'Delivery days is the period of the rental or lease agreement'
                case 'CONSTRUCTION':
                    return 'Delivery days is the expected duration to complete this specific construction milestone.'
                case 'CONTRACT_WORK_FREELANCING':
                    return 'Delivery days is the expected duration to complete this specific project or freelance work milestone.'
                default:
                    return 'Delivery days is the expected duration of the delivery of the goods or services for this milestone.'
            }
        },
        inspectionDaysTooltip() {
            switch (this.$store.state.txCreate.transaction.transaction.industry) {
                case 'MERGERS_ACQUISITIONS':
                    return 'Inspection days is the number of days the buyer has to inspect a specific M&A or sales process.'
                case 'AGRICULTURE_LIVESTOCK_GAME':
                    return 'Inspection days is the number of days the buyer has to inspect the agricultural goods or services delivered in this milestone.'
                case 'PROPERTY':
                    return 'Inspection days is the number of days the buyer has to inspect what has been delivered for this milestone.'
                case 'CONSTRUCTION':
                    return 'Inspection days is the number of days the buyer has to inspect the work completed for this construction milestone.'
                case 'CONTRACT_WORK_FREELANCING':
                    return 'Inspection days is the number of days the buyer has to inspect the project or freelance work delivered for this milestone.'
                default:
                    return 'Inspection days is the number of days the buyer has to inspect the goods or services delivered for this milestone'
            }
        },
        lastMilestone() {
            return this.index === this.$store.state.txCreate.allocations.allocations.length - 1
        },
        transactionValue() {
            return this.$store.getters['txCreate/transactionValue']
        },
        title: {
            get() {
                return this.$store.state.txCreate.allocations.allocations[this.index].title
            },
            set(v) {
                this.$store.commit('txCreate/setAllocationField', { index: this.index, key: 'title', value: v })
            },
        },
        description: {
            get() {
                return this.$store.state.txCreate.allocations.allocations[this.index].description
            },
            set(v) {
                this.$store.commit('txCreate/setAllocationField', { index: this.index, key: 'description', value: v })
            },
        },
        daysToInspect: {
            get() {
                return this.$store.state.txCreate.allocations.allocations[this.index].daysToInspect
            },
            set(v) {
                this.$store.commit('txCreate/setAllocationField', { index: this.index, key: 'daysToInspect', value: v })
            },
        },
        daysToDeliver: {
            get() {
                return this.$store.state.txCreate.allocations.allocations[this.index].daysToDeliver
            },
            set(v) {
                this.$store.commit('txCreate/setAllocationField', { index: this.index, key: 'daysToDeliver', value: v })
            },
        },
        value: {
            get() {
                return this.$store.state.txCreate.allocations.allocations[this.index].value
            },
            set(v) {
                this.$store.commit('txCreate/setAllocationField', { index: this.index, key: 'value', value: v })
            },
        },
    },
}
</script>

<style scoped>
.close {
    position: absolute;
    top: 0;
    right: 0;
    padding-right: 10px;
    padding-top: 3px;
    font-size: 20px;
}

.close:hover {
    cursor: pointer;
    color: black;
}
</style>
