import { render, staticRenderFns } from "./card-button.vue?vue&type=template&id=5de8cbe6&scoped=true"
import script from "./card-button.vue?vue&type=script&lang=js"
export * from "./card-button.vue?vue&type=script&lang=js"
import style0 from "./card-button.vue?vue&type=style&index=0&id=5de8cbe6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5de8cbe6",
  null
  
)

export default component.exports