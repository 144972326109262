<template>
    <div>
        <div class="uk-text-center uk-padding-small uk-margin">
            <h3 class="uk-text-secondary uk-margin-remove">Describe in detail what you are {{ userActionText }}</h3>
        </div>
        <div class="uk-flex uk-flex-center">
            <form class="uk-width-4-5@m">
                <div class="uk-card uk-card-default uk-card-body uk-width-1-1 uk-margin-small">
                    <div uk-grid>
                        <GL-TextInput
                            class="uk-width-1-2@s"
                            label="Transaction Name"
                            tooltip="Give your transaction a name i.e. 2020 6 Series Row-Crop Tractors. The transaction name can also include an invoice number or Purchase Order number."
                            v-model="title"
                            :error-messages="validation.requiredField(title)"
                        />
                        <GL-SelectInput
                            class="uk-width-1-2@s"
                            v-model="category"
                            label="Category"
                            :option-list="Categories"
                            :error-messages="validation.requiredField(category)"
                        />
                        <GL-TextAreaInput
                            class="uk-width-1-1 uk-margin-small-top"
                            label="Description"
                            v-model="description"
                            tooltip="Give the transaction a detailed description. Provide as much detail as possible including the event that needs to take place in order for the funds to be released. You will be able to upload documents to this transaction later on (you can reference these documents in the description box below)."
                            :error-messages="validation.requiredField(description)"
                        />
                        <GL-SelectInput
                            class="uk-width-1-2 uk-margin-small-top"
                            v-model="feeAllocation"
                            label="Who Will Pay The Escrow Fee?"
                            :option-list="feeAllocationOptions"
                            :error-messages="validation.requiredField(feeAllocation)"
                        />
                        <div v-if="!drawdown" class="uk-width-1-2@s uk-visible@s"></div>
                        <GL-CurrencyInput
                            v-model="value"
                            class="uk-width-1-2 uk-margin-small-top"
                            label="price"
                            :error-messages="validation.allocationValue(value)"
                        />
                        <GL-NumberInput
                            v-if="!drawdown"
                            class="uk-width-1-4@s uk-margin-small-top"
                            label="Delivery Days"
                            tooltip="Delivery Days is the expected duration of the delivery of the agricultural goods or services."
                            right-align
                            v-model="daysToDeliver"
                            :error-messages="validation.requiredField(daysToDeliver)"
                        />
                        <GL-NumberInput
                            v-if="!drawdown"
                            class="uk-width-1-4@s uk-margin-small-top"
                            label="Inspection Days"
                            right-align
                            tooltip="Inspection days is the number of days a buyer to inspect the agricultural goods or services."
                            v-model="daysToInspect"
                            :error-messages="validation.requiredField(daysToInspect)"
                        />
                    </div>
                </div>
            </form>
        </div>
        <PartiesFees />
        <TransactionInformation />
        <CreateTxNavButtons :disable-next="!(allowProgress && category)" />
    </div>
</template>

<script>
import { Categories } from '../../../../../../constants/constants-agriculture'
import CreateTxNavButtons from '../../common/create-transaction-navigation'
import PartiesFees from '../../common/parties-fees/index'
import validation from '@/scripts/transaction-create-field-validation'
import TransactionInformation from '../../transaction-information/index'
import TransactionCreateMixin from '../../transaction-create-mixin'

export default {
    name: 'AgricultureDetails',
    components: { TransactionInformation, PartiesFees, CreateTxNavButtons },
    mixins: [TransactionCreateMixin],
    data() {
        return {
            Categories,
            validation,
        }
    },
    computed: {
        feeAllocationOptions() {
            return this.$store.getters['txCreate/feeAllocations']
        },
        userActionText() {
            if (this.$store.state.txCreate.parties.parties[0].role === 'BUYER') {
                return 'buying'
            } else if (this.$store.state.txCreate.parties.parties[0].role === 'SELLER') {
                return 'selling'
            }
            return 'brokering'
        },
        category: {
            get() {
                return this.$store.state.txCreate.transaction.auxiliaryData.agricultureCategory
            },
            set(value) {
                this.$store.commit('txCreate/setTransactionAuxiliaryField', { key: 'agricultureCategory', value })
            },
        },
    },
}
</script>
