<template>
    <div>
        <div class="uk-text-center">
            <h3>Let's Structure The Loads For This Transaction</h3>
        </div>
        <MiningAddress class="uk-margin-large-top" />

        <MiningLoads class="uk-margin-large-top" v-if="showMiningLoads" />

        <TransactionInformation />
        <CreateTxNavButtons :disable-next="!allowProgress" />
    </div>
</template>

<script>
import CreateTxNavButtons from '../../common/create-transaction-navigation'
import MiningLoads from './MiningLoads'
import MiningAddress from './MiningAddress'
import TransactionInformation from '../../transaction-information/index'
import validation from '@/scripts/transaction-create-field-validation'

export default {
    name: 'MiningAndMetals',
    components: {
        TransactionInformation,
        MiningAddress,
        MiningLoads,
        CreateTxNavButtons,
    },
    data() {
        return {
            delivery: null,
            showCalculation: false,
        }
    },
    mounted() {
        this.$store.dispatch('txCreate/transactionCalculation')
    },
    computed: {
        allocations() {
            return this.$store.state.txCreate.allocations.allocations
        },
        milestonesToSameAddress() {
            return this.$store.state.txCreate.transaction.auxiliaryData.milestonesToSameAddress
        },
        editMode() {
            return this.$store.getters['txCreate/editMode']
        },
        showMiningLoads() {
            if (this.editMode) {
                return this.addressDataComplete
            }
            return true
        },
        addressDataComplete() {
            if (this.milestonesToSameAddress) {
                const address = this.$store.state.txCreate.address.address
                return !!(
                    address.id &&
                    address.name &&
                    address.line1 &&
                    address.line2 &&
                    address.city &&
                    address.area &&
                    address.code
                )
            } else if (!this.milestonesToSameAddress) {
                return true
            }
            return false
        },
        allowProgress() {
            if (this.allocations.length < 2) {
                return false
            }
            if (!validation.allocationDetailsComplete()) {
                return false
            }
            if (this.editMode) {
                if (this.milestonesToSameAddress) {
                    return this.addressDataComplete
                } else {
                    for (const address of this.$store.state.txCreate.allocations.addresses) {
                        if (
                            !(
                                address.name &&
                                address.line1 &&
                                address.line2 &&
                                address.area &&
                                address.city &&
                                address.code
                            )
                        ) {
                            return false
                        }
                    }
                }
            }
            return true
        },
        calculationDataReady() {
            return this.$store.getters['txCreate/calculationDataReady']
        },
        calculationDataChanged() {
            return this.$store.getters['txCreate/calculationDataChanged']
        },
    },
    watch: {
        calculationDataChanged() {
            if (this.calculationDataChanged && !this.milestonesToSameAddress) {
                this.$store.commit('txCreate/setCalculation', '__loading__')
                setTimeout(() => this.$store.dispatch('txCreate/transactionCalculation'), 1500)
            }
        },
        calculationDataReady() {
            if (!this.$store.state.txCreate.calculator.dataUsedInPreviousCalculation && this.calculationDataReady) {
                if (!this.milestonesToSameAddress) {
                    this.$store.commit('txCreate/setCalculation', '__loading__')
                    setTimeout(() => this.$store.dispatch('txCreate/transactionCalculation'), 1500)
                }
            }
        },
    },
}
</script>

<style scoped></style>
