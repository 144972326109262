<template>
    <GeneralMilestone />
</template>

<script>
import GeneralMilestone from '../general-milestone/index'
export default {
    name: 'FilmMilestone',
    components: { GeneralMilestone },
}
</script>
