<template>
    <div class="uk-margin-small-top">
        <div class="uk-text-center uk-text-large uk-margin">Load {{ index + 1 }} - Delivery Location</div>
        <div class="uk-grid-small" uk-grid>
            <SearchableSelect
                v-model="fuelZone"
                class="uk-width-1-2@s uk-margin-small-top"
                label="Fuel Zone"
                :search-data="fuelZones"
            />
            <SearchableSelect
                v-model="fuelMagisterialDistrict"
                class="uk-width-1-2@s uk-margin-small-top"
                :class="{
                    'uk-invisible': !magisterialDistricts.length,
                    'uk-animation-slide-right-small': magisterialDistricts.length,
                }"
                label="Magisterial District"
                :search-data="magisterialDistricts"
            />
            <GL-NumberInput :value="0" class="uk-width-1-2 uk-margin-top uk-invisible placeholder" />
            <GL-NumberInput :value="0" class="uk-width-1-2 uk-margin-top uk-invisible placeholder" />
        </div>
        <div class="uk-width-1-1@m uk-text-right uk-margin-top uk-card-footer">
            <GL-Button
                text="previous"
                type="primary-outlined"
                class="uk-margin-small-right"
                @click="$emit('view-previous')"
            />
            <GL-Button
                text="next"
                type="primary"
                @click="$emit('complete-step')"
                disabled-text="next"
                :disabled="disableNext"
            />
        </div>
    </div>
</template>

<script>
import SearchableSelect from '@/modules/common/components/input/searchable-select'
import fuelZones from '../../../../../../../../constants/fuelZones'

export default {
    name: 'MilestoneZone',
    components: { SearchableSelect },
    props: {
        index: {
            type: Number,
            required: true,
        },
    },
    computed: {
        fuelZones() {
            const zones = fuelZones.map(zone => zone.zone)
            return zones.filter((zone, index, self) => {
                return self.indexOf(zone) === index
            })
        },
        magisterialDistricts() {
            const districts = fuelZones.filter(zone => zone.zone === this.fuelZone)
            return districts.map(district => `${district.magisterialDistrict} - ${district.province}`)
        },
        fuelMagisterialDistrict: {
            get() {
                return this.$store.state.txCreate.allocations.auxiliaryData[this.index].fuelMagisterialDistrict
            },
            set(value) {
                this.$store.commit('txCreate/setAllocationAuxiliaryField', {
                    index: this.index,
                    key: 'fuelMagisterialDistrict',
                    value,
                })
            },
        },
        fuelZone: {
            get() {
                return this.$store.state.txCreate.allocations.auxiliaryData[this.index].fuelZone
            },
            set(value) {
                this.$store.commit('txCreate/setAllocationAuxiliaryField', {
                    index: this.index,
                    key: 'fuelZone',
                    value,
                })
            },
        },
        disableNext() {
            return !(this.fuelZone && this.fuelMagisterialDistrict)
        },
    },
}
</script>

<style scoped>
.placeholder {
    height: 64px;
}
</style>
