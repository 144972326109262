export const propertyTypes = [
    {
        key: 'HOUSE',
        value: 'House',
    },
    {
        key: 'APARTMENT_FLAT',
        value: 'Apartment / Flat',
    },
    {
        key: 'TOWNHOUSE',
        value: 'Townhouse',
    },
    {
        key: 'VACANT_LAND',
        value: 'Vacant Land / Plot',
    },
    {
        key: 'FARM',
        value: 'Farm',
    },
    {
        key: 'COMMERCIAL',
        value: 'Commercial property',
    },
    {
        key: 'INDUSTRIAL',
        value: 'Industrial property',
    },
]

export const areaUnits = [
    {
        key: 'SQUARE_METERS',
        value: 'Square Meters',
    },
    {
        key: 'HECTARES',
        value: 'Hectares',
    },
]
