<template>
    <component :is="loadComponent" />
</template>

<script>
import Fuel from './fuel/index'
import Mining from './mining/index'
export default {
    name: 'Loads',
    components: { Fuel, Mining },
    computed: {
        loadComponent() {
            const industry = this.$store.state.txCreate.transaction.transaction.industry
            if (industry) {
                switch (industry) {
                    case 'FUEL':
                        return Fuel
                    case 'MINING':
                        return Mining
                    default:
                        return null
                }
            }
            return null
        },
    },
}
</script>
