<template>
    <div class="uk-card uk-card-default uk-padding-small uk-margin-small">
        <div class="uk-flex uk-flex-left">
            <!-- Avatar -->
            <div>
                <img
                    class="uk-border-circle uk-margin-right animated flipInY avatar"
                    width="48"
                    height="48"
                    :src="null | safeAvatarURL"
                />
            </div>
            <!-- Details -->
            <div class="uk-width-expand">
                <h4 class="uk-margin-small-bottom">
                    {{ party.role | transactionRoleDisplay }}
                </h4>
                <div class="uk-grid">
                    <GL-InfoItem label="Role" :value="party.role | transactionRoleDisplay" />
                    <GL-InfoItem label="Email" :value="party.email" />
                </div>
                <div class="uk-grid uk-margin-small-top uk-margin-bottom">
                    <FeeTypeSelect
                        v-if="!(industry === 'FUEL')"
                        label="Fee Type"
                        v-model="feeType"
                        class="uk-width-1-3@m"
                    />
                    <GL-SelectInput
                        v-else
                        label="Fee Type"
                        :option-list="feeOptions"
                        v-model="feeType"
                        class="uk-width-1-3@m"
                    />
                    <GL-NumberInput
                        v-if="feeType !== 'FLAT'"
                        label="Amount Payable"
                        name="party-fee"
                        v-model="fee"
                        step="0.01"
                        right-align
                        class="uk-width-1-3@m"
                        :append="appendIcon"
                    />
                    <GL-CurrencyInput
                        v-if="feeType === 'FLAT'"
                        label="Amount Payable"
                        name="party-fee"
                        v-model="fee"
                        right-align
                        class="uk-width-1-3@m"
                    />
                    <GL-SelectInput
                        v-if="allocationEnabled"
                        v-model="feeAllocation"
                        name="party-fee-allocation"
                        :option-list="feeAllocationOptions"
                        label="Which Party Absorbs This Fee?"
                        class="uk-width-1-3@m"
                    />
                    <GL-SelectInput
                        v-if="!allocationEnabled"
                        :value="'SELLER'"
                        :option-list="allocationOption"
                        label="Which Party Absorbs This Fee?"
                        class="uk-width-1-3@m"
                        :disabled="true"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FeeTypeSelect from './fee-type-select'
export default {
    name: 'PartiesCard',
    components: { FeeTypeSelect },
    props: {
        index: {
            type: Number,
        },
    },
    data() {
        return {
            feeOptions: [
                {
                    key: 'PER_UNIT',
                    value: 'Cents per litre',
                },
                {
                    key: 'PERCENT',
                    value: 'Percentage of the transaction',
                },
                {
                    key: 'FLAT',
                    value: 'Fixed Fee',
                },
            ],
        }
    },
    computed: {
        allocationOption() {
            if (this.industry === 'FUEL' || this.industry === 'MINING') {
                return [
                    {
                        key: 'SELLER',
                        value: 'Supplier',
                    },
                ]
            }
            return [
                {
                    key: 'SELLER',
                    value: 'Seller',
                },
            ]
        },
        allocationEnabled() {
            return this.party.role !== 'AGENT'
        },
        feeAllocationOptions() {
            return this.$store.getters['txCreate/feeAllocations']
        },
        party() {
            return this.$store.state.txCreate.parties.parties[this.index]
        },

        appendIcon() {
            if (this.feeType === 'PERCENT') {
                return '%'
            } else if (this.feeType === 'PER_UNIT' && this.industry === 'FUEL') {
                return 'c/l'
            }
            return ''
        },
        fee: {
            get() {
                if (this.feeType === 'PER_UNIT' && this.industry === 'FUEL') {
                    return this.$store.state.txCreate.parties.parties[this.index].fee * 100
                }
                return this.$store.state.txCreate.parties.parties[this.index].fee
            },
            set(v) {
                if (this.feeType === 'PER_UNIT' && this.industry === 'FUEL') {
                    this.$store.commit('txCreate/setPartyField', { index: this.index, key: 'fee', value: v / 100 })
                } else {
                    this.$store.commit('txCreate/setPartyField', { index: this.index, key: 'fee', value: v })
                }
            },
        },
        feeAllocation: {
            get() {
                return this.$store.state.txCreate.parties.parties[this.index].feeAllocation
            },
            set(v) {
                this.$store.commit('txCreate/setPartyField', { index: this.index, key: 'feeAllocation', value: v })
            },
        },
        feeType: {
            get() {
                return this.$store.state.txCreate.parties.parties[this.index].feeType
            },
            set(v) {
                this.$store.commit('txCreate/setPartyField', { index: this.index, key: 'feeType', value: v })
            },
        },
        industry() {
            return this.$store.state.txCreate.transaction.transaction.industry
        },
    },
}
</script>
