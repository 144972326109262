<template>
    <div>
        <Documents :industry="$store.state.txCreate.transaction.transaction.industry" />
        <CreateTxNavButtons :disable-next="$store.state.txCreate.documents.loading" />
    </div>
</template>

<script>
import Documents from './documents'
import CreateTxNavButtons from '../common/create-transaction-navigation'

export default {
    name: 'DocumentsTransactionCreate',
    components: { CreateTxNavButtons, Documents },
}
</script>
