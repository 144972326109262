<template>
    <div class="uk-text-center uk-padding">
        <div>
            <h3 class="uk-text-secondary">Is This a Delivery or Collection Order?</h3>
        </div>

        <div class="uk-flex uk-flex-center uk-flex-wrap">
            <div class="fix-width">
                <CardButton
                    class="selectable-card"
                    text="COD"
                    icon-path="truck-solid.svg"
                    :selected="fuelTransactionType === 'COD'"
                    @select="fuelTransactionType = 'COD'"
                />
            </div>
            <div class="fix-width">
                <CardButton
                    text="COC"
                    class="selectable-card"
                    icon-path="industry-solid.svg"
                    :selected="fuelTransactionType === 'COC'"
                    @select="fuelTransactionType = 'COC'"
                />
            </div>
        </div>
    </div>
</template>

<script>
import CardButton from '@/modules/common/components/input/card-button'
export default {
    name: 'FuelOrderType',
    components: { CardButton },
    computed: {
        fuelTransactionType: {
            get() {
                return this.$store.state.txCreate.transaction.auxiliaryData.fuelTransactionType
            },
            set(value) {
                this.$store.commit('txCreate/setTransactionAuxiliaryField', { key: 'fuelTransactionType', value })
            },
        },
    },
    watch: {
        fuelTransactionType() {
            if (this.fuelTransactionType === 'COD') {
                this.$store.commit('txCreate/setTransactionAuxiliaryField', {
                    key: 'fuelUpliftmentLocation',
                    value: '',
                })
            } else if (this.fuelTransactionType === 'COC') {
                this.$store.commit('txCreate/setTransactionAuxiliaryField', {
                    key: 'fuelMagisterialDistrict',
                    value: '',
                })
                this.$store.commit('txCreate/setTransactionAuxiliaryField', {
                    key: 'fuelZone',
                    value: '',
                })
            }
        },
    },
}
</script>
<style scoped>
.selectable-card {
    margin: 0.5rem;
}
</style>
