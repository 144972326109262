<template>
    <div>
        <FuelOrderType v-if="!hasMilestones" />
        <component :is="addressComponent" />
        <CreateTxNavButtons :disable-next="!allowProgress" />
    </div>
</template>

<script>
import CODFuelDeliveryAddress from './cod-fuel-delivery-address'
import COCFuelDeliveryAddress from './coc-fuel-delivery-address'
import CreateTxNavButtons from '../../common/create-transaction-navigation'
import FuelOrderType from './fuel-order-type'

export default {
    name: 'FuelDelivery',
    components: { CODFuelDeliveryAddress, COCFuelDeliveryAddress, CreateTxNavButtons, FuelOrderType },
    mounted() {
        this.$store.commit('txCreate/deliveryAddressRequired', true)
    },
    computed: {
        fuelTransactionType() {
            return this.$store.state.txCreate.transaction.auxiliaryData.fuelTransactionType
        },
        hasMilestones() {
            return this.$store.state.txCreate.allocations.allocations.length > 1
        },
        addressComponent() {
            if (this.fuelTransactionType === 'COD') {
                return 'CODFuelDeliveryAddress'
            } else if (this.fuelTransactionType === 'COC') {
                return 'COCFuelDeliveryAddress'
            }
            return null
        },
        addressDataComplete() {
            return (
                this.$store.state.txCreate.address.address.name &&
                this.$store.state.txCreate.address.address.line1 &&
                this.$store.state.txCreate.address.address.line2 &&
                this.$store.state.txCreate.address.address.city &&
                this.$store.state.txCreate.address.address.area &&
                this.$store.state.txCreate.address.address.code
            )
        },
        allowProgress() {
            if (!this.fuelTransactionType) {
                return false
            }
            if (this.fuelTransactionType === 'COD') {
                if (
                    !(
                        this.$store.state.txCreate.transaction.auxiliaryData.fuelZone &&
                        this.$store.state.txCreate.transaction.auxiliaryData.fuelMagisterialDistrict
                    )
                )
                    return false
                return this.addressDataComplete
            }
            if (this.fuelTransactionType === 'COC') {
                if (this.hasMilestones) {
                    if (!this.$store.state.txCreate.transaction.auxiliaryData.fuelUpliftmentLocation) {
                        return false
                    }
                } else {
                    if (
                        !(
                            this.$store.state.txCreate.transaction.auxiliaryData.fuelUpliftmentLocation &&
                            this.$store.state.txCreate.transaction.auxiliaryData.fuelVehicleRegistration &&
                            this.$store.state.txCreate.transaction.auxiliaryData.fuelDriverName
                        )
                    ) {
                        return false
                    }
                }
                if (
                    this.$store.state.txCreate.transaction.auxiliaryData.fuelUpliftmentLocation === 'Mini Depot' ||
                    this.$store.state.txCreate.transaction.auxiliaryData.fuelUpliftmentLocation === 'Private Depot'
                ) {
                    return this.addressDataComplete
                }
            }
            return true
        },
    },
}
</script>
