import api from '@/scripts/api/api'
import rootStore from '@/store/index'

function filterFileType(type, fileList) {
    const result = []
    for (const file of fileList) {
        const metadata = JSON.parse(file.metadata)
        if (metadata.type === type) {
            result.push(file)
        }
    }
    return result
}

const user_schema = {
    profilePicture: null,
    id: '',
    details: {
        givenName: '',
        familyName: '',
        idType: '',
        idCountry: '',
        idNumber: '',
        email: '',
        mobile: '',
        createdAt: 'April 01, 2016',
    },
    documents: {
        passportFiles: null,
        passportFilesUploaded: null,
    },
    avatar: {
        avatarFile: null,
        avatarURL: '',
    },
    status: {
        emailVerified: false,
        mobileVerified: false,
        verified: false,
        complete: false,
        hasActiveTransactions: false,
    },
    banking: {
        bank: '',
        bankName: '',
        branchCode: '',
        accountNumber: '',
        accountType: '',
        verified: false,
    },
    addressList: [],
    address: {
        id: '',
        name: '',
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        city: '',
        area: '',
        code: '',
    },
    password: {
        oldPassword: '',
        newPassword: '',
        newPasswordConfirm: '',
    },
    settings: {
        isPrivate: false,
        creditAccount: false,
        seenTour: {
            home: false,
            profile: false,
            create: false,
        },
        payout: {
            interval: '',
            refund: '',
        },
    },
}

export default {
    namespaced: true,
    state: {
        fetchedUser: '__loading__',
        user: '__loading__',
    },
    mutations: {
        setOTP(state, payload) {
            state.otp = payload
        },
        setUserField(state, payload) {
            const keyList = payload.key.split('|')
            let obj = state.user
            let key = payload.key
            for (let i = 0; i < keyList.length; i++) {
                key = keyList[i]
                if (i < keyList.length - 1) {
                    obj = obj[key]
                }
            }
            obj[key] = payload.value
        },
        setUserFetched(state, payload) {
            state.fetchedUser = payload
            state.user = JSON.parse(JSON.stringify(user_schema))

            state.user.id = payload.id
            state.user.details.givenName = payload.givenName
            state.user.details.familyName = payload.familyName
            state.user.details.idType = payload.idType
            state.user.details.idCountry = payload.idCountry
            state.user.details.idNumber = payload.idNumber
            state.user.details.mobile = payload.mobile
            state.user.details.email = payload.email
            state.user.details.createdAt = payload.createdAt
            state.user.avatar.avatarURL = payload.avatar

            if (payload.status) {
                state.user.status.emailVerified = payload.status.emailVerified
                state.user.status.mobileVerified = payload.status.mobileVerified
                state.user.status.verified = payload.status.verified
                state.user.status.complete = payload.status.complete
                state.user.status.hasActiveTransactions = payload.status.hasActiveTransactions
            }

            if (payload.bankAccount) {
                state.user.banking.bank = payload.bankAccount.bank
                state.user.banking.bankName = payload.bankAccount.bankName
                state.user.banking.branchCode = payload.bankAccount.branchCode
                state.user.banking.accountNumber = payload.bankAccount.accountNumber
                state.user.banking.accountType = payload.bankAccount.accountType
                state.user.banking.verified = payload.bankAccount.verified
            }

            if (payload.addresses) {
                state.user.addressList = payload.addresses
            }

            if (payload.settings) {
                state.user.settings.isPrivate = payload.settings.private
                state.user.settings.creditAccount = payload.settings.creditAccount
                state.user.settings.seenTour = payload.settings.seenTour
                state.user.settings.consent = payload.settings.consent

                if (payload.settings.payout) {
                    state.user.settings.payout.interval = payload.settings.payout.interval
                    state.user.settings.payout.refund = payload.settings.payout.refund
                }
            }

            if (payload.files) {
                state.user.documents.passportFilesUploaded = filterFileType('passport', payload.files)
            }
        },
        setUserLoading(state) {
            state.user = '__loading__'
        },
    },
    actions: {
        signOut() {
            api.user.signOut()
        },
        async userFetch() {
            const user = await api.user.userFetch()
            this.commit('user/setUserFetched', user)
        },
        async userRefetch() {
            const user = await api.user.userFetch()
            this.commit('user/setUserFetched', user)
        },
        async userUpdate(store) {
            if (store.state.user.details.idType === 'NATIONAL') {
                store.commit('setUserField', { key: 'details|idCountry', value: 'ZAF' })
            }
            // TODO: This is ugly, refactor needed
            await api.user.userUpdate({
                givenName:
                    store.state.user.details.givenName !== store.state.fetchedUser.givenName
                        ? store.state.user.details.givenName
                        : undefined,
                familyName:
                    store.state.user.details.familyName !== store.state.fetchedUser.familyName
                        ? store.state.user.details.familyName
                        : undefined,
                email:
                    store.state.user.details.email !== store.state.fetchedUser.email
                        ? store.state.user.details.email
                        : undefined,
                mobile:
                    store.state.user.details.mobile !== store.state.fetchedUser.mobile
                        ? store.state.user.details.mobile
                        : undefined,
                idType: store.state.user.details.idType,
                idCountry: store.state.user.details.idCountry,
                idNumber: store.state.user.details.idNumber,
            })

            if (store.state.user.details.idType === 'PASSPORT' && store.state.user.documents.passportFiles) {
                for (const file of store.state.user.documents.passportFiles) {
                    await api.user.userFileCreate({
                        file: file,
                        metadata: JSON.stringify({
                            type: 'passport',
                        }),
                    })
                }
            }
        },
        async userFileDelete(store, payload) {
            await api.user.userFileDelete({
                id: payload.id,
            })
        },
        async userAvatarCreate(store, payload) {
            await api.user.userAvatarCreate({
                image: payload.file,
            })
            await rootStore.dispatch('user/userRefetch')
            await rootStore.dispatch('context/contextFetch')
        },
        async userPasswordUpdate(store) {
            await api.user.userUpdatePassword({
                oldPassword: store.state.user.password.oldPassword,
                password: store.state.user.password.newPassword,
                passwordConfirmation: store.state.user.password.newPasswordConfirm,
            })
        },
        async userSettingsUpdate(store) {
            await api.user.userUpdateSettings({
                private: store.state.user.settings.isPrivate,
                creditAccount: store.state.user.settings.creditAccount,
                tourHome: store.state.user.settings.seenTour.home,
                tourProfile: store.state.user.settings.seenTour.profile,
                tourCreate: store.state.user.settings.seenTour.create,
                payout: {
                    interval: store.state.user.settings.payout.interval,
                    refund: store.state.user.settings.payout.refund,
                },
            })
        },
        async userSyncUserinfo() {
            await api.user.syncUserInfo()
            await rootStore.dispatch('user/userRefetch')
            await rootStore.dispatch('context/contextFetch')
        },
        async userDelete() {
            await api.user.userDelete()
            api.user.signOut()
        },
        async bankingUpdate(store) {
            if (store.state.user.banking.bank === 'OTHER') {
                await api.user.userBankAccountCreate({
                    bank: store.state.user.banking.bank,
                    bankName: store.state.user.banking.bankName,
                    branchCode: store.state.user.banking.branchCode,
                    accountNumber: store.state.user.banking.accountNumber,
                    accountType: store.state.user.banking.accountType,
                })
            } else {
                await api.user.userBankAccountCreate({
                    bank: store.state.user.banking.bank,
                    accountNumber: store.state.user.banking.accountNumber,
                    accountType: store.state.user.banking.accountType,
                })
            }
        },
        async bankingVerify() {
            await api.user.userBankAccountVerify()
        },
        async addressCreate(store) {
            await api.user.userAddressCreate({
                name: store.state.user.address.name,
                line1: store.state.user.address.addressLine1,
                line2: store.state.user.address.addressLine2,
                line3: store.state.user.address.addressLine3,
                city: store.state.user.address.city,
                area: store.state.user.address.area,
                code: store.state.user.address.code,
                country: 'ZAF',
            })
        },
        async addressDelete(store, payload) {
            await api.user.userAddressDelete({
                id: payload.id,
            })
        },
        async addressUpdate(store) {
            await api.user.userAddressUpdate({
                id: store.state.user.address.id,
                address: {
                    name: store.state.user.address.name,
                    line1: store.state.user.address.addressLine1,
                    line2: store.state.user.address.addressLine2,
                    line3: store.state.user.address.addressLine3,
                    city: store.state.user.address.city,
                    area: store.state.user.address.area,
                    code: store.state.user.address.code,
                    country: 'ZAF',
                },
            })
        },
        async resendEmail() {
            await api.user.userResendEmail()
        },
        async sendMobileOTP() {
            await api.user.userSendMobileOTP()
        },
        async verifyMobileOTP(store, payload) {
            await api.user.userVerifyMobileOTP({
                code: payload.code,
            })
        },
        async setConsent(store, payload) {
            await api.user.userConsentUpdate({
                terms: payload.terms,
                age: payload.age,
                local: payload.local,
            })
            await rootStore.dispatch('user/userRefetch')
        },
    },
    getters: {
        user: state => {
            return state.user
        },
        detailsComplete: state => {
            if (!state.fetchedUser.idCountry) {
                return false
            }
            if (!state.fetchedUser.idNumber) {
                return false
            }
            if (!state.fetchedUser.idType) {
                return false
            }
            if (!state.fetchedUser.mobile) {
                return false
            }
            return true
        },
        detailsChanged: state => {
            if (
                state.user.documents.passportFiles !== null ||
                state.user.details.givenName !== state.fetchedUser.givenName ||
                state.user.details.familyName !== state.fetchedUser.familyName ||
                state.user.details.idType !== state.fetchedUser.idType ||
                state.user.details.idCountry !== state.fetchedUser.idCountry ||
                state.user.details.idNumber !== state.fetchedUser.idNumber ||
                state.user.details.email !== state.fetchedUser.email ||
                state.user.details.mobile !== state.fetchedUser.mobile
            ) {
                return true
            }
            return false
        },
        bankingChanged: state => {
            if (!state.fetchedUser.bankAccount) {
                return true
            }
            if (
                state.user.banking.accountNumber !== state.fetchedUser.bankAccount.accountNumber ||
                state.user.banking.accountType !== state.fetchedUser.bankAccount.accountType ||
                state.user.banking.bank !== state.fetchedUser.bankAccount.bank ||
                state.user.banking.bankName !== state.fetchedUser.bankAccount.bankName ||
                state.user.banking.branchCode !== state.fetchedUser.bankAccount.branchCode
            ) {
                return true
            }
            return false
        },
    },
}
