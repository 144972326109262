import api from '@/scripts/api/api'
import {
    findElementsToDelete,
    cleanObjsForTransactionUpdateInPlace,
    findAllocationsToCreate,
    findAllocationsToUpdate,
} from '@/scripts/transaction-create-helpers'

const address_schema = {
    id: '',
    name: '',
    line1: '',
    line2: '',
    city: '',
    area: '',
    code: '',
    country: 'ZAF',
}

const auxiliary_data_schema = {
    fuelZone: '',
    fuelMagisterialDistrict: '',
    fuelUpliftmentLocation: '',
    fuelVehicleRegistration: '',
    fuelDriverName: '',
}

const allocation_schema = {
    id: '',
    title: '',
    description: '',
    deliveryInstructions: '',
    value: 0,
    units: 0,
    unitCost: 0,
    daysToDeliver: 0,
    daysToInspect: 0,
}

export default {
    state: {
        // The allocations and addresses array are to be kept in sequence.
        // Code expects the index of an address to be for the same index of an allocation
        allocations: [{ ...allocation_schema }],
        addresses: [{ ...address_schema }],
        auxiliaryData: [{ ...auxiliary_data_schema }],
        fuelTransactionType: '',
    },
    getters: {
        transactionValue: state => {
            return state.allocations.reduce((acc, alloc) => acc + alloc.value, 0)
        },
        transactionValueUnitBased: state => {
            return state.allocations.reduce((acc, alloc) => acc + alloc.units * alloc.unitCost, 0)
        },
        transactionUnits: state => {
            return state.allocations.reduce((acc, alloc) => acc + alloc.units, 0)
        },
        savedAllocations: (state, getters, rootState) => {
            return rootState.transaction.transaction.allocations
        },
        allocationsToUpdate: (state, getters) => {
            const toUpdate = findAllocationsToUpdate(
                state.allocations,
                state.auxiliaryData,
                state.addresses,
                getters['savedAllocations'],
                allocation_schema,
                auxiliary_data_schema,
            )
            return toUpdate.map(
                ({
                    id,
                    title,
                    description,
                    value,
                    units,
                    unitCost,
                    daysToDeliver,
                    daysToInspect,
                    auxiliaryData,
                    addressId,
                    deliveryInstructions,
                }) => ({
                    id,
                    addressId,
                    auxiliaryData,
                    title,
                    description,
                    value,
                    units,
                    unitCost,
                    daysToDeliver,
                    daysToInspect,
                    deliveryInstructions: JSON.stringify({ deliveryInstructions }),
                }),
            )
        },
        allocationsToCreate: state => {
            return findAllocationsToCreate(state.allocations, state.auxiliaryData, state.addresses)
        },
        allocationsToDelete: (state, getters) => {
            return findElementsToDelete(state.allocations, getters['savedAllocations'])
        },
    },
    mutations: {
        addAllocation(state) {
            state.allocations.push({ ...allocation_schema })
            state.auxiliaryData.push({ ...auxiliary_data_schema })
            state.addresses.push({ ...address_schema })
        },
        removeAllocation(state, index) {
            state.allocations = state.allocations.filter((item, i) => i !== index)
            state.auxiliaryData = state.auxiliaryData.filter((item, i) => i !== index)
            state.addresses = state.addresses.filter((item, i) => i !== index)
        },
        removeExtraAllocations(state) {
            state.allocations = state.allocations.filter((item, i) => i === 0)
            state.auxiliaryData = state.auxiliaryData.filter((item, i) => i === 0)
            state.addresses = state.addresses.filter((item, i) => i === 0)
        },
        setAllocationField(state, payload) {
            state.allocations[payload.index][payload.key] = payload.value
        },
        setAllocationAuxiliaryField(state, payload) {
            state.auxiliaryData[payload.index][payload.key] = payload.value
        },
        deepSetAllocationAuxiliaryField(state, payload) {
            state.auxiliaryData.forEach(aux => (aux[payload.key] = payload.value))
        },
        setAllocationAddressField(state, payload) {
            state.addresses[payload.index][payload.key] = payload.value
        },
        initAllocationAddress(state, payload) {
            console.log(payload)
            for (const key in address_schema) {
                state.addresses[payload.index][key] = payload.address[key]
            }
        },
    },
    actions: {
        initAllocations({ state, getters }) {
            const transaction = getters['fetchedTransaction']
            if (transaction === '__loading__' || !transaction.allocations.length) {
                state.allocations = [{ ...allocation_schema }]
                state.addresses = [{ ...address_schema }]
                state.auxiliaryData = [{ ...auxiliary_data_schema }]
            } else {
                state.allocations = []
                state.addresses = []
                state.auxiliaryData = []

                for (const allocation of transaction.allocations) {
                    const { auxiliaryData, address, deliveryInstructions, ...savedAllocation } = allocation

                    if (address) {
                        state.addresses.push({ ...address })
                    } else {
                        state.addresses.push({ ...address_schema })
                    }

                    const stateAux = { ...auxiliary_data_schema }
                    if (auxiliaryData) {
                        const savedAuxData = JSON.parse(auxiliaryData)
                        for (const auxKey in auxiliary_data_schema) {
                            if (savedAuxData[auxKey]) {
                                stateAux[auxKey] = savedAuxData[auxKey]
                            } else {
                                stateAux[auxKey] = ''
                            }
                        }
                    }
                    state.auxiliaryData.push(stateAux)

                    let instructions
                    try {
                        instructions = JSON.parse(deliveryInstructions).deliveryInstructions
                    } catch {
                        instructions = ''
                    }

                    state.allocations.push({
                        ...savedAllocation,
                        deliveryInstructions: instructions,
                    })
                }
            }
        },

        async saveAllocations({ getters, dispatch }) {
            const transaction = getters['fetchedTransaction']

            if (transaction !== '__loading__') {
                const allocations = {
                    create: JSON.parse(JSON.stringify(getters['allocationsToCreate'])),
                    update: JSON.parse(JSON.stringify(getters['allocationsToUpdate'])),
                    delete: JSON.parse(JSON.stringify(getters['allocationsToDelete'])),
                }

                console.log(allocations)
                cleanObjsForTransactionUpdateInPlace(allocations)

                if (Object.keys(allocations).length) {
                    await api.transaction.create.transactionUpdate({
                        id: transaction.id,
                        allocations,
                    })
                    await dispatch('transaction/transactionRefetch', null, { root: true })
                    dispatch('initializeState')
                }
            }
        },

        async saveAllocationAddresses({ rootState, state, dispatch }) {
            console.log('Saving Allocation Addresses')
            console.log('Before save', state.addresses)
            let addressDataUpdated = false
            for (const address of state.addresses) {
                if (address.id === 'NEW') {
                    console.log('About to create a new address', address)
                    const { name, line1, line2, area, code, city } = address
                    const newAddress = await api.user.userAddressCreate({
                        name,
                        line1,
                        line2,
                        area,
                        code,
                        city,
                        country: 'ZAF',
                    })
                    addressDataUpdated = true
                    address.id = newAddress.id
                } else if (address.id) {
                    const originalAddress = rootState.user.fetchedUser.addresses.find(({ id }) => id === address.id)
                    for (const key of Object.keys(address_schema)) {
                        if (address[key] !== originalAddress[key]) {
                            const { name, line1, line2, area, code, city, id } = address
                            await api.user.userAddressUpdate({
                                id,
                                address: {
                                    name,
                                    line1,
                                    line2,
                                    area,
                                    code,
                                    city,
                                    country: 'ZAF',
                                },
                            })
                            addressDataUpdated = true
                            break
                        }
                    }
                }
            }
            if (addressDataUpdated) {
                await dispatch('user/userFetch', {}, { root: true })
            }
        },
    },
}
