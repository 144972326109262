<template>
    <div>
        <div
            class="uk-grid-collapse uk-child-width-expand@s uk-text-center uk-flex-nowrap stepper"
            :hidden="hideSteps"
            uk-grid
        >
            <div
                v-for="(tab, index) in tabs"
                :key="tab.name"
                class="stepper-tab"
                @click="select(index + 1)"
                :class="[
                    {
                        completed: index < completed,
                        active: index === completed,
                        viewing: index === step - 1,
                        success: success,
                        selectable: selectableSteps,
                    },
                    tab.tourStep,
                ]"
            >
                <div class="stepper-tab-content uk-flex uk-flex-center uk-flex-middle">
                    <div v-if="index < completed" class="stepper-tab-step">
                        <img alt="completed check" src="/icons/check.svg" :size="1" uk-svg />
                    </div>
                    <div v-else class="stepper-tab-step uk-text-large">
                        {{ index + 1 }}
                    </div>
                    <div class="stepper-tab-name uk-visible@m">
                        <span class="uk-margin-left">
                            {{ tab.name }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'Stepper',
    props: {
        step: {
            type: Number,
            required: true,
        },
        selectableSteps: {
            type: Boolean,
            default: true,
        },
        completed: {
            type: Number,
            required: true,
        },
        success: {
            type: Boolean,
            default: false,
        },
        hideSteps: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            tabs: null,
        }
    },
    watch: {
        step() {
            this.redraw()
        },
    },
    mounted() {
        this.tabs = this.$children
        this.redraw()
    },
    methods: {
        select(step) {
            if (this.selectableSteps) {
                if (step <= this.completed + 1) {
                    this.$emit('select', step)
                }
            }
        },
        redraw() {
            this.tabs.forEach(tab => tab.hide())
            this.$nextTick(() => this.tabs[this.step - 1].show())
        },
    },
}
</script>
