<template>
    <div class="uk-margin-small-top">
        <div class="uk-text-center uk-text-large uk-margin">Load {{ index + 1 }} - Details</div>
        <div class="uk-grid-small" uk-grid>
            <GL-TextInput
                class="uk-width-1-1"
                label="load title"
                v-model="title"
                tooltip="Give this load a name. For example, delivery of the first 4 000 tonnes of thermal coal per total 10 000 tonnes order etc."
            />
            <GL-TextAreaInput
                class="uk-width-1-1 uk-margin-small-top"
                label="Load Description"
                tooltip="Give this load a detailed description. Provide as much details as possible including the event that needs to take place in order for the funds to be released. This can include the customer’s receipt of the Delivery Note (DN) or metallurgical assay certificate etc."
                v-model="description"
            />
            <GL-NumberInput
                class="uk-width-1-2@s uk-margin-small-top"
                v-model="units"
                right-align
                :label="massUnits.label"
            />
            <GL-CurrencyInput class="uk-width-1-2@s uk-margin-small-top" v-model="unitCost" :label="massUnits.price" />
            <GL-NumberInput
                class="uk-width-1-4@s uk-margin-small-top"
                label="Delivery Days"
                tooltip="Delivery days is the expected duration of the delivery of the product."
                right-align
                v-model="daysToDeliver"
            />
            <GL-NumberInput
                class="uk-width-1-4@s uk-margin-small-top"
                label="Inspection Days"
                tooltip="Inspection days is the expected number of days the customer has to inspect the product."
                right-align
                v-model="daysToInspect"
            />
            <div class="uk-width-1-2@m" />
        </div>
        <div class="uk-width-1-1@m uk-text-right uk-margin-top uk-card-footer">
            <GL-Button
                text="next"
                type="primary"
                @click="$emit('complete-step')"
                disabled-text="next"
                :disabled="!allowProgress"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'MiningMilestoneDetails',
    props: {
        index: {
            type: Number,
            required: true,
        },
    },
    computed: {
        title: {
            get() {
                return this.$store.state.txCreate.allocations.allocations[this.index].title
            },
            set(v) {
                this.$store.commit('txCreate/setAllocationField', { index: this.index, key: 'title', value: v })
            },
        },
        description: {
            get() {
                return this.$store.state.txCreate.allocations.allocations[this.index].description
            },
            set(v) {
                this.$store.commit('txCreate/setAllocationField', { index: this.index, key: 'description', value: v })
            },
        },
        units: {
            get() {
                return this.$store.state.txCreate.allocations.allocations[this.index].units
            },
            set(value) {
                this.$store.commit('txCreate/setAllocationField', {
                    index: this.index,
                    key: 'units',
                    value,
                })
            },
        },
        unitCost: {
            get() {
                return this.$store.state.txCreate.allocations.allocations[this.index].unitCost
            },
            set(value) {
                this.$store.commit('txCreate/setAllocationField', {
                    index: this.index,
                    key: 'unitCost',
                    value,
                })
            },
        },
        daysToInspect: {
            get() {
                return this.$store.state.txCreate.allocations.allocations[this.index].daysToInspect
            },
            set(value) {
                this.$store.commit('txCreate/setAllocationField', {
                    index: this.index,
                    key: 'daysToInspect',
                    value,
                })
            },
        },
        daysToDeliver: {
            get() {
                return this.$store.state.txCreate.allocations.allocations[this.index].daysToDeliver
            },
            set(value) {
                this.$store.commit('txCreate/setAllocationField', {
                    index: this.index,
                    key: 'daysToDeliver',
                    value,
                })
            },
        },
        commodity() {
            return this.$store.state.txCreate.transaction.auxiliaryData.miningCommodity
        },
        commodityType() {
            return this.$store.state.txCreate.transaction.auxiliaryData.miningCommodityType
        },
        massUnits() {
            if (this.commodityType === 'PRECIOUS') {
                if (this.commodity === 'DIAMONDS') {
                    return {
                        label: 'Carats',
                        price: 'Price Per Carat',
                    }
                }
                return {
                    label: 'Troy Ounces',
                    price: 'Price Per Troy Ounce',
                }
            }
            return {
                label: 'Tonnage',
                price: 'Price Per Ton',
            }
        },
        allowProgress() {
            return !!(
                this.units &&
                this.unitCost &&
                this.daysToDeliver &&
                this.daysToInspect &&
                this.title &&
                this.description
            )
        },
    },
}
</script>
