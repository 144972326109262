<template>
    <span>
        <img :style="getSize" :src="'/icons/' + path" uk-svg />
    </span>
</template>

<script>
export default {
    name: 'GL-Icon',
    props: {
        path: {
            type: String,
            default: '',
        },
        size: {
            type: Number,
            default: 1.5,
        },
    },
    computed: {
        getSize: function () {
            return 'width:' + this.size.toString() + 'rem; height:' + this.size.toString() + 'rem'
        },
    },
}
</script>
