import api from '@/scripts/api/api'
import router from '@/router'
import { cleanObjsForTransactionUpdateInPlace, prepareAuxiliaryData } from '@/scripts/transaction-create-helpers'

// This schema should only include values applicable to the details pages
// excluding allocations as they are added at create and update
const transaction_schema = {
    title: '',
    description: '',
    industry: '',
    feeAllocation: '',
    privacy: 'NONE',
    workflow: null,
}

const auxiliary_data_schema = {
    milestonesToSameAddress: true,
    fuelProduct: '',
    fuelProductDescription: '',
    fuelTransactionType: '',
    fuelZone: '',
    fuelMagisterialDistrict: '',
    fuelUpliftmentLocation: '',
    fuelVehicleRegistration: '',
    fuelDriverName: '',
    miningCommodityType: '',
    miningCommodity: '',
    miningCommodityDescription: '',
    vehicleType: '',
    vehicleMake: '',
    vehicleModel: '',
    vehicleYear: 0,
    vehicleKilometers: 0,
    vehicleAirframeHours: 0,
    vehicleEngineHours: 0,
    vehicleVIN: '',
    electronicsType: '',
    electronicsMake: '',
    // If make is set to other
    electronicsSpecifiedMake: '',
    electronicsModel: '',
    electronicsMemory: '',
    electronicsMemoryUnits: '',
    electronicsYear: 0,
    domains: '',
    propertyType: '',
    propertyStreet: '',
    propertySuburb: '',
    propertyCity: '',
    propertyProvince: '',
    propertyAreaUnits: '',
    propertyArea: 0,
    agricultureCategory: '',
}

function updatedTransactionValues(workingTransaction, savedTransaction) {
    const updated = {}
    for (const key in transaction_schema) {
        if (workingTransaction[key] !== savedTransaction[key]) {
            updated[key] = workingTransaction[key]
        }
    }
    if (Object.keys(updated).length) {
        return updated
    }
    return null
}

function auxiliaryDataUpdated(workingAuxData, savedAuxData) {
    for (const key in auxiliary_data_schema) {
        if (key === 'domains' && workingAuxData.domains) {
            if (!savedAuxData.domains || workingAuxData.domains.length !== savedAuxData.domains.length) {
                return true
            }
            for (const domain of workingAuxData.domains) {
                if (!savedAuxData.domains.includes(domain)) {
                    return true
                }
            }
        } else if (key === 'milestonesToSameAddress' && workingAuxData[key] !== savedAuxData[key]) {
            return true
        } else if (workingAuxData[key] && savedAuxData[key] && workingAuxData[key] !== savedAuxData[key]) {
            return true
        } else if (workingAuxData[key] && !savedAuxData[key]) {
            return true
        } else if (!workingAuxData[key] && savedAuxData[key]) {
            return true
        }
    }
    return false
}

export default {
    state: {
        transaction: { ...transaction_schema },
        auxiliaryData: { ...auxiliary_data_schema },
    },
    mutations: {
        setTransactionField(state, payload) {
            state.transaction[payload.key] = payload.value
        },
        setTransactionAuxiliaryField(state, payload) {
            state.auxiliaryData[payload.key] = payload.value
        },
        setAuxiliaryFieldArrayValue(state, payload) {
            state.auxiliaryData[payload.array][payload.index] = payload.value
            state.auxiliaryData[payload.array] = state.auxiliaryData[payload.array].map((elem, index) => {
                if (index === payload.index) {
                    return payload.value
                }
                return elem
            })
        },
        deleteAuxiliaryFieldArrayElement(state, payload) {
            state.auxiliaryData[payload.array] = state.auxiliaryData[payload.array].filter(
                (elem, i) => i !== payload.index,
            )
        },
        addAuxiliaryFieldArrayElement(state, payload) {
            state.auxiliaryData[payload.array].push('')
        },
    },
    getters: {
        milestoneTransaction: (state, getters, rootState) => {
            if (state.transaction.workflow === 'MILESTONE') {
                return true
            }
            if (!rootState.txCreate.allocations.allocations || rootState.txCreate.allocations.allocations.length > 1) {
                return true
            }
            return false
        },
        updateTransactionData: (state, getters) => {
            const savedTransaction = getters['fetchedTransaction']

            const updatedTransactionData = updatedTransactionValues(state.transaction, savedTransaction)
            let updatedAuxData = null
            let updatedAllocationData = null
            let updatedPartyData = null

            if (auxiliaryDataUpdated(state.auxiliaryData, JSON.parse(savedTransaction.auxiliaryData))) {
                console.log('Auxiliary date updated')
                updatedAuxData = prepareAuxiliaryData(state.auxiliaryData)
            }

            const allocations = {
                create: JSON.parse(JSON.stringify(getters['allocationsToCreate'])),
                update: JSON.parse(JSON.stringify(getters['allocationsToUpdate'])),
                delete: JSON.parse(JSON.stringify(getters['allocationsToDelete'])),
            }

            for (const alloc of allocations.create) {
                if (!alloc.title) {
                    alloc.title = savedTransaction.title
                }
                if (!alloc.description) {
                    alloc.description = savedTransaction.description
                }
            }

            cleanObjsForTransactionUpdateInPlace(allocations)
            if (Object.keys(allocations).length) {
                updatedAllocationData = allocations
            }

            const parties = {
                update: JSON.parse(JSON.stringify(getters['partiesToUpdate'])),
            }

            cleanObjsForTransactionUpdateInPlace(parties)
            if (parties.update && parties.update.length) {
                updatedPartyData = parties
            }

            let updateRequest = {}
            if (updatedTransactionData) {
                updateRequest = { ...updatedTransactionData }
            }
            if (updatedPartyData) {
                updateRequest.parties = updatedPartyData
            }
            if (updatedAllocationData) {
                updateRequest.allocations = updatedAllocationData
            }
            if (updatedAuxData) {
                updateRequest.auxiliaryData = updatedAuxData
            }

            return updateRequest
        },
    },
    actions: {
        async saveSetup({ dispatch, getters }) {
            const transaction = getters['fetchedTransaction']
            if (transaction !== '__loading__') {
                await dispatch('updateTransaction')
            }
        },
        async deleteTransaction({ getters }) {
            const transaction = getters['fetchedTransaction']
            if (transaction.id) {
                await api.transaction.transactionDelete({ id: transaction.id })
            }
        },
        initTransaction({ state, getters, commit }) {
            const transaction = getters['fetchedTransaction']
            if (transaction === '__loading__') {
                state.transaction = { ...transaction_schema }
                state.auxiliaryData = JSON.parse(JSON.stringify(auxiliary_data_schema))
            } else {
                for (const key in transaction_schema) {
                    state.transaction[key] = transaction[key]
                }
                const aux = JSON.parse(transaction.auxiliaryData)
                for (const key in auxiliary_data_schema) {
                    if (key === 'milestonesToSameAddress' || aux[key]) {
                        commit('setTransactionAuxiliaryField', { key, value: aux[key] })
                    } else {
                        state.auxiliaryData[key] = ''
                    }
                }
            }
        },
        async saveTransaction({ dispatch, getters }) {
            const fetchedTransaction = getters['fetchedTransaction']
            if (fetchedTransaction === '__loading__') {
                await dispatch('createTransaction')
            } else {
                await dispatch('updateTransaction')
            }
            dispatch('initializeState')
        },
        async createTransaction({ state, dispatch, getters }) {
            const transaction = { ...state.transaction }

            const allocations = { create: JSON.parse(JSON.stringify(getters['allocationsToCreate'])) }
            for (const alloc of allocations.create) {
                if (!alloc.title) {
                    alloc.title = transaction.title
                }
                if (!alloc.description) {
                    alloc.description = transaction.description
                }
            }
            cleanObjsForTransactionUpdateInPlace(allocations)
            if (Object.keys(allocations).length !== 0) {
                transaction.allocations = allocations
            }

            const parties = {
                create: JSON.parse(JSON.stringify(getters['partiesToCreate'])),
            }
            cleanObjsForTransactionUpdateInPlace(parties)

            transaction.parties = parties
            transaction.currency = 'ZAR'
            transaction.auxiliaryData = prepareAuxiliaryData(state.auxiliaryData)

            const newTransaction = await api.transaction.create.transactionCreate(transaction)
            await dispatch('transaction/transactionFetch', { id: newTransaction.id }, { root: true })
            // newTransaction.files = []
            // commit('transaction/setTransaction', newTransaction, { root: true })
            dispatch('initializeState')
            await router.push({ path: newTransaction.id })
        },
        async updateTransaction({ getters, dispatch }) {
            const savedTransaction = getters['fetchedTransaction']

            const updateRequest = getters['updateTransactionData']

            if (Object.keys(updateRequest).length) {
                await api.transaction.create.transactionUpdate({
                    id: savedTransaction.id,
                    ...updateRequest,
                })
                await dispatch('transaction/transactionRefetch', null, { root: true })
            }
        },
        async updateTransactionNoReload({ dispatch }) {
            await dispatch('updateTransaction')
        },
    },
}
