<template>
    <div>
        <h4>Confidentiality is enabled</h4>
        <div v-if="transaction.privacy === 'ALL'">
            <p>
                The identities of the buying and selling parties are marked as confidential and are not disclosed to
                anyone.
            </p>
            <p>Agent's fees and costs are undisclosed and are hidden from the transacting parties.</p>
        </div>
        <div v-if="transaction.privacy === 'DETAILS'">
            The identities of the buying and selling parties are marked as confidential and are not disclosed to anyone.
        </div>
        <div v-if="transaction.privacy === 'CALCULATIONS'">
            Agent's fees and costs are undisclosed and are hidden from the transacting parties.
        </div>
    </div>
</template>
<script>
export default {
    props: {
        /*
            Transaction object is expected to have :
            - Privacy
        */
        transaction: {
            type: Object,
            required: true,
        },
    },
}
</script>
