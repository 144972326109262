import AllocationCourierDetails from './graphql/allocation/allocation-courier-details.gql'
import AllocationStartDelivery from './graphql/allocation/allocation-start-delivery.gql'
import AllocationAcceptDelivery from './graphql/allocation/allocation-accept-delivery.gql'
import AllocationDisputeDelivery from './graphql/allocation/allocation-dispute-delivery.gql'

export default class APITransactionAllocation {
    constructor(api) {
        this.api = api
    }

    async allocationCourierDetails(input) {
        const result = await this.api.client.mutate({
            mutation: AllocationCourierDetails,
            variables: {
                id: input.id,
                name: input.name,
                code: input.code,
            },
        })
        return result.data.allocationCourierDetails
    }
    async allocationStartDelivery(input) {
        const result = await this.api.client.mutate({
            mutation: AllocationStartDelivery,
            variables: {
                id: input.id,
            },
        })
        return result.data.allocationStartDelivery
    }
    async allocationAcceptDelivery(input) {
        const result = await this.api.client.mutate({
            mutation: AllocationAcceptDelivery,
            variables: {
                id: input.id,
            },
        })
        return result.data.allocationAcceptDelivery
    }
    async allocationDisputeDelivery(input) {
        const result = await this.api.client.mutate({
            mutation: AllocationDisputeDelivery,
            variables: {
                id: input.id,
                comment: input.comment,
            },
        })
        return result.data.allocationDisputeDelivery
    }
}
