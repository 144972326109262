import { id_za } from './id-number'
import { min_length, max_length } from './length'
import { min_value, max_value } from './value'
import { unique, same_as, required, required_allow_zero } from './common'
import { password } from './password'
import { email } from './email'

class Validation {
    constructor(injectedValidators = {}) {
        console.log('Validation Instantiated')
        this.list = {
            min_length,
            max_length,
            min_value,
            max_value,
            id_za,
            password,
            email,
            unique,
            same_as,
            required,
            required_allow_zero,
            ...injectedValidators,
        }
    }

    validate(value, validations, errorCount = 5) {
        let list = []
        for (const func of validations) {
            const result = func(value)
            for (const message of result) {
                list.push(message)
            }
        }
        if (list.length > errorCount) {
            list = list.slice(0, errorCount)
        }
        return list
    }
}

export default Validation
