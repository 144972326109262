<template>
    <div>
        <div class="uk-text-center uk-margin-large-bottom">
            <h3 class="uk-text-secondary uk-margin-remove">
                {{ heading }}
            </h3>
        </div>
        <div
            class="uk-margin uk-flex uk-flex-center uk-flex-wrap"
            :class="{ 'uk-margin-large-bottom': !deliveryAddressRequired }"
        >
            <CardButton
                icon-path="truck-solid.svg"
                text="Delivery Required"
                class="selectable-card"
                :selected="deliveryAddressRequired === true"
                @select="deliveryAddressRequired = true"
            />
            <CardButton
                icon-path="handshake-solid.svg"
                text="Will arrange privately"
                class="selectable-card"
                :selected="deliveryAddressRequired === false"
                @select="deliveryAddressRequired = false"
            />
        </div>

        <div class="uk-flex uk-flex-center uk-animation-slide-right-small" v-if="deliveryAddressRequired" uk-grid>
            <AddressForm class="uk-width-5-6@m" />
        </div>
        <CreateTxNavButtons :disable-next="!allowProgress" />
    </div>
</template>

<script>
import CardButton from '@/modules/common/components/input/card-button'
import AddressForm from './address-form'
import CreateTxNavButtons from '../common/create-transaction-navigation'
export default {
    name: 'GeneralDelivery',
    components: { CardButton, AddressForm, CreateTxNavButtons },
    computed: {
        industry() {
            return this.$store.state.txCreate.transaction.transaction.industry
        },
        heading() {
            if (this.industry === 'VEHICLES_WATERCRAFT') {
                return 'Where must delivery of the vehicle take place?'
            }
            return 'Where must delivery of the goods or services take place?'
        },
        deliveryAddressRequired: {
            get() {
                return this.$store.state.txCreate.address.deliveryAddressRequired
            },
            set(v) {
                this.$store.commit('txCreate/deliveryAddressRequired', v)
            },
        },
        allowProgress() {
            if (this.deliveryAddressRequired) {
                return (
                    this.$store.state.txCreate.address.address.name &&
                    this.$store.state.txCreate.address.address.line1 &&
                    this.$store.state.txCreate.address.address.line2 &&
                    this.$store.state.txCreate.address.address.city &&
                    this.$store.state.txCreate.address.address.area &&
                    this.$store.state.txCreate.address.address.code
                )
            }
            return true
        },
    },
}
</script>

<style scoped>
.selectable-card {
    margin: 0.5rem;
    padding: 1rem;
}
</style>
