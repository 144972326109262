<template>
    <div v-if="visible" style="overflow: hidden">
        <div class="uk-container uk-container-large animated fadeInRight uk-margin-bottom">
            <div class="notification" :class="classComputed">
                <div class="uk-flex uk-flex-left uk-flex-middle">
                    <div class="uk-margin-right">
                        <div style="border-radius: 50%; border-width: 0.1rem; border-style: solid; padding: 1rem">
                            <GL-Icon :path="icon" :size="4" />
                        </div>
                    </div>
                    <div class="uk-width-expand">
                        <h4 style="color: white; padding: 0rem">{{ title }}</h4>
                        <div @click="hide" class="notification-close">
                            <GL-Icon path="times-circle-solid.svg" :size="2" />
                        </div>

                        <p>
                            {{ body }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            visible: false,
            type: 'success',
            title: 'Title',
            body: 'body',
        }
    },
    mounted() {
        let _this = this
        this.$globalEventOn('notification', payload => {
            window.scroll(0, 0)
            _this.visible = true
            _this.title = payload.title
            _this.type = payload.type
            _this.body = payload.body
        })
        this.$globalEventOn('notificationHide', () => (_this.visible = false))
    },
    computed: {
        icon() {
            if (this.type === 'danger') {
                return 'times.svg'
            }
            return 'check-solid.svg'
        },
        classComputed() {
            if (this.type === 'danger') {
                return 'notification-danger'
            }
            return 'notification-success'
        },
    },
    methods: {
        hide() {
            this.visible = false
        },
    },
    watch: {
        '$route.fullPath'() {
            this.hide()
        },
    },
}
</script>
<style scoped>
.notification {
    padding: 1rem;
    color: white;
    border-radius: 1rem;
    border-top-left-radius: 5rem;
    border-bottom-left-radius: 5rem;
    height: 6rem;
    border-width: 0.25rem;
    border-color: #5cb85c;
}
.notification-success {
    background: linear-gradient(90deg, #32d26f, #32d296);
}
.notification-danger {
    background: linear-gradient(-90deg, #d23232, #d25232);
}
.notification-close {
    position: absolute;
    top: 1rem;
    right: 4rem;
}
.notification-close:hover {
    cursor: pointer;
    transform: scale(1.2);
}
</style>
