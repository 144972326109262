import validation from '@/scripts/transaction-create-field-validation'

export default {
    computed: {
        transactionType: {
            get() {
                return this.$store.state.txCreate.transaction.transaction.workflow
            },
            set(v) {
                this.$store.commit('txCreate/setTransactionField', { key: 'workflow', value: v })
            },
        },
        drawdown() {
            return this.transactionType === 'DRAWDOWN'
        },
        title: {
            get() {
                return this.$store.state.txCreate.transaction.transaction.title
            },
            set(v) {
                this.$store.commit('txCreate/setTransactionField', { key: 'title', value: v })
            },
        },
        description: {
            get() {
                return this.$store.state.txCreate.transaction.transaction.description
            },
            set(v) {
                this.$store.commit('txCreate/setTransactionField', { key: 'description', value: v })
            },
        },
        feeAllocation: {
            get() {
                return this.$store.state.txCreate.transaction.transaction.feeAllocation
            },
            set(v) {
                this.$store.commit('txCreate/setTransactionField', { key: 'feeAllocation', value: v })
            },
        },
        value: {
            get() {
                return this.$store.state.txCreate.allocations.allocations[0].value
            },
            set(v) {
                this.$store.commit('txCreate/setAllocationField', { key: 'value', index: 0, value: v })
            },
        },
        units: {
            get() {
                return this.$store.state.txCreate.allocations.allocations[0].units
            },
            set(v) {
                this.$store.commit('txCreate/setAllocationField', { key: 'units', index: 0, value: v })
            },
        },
        unitCost: {
            get() {
                return this.$store.state.txCreate.allocations.allocations[0].unitCost
            },
            set(v) {
                this.$store.commit('txCreate/setAllocationField', { key: 'unitCost', index: 0, value: v })
            },
        },
        daysToDeliver: {
            get() {
                return this.$store.state.txCreate.allocations.allocations[0].daysToDeliver
            },
            set(v) {
                this.$store.commit('txCreate/setAllocationField', { key: 'daysToDeliver', index: 0, value: v })
            },
        },
        daysToInspect: {
            get() {
                return this.$store.state.txCreate.allocations.allocations[0].daysToInspect
            },
            set(v) {
                this.$store.commit('txCreate/setAllocationField', { key: 'daysToInspect', index: 0, value: v })
            },
        },
        allowProgress() {
            if (this.transactionType === 'DRAWDOWN') {
                return this.allowProgressDrawdown
            } else if (this.transactionType === 'STANDARD') {
                return this.allowProgressStandard
            } else if (this.transactionType === 'MILESTONE') {
                return this.allowProgressMilestone
            }
        },
        allowProgressStandard() {
            return !!(
                validation.allocationValue(this.value).length === 0 &&
                validation.partyDetailsComplete() &&
                validation.allocationDetailsComplete() &&
                validation.basicDetailsComplete()
            )
        },
        allowProgressDrawdown() {
            return !!(
                validation.partyDetailsComplete() &&
                validation.allocationValue(this.value).length === 0 &&
                validation.basicDetailsComplete()
            )
        },
        allowProgressDrawdownUnits() {
            return !!(
                validation.partyDetailsComplete() &&
                validation.allocationValue(this.units * this.unitCost).length === 0 &&
                validation.basicDetailsComplete()
            )
        },
        allowProgressUnits() {
            if (this.drawdown) {
                return this.allowProgressDrawdownUnits
            }
            return !!(
                validation.allocationValue(this.units * this.unitCost).length === 0 &&
                validation.partyDetailsComplete() &&
                validation.allocationDetailsComplete() &&
                validation.basicDetailsComplete()
            )
        },
        allowProgressMilestone() {
            return !!(validation.partyDetailsComplete() && validation.basicDetailsComplete())
        },
    },
}
