<template>
    <div>
        <div class="uk-text-center uk-padding-small uk-margin">
            <h3 class="uk-text-secondary uk-margin-remove">Describe in detail what you are {{ userActionText }}</h3>
        </div>

        <div class="uk-flex uk-flex-center uk-margin-small-right uk-margin-small-left">
            <div class="uk-width-5-6@m">
                <div class="uk-card uk-card-default uk-card-body uk-width-1-1 uk-margin-small">
                    <div uk-grid>
                        <GL-TextInput
                            class="uk-width-1-1 uk-margin-small-top"
                            label="transaction name"
                            tooltip="Give your transaction a name. For example, Inv. 1234 10 000 tonnes of thermal coal etc."
                            v-model="title"
                            :error-messages="validation.requiredField(title)"
                        />
                        <GL-TextAreaInput
                            class="uk-width-1-1 uk-margin-small-top"
                            label="Description"
                            tooltip="Give the transaction a detailed description. Provide as much details as possible including the event that needs to take place in order for the funds to be released. This can include the customer’s receipt of the Delivery Notes (DN) or metallurgical assay certificates etc."
                            v-model="description"
                            :error-messages="validation.requiredField(description)"
                        />
                        <GL-SelectInput
                            class="uk-width-1-2 uk-margin-small-top"
                            v-model="feeAllocation"
                            label="Who Will Pay The Escrow Fee?"
                            :option-list="feeAllocationOptions"
                            :error-messages="validation.requiredField(feeAllocation)"
                        />
                        <div class="uk-width-1-1 uk-margin-small-top">
                            <PreciousNonPreciousSelector
                                @select="selectCommodityType"
                                :value="commodityType"
                                label="Select the commodity type"
                            />
                        </div>
                        <GL-SelectInput
                            :class="{
                                'uk-invisible': !commodityType,
                                ' uk-animation-slide-right-small': commodityType,
                            }"
                            class="uk-width-1-2@s uk-margin-small-top"
                            label="Commodity Type"
                            :option-list="commodities"
                            v-model="commodity"
                            :error-messages="validation.requiredField(commodity)"
                        />
                        <GL-TextInput
                            :class="{
                                'uk-invisible': !(commodity === 'OTHER'),
                                ' uk-animation-slide-right-small': commodity === 'OTHER',
                            }"
                            class="uk-width-1-2@s uk-margin-small-top"
                            label="Please Specify Product"
                            v-model="commodityDescription"
                            :error-messages="validation.requiredField(commodityDescription)"
                        />
                    </div>
                </div>
                <PartiesFees />
                <CreateTxNavButtons :disable-next="!allowProgress" />
            </div>
        </div>
    </div>
</template>

<script>
import { constantsCommodities } from '../../../../../../constants/constants-commodities'
import CreateTxNavButtons from '../../common/create-transaction-navigation'
import PartiesFees from '../../common/parties-fees/index'
import PreciousNonPreciousSelector from '../mining/precious-non-precious-selector'
import validation from '@/scripts/transaction-create-field-validation'
export default {
    name: 'MiningAndMetalsMilestone',
    components: { PartiesFees, PreciousNonPreciousSelector, CreateTxNavButtons },
    data() {
        return {
            validation,
        }
    },
    computed: {
        userActionText() {
            if (this.$store.state.txCreate.parties.parties[0].role === 'BUYER') {
                return 'buying'
            } else if (this.$store.state.txCreate.parties.parties[0].role === 'SELLER') {
                return 'supplying'
            }
            return 'brokering'
        },
        commodities() {
            if (this.commodityType) {
                return constantsCommodities.filter(
                    commodity => commodity.category === this.commodityType || commodity.category === 'ALL',
                )
            }
            return constantsCommodities
        },
        feeAllocationOptions() {
            return this.$store.getters['txCreate/feeAllocations']
        },
        title: {
            get() {
                return this.$store.state.txCreate.transaction.transaction.title
            },
            set(v) {
                this.$store.commit('txCreate/setTransactionField', { key: 'title', value: v })
            },
        },
        description: {
            get() {
                return this.$store.state.txCreate.transaction.transaction.description
            },
            set(v) {
                this.$store.commit('txCreate/setTransactionField', { key: 'description', value: v })
            },
        },
        feeAllocation: {
            get() {
                return this.$store.state.txCreate.transaction.transaction.feeAllocation
            },
            set(v) {
                this.$store.commit('txCreate/setTransactionField', { key: 'feeAllocation', value: v })
            },
        },
        units: {
            get() {
                return this.$store.state.txCreate.allocations.allocations[0].units
            },
            set(v) {
                this.$store.commit('txCreate/setAllocationField', { key: 'units', index: 0, value: v })
            },
        },
        unitCost: {
            get() {
                return this.$store.state.txCreate.allocations.allocations[0].unitCost
            },
            set(v) {
                this.$store.commit('txCreate/setAllocationField', { key: 'unitCost', index: 0, value: v })
            },
        },
        daysToDeliver: {
            get() {
                return this.$store.state.txCreate.allocations.allocations[0].daysToDeliver
            },
            set(v) {
                this.$store.commit('txCreate/setAllocationField', { key: 'daysToDeliver', index: 0, value: v })
            },
        },
        daysToInspect: {
            get() {
                return this.$store.state.txCreate.allocations.allocations[0].daysToInspect
            },
            set(v) {
                this.$store.commit('txCreate/setAllocationField', { key: 'daysToInspect', index: 0, value: v })
            },
        },
        commodity: {
            get() {
                return this.$store.state.txCreate.transaction.auxiliaryData.miningCommodity
            },
            set(v) {
                this.$store.commit('txCreate/setTransactionAuxiliaryField', { key: 'miningCommodity', value: v })
            },
        },
        commodityType: {
            get() {
                return this.$store.state.txCreate.transaction.auxiliaryData.miningCommodityType
            },
            set(v) {
                this.$store.commit('txCreate/setTransactionAuxiliaryField', { key: 'miningCommodityType', value: v })
            },
        },
        commodityDescription: {
            get() {
                return this.$store.state.txCreate.transaction.auxiliaryData.miningCommodityDescription
            },
            set(v) {
                this.$store.commit('txCreate/setTransactionAuxiliaryField', {
                    key: 'miningCommodityDescription',
                    value: v,
                })
            },
        },
        allowProgress() {
            if (!this.commodity || !this.commodityType || (this.commodity === 'OTHER' && !this.commodityDescription)) {
                return false
            }
            return !!(validation.partyDetailsComplete() && validation.basicDetailsComplete())
        },
    },
    watch: {
        commodity() {
            if (this.commodity !== 'OTHER') {
                this.commodityDescription = ''
            }
        },
        commodityType() {
            this.commodity = ''
        },
    },
    methods: {
        selectCommodityType(v) {
            this.commodityType = v
        },
    },
}
</script>

<style scoped></style>
