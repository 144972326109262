import { fuelZones, magisterialDistricts } from '../../constants/fuel'

export function valid_zone(message = 'Invalid fuel Zone') {
    return function (value) {
        const errors = []

        if (!value) {
            return errors
        }

        if (!fuelZones().includes(value)) {
            errors.push(message)
        }

        return errors
    }
}

export function valid_magisterial_district(message = 'Invalid Magisterial District') {
    return function (value) {
        const errors = []

        if (!value) {
            return errors
        }

        if (!magisterialDistricts().includes(value)) {
            errors.push(message)
        }

        return errors
    }
}
