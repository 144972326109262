import Vue from 'vue'

const state = Vue.observable({
    width: window.innerWidth,
    height: window.innerHeight,
})

window.addEventListener('resize', () => {
    state.width = window.innerWidth
    state.height = window.innerHeight
})

const sizeDictionary = {
    small: 640,
    medium: 960,
    large: 1200,
    xlarge: 1600,
}

export default {
    larger(size) {
        const sizeWidth = sizeDictionary[size]
        return state.width > sizeWidth
    },
    smaller(size) {
        const sizeWidth = sizeDictionary[size]
        return state.width < sizeWidth
    },
}
