<template>
    <div>
        <div class="uk-text-center uk-padding-small uk-margin">
            <h3 class="uk-text-secondary uk-margin-remove">Describe in detail what you are {{ userActionText }}</h3>
        </div>

        <div class="uk-flex uk-flex-center">
            <div class="uk-width-4-5@m">
                <div class="uk-card uk-card-default uk-card-body uk-width-1-1 uk-margin-small">
                    <div uk-grid>
                        <GL-TextInput
                            class="uk-width-1-1 uk-margin-small-top"
                            label="Transaction Name"
                            tooltip="Give your transaction a name i.e. Sale of www.maverickjoe.co.za etc. The transaction name can also include an invoice number or Purchase Order Number."
                            v-model="title"
                            :error-messages="validation.requiredField(title)"
                        />
                        <DomainInputFieldList class="uk-width-1-1 uk-margin-small-top" />

                        <GL-TextAreaInput
                            class="uk-width-1-1 uk-margin-small-top"
                            label="Description"
                            v-model="description"
                            tooltip="Give the transaction a detailed description. Provide as much detail as possible including the event that needs to take place in order for the funds to be released. You will be able to upload documents to this transaction later on (you can reference these documents in the description box below)."
                            :error-messages="validation.requiredField(description)"
                        />
                        <GL-SelectInput
                            class="uk-width-1-2@s uk-margin-small-top"
                            v-model="feeAllocation"
                            label="Who Will Pay The Escrow Fee?"
                            :option-list="feeAllocationOptions"
                            :error-messages="validation.requiredField(feeAllocation)"
                        />
                        <div v-if="!drawdown" class="uk-width-1-2@s uk-visible@s"></div>
                        <GL-CurrencyInput
                            v-model="value"
                            class="uk-width-1-2@s uk-margin-small-top"
                            right-align
                            label="price"
                            :error-messages="validation.allocationValue(value)"
                        />
                        <GL-NumberInput
                            class="uk-width-1-4@s uk-margin-small-top"
                            label="Delivery Days"
                            v-if="!drawdown"
                            right-align
                            tooltip="Delivery Days is the expected duration to complete the domain purchase, sale or transfer."
                            v-model="daysToDeliver"
                            :error-messages="validation.requiredField(daysToDeliver)"
                        />
                        <GL-NumberInput
                            class="uk-width-1-4@s uk-margin-small-top"
                            v-if="!drawdown"
                            label="Inspection Days"
                            right-align
                            tooltip="Inspection Days is the number of days the buyer has to inspect that the domain purchase, sale or transfer was successful."
                            v-model="daysToInspect"
                            :error-messages="validation.requiredField(daysToInspect)"
                        />
                    </div>
                </div>
            </div>
        </div>

        <PartiesFees />
        <TransactionInformation />
        <CreateTxNavButtons :disable-next="!allowProgressDomains" />
    </div>
</template>

<script>
import CreateTxNavButtons from '../../common/create-transaction-navigation'
import DomainInputFieldList from './domain-input-field-list'
import PartiesFees from '../../common/parties-fees/index'
import validation from '@/scripts/transaction-create-field-validation'
import TransactionInformation from '../../transaction-information/index'
import TransactionCreateMixin from '../../transaction-create-mixin'

export default {
    name: 'DomainDetails',
    components: { TransactionInformation, PartiesFees, DomainInputFieldList, CreateTxNavButtons },
    mixins: [TransactionCreateMixin],
    data() {
        return {
            validation,
        }
    },
    computed: {
        userActionText() {
            if (this.$store.state.txCreate.parties.parties[0].role === 'BUYER') {
                return 'buying'
            } else if (this.$store.state.txCreate.parties.parties[0].role === 'SELLER') {
                return 'selling'
            }
            return 'brokering'
        },
        feeAllocationOptions() {
            return this.$store.getters['txCreate/feeAllocations']
        },
        domains() {
            return this.$store.state.txCreate.transaction.auxiliaryData.domains
        },
        allowProgressDomains() {
            for (const domain of this.domains) {
                if (!domain) {
                    return false
                }
            }
            return this.allowProgress
        },
    },
}
</script>
