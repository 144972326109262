import api from '@/scripts/api/api'
import rootStore from '@/store/index'

export default {
    namespaced: true,
    state: {
        refetch: true,
        context: '__loading__',
        actingAsBusiness: false,
    },
    mutations: {
        setActingAsBusiness(state, payload) {
            state.actingAsBusiness = payload
        },
        setContextFetched(state, payload) {
            state.context = payload
            if (payload.__typename === 'Organization') {
                state.actingAsBusiness = true
            } else {
                state.actingAsBusiness = false
            }
            state.refetch = false
        },
        setContextLoading(state) {
            state.context = '__loading__'
        },
        setContextRefetchable(state) {
            state.refetch = true
        },
    },
    actions: {
        async contextFetch() {
            const context = await api.context.contextFetch()
            console.log('Context Fetched : ')
            console.log(context)

            this.commit('context/setContextFetched', context)
        },
        async contextSelect(store, payload) {
            store.commit('setContextLoading')
            await api.context.contextSet(payload)
            await store.dispatch('contextFetch')
        },
        async contextSelectPersonal(store) {
            const id = store.getters['contextList'][0].id
            store.commit('setContextLoading')
            await api.context.contextSet({
                id: id,
            })
            await store.dispatch('contextFetch')
        },
        async contextSelectBusiness(store) {
            const id = store.getters['contextList'][1].id
            store.commit('setContextLoading')
            await api.context.contextSet({
                id: id,
            })
            await store.dispatch('contextFetch')
        },
    },
    getters: {
        context: state => {
            const context = {...state.context}

            if (rootStore.getters['user/user'] !== '__loading__') {
                context.email = rootStore.getters['user/user'].details.email
                context.status = {
                    emailVerified: rootStore.getters['user/user'].status.emailVerified,
                    ...state.context.status,
                }
            }

            return context
        },
        contextList: () => {
            const organizationList = rootStore.getters['organization/organizationList']
            const user = rootStore.getters['user/user']

            if (organizationList === '__loading__' || user === '__loading__') {
                return '__loading__'
            }
            const contextList = []

            if (user === '__loading__') {
                return '__loading__'
            }
            contextList.push({
                id: user.id,
                name: user.details.givenName + ' ' + user.details.familyName,
                avatar: user.avatar.avatarURL,
                type: 'Personal',
                verified: user.status.verified,
            })

            if (organizationList === '__loading__') {
                return '__loading__'
            }
            for (const organization of organizationList) {
                contextList.push({
                    id: organization.id,
                    name: organization.name,
                    avatar: organization.avatar,
                    type: 'Company',
                    verified: organization.status.verified,
                })
            }

            return contextList
        },
        contextVerification: () => {
            if (rootStore.getters['context/context'] === '__loading__') {
                return null
            }
            if (rootStore.getters['context/context'].__typename === 'User') {

                if (rootStore.getters['user/user'] === '__loading__') {
                    return null
                }
                if (rootStore.getters['user/user'].status.verified) {
                    return null
                }
                if (rootStore.getters['user/detailsComplete'] === false) {
                    return {
                        message: 'Your profile is not complete',
                    }
                }
                if (rootStore.getters['user/user'].status.emailVerified === false) {
                    return {
                        message: 'Your email is not verified',
                    }
                }
                if (rootStore.getters['user/user'].status.mobileVerified === false) {
                    return {
                        message: 'Your mobile number is not verified',
                    }
                }
                if (!rootStore.getters['user/user'].banking.bank) {
                    return {
                        message: 'Banking details not provided',
                    }
                }
                if (!rootStore.getters['user/user'].banking.verified) {
                    return {
                        message: 'Banking details not verified',
                    }
                }
            }
            if (rootStore.getters['context/context'].__typename === 'Organization') {
                if (rootStore.getters['organization/organization'] === '__loading__') {
                    return {
                        message: 'Waiting for information be be loaded',
                    }
                }
                if (rootStore.getters['organization/organization'].banking.verified === false) {
                    return {
                        message: 'The company bank account is not verified',
                    }
                }
                if (rootStore.getters['organization/organization'].status.verified === false) {
                    return {
                        message: 'The company has not been verified',
                    }
                }
                // todo: When the director check is working confirm the approved status
                if (
                    rootStore.getters['organization/organization'].directorCheck &&
                    rootStore.getters['organization/organization'].directorCheck.status !== 'ACCEPTED'
                ) {
                    return {
                        message: 'Director confirmation still outstanding',
                    }
                }
            }
            return null
        },
        hasBusiness: () => {
            if (rootStore.getters['context/contextList'] === '__loading__') {
                return '__loading__'
            }
            return rootStore.getters['context/contextList'].length > 1
        },
        sbsaBusiness: () => {
            if (rootStore.getters['context/context'] !== '__loading__') {
                return !!(
                    rootStore.getters['context/context'].__typename === 'Organization' &&
                    rootStore.getters['context/context'].bankAccount.bank === 'SBSA'
                )
            }
            return false
        },
    },
}
