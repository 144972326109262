<template>
    <div>
        <div class="uk-text-center" uk-grid>
            <div class="uk-width-expand@m uk-visible@m" />
            <div class="uk-width-5-6@m">
                <div class="uk-margin-bottom">
                    <h3 class="uk-text-secondary">Who are the parties to this transaction?</h3>
                </div>
                <div class="uk-padding-small">
                    <p>Add the parties below and provide their email address.</p>
                    <p>
                        Please ensure their email address is correct as TradeSafe will email them a trade invitation
                        (the party will use the email address you provided to sign into TradeSafe to view and accept
                        your transaction).
                    </p>
                </div>
            </div>
            <div class="uk-width-expand@m uk-visible@m" />
        </div>
        <div class="uk-margin-remove-top" uk-grid>
            <div class="uk-width-expand@m uk-visible@m" />
            <div class="uk-width-5-6@m uk-margin-small-right uk-margin-small-left">
                <OwnerCard v-if="!editMode" />
                <div v-for="(party, index) in parties" :key="index" class="uk-margin-small">
                    <PartiesCard v-if="editMode || index > 0" :index="index" />
                </div>
            </div>
            <div class="uk-width-expand@m uk-visible@m" />
        </div>
        <div class="uk-text-center uk-padding" :class="{ 'uk-padding-remove-bottom': editMode }">
            <GL-Button text="add another party" @click="addParty" type="primary-outlined" />
        </div>
        <TransactionInformation v-if="editMode" />
        <CreateTxNavButtons :disable-next="!allowProgress" />
    </div>
</template>

<script>
import PartiesCard from './parties-card'
import OwnerCard from './owner-card'
import CreateTxNavButtons from '../common/create-transaction-navigation'
import validation from '@/scripts/validation/validation'
import TransactionInformation from '../transaction-information/index'
import fieldValidation from '@/scripts/transaction-create-field-validation'

export default {
    components: { TransactionInformation, OwnerCard, PartiesCard, CreateTxNavButtons },
    name: 'Parties',
    data() {
        return {
            errors: {},
        }
    },
    computed: {
        parties() {
            return this.$store.state.txCreate.parties.parties
        },
        editMode() {
            return this.$store.getters['txCreate/editMode']
        },
        partyFeesComplete() {
            for (const party of this.parties) {
                if (party.role !== 'BUYER' && party.role !== 'SELLER') {
                    if (party.role === 'AGENT') {
                        if (!(party.fee && party.feeType)) {
                            return false
                        }
                    } else {
                        if (!(party.fee && party.feeType && party.feeAllocation)) {
                            return false
                        }
                    }
                }
            }
            return true
        },
        allowProgress() {
            for (let i = 1; i < this.parties.length; i += 1) {
                const test = validation.validate(this.parties[i].email, [
                    validation.list.required('Please provide a email address for this party'),
                    validation.list.unique('Must be a unique email', this.otherPartyEmails(i)),
                    validation.list.email('Invalid email'),
                    validation.list.same_as(
                        'Emails do not match',
                        this.$store.state.txCreate.parties.emailConfirmations[i],
                    ),
                ])
                if (!this.parties[i].email || test.length > 0 || !this.parties[i].role) {
                    return false
                }
            }
            if (this.editMode) {
                fieldValidation.partyDetailsComplete()
            }
            return true
        },
    },
    methods: {
        otherPartyEmails(myIndex) {
            const otherParties = this.$store.state.txCreate.parties.parties.filter((party, index) => index !== myIndex)
            return otherParties.map(({ email }) => email)
        },
        addParty() {
            this.$store.commit('txCreate/addParty')
        },
        removeParty(index) {
            this.$store.commit('txCreate/removeParty', index)
        },
    },
}
</script>
