<template>
    <div class="uk-card uk-card-default uk-position-relative uk-margin-bottom uk-width-1-1">
        <span @click="$store.commit('txCreate/removeAllocation', index)" v-if="index > 1" class="delete">
            <GL-Icon path="trash.svg" />
        </span>
        <div class="uk-card-body uk-position-relative">
            <div class="uk-text-center uk-text-large uk-margin-bottom">Load {{ index + 1 }} - Details</div>
            <div>
                <div class="uk-grid-small" uk-grid>
                    <GL-TextInput
                        class="uk-width-1-1"
                        label="load title"
                        tooltip="Give this load a name. For example, delivery of the first 4 000 tonnes of thermal coal per total 10 000 tonnes order etc."
                        v-model="title"
                    />
                    <GL-TextAreaInput
                        class="uk-width-1-1 uk-margin-small-top"
                        tooltip="Give this load a detailed description. Provide as much details as possible including the event that needs to take place in order for the funds to be released. This can include the customer’s receipt of the Delivery Note (DN) or metallurgical assay certificate etc."
                        label="Load Description"
                        v-model="description"
                    />
                    <GL-NumberInput
                        class="uk-width-1-2@s uk-margin-small-top"
                        name="mass"
                        right-align
                        v-model="units"
                        :label="massUnits.label"
                    />
                    <GL-CurrencyInput
                        class="uk-width-1-2@s uk-margin-small-top"
                        name="rate"
                        v-model="unitCost"
                        :label="massUnits.price"
                    />
                    <GL-NumberInput
                        class="uk-width-1-4@s uk-margin-small-top"
                        label="Delivery Days"
                        right-align
                        tooltip="Delivery days is the expected duration of the delivery of the commodities."
                        v-model="daysToDeliver"
                    />
                    <GL-NumberInput
                        class="uk-width-1-4@s uk-margin-small-top"
                        label="Inspection Days"
                        right-align
                        tooltip="Inspection days is the expected number of days the customer has to inspect the commodities."
                        v-model="daysToInspect"
                    />
                    <div class="uk-width-expand"></div>
                    <GL-CurrencyInput
                        disabled
                        class="uk-width-1-4@s uk-margin-small-top"
                        label="Load Value"
                        :value="unitCost * units"
                    />
                </div>
            </div>
        </div>

        <MilestoneRunningSummaryUnits
            class="uk-visible@l uk-animation-slide-right-small"
            v-if="lastMilestone && transactionValue"
            total-unit-heading="Total Mass"
            :index="index"
        />
    </div>
</template>

<script>
import MilestoneRunningSummaryUnits from '../../common/milestone-running-summary-card'
export default {
    name: 'MiningMilestoneCardNoAddress',
    components: { MilestoneRunningSummaryUnits },
    props: {
        index: {
            type: Number,
            required: true,
        },
    },
    computed: {
        title: {
            get() {
                return this.$store.state.txCreate.allocations.allocations[this.index].title
            },
            set(v) {
                this.$store.commit('txCreate/setAllocationField', { index: this.index, key: 'title', value: v })
            },
        },
        description: {
            get() {
                return this.$store.state.txCreate.allocations.allocations[this.index].description
            },
            set(v) {
                this.$store.commit('txCreate/setAllocationField', { index: this.index, key: 'description', value: v })
            },
        },
        lastMilestone() {
            return this.index + 1 === this.$store.state.txCreate.allocations.allocations.length
        },
        transactionValue() {
            return this.$store.getters['txCreate/transactionValueUnitBased']
        },
        units: {
            get() {
                return this.$store.state.txCreate.allocations.allocations[this.index].units
            },
            set(value) {
                this.$store.commit('txCreate/setAllocationField', {
                    index: this.index,
                    key: 'units',
                    value,
                })
            },
        },
        unitCost: {
            get() {
                return this.$store.state.txCreate.allocations.allocations[this.index].unitCost
            },
            set(value) {
                this.$store.commit('txCreate/setAllocationField', {
                    index: this.index,
                    key: 'unitCost',
                    value,
                })
            },
        },
        daysToInspect: {
            get() {
                return this.$store.state.txCreate.allocations.allocations[this.index].daysToInspect
            },
            set(value) {
                this.$store.commit('txCreate/setAllocationField', {
                    index: this.index,
                    key: 'daysToInspect',
                    value,
                })
            },
        },
        daysToDeliver: {
            get() {
                return this.$store.state.txCreate.allocations.allocations[this.index].daysToDeliver
            },
            set(value) {
                this.$store.commit('txCreate/setAllocationField', {
                    index: this.index,
                    key: 'daysToDeliver',
                    value,
                })
            },
        },
        commodity() {
            return this.$store.state.txCreate.transaction.auxiliaryData.miningCommodity
        },
        commodityType() {
            return this.$store.state.txCreate.transaction.auxiliaryData.miningCommodityType
        },
        massUnits() {
            if (this.commodityType === 'PRECIOUS') {
                if (this.commodity === 'DIAMONDS') {
                    return {
                        label: 'Carats',
                        price: 'Price Per Carat',
                    }
                }
                return {
                    label: 'Troy Ounces',
                    price: 'Price Per Troy Ounce',
                }
            }
            return {
                label: 'Tonnage',
                price: 'Price Per Ton',
            }
        },
    },
}
</script>

<style scoped>
.delete {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 100;
}
.delete:hover {
    cursor: pointer;
    color: black;
}
</style>
