<template>
    <div>
        <div class="uk-text-center uk-padding-small uk-margin">
            <h3 class="uk-text-secondary uk-margin-remove">Describe in detail what you are {{ userActionText }}</h3>
        </div>
        <div class="uk-flex uk-flex-center uk-margin-small-right uk-margin-small-left">
            <div class="uk-width-5-6@m">
                <div class="uk-card uk-card-default uk-card-body uk-width-1-1 uk-margin-small">
                    <div uk-grid>
                        <GL-TextInput
                            class="uk-width-1-1 uk-margin-small-top"
                            label="transaction name"
                            tooltip="Give your transaction a name. For example, invoice 1235 COC IVT 39,000 litres etc."
                            v-model="title"
                            :error-messages="validation.requiredField(title)"
                        />
                        <GL-TextAreaInput
                            class="uk-width-1-1 uk-margin-small-top"
                            label="Description"
                            tooltip="Give the transaction a detailed description. Provide as much details as possible including the event that needs to take place in order for the funds to be released. This can include the customer’s receipt of the Bill of Lading (BOL), Delivery Notes (DN) or pick-up confirmation slips."
                            v-model="description"
                            :error-messages="validation.requiredField(description)"
                        />
                        <GL-SelectInput
                            class="uk-width-1-2@s uk-margin-small-top"
                            label="Product"
                            :option-list="products"
                            v-model="product"
                            :error-messages="validation.requiredField(product)"
                        />
                        <GL-TextInput
                            v-if="product === 'Other'"
                            class="uk-width-1-2@s uk-margin-small-top uk-animation-slide-right-small"
                            label="Please Specify Product"
                            v-model="productDescription"
                            :error-messages="validation.requiredField(productDescription)"
                        />
                        <div v-else class="uk-width-1-2@s uk-margin-small-top" />
                        <GL-SelectInput
                            class="uk-width-1-1 uk-margin-small-top"
                            v-model="feeAllocation"
                            name="fee"
                            label="Who Will Pay The Escrow Fee?"
                            :option-list="feeAllocationOptions"
                            :error-messages="validation.requiredField(feeAllocation)"
                        />
                    </div>
                </div>
            </div>
        </div>
        <PartiesFees />
        <CreateTxNavButtons :disable-next="!allowProgress" />
    </div>
</template>

<script>
import { products } from '../../../../../../constants/fuel'
import PartiesFees from '../../common/parties-fees/index'
import CreateTxNavButtons from '../../common/create-transaction-navigation'
import validation from '@/scripts/transaction-create-field-validation'

export default {
    name: 'FuelMilestone',
    components: { PartiesFees, CreateTxNavButtons },
    data() {
        return {
            products,
            validation,
        }
    },
    computed: {
        userActionText() {
            if (this.$store.state.txCreate.parties.parties[0].role === 'BUYER') {
                return 'buying'
            } else if (this.$store.state.txCreate.parties.parties[0].role === 'SELLER') {
                return 'supplying'
            }
            return 'brokering'
        },
        feeAllocationOptions() {
            return this.$store.getters['txCreate/feeAllocations']
        },
        title: {
            get() {
                return this.$store.state.txCreate.transaction.transaction.title
            },
            set(v) {
                this.$store.commit('txCreate/setTransactionField', { key: 'title', value: v })
            },
        },
        description: {
            get() {
                return this.$store.state.txCreate.transaction.transaction.description
            },
            set(v) {
                this.$store.commit('txCreate/setTransactionField', { key: 'description', value: v })
            },
        },
        feeAllocation: {
            get() {
                return this.$store.state.txCreate.transaction.transaction.feeAllocation
            },
            set(v) {
                this.$store.commit('txCreate/setTransactionField', { key: 'feeAllocation', value: v })
            },
        },
        product: {
            get() {
                return this.$store.state.txCreate.transaction.auxiliaryData.fuelProduct
            },
            set(v) {
                this.$store.commit('txCreate/setTransactionAuxiliaryField', { key: 'fuelProduct', value: v })
            },
        },
        productDescription: {
            get() {
                return this.$store.state.txCreate.transaction.auxiliaryData.fuelProductDescription
            },
            set(v) {
                this.$store.commit('txCreate/setTransactionAuxiliaryField', { key: 'fuelProductDescription', value: v })
            },
        },
        allowProgress() {
            if (!this.product || (this.product === 'Other' && !this.productDescription)) {
                return false
            }
            return !!(validation.partyDetailsComplete() && validation.basicDetailsComplete())
        },
    },
}
</script>

<style scoped>
.selectable-card {
    margin: 0.5rem;
}
</style>
