<template>
    <div class="uk-width-1-1">
        <div class="uk-text-center uk-padding-small uk-padding-remove-top">
            <h3 class="uk-text-secondary uk-margin-remove">Now, Let's Describe The Loads</h3>
        </div>
        <div class="uk-flex uk-flex-center uk-margin-small-right uk-margin-small-left">
            <div class="uk-width-5-6@m">
                <div class="uk-animation-slide-top-small">
                    <div class="uk-flex uk-flex-center uk-flex-wrap">
                        <component
                            :is="milestoneComponent"
                            v-for="(allocation, index) in allocations"
                            :key="index"
                            :index="index"
                            class="uk-animation-slide-right-small"
                            :allocation="allocation"
                        />
                    </div>
                    <div class="uk-text-center uk-padding uk-padding-remove-bottom">
                        <GL-Button type="primary" text="Add Load" @click="$store.commit('txCreate/addAllocation')" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MiningMilestoneCardNoAddress from './milestone-cards/mining-milestone-card-same-address'
import MiningMilestoneCard from './milestone-cards/mining-milestone-card-unique-address'
export default {
    name: 'MiningLoads',
    components: { MiningMilestoneCard, MiningMilestoneCardNoAddress },
    computed: {
        milestoneComponent() {
            if (this.$store.state.txCreate.transaction.auxiliaryData.milestonesToSameAddress) {
                return MiningMilestoneCardNoAddress
            }
            return MiningMilestoneCard
        },
        allocations() {
            return this.$store.state.txCreate.allocations.allocations
        },
    },
}
</script>
