export default {
    getters: {
        transactionType: (state, getters, rootState) => {
            return rootState.txCreate.transaction.auxiliaryData.fuelTransactionType
        },
        milestonesToSameAddress: (state, getters, rootState) => {
            return rootState.txCreate.transaction.auxiliaryData.milestonesToSameAddress
        },
    },
    actions: {
        async saveLoads({ dispatch, getters, commit }) {
            if (getters['milestonesToSameAddress']) {
                commit('txCreate/deliveryAddressRequired', true, { root: true })
                // await dispatch('txCreate/saveAddress', {}, { root: true })
                // await dispatch('txCreate/initializeState', {}, { root: true })
                await dispatch('txCreate/saveTransaction', {}, { root: true })
            } else if (!getters['milestonesToSameAddress']) {
                await dispatch('txCreate/saveAllocationAddresses', {}, { root: true })
                await dispatch('txCreate/saveTransaction', {}, { root: true })
            }
        },
    },
}
