<template>
    <div class="uk-margin-small-top">
        <div class="uk-text-center uk-text-large uk-margin">Load {{ index + 1 }} - Delivery Instructions</div>
        <div class="uk-grid-small" uk-grid>
            <GL-TextAreaInput class="uk-width-1-1" label="Delivery Instructions" v-model="deliveryInstructions" />
        </div>
        <div class="uk-width-1-1 uk-margin-top uk-text-right uk-card-footer">
            <GL-Button
                text="previous"
                type="primary-outlined"
                class="uk-margin-small-right"
                @click="$emit('view-previous')"
            />
            <GL-Button text="next" disabled-text="next" type="primary" @click="$emit('complete-step')" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'DeliveryInstructions',
    props: {
        index: {
            type: Number,
            required: true,
        },
    },
    computed: {
        deliveryInstructions: {
            get() {
                return this.$store.state.txCreate.allocations.allocations[this.index].deliveryInstructions
            },
            set(value) {
                this.$store.commit('txCreate/setAllocationField', {
                    index: this.index,
                    key: 'deliveryInstructions',
                    value,
                })
            },
        },
    },
}
</script>
