<template>
    <div class="navbar">
        <nav class="uk-navbar uk-navbar-container uk-margin">
            <div v-if="$screenSmaller('small')" class="uk-navbar-left">
                <div class="hamburger unselectable" @click.prevent="toggle()">
                    <GL-Icon class="uk-margin-left" path="bars.svg"/>
                </div>
            </div>
            <div class="uk-navbar-center">
                <img style="width: 8rem; height: 4rem" src="/icons/ts_logo.svg" uk-svg/>
            </div>
            <div class="uk-navbar-right unselectable home-tour-step-9 profile-tour-step-4" v-if="status">
                <ContextSwitcher/>
            </div>
        </nav>
        <div class="unselectable" v-if="status">
            <div v-if="$screenLarger('small')" class="uk-flex uk-flex-center">
                <div class="bot uk-flex uk-flex-between">
                    <div
                        @click="navigate('/')"
                        class="item home-tour-step-1"
                        :class="{ select: $route.name === 'Home' }"
                    >
                        <GL-Icon path="home.svg"/>
                        <span> Home </span>
                    </div>
                    <div
                        @click="navigate('/profile')"
                        class="item home-tour-step-2 home-tour-step-10 profile-tour-step-1"
                        :class="{ select: $route.name === 'Profile' }"
                    >
                        <GL-Icon path="user.svg"/>
                        <span> My Profile </span>
                        <span
                            v-if="profileIcon"
                            class="uk-margin-small-left uk-text-warning"
                            :uk-tooltip="profileIcon.tooltip"
                        >
                            <GL-Icon :path="profileIcon.path"/>
                        </span>
                    </div>
                    <div
                        @click="navigate('/escrow-account')"
                        class="item home-tour-step-4"
                        :class="{ select: $route.name === 'EscrowAccount' }"
                    >
                        <GL-Icon path="wallet.svg" :size="1.25"/>
                        <span> Escrow Account </span>
                    </div>
                    <div
                        @click="navigate('/dashboard')"
                        class="item home-tour-step-8"
                        :class="{ select: $route.name === 'Dashboard' }"
                    >
                        <GL-Icon path="list-solid.svg"/>
                        <span> My Transactions </span>
                    </div>
                    <div @click="navigate('/support')" class="item" :class="{ select: $route.name === 'Support' }">
                        <GL-Icon path="phone.svg"/>
                        <span> Support </span>
                    </div>
                </div>
            </div>
            <div v-if="$screenSmaller('small')" class="uk-margin-small-bottom">
                <div v-if="showBottom">
                    <div class="small">
                        <div @click="navigate('/')" class="item">
                            <GL-Icon path="home.svg"/>
                            <span> Home </span>
                        </div>
                        <div @click="navigate('/profile')" class="item">
                            <GL-Icon path="user.svg"/>
                            <span>
                                My Profile
                                <span
                                    v-if="profileIcon"
                                    class="uk-margin-small-left uk-text-warning"
                                    :uk-tooltip="profileIcon.tooltip"
                                >
                                    <GL-Icon :path="profileIcon.path"/>
                                </span>
                            </span>
                        </div>
                        <div @click="navigate('/escrow-account')" class="item">
                            <GL-Icon path="wallet.svg" :size="1.25"/>
                            <span> Escrow Account </span>
                        </div>
                        <div @click="navigate('/dashboard')" class="item">
                            <GL-Icon path="list-solid.svg" :size="1.25"/>
                            <span> Dashboard </span>
                        </div>
                        <div @click="navigate('/support')" class="item">
                            <GL-Icon path="phone.svg"/>
                            <span> Support </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ContextSwitcher from './navbar-context'
import api from "@/scripts/api/api";

export default {
    name: 'Navbar',
    components: {
        ContextSwitcher,
    },
    data() {
        return {
            showBottom: false,
            status: false,
        }
    },
    mounted() {
        api.auth.loginStatus().then(status => {
            if (window.location.pathname.startsWith('/pay/')) {
                this.false = false
            } else {
                this.status = status
            }
        })
    },
    computed: {
        profileIcon() {
            if (this.$store.getters['context/contextVerification']) {
                return {
                    path: 'exclamation-circle-solid.svg',
                    tooltip: this.$store.getters['context/contextVerification'].message,
                }
            }
            return null
        },
    },
    methods: {
        toggle() {
            this.showBottom = !this.showBottom
        },
        navigate(target) {
            if (this.$screenSmaller('small')) {
                this.toggle()
            }
            if (this.$route.path !== target) {
                this.$router.push(target)
            }
        },
    },
}
</script>
<style lang="scss" scoped>
@import '@/scss/components/navbar.scss';
</style>
