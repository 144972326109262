<template>
    <div>
        <component :is="form" />
    </div>
</template>

<script>
import General from './general/index'
import Vehicle from './vehicle/index'
import Electronics from './electronics/index'
import Agriculture from './agriculture/index'
import Property from './property/index'
import Domains from './domains/index'
import Art from './art/index'
import Construction from './construction/index'
import Events from './events/index'
import Film from './film/index'
import Freelancing from './freelancing/index'
import Mergers from './mergers/index'
import { transactionCreate } from '../../../../../constants/constants-transaction-create'

export default {
    name: 'TransactionMilestones',
    components: {
        Vehicle,
        General,
        Electronics,
        Agriculture,
        Property,
        Domains,
        Art,
        Construction,
        Events,
        Film,
        Freelancing,
        Mergers,
    },
    data() {
        return {
            forms: {
                industry: 'general Goods',
            },
        }
    },
    computed: {
        form() {
            if (!this.$store.state.txCreate.transaction.transaction.industry) {
                return null
            } else {
                const industry = transactionCreate.industries.find(
                    industry => industry.value === this.$store.state.txCreate.transaction.transaction.industry,
                )
                return industry.formComponentName
            }
        },
    },
}
</script>
