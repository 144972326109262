<template>
    <component :is="form" />
</template>

<script>
import General from './general'
import Fuel from './fuel/index'
import Mining from './mining/index'
export default {
    name: 'Delivery',
    components: { General, Fuel, Mining },
    computed: {
        form() {
            const transaction = this.$store.getters['txCreate/fetchedTransaction']
            if (transaction === '__loading__') {
                return null
            } else {
                if (transaction.industry === 'FUEL') {
                    return 'Fuel'
                } else if (transaction.industry === 'MINING') {
                    return 'Mining'
                }
                return 'General'
            }
        },
    },
}
</script>
