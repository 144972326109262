<template>
    <div class="bordered-card document-card">
        <div class="content-area">
            <div class="upload-area uk-placeholder uk-text-center" @drop.prevent="onFileDrop" @dragover.prevent>
                <GL-Icon path="plus-solid.svg" :size="2" />
                <div class="uk-margin-top">
                    <span class="uk-text-middle">Drop files here or</span>
                    <div />
                    <div>
                        <input type="file" multiple @change="onFileSelect" class="uk-hidden" ref="fileUploadInput" />
                        <a @click="$refs.fileUploadInput.click()">select file</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'UploadCard',
    methods: {
        onFileDrop(event) {
            this.$emit('add', event.dataTransfer.files)
        },
        onFileSelect(event) {
            this.$emit('add', event.target.files)
        },
    },
}
</script>

<style lang="scss" scoped>
.content-area {
    margin: 0.5rem;
    width: 215px;
    height: 215px;
}
$hover-color: #7eb9f536;
.upload-area {
    box-sizing: border-box;
    margin: 0.5rem;
    border-radius: 0.5rem;
    min-height: 100%;

    &:hover {
        background: $hover-color;
        cursor: pointer;
        border-color: $hover-color;
    }
}
.uk-link:hover {
    cursor: pointer !important;
    text-decoration: underline;
}

.document-card {
    position: relative;
    margin: 2px;
}
</style>
