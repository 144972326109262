export default [
    {
        path: '/transaction/:id',
        name: 'Transaction',
        component: () => import('./views/transaction.vue'),
    },
    {
        path: '/transaction/:id/documents',
        name: 'DocumentsEdit',
        component: () => import('./views/transaction-documents-edit'),
    },
    {
        path: '/ozow/success',
        name: 'OzowSuccess',
        component: () => import('./views/ozow-success.vue'),
    },
    {
        path: '/wallet',
        name: 'OzowSuccess2',
        component: () => import('./views/ozow-success.vue'),
    },
    {
        path: '/letterofcomfort/:code',
        name: 'LetterOfComfort',
        component: () => import('./views/letter-of-comfort'),
    },
    {
        path: '/pay/:id',
        name: 'PayWithTradeSafe',
        component: () => import('./views/pay'),
    },
]
