import config from '../../../config'
import errorHandler from '../error-handler'
import API from '_shared/api/api'
import ApolloClientWrapper from '_shared/apollo-client'

// Sign out on authentication error
errorHandler.authenticationError = function () {
    window.location = '/signed_out'
    console.log('auth error found')
}

// Instantiate Apollo Client
const clientWrapper = new ApolloClientWrapper(config.graphURL, config.csrfTokenURL)

// Catch apollo errors
clientWrapper.errorHandler = function (errors) {
    errorHandler.setApolloErrors(errors)
}

// Instantiate API
const api = new API(config, clientWrapper)

export default api
