<template>
    <div class="uk-card uk-card-default uk-margin-small">
        <PartyCard :party="party" :badge="badge" :industry="$store.state.txCreate.transaction.transaction.industry" />
    </div>
</template>
<script>
import PartyCard from '@/modules/common/components/party-card'
import mixinParty from '@/modules/common/mixin-party'
export default {
    components: { PartyCard },
    mixins: [mixinParty],
    computed: {
        party() {
            let context = this.$store.getters['context/context']

            let party = {}
            party.email = context.email
            party.role = this.$store.state.txCreate.parties.parties[0].role
            party.avatar = context.avatar
            party.details = {}
            party.owner = true
            party.acceptance = {
                accepted: true,
            }

            if (context.__typename && context.__typename.toUpperCase() === 'ORGANIZATION') {
                party.details.organization = {
                    name: `${context.name}`,
                    registration: context.registration,
                    taxNumber: context.taxNumber,
                    tradeName: context.tradeName,
                    type: context.type,
                }
            }
            if (context.__typename && context.__typename.toUpperCase() === 'USER') {
                party.details.user = {
                    givenName: context.givenName,
                    familyName: context.familyName,
                }
            }

            return party
        },
        badge() {
            if (this.partyIsOrganization(this.party)) {
                return {
                    text: 'Your business account',
                    type: 'success',
                }
            }
            if (this.partyIsUser(this.party) === 'USER') {
                return {
                    text: 'Your personal account',
                    type: 'success',
                }
            }
            return null
        },
    },
}
</script>
