import AmendmentAccept from './graphql/amendment/amendment-accept.gql'
import AmendmentCreate from './graphql/amendment/amendment-create.gql'
import AmendmentDecline from './graphql/amendment/amendment-decline.gql'
import AmendmentCancel from './graphql/amendment/amendment-cancel.gql'

export default class APITransactionAmendment {
    constructor(api) {
        this.api = api
    }

    async amendmentAccept(input) {
        const result = await this.api.client.mutate({
            mutation: AmendmentAccept,
            variables: {
                id: input.id,
            },
        })
        return result.data.amendmentAccept
    }
    async amendmentCreate(input) {
        const result = await this.api.client.mutate({
            mutation: AmendmentCreate,
            variables: {
                allocationId: input.allocationId,
                input: input.input,
            },
        })
        return result.data.amendmentCreate
    }
    async amendmentDecline(input) {
        const result = await this.api.client.mutate({
            mutation: AmendmentDecline,
            variables: {
                id: input.id,
                comment: input.comment,
            },
        })
        return result.data.amendmentDecline
    }
    async amendmentCancel(input) {
        const result = await this.api.client.mutate({
            mutation: AmendmentCancel,
            variables: {
                id: input.id,
                comment: input.comment,
            },
        })
        return result.data.amendmentCancel
    }
}
