<template>
    <div>
        <div class="uk-text-center uk-padding-small uk-margin">
            <h3 class="uk-text-secondary uk-margin-remove">Describe in detail what you are {{ userActionText }}</h3>
        </div>
        <div class="uk-flex uk-flex-center uk-flex-wrap uk-margin-small-right uk-margin-small-left">
            <div class="uk-card uk-card-default uk-card-body uk-width-5-6@m">
                <GL-TextInput
                    class="uk-width-1-1"
                    label="name"
                    name="name"
                    v-model="title"
                    :tooltip="transactionTitleTooltip"
                    :error-messages="validation.requiredField(title)"
                />
                <GL-TextAreaInput
                    class="uk-width-1-1 uk-margin-small-top"
                    name="description"
                    label="Description"
                    v-model="description"
                    tooltip="Give the transaction a detailed description. Provide as much detail as possible including the event that needs to take place in order for the funds to be released. You will be able to upload documents to this transaction later on (you can reference these documents in the description box below)."
                    :error-messages="validation.requiredField(description)"
                />
                <GL-SelectInput
                    class="uk-width-1-2@m uk-margin-small-top"
                    name="escrow-fee-allocation"
                    label="Who Will Pay The Escrow Fee?"
                    :option-list="feeAllocationOptions"
                    v-model="feeAllocation"
                    :error-messages="validation.requiredField(feeAllocation)"
                />
            </div>
        </div>
        <PartiesFees />
        <CreateTxNavButtons :disable-next="!allowProgress" />
    </div>
</template>

<script>
import PartiesFees from '../../common/parties-fees/index'
import validation from '@/scripts/transaction-create-field-validation'
import CreateTxNavButtons from '../../common/create-transaction-navigation'
import TransactionCreateMixin from '../../transaction-create-mixin'

export default {
    name: 'GeneralMilestoneDetails',
    components: { PartiesFees, CreateTxNavButtons },
    mixins: [TransactionCreateMixin],
    data() {
        return {
            validation,
        }
    },
    computed: {
        userActionText() {
            if (this.$store.state.txCreate.parties.parties[0].role === 'BUYER') {
                return 'buying'
            } else if (this.$store.state.txCreate.parties.parties[0].role === 'SELLER') {
                return 'selling'
            }
            return 'brokering'
        },
        transactionTitleTooltip() {
            switch (this.$store.state.txCreate.transaction.transaction.industry) {
                case 'FILMS_PRODUCTION':
                    return 'Give your transaction a name i.e. Baby Joe’s photo shoot etc. The transaction name can also include an invoice number or Purchase Order Number.'
                case 'EVENTS':
                    return 'Give your transaction a name i.e. Wedding planning  - Joe and Cate’s Big Day etc. The transaction name can also include an invoice number or Purchase Order Number.'
                case 'CONSTRUCTION':
                    return 'Give your transaction a name i.e. Renovation of Eagle Office Park Sandton. The transaction name can also include an invoice number or Purchase Order number.'
                case 'ART_ANTIQUES_COLLECTIBLES':
                    return 'Give your transaction a name i.e. The Starry Night by Vincent van Gogh. The transaction name can also include an invoice number or Purchase Order Number.'
                case 'CONTRACT_WORK_FREELANCING':
                    return 'Give your transaction a name i.e. Build new logo and CI portfolio for client Joe. The transaction name can also include an invoice number of Purchase Order Number.'
                case 'MERGERS_ACQUISITIONS':
                    return 'Give your transaction a name i.e. Sale of 35% of Joe (Pty) Ltd, Acquisition of 100% of Automated Drones Ltd or Investment opportunity in concentrating solar power systems company ThunderTech Inc etc.'
                default:
                    return 'Give your transaction a name i.e. BMW Z4 2010. The transaction name can also include an invoice number or Purchase Order number.'
            }
        },
        feeAllocationOptions() {
            return this.$store.getters['txCreate/feeAllocations']
        },
        allowProgress() {
            return !!(validation.partyDetailsComplete() && validation.basicDetailsComplete())
        },
    },
}
</script>

<style lang="scss" scoped>
$primary: #e50045;
.new-entry-button {
    color: $primary;
    &:hover {
        cursor: pointer;
        color: lighten($primary, 10%);
        text-decoration: underline;
    }
}
</style>
