<template>
    <div class="uk-margin-small-top">
        <div class="uk-text-center uk-text-large uk-margin">Load {{ index + 1 }} - Driver Details</div>
        <div class="uk-grid-small" uk-grid>
            <GL-TextInput class="uk-width-1-2@s" label="Vehicle Registration" v-model="fuelVehicleRegistration" />
            <GL-TextInput class="uk-width-1-2@s" label="Driver Name" v-model="fuelDriverName" />
            <GL-NumberInput :value="0" class="uk-width-1-2 uk-margin-top uk-invisible placeholder" />
            <GL-NumberInput :value="0" class="uk-width-1-2 uk-margin-top uk-invisible placeholder" />
        </div>
        <div class="uk-width-1-1 uk-margin-top uk-text-right uk-card-footer">
            <GL-Button
                text="previous"
                type="primary-outlined"
                class="uk-margin-small-right"
                @click="$emit('view-previous')"
            />
            <GL-Button
                text="next"
                disabled-text="next"
                :disabled="disableNext"
                type="primary"
                @click="$emit('complete-step')"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'DriverDetails',
    props: {
        index: {
            type: Number,
            required: true,
        },
    },
    computed: {
        fuelVehicleRegistration: {
            get() {
                return this.$store.state.txCreate.allocations.auxiliaryData[this.index].fuelVehicleRegistration
            },
            set(value) {
                this.$store.commit('txCreate/setAllocationAuxiliaryField', {
                    index: this.index,
                    key: 'fuelVehicleRegistration',
                    value,
                })
            },
        },
        fuelDriverName: {
            get() {
                return this.$store.state.txCreate.allocations.auxiliaryData[this.index].fuelDriverName
            },
            set(value) {
                this.$store.commit('txCreate/setAllocationAuxiliaryField', {
                    index: this.index,
                    key: 'fuelDriverName',
                    value,
                })
            },
        },
        disableNext() {
            return !(this.fuelDriverName && this.fuelVehicleRegistration)
        },
    },
}
</script>
<style scoped>
.placeholder {
    height: 64px;
}
</style>
