export default [
    {
        path: '/escrow-account',
        name: 'EscrowAccount',
        component: () => import('./views/escrow-account.vue'),
    },
    {
        path: '/escrow-account/deposit',
        name: 'EscrowAccountDeposit',
        component: () => import('./views/escrow-account-deposit.vue'),
    },
    {
        path: '/escrow-account/withdraw',
        name: 'EscrowAccountWithdraw',
        component: () => import('./views/escrow-account-withdraw.vue'),
    },
]
